<template>
  <div class="row gutter-1">
    <div class="activity-date">
      {{ activity.date }}
    </div>
    <div class="col-auto">
      <div class="activity-icon">
        <i class="sdicon-check" />
      </div>
    </div>
    <div :class="$viewport.lt.md ? 'col-12 pt-2' : 'col'">
      <h3 class="activity-title">
        Prospect qualified
      </h3>
      <div class="font-13">
        <div>
          <span class="font-weight-strong">
            <ProspectProfileLink :prospect="activity.data.lead" />
          </span>
          qualified for the screening criteria:
        </div>
        <div
          v-for="(criteria, key) in activity.data.qualified_criteria"
          :key="key"
        >
          <template v-if="criteria.key === 'move_in_date'">
            - {{ criteria.label }} {{ criteria.chosen_answer }} ({{ criteria.chosen_option }})
          </template>
          <template v-else-if="criteria.key === 'minimum_income_rent_ratio'">
            - {{ `${get(screeningDefinition, `${criteria.key}.label`)}:` }} {{ generateMinIncomeRentRatioAnswer(criteria) }}
          </template>
          <template v-else>
            - {{ criteria.label }} {{ criteria.chosen_answer }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import get from 'lodash.get';
import {
  screeningDefinition,
  generateMinIncomeRentRatioAnswer,
} from '@/utils/ScreeningUtil';
import ProspectProfileLink from '../../prospect/ProspectProfileLink.vue';

export default {
  components: { ProspectProfileLink },
  props: {
    activity: {
      required: true,
      type: Object,
    },
  },
  setup(_) {
    return {
      screeningDefinition,
      generateMinIncomeRentRatioAnswer,
      get,
    };
  },
};
</script>
<style lang="scss" scoped>
.activity-title {
  margin-bottom: 0.5rem;
}

.qualified-answers {
  color: gray-color();
}
</style>
