<template>
  <ElDialog
    :center="true"
    top="30vh"
    :show-close="false"
    :visible="visible"
    :fullscreen="$viewport.lt.md"
    width="545px"
    :title="reassignTitleRef"
    @close="$emit('close')"
  >
    <div>
      <div
        v-if="dialogState.isLoading"
        class="text-center"
      >
        <ElSpinner
          color="primary"
          :radius="50"
        />
      </div>
      <template v-else>
        <div>
          This showing is currently assigned to {{ currentUserRef.full_name }}
          <div class="d-flex font-13 align-items-center mt-1 mb-3">
            <SdEmailIndicator
              class="font-16"
              :email="currentUserRef.email"
            />
            <div
              v-if="currentUserRef.formatted_public_phone_number"
              class="dot bg-gray"
            />
            <SdPhoneIndicator
              v-if="currentUserRef.formatted_public_phone_number"
              class="font-16"
              :phone-number="currentUserRef.public_phone_number"
              :formatted-phone-number="currentUserRef.formatted_public_phone_number"
            />
          </div>
        </div>
        <div class="w-100">
          <SdFloatLabel>
            <ElSelect
              v-model="assigneeRef"
              value-key="label"
              :placeholder="reassignPlaceholderRef"
            >
              <ElOption
                v-for="(assignee, i) in assigneesListRef"
                :key="i"
                value-key="label"
                :disabled="i === 0 && !canBeSurrendered(schedule)"
                class="m-1"
                :label="`${assignee.label}`"
                :value="assignee"
              >
                <div
                  v-if="i !== 0"
                  class="user-tag d-inline"
                >
                  <i class="sdicon-user-tie user-tag-icon" />
                  {{ assignee.label }}
                </div>
                <div
                  v-else
                  class="row gutter-1 justify-content-between"
                >
                  <span class="col-auto">{{ assignee.label }}</span>
                  <span
                    v-if="!canBeSurrendered(schedule)"
                    class="col-auto"
                    style="color: #8492a6; font-size: 13px"
                  >
                    <template v-if="!schedule.property_market_id || !schedule.property_market_showing_agents_available">
                      Only available for properties within the supported areas.
                    </template>
                    <template v-else>
                      Only available up to
                      {{ getSettingValueByKey('min_time_to_schedule_tour', schedule.property_market_id) }}
                      minutes before the tour
                    </template>
                  </span>
                </div>
              </ElOption>
            </ElSelect>
          </SdFloatLabel>
        </div>
      </template>
      <WarningNoteBeforeAssign
        class="mb-3 mt-3"
        :visible="assigneeWarningNoteVisible"
        :user.sync="tourAssigneeResultsRef.user"
      />
      <div slot="footer">
        <div class="mt-4">
          <p v-show="assigneeRef === 'showdigs'">
            A showdigs agent will be assigned to this tour
          </p>

          <p
            v-if="assigneeRef && assigneeRef.value !== 'showdigs'"
            class="mb-1"
          >
            We will update if there are any changes or cancellations at
          </p>
          <div
            v-if="assigneeRef && assigneeRef.value !== 'showdigs'"
            class="d-flex font-13 align-items-center"
          >
            <SdEmailIndicator
              class="font-16"
              :email="assigneeRef.value.email"
            />
            <div
              v-if="assigneeRef.value.formatted_public_phone_number"
              class="dot bg-gray"
            />
            <SdPhoneIndicator
              v-if="assigneeRef.value.formatted_public_phone_number"
              class="font-16"
              :phone-number="assigneeRef.value.formatted_public_phone_number"
            />
          </div>
        </div>
        <div class="row justify-content-around mt-4">
          <ElButton
            size="medium"
            @click="$emit('close')"
          >
            Cancel
          </ElButton>
          <ElButton
            size="medium"
            type="primary"
            :loading="dialogState.isBtnLoading"
            :disabled="assigneeRef === null"
            @click="reassignOrSurrender"
          >
            Reassign
          </ElButton>
        </div>
      </div>
    </div>
  </ElDialog>
</template>

<script>
import {
  onMounted, reactive, ref, watch, onUnmounted,
} from '@vue/composition-api';
import get from 'lodash.get';
import WarningNoteBeforeAssign from '@/components/tour/WarningNoteBeforeAssign';
import { getTourByDateAndAssignee } from '@/utils/TourUtil';
import SdPhoneIndicator from '@/components/common/SdPhoneIndicator';
import SdEmailIndicator from '@/components/common/SdEmailIndicator';
import { canBeSurrendered } from '../../utils/ScheduleUtil';
import { getSettingValueByKey } from '../../utils/SettingUtil';
import { showErrorNotify, showSuccessNotify } from '../../utils/NotifyUtil';

export default {
  name: 'TourReassignDialog',
  components: {
    WarningNoteBeforeAssign,
    SdPhoneIndicator,
    SdEmailIndicator,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    schedule: {
      type: Object,
      required: true,
      default: () => {
      },
    },
  },

  setup(props, context) {
    const assigneeWarningNoteVisible = ref(false);
    const tourAssigneeResultsRef = ref({ user: {} });
    const dialogState = reactive(defaultDialogState);
    const currentUserRef = ref([]);
    const assigneeRef = ref(null);
    const assigneesListRef = ref(null);
    const reassignTitleRef = ref(props.schedule.is_open_house ? 'Reassign Open House' : 'Reassign Showing');
    const reassignPlaceholderRef = ref(props.schedule.is_open_house ? 'Reassign Open House to...' : 'Reassign tour to...');

    watch(() => assigneeRef.value, () => {
      if (!props.visible) {
        return;
      }

      showAssigneeWarningNote(props.schedule);
    });

    onMounted(() => {
      getAssigneeList();
    });

    return {
      canBeSurrendered,
      getSettingValueByKey,
      assigneeRef,
      currentUserRef,
      assigneesListRef,
      dialogState,
      selectedAssignee,
      reassignOrSurrender,
      showAssigneeWarningNote,
      assigneeWarningNoteVisible,
      tourAssigneeResultsRef,
      reassignPlaceholderRef,
      reassignTitleRef,
    };

    async function showAssigneeWarningNote(schedule) {
      assigneeWarningNoteVisible.value = false;
      if (assigneeRef?.value?.value === 'showdigs') {
        return;
      }

      const assigneeId = assigneeRef.value?.value?.id ?? props.schedule.assignee_id;
      const result = await getTourByDateAndAssignee(schedule, assigneeId);
      if (Object.keys(result).length > 0) {
        tourAssigneeResultsRef.value = {
          user: result,
        };
        assigneeWarningNoteVisible.value = true;
      }
    }

    async function getAssigneeList() {
      dialogState.isLoading = true;
      const response = await context.root.$store.dispatch('Auth/getTeamMembers', {
        paginate: 0,
        'scopes[verified]': 1,
      });
      const assigneeList = [{
        value: 'showdigs',
        label: 'Back to Showdigs',
      }];
      response.forEach((user) => {
        const assigneeId = get(assigneeRef.value, 'value.id', props.schedule.assignee_id);
        if (user.id === assigneeId) {
          currentUserRef.value = user;
        } else {
          assigneeList.push({
            value: {
              id: user.id,
              formatted_public_phone_number: user.formatted_public_phone_number,
              full_name: user.full_name,
              email: user.email,
            },
            label: user.full_name,
          });
        }
      });

      assigneesListRef.value = assigneeList;
      dialogState.isLoading = false;
    }

    async function selectedAssignee(assignee) {
      assigneeRef.value = assigneesListRef.value.filter((user) => user.value.id === assignee.id)[1];
    }

    async function reassignOrSurrender() {
      dialogState.isBtnLoading = true;
      if (assigneeRef.value.value === 'showdigs') {
        try {
          await context.root.$store.dispatch('Schedule/surrender', { id: props.schedule.id });
          context.emit('close', 'success');
          showSuccessNotify(context, 'Tour was successfully returned to showdigs');
        } catch (error) {
          if (!(error instanceof Error)) {
            return; // Validator failed
          }
          showErrorNotify(context, error.message);
        } finally {
          dialogState.isBtnLoading = false;
        }
      } else {
        try {
          await context.root.$store.dispatch('Schedule/reassign',
            {
              id: props.schedule.id,
              assignee_id: assigneeRef.value.value.id,
            });
          context.emit('close', 'success');
          showSuccessNotify(context, `Tour was successfully reassigned to ${assigneeRef.value.value.full_name}`);
        } catch (error) {
          if (!(error instanceof Error)) {
            return; // Validator failed
          }
          showErrorNotify(context, error.message);
        } finally {
          dialogState.isBtnLoading = false;
        }
      }
      await getAssigneeList();
      assigneeRef.value = null;
    }
  },
};

const defaultDialogState = {
  isLoading: true,
  isBtnLoading: false,
};
</script>
