<template>
  <SdPage
    id="prospects-page"
    :style="unit ? 'padding: 0;' : ''"
  >
    <template v-if="unit">
      <div class="prospects-header">
        <SdSearchInput
          placeholder="Search prospect"
          @input="(query) => filteredProspectQuery = query"
        />
        <div
          v-if="unit"
          class="ml-3"
        >
          <ElCheckbox
            v-model="filteredByActiveLeasePeriod"
            :disabled="!unit.active_lease_period"
            @change="filterProspects"
          >
            <span
              class="font-13"
              :class="unit.active_lease_period ? 'text-gray-dark' : 'text-gray'"
            >
              Current leasing period only
            </span>
          </ElCheckbox>
        </div>
        <div class="unit-prospects-dropdown-container">
          <ElSelect
            v-model="filteredProspectStatuses"
            multiple
            collapse-tags
            :disabled="uiFlags.isLoadingProspects"
            placeholder="All prospects"
            class="light-background"
            popper-class="unit-prospects-dropdown"
            @change="filterProspects"
          >
            <ElOption
              v-for="status in prospectStatusesOptions"
              :key="String(status.value)"
              :label="status.label"
              :value="status.value"
            />
          </ElSelect>
        </div>
      </div>
    </template>
    <SdLoadingLayer
      ref="loadingLayer"
      :init="init"
    >
      <template #loaded>
        <div
          v-if="!prospects.length"
          class="text-center mt-5"
        >
          <img src="@/assets/properties-empty-state.svg">
          <div class="font-weight-bold font-21 mt-5">
            No prospects found
          </div>
        </div>
        <ProspectListItem
          v-for="prospect in filteredProspects"
          :key="prospect.id"
          :is-rendered-from-unit-page="Boolean(unit)"
          :prospect="prospect"
          :with-schedule-tour-action="shouldHaveScheduleTourAction"
          @schedule-tour="$emit('schedule-tour', prospect)"
        />
      </template>
    </SdLoadingLayer>
  </SdPage>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api';
import get from 'lodash.get';
import SdLoadingLayer from '@/components/common/SdLoadingLayer';
import ProspectListItem from '@/components/prospect/ProspectListItem';
import Tour from '@/constants/Tour';
import MomentUtil from '@/utils/MomentUtil';
import Showing from '@/constants/Showing';
import Inquiry from '@/constants/Inquiry';
import SdSearchInput from '@/components/common/form/SdSearchInput.vue';

export default {
  components: {
    SdSearchInput,
    ProspectListItem,
    SdLoadingLayer,
  },
  props: {
    unit: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const { $route } = context.root;
    const propertyUnitId = $route?.params?.unit_id;
    const prospects = ref([]);
    const filteredProspects = computed(filterProspects);
    const filteredProspectQuery = ref('');
    const filteredByActiveLeasePeriod = ref(Boolean(props.unit.active_lease_period));
    const filteredProspectStatuses = ref([]);
    const prospectStatusesOptions = getProspectStatusOptions();
    const uiFlags = reactive({ isLoadingProspects: false });
    const isShowingProfileNotPublishListing = props.unit?.active_showing_profile?.type !== Showing.TYPE_PUBLISH_LISTING;
    const shouldHaveScheduleTourAction = ref(isShowingProfileNotPublishListing);

    return {
      init,
      prospects,
      prospectStatusesOptions,
      filteredProspectStatuses,
      filteredByActiveLeasePeriod,
      filteredProspectQuery,
      filteredProspects,
      filterProspects,
      filterBySearch,
      uiFlags,
      shouldHaveScheduleTourAction,
    };

    async function init() {
      setTimeout(() => { context.emit('stop-fetching'); }, 2000);
      context.emit('start-fetching');
      uiFlags.isLoadingProspects = true;
      try {
        await fetchProspects();
      } finally {
        context.emit('stop-fetching');
        uiFlags.isLoadingProspects = false;
      }
    }

    async function fetchProspects() {
      prospects.value = (await context.root.$store.dispatch('Unit/leads', propertyUnitId));
    }

    function filterByStatus(prospect) {
      const tourStatus = prospect.recent_inquiry?.schedule_inquiry?.tour?.status;
      const isTourIssue = ![Tour.STATUS_COMPLETED, Tour.STATUS_PENDING].includes(tourStatus);

      if (!filteredProspectStatuses.value[0]) {
        return true;
      }

      return filteredProspectStatuses.value.some((selection) => {
        if (
          (prospect.recent_inquiry.status === Inquiry.STATUS_COMPLETED && isTourIssue)
          && selection.includes('not-toured')
        ) {
          return true;
        } if (selection.includes(prospect.recent_inquiry.status)) {
          return true;
        }

        return false;
      });
    }

    function filterBySearch(prospect) {
      if (!filteredProspectQuery.value) {
        return true;
      }

      function prepareStringToFilter(query) {
        const NO_SPECIAL_CHARS_AND_SPACES_REGEX = /[^a-zA-Z0-9]/g;
        return String(query)
          .replace(NO_SPECIAL_CHARS_AND_SPACES_REGEX, '')
          .toLowerCase();
      }

      const relevantAttributes = ['phone_number', 'email', 'full_name'];
      const queryToFilterBy = prepareStringToFilter(filteredProspectQuery.value);
      const isMatched = relevantAttributes.some((attribute) => {
        const attributeValue = prospect[attribute] || '';
        return prepareStringToFilter(attributeValue).includes(queryToFilterBy);
      });
      return isMatched;
    }

    function filterByActiveLeasePeriod(prospect, activeLeaseStartDate) {
      if (!filteredByActiveLeasePeriod.value || !activeLeaseStartDate) {
        return true;
      }
      const prospectMatchedAt = MomentUtil(prospect.recent_inquiry.matched_at, null);
      return activeLeaseStartDate.isSameOrBefore(prospectMatchedAt);
    }

    function filterProspects() {
      const activeLeaseStartDateStr = get(props, 'unit.active_lease_period.created_at');
      const activeLeaseStartDate = activeLeaseStartDateStr ? MomentUtil(activeLeaseStartDateStr, null) : null;
      return prospects.value.filter((prospect) => filterBySearch(prospect)
        && filterByStatus(prospect)
        && filterByActiveLeasePeriod(prospect, activeLeaseStartDate));
    }

    function getProspectStatusOptions() {
      return [
        {
          label: 'Inquired',
          value: [Inquiry.STATUS_PENDING, Inquiry.STATUS_NEW],
        },
        {
          label: 'Scheduled',
          value: [Inquiry.STATUS_IN_PROGRESS, Inquiry.STATUS_SCHEDULED],
        },
        {
          label: 'Toured',
          value: [Inquiry.STATUS_COMPLETED],
        },
        {
          label: 'Not Toured',
          value: 'not-toured',
        },
      ];
    }
  },
};
</script>

<style lang="scss">
@import '@/styles/utils';

.unit-prospects-dropdown {
  @media (min-width: 541px) {
    width: 250px;
  }
}

#prospects-page {
  .unit-prospects-dropdown-container {
    margin-left: auto;
    @include dropdown-select();
  }

  .prospects-header {
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 1rem;
  }
}
</style>
