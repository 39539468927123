<template>
  <div
    class="sd-unit-action-buttons mt-auto d-flex justify-content-end unit-action-buttons-section w-100 mb-2"
  >
    <slot name="pre" />
    <UnitLink
      v-if="isUnitShowing"
      class="unit-link-btn"
      :unit="unit"
    />
    <ElButton
      v-if="unit.showing_enabled"
      @click="handleAddInquiryClick"
    >
      <i class="font-12 sdicon-user-plus" />
      New Inquiry
    </ElButton>
    <ElButton
      v-if="shouldShowUnitActivateActionButtons && unit.showing_enabled"
      @click="handleStopClick"
    >
      <i class="font-12 dot bg-red mx-1" />
      Stop Showing
    </ElButton>
    <ElButton
      v-if="shouldShowUnitActivateActionButtons && isPauseEnabled"
      @click="handlePauseClick"
    >
      <i class="font-10 sdicon-pause" />
      Pause Showing
    </ElButton>
    <ElButton
      v-if="shouldShowUnitActivateActionButtons && !unit.showing_enabled && unit.deleted_at === null"
      :disabled="unit.property.is_null_location"
      @click="handleStartClick"
    >
      <i class="font-12 sdicon-play" />
      Start Showing
    </ElButton>
    <ElButton
      v-if="isEditVisible"
      @click="handleEditClick"
    >
      <i class="font-14 sdicon-pencil ml-n1" />
      Edit
    </ElButton>
  </div>
</template>

<script>
import get from 'lodash.get';
import { computed } from '@vue/composition-api';
import UnitLink from '@/components/unit/UnitLink.vue';
import Showing from '@/constants/Showing';

export default {
  name: 'UnitActionButtons',
  components: { UnitLink },
  props: {
    isEditVisible: {
      type: Boolean,
    },
    shouldShowUnitActivateActionButtons: {
      type: Boolean,
    },
    isUnitShowing: {
      type: Boolean,
    },
    unit: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const { unit } = props;
    const isPauseEnabled = computed(computePauseEnabled);

    return {
      isPauseEnabled,
      handleStartClick,
      handlePauseClick,
      handleStopClick,
      handleAddInquiryClick,
      handleUnpauseClick,
      handleEditClick,
    };

    function computePauseEnabled() {
      const showingEnabled = unit.showing_enabled;
      const isNotCurrentlyPaused = !get(unit, 'last_showing_profile.paused_until');
      const isNotFuturePaused = !get(unit, 'last_showing_profile.future_pause');
      const isShowingTypeNotPublishListing = get(unit, 'active_showing_profile.type') !== Showing.TYPE_PUBLISH_LISTING;

      return isShowingTypeNotPublishListing && showingEnabled && (isNotCurrentlyPaused && isNotFuturePaused);
    }

    function handleAddInquiryClick() {
      context.emit('add-inquiry');
    }

    function handleStartClick() {
      context.emit('start-showing');
    }

    function handlePauseClick() {
      context.emit('pause-showing');
    }

    function handleUnpauseClick() {
      context.emit('unpause-showing');
    }

    function handleStopClick() {
      context.emit('stop-showing');
    }

    function handleEditClick() {
      context.emit('edit-unit');
    }
  },
};
</script>
<style lang="scss">
.sd-unit-action-buttons {
  .unit-link-btn {
    display: inline-flex;
    padding: 0 8px;
    height: 32px;
    align-items: center;
    font-size: 13px;
    border-radius: 4px;
    background-color: gray-color('lighter');
    margin-right: 10px;
    border: 1px solid gray-color('light');
    color: theme-color("primary");
    &:hover {
      opacity: 0.75;
    }
  }

  button {
    display: inline-flex;
    padding: 0 8px;
    height: 32px;
    align-items: center;
    font-size: 13px;
    border-radius: 4px;
    background-color: gray-color('lighter');
    background-color: theme-color('primary-superlight');
    border: 1px solid theme-color('primary-lightest');
    color: theme-color("primary");
    &:hover {
      opacity: 0.75;
    }
  }
}
</style>
