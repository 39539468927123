<template>
  <router-link
    :to="{ name: 'app.tours.show', params: { id: tour.id } }"
    class="tour card-item-with-hover-shadow font-14 row no-gutters"
  >
    <div class="col-lg-4 col-12 align-self-center pl-3 pt-3 pb-0 py-lg-3 mr-0">
      <div class="d-flex align-items-center mb-1">
        <span :class="shortDateFormat ? 'font-21' : 'font-17'">
          {{ shortDateFormat ? tour.tour_at.toDisplayShortTimeFormat() : tour.tour_at.toDisplayWithDayFormatAndYear() }}
          <template v-if="tour.is_open_house">
            <span class="px-1">-</span>
            {{ tour.tour_until.toDisplayShortTimeFormat() }}
          </template>
        </span>
        <span
          v-if="tour.is_video"
          class="video-tag"
        >
          <i class="sdicon-video" />
        </span>
        <el-tooltip
          :disabled="$viewport.lt.md"
          content="Open House Tour"
          placement="top"
        >
          <span
            v-if="tour.is_open_house"
            class="indicator-tag"
          >
            <i class="sdicon-group" />
          </span>
        </el-tooltip>
        <el-tooltip
          :disabled="$viewport.lt.md"
          content="Self Showing"
          placement="top"
        >
          <span
            v-if="tour.is_self_showing"
            class="indicator-tag"
          >
            <i class="sdicon-key self-showing-key font-15" />
          </span>
        </el-tooltip>
      </div>
      <span
        v-if="withTourIdIndicator"
        class="text-gray-dark mr-1"
      >
        #{{ tour.id }}
      </span>
      <template v-if="!isRenderedFromUnitPage">
        <router-link
          :underline="true"
          :to="{ name: 'app.units.show', params: { id: tour.property_id, unit_id: tour.unit_id } }"
        >
          {{ `${tour.property_address} ${tour.unit_name ? ' - ' + tour.unit_name : ''}` }}
        </router-link>
        <i
          v-if="tour.has_identity_verification"
          class="sdicon-fingerprint text-success font-17"
        />
      </template>
    </div>

    <div
      class="col-lg col-12 align-self-center pl-3 pt-1 pt-lg-3 pb-0 py-lg-3 border-left"
    >
      <div
        v-if="tour.is_self_showing"
        class="d-flex align-items-center"
      >
        Self Showing
      </div>
      <div
        v-else
        class="d-flex align-items-center"
      >
        <span class="text-gray-dark mr-1">Agent:</span>
        <div v-if="!tour.is_taken_over">
          <img
            src="@/assets/icon.svg"
            class="avatar-icon"
          >
          Showdigs
        </div>
        <div
          v-else
          class="d-flex"
        >
          <SdImageWithFallback
            :src="tour.assignee_avatar"
            class="avatar-icon mr-1"
          >
            <template
              v-for="slotName in ['loading-placeholder', 'loading-error']"
              :slot="slotName"
            >
              <div
                v-if="slotName"
                :key="`${slotName}-placeholder`"
                class="font-10 d-flex align-items-center justify-content-center avatar-icon"
                :style="{
                  backgroundColor: `#${agentInitials.color}`,
                  color: agentInitials.textColor,
                }"
              >
                {{ agentInitials.initials }}
              </div>
            </template>
          </SdImageWithFallback>
          {{ tour.assignee_name }}
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div
          v-if="leads.general.length"
          :class="leads.general.length > 1 ? 'mt-1' : 'd-flex align-items-center flex-wrap mt-1'"
        >
          <span class="text-gray-dark mr-2">{{ $pluralize('Prospect', leads.general.length) }}:</span>
          <div
            v-for="lead in leads.general"
            :key="`tour-list-item-lead-${lead.id}-${lead.created_at}`"
            class="lead"
          >
            <ProspectProfileLink
              class="mr-1"
              :prospect="lead"
            />
            <ProspectStatusIcon
              :prospect="lead"
              :is-open-house="tour.is_open_house"
            />
            <SdPhoneIndicator
              with-icon
              :phone-number="lead.phone_number"
              :formatted-phone-number="lead.formatted_phone_number"
            />
          </div>
        </div>
        <div
          v-if="leads.canceled.length"
          :class="leads.canceled.length > 1 ? 'mt-1' : 'd-flex align-items-center flex-wrap mt-1'"
        >
          <span class="text-gray-dark mr-2">
            {{ $pluralize('Prospect', leads.canceled.length) }}
            No
            {{ $pluralize('Show', leads.canceled.length) }}:
          </span>
          <div
            v-for="(lead, index) in leads.canceled"
            :key="`${lead.id}-i${index}-cancelled`"
            class="lead"
          >
            <ProspectProfileLink
              class="mr-1"
              :prospect="lead"
            />
            <ProspectStatusIcon
              :prospect="lead"
              :is-open-house="tour.is_open_house"
            />
            <SdPhoneIndicator
              with-icon
              :phone-number="lead.phone_number"
              :formatted-phone-number="lead.formatted_phone_number"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-auto col-12 ml-auto align-self-center mt-0 mt-lg-1 mb-2 mb-lg-0 pl-3 pr-1 align-items-center">
      <router-link
        v-if="tour.survey_result_id"
        :to="{ name: 'app.tours.show', params: { id: tour.id } }"
        class="mr-md-3 mr-1 underline"
      >
        <ElButton size="small">
          View report
        </ElButton>
      </router-link>
      <SdStatusBadge :type="tour.tour_status" />
      <ElDropdown
        ref="actionsDropDown"
        trigger="click"
        hide-on-click
        class="actions pointer"
        @click.native.prevent
      >
        <div>
          <i
            class="sdicon-more-vert font-24"
            :class="!availableActions.any ? 'disabled' : ''"
          />
        </div>
        <ElDropdownMenu class="no-popper">
          <ElDropdownItem
            v-if="availableActions.reschedule"
            @click.native="emitShowDialog('isRescheduleDialogVisible')"
          >
            Reschedule
          </ElDropdownItem>
          <ElDropdownItem
            v-if="availableActions.takeOver || (!tour.is_taken_over &&
              (tour.tour_status === ScheduleStatuses.STATUS_PENDING || tour.tour_status === ScheduleStatuses.STATUS_LOOKING_FOR_AGENT))"
            :class="{ 'pb-2': !availableActions.takeOver }"
            class="available-takeover"
            :disabled="!availableActions.takeOver"
            @click.native="emitShowDialog('isTakeOverDialogVisible')"
          >
            Take over
            <div v-if="!availableActions.takeOver">
              You can only take over a tour up to {{ takeoverThresholdMinutes }} minutes in advance
            </div>
          </ElDropdownItem>
          <ElDropdownItem
            v-if="availableActions.becomeRegular"
            @click.native="emitShowDialog('isBecomeRegularTourDialogVisible')"
          >
            Convert to Showdigs agent showing
          </ElDropdownItem>
          <ElDropdownItem
            v-if="availableActions.becomeSelf"
            @click.native="emitShowDialog('isBecomeSelfTourDialogVisible')"
          >
            Convert back to self-showing
          </ElDropdownItem>
          <ElDropdownItem
            v-if="availableActions.reassign"
            @click.native="emitShowDialog('isReassignDialogVisible')"
          >
            Reassign
          </ElDropdownItem>
          <ElDropdownItem
            v-if="availableActions.cancel"
            @click.native="emitShowDialog('isCancelDialogVisible')"
          >
            Cancel {{ tour.is_open_house ? 'open house' : 'tour' }}
          </ElDropdownItem>
        </ElDropdownMenu>
      </ElDropdown>
    </div>
  </router-link>
</template>

<script>
import { ScheduleStatuses } from '@/constants/Schedule';
import SdStatusBadge from '@/components/common/SdStatusBadge';
import {
  canBeCancelled, canBecomeRegularTour, canBecomeSelfTour, canBeReassigned, canBeRescheduled,
} from '@/utils/ScheduleUtil';
import Plan from '@/constants/Plan';
import Tour from '@/constants/Tour';
import { computed } from '@vue/composition-api';
import ProspectStatusIcon from '@/components/tour/ProspectStatusIcon';
import { groupLeadsByStatus } from '@/utils/LeadsUtil';
import { getAvatarInitials } from '@/utils/AvatarUtil';
import SdPhoneIndicator from '@/components/common/SdPhoneIndicator';
import SdImageWithFallback from '@/components/common/SdImageWithFallback.vue';
import { getSettingValueByKey } from '../../utils/SettingUtil';
import ProspectProfileLink from '../prospect/ProspectProfileLink.vue';

export default {
  name: 'TourListItem',
  components: {
    ProspectStatusIcon,
    SdStatusBadge,
    ProspectProfileLink,
    SdImageWithFallback,
    SdPhoneIndicator,
  },
  props: {
    isRenderedFromUnitPage: {
      type: Boolean,
    },
    tour: {
      type: Object,
      required: true,
    },
    withTourIdIndicator: {
      type: Boolean,
      required: false,
      default: false,
    },
    shortDateFormat: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const plan = context.root.$store.getters['Auth/plan'];
    const isBasicPlan = plan.name === Plan.BASIC;
    const takeoverThresholdMinutes = getSettingValueByKey('threshold_for_takeover');
    const availableActions = computed(computeAvailableActions);
    const leads = groupLeadsByStatus(props.tour);
    const agentInitials = getAvatarInitials(props.tour.assignee_name);

    return {
      ScheduleStatuses,
      Tour,
      emitShowDialog,
      availableActions,
      takeoverThresholdMinutes,
      isBasicPlan,
      leads,
      agentInitials,
    };

    function emitShowDialog(visibilityProperty) {
      context.emit('show-dialog', visibilityProperty);
    }

    function computeAvailableActions() {
      const tour = props.tour;
      const ableToTakeOverStatuses = [
        ScheduleStatuses.STATUS_PENDING,
        ScheduleStatuses.STATUS_LOOKING_FOR_AGENT,
        ScheduleStatuses.STATUS_ABOUT_TO_EXPIRE,
        ScheduleStatuses.STATUS_SCHEDULED,
        Tour.STATUS_CANCELLED_SURRENDERED,
      ];
      const availableActions = {
        reschedule: canBeRescheduled(tour),
        takeOver: tour.can_be_taken_over && ableToTakeOverStatuses.includes(tour.tour_status),
        reassign: canBeReassigned(tour),
        cancel: canBeCancelled(tour),
        becomeRegular: canBecomeRegularTour(tour),
        becomeSelf: canBecomeSelfTour(tour),
      };

      availableActions.any = availableActions.reschedule || availableActions.takeOver || availableActions.reassign || availableActions.cancel;

      return availableActions;
    }
  },
};
</script>

<style lang="scss">
.no-popper {
  .popper__arrow {
    display: none;
  }
}

.available-takeover {
  min-width: 208px;

  div {
    font-size: $--font-size-bit-extra-small;
    line-height: $--font-size-small;
    color: gray-color(darker);
  }
}

.tour {
  .avatar-icon {
    position: relative;
    margin-top: 1px;
    width: 18px;
    height: 18px;
    border-radius: 18px;
  }

  .border-left {
    border-left: 1px solid gray-color(light);

    @include media-breakpoint-down(md) {
      border-left: none;
    }
  }

  .video-tag {
    width: 19px;
    height: 19px;
    margin-left: 0.75rem;
    background: theme-color('blue');
    border-radius: $app-border-radius;
    color: $white;
    display: flex;
    font-size: 6px;
    justify-content: center;
    align-items: center;
  }

  .indicator-tag {
    width: 20px;
    height: 20px;
    margin-left: 0.75rem;
    border-radius: $app-border-radius;
    background: theme-color('blue');
    border: 1px solid theme-color('blue');
    color: $white;
    display: flex;
    font-size: 10px;
    justify-content: center;
    align-items: center;
  }

  .assignee-chip {
    border-radius: $app-border-radius;
    background: rgba(theme-color('purple'), 0.2);
    color: theme-color('purple');
    padding: 0.125rem 0.25rem;

    >img {
      width: 14px;
      height: 14px;
    }
  }

  .lead {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin-right: 0.75rem;
      margin-bottom: 0.25rem;
    }

    &.compact {
      margin: 0 -4px;
    }
  }

  .actions {
    top: 5px;

    i {
      &.disabled {
        color: gray-color(light) !important;
      }

      &:not(.disabled) {
        transition: $transition-base;

        &:hover {
          color: $link-color !important;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .actions {
      position: absolute;
      right: 0;
    }
  }
}
</style>
