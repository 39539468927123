<template>
  <div id="showing-profile-pre-screening">
    <div v-if="!isDisabledType && !isCustomType">
      <div
        v-for="(criteria, index) in screeningCriteria"
        :key="index"
        class="text-gray-dark font-17 detail"
      >
        <label>{{ criteria.label }}:</label>
        <span class="text-prewrap">{{ criteria.value }}</span>
      </div>
    </div>
    <div v-if="isCustomType">
      <p class="text-gray-dark">
        <strong>Enabled</strong>:
        We will ask prospects questions to determine whether they qualify for the criteria you choose below.
        Some prospects will not be able to tour, depending on their answers.
      </p>
      <p class="text-gray-dark">
        All of these are optional - you can choose what criteria you want us to pre-screen for.
      </p>
    </div>
    <div
      v-if="isDisabledType"
      class="text-gray-dark"
    >
      <p class="text-gray-dark">
        <strong>Disabled</strong>:
        Prospects will not be shown the pre-screening criteria, and will not be required to answer screening
        questions before scheduling a tour.
      </p>
      <p class="text-gray-dark mb-0">
        Good for companies who already have their own screening process in place.
      </p>
    </div>
    <div v-if="isCustomType || prevVersion">
      <slot name="property-screening" />
      <ElDivider />
      <slot name="unit-screening" />
      <ElDivider />
      <slot name="additional-unit-screening" />
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import get from 'lodash.get';
import { truncateText } from '@/utils/StringUtil';
import { DEFAULT_EMPTY_SCREENING_INPUT } from '@/constants/Unit';
import { resolveQualifiedAnswerFromCustomQuestion } from '@/utils/ScreeningCriteriaUtil';

export default {
  name: 'ShowingProfilePreScreening',
  props: {
    screeningTemplate: {
      type: Object,
      required: false,
      default: null,
    },
    prevVersion: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const screeningSummaryResolved = ref([]);
    const isDisabledType = ref(props.screeningTemplate?.title === 'Disabled');
    const isCustomType = ref(props.screeningTemplate?.title === 'Custom');
    const screeningCriteria = computed(computeScreeningCriteria);
    const screeningCriteriaComponentsRef = ref(null);

    getScreeningComponents();
    return {
      screeningSummaryResolved,
      isDisabledType,
      isCustomType,
      screeningCriteria,
    };

    async function getScreeningComponents() {
      screeningCriteriaComponentsRef.value = store.getters['ScreeningCriteria/get'] || (await store.dispatch('ScreeningCriteria/load'));
    }

    function computeScreeningCriteria() {
      if (!props.screeningTemplate) {
        return undefined;
      }
      const screeningCollection = [];

      const screeningTemplate = props.screeningTemplate;
      Object.keys(screeningCriteriaComponentsRef.value).forEach((scComponent) => {
        let value;
        if (screeningCriteriaComponentsRef.value[scComponent].model === 'Property') {
          value = screeningTemplate.title === 'Custom'
            ? get(props.unit, `property.screening_criteria.${scComponent}`)
            : get(screeningTemplate, `property_screening.${scComponent}`);
        } else {
          value = screeningTemplate.title === 'Custom'
            ? get(props.screeningTemplate, `screening_criteria.${scComponent}`)
            : get(screeningTemplate, `showing_screening.${scComponent}`);
        }
        const options = screeningCriteriaComponentsRef.value[scComponent].options;
        if (options) {
          value = options[value];
        }
        if (!value) {
          return;
        }
        let label = '';
        if (scComponent === 'move_in_cost') {
          label = 'Security Deposit';
        } else {
          label = scComponent.split('_').join(' ');
        }

        if (scComponent === 'move_in_cost') {
          value = `$${value}`;
        }

        if (scComponent === 'eviction_history') {
          label += ' policy';
        }

        if (scComponent === 'additional_criteria') {
          value = truncateText(value);
        }

        if (scComponent === 'smoking_policy' && value === DEFAULT_EMPTY_SCREENING_INPUT) {
          return;
        }
        screeningCollection.push({ label, value, key: scComponent });
      });

      if (screeningTemplate.custom_screening) {
        screeningTemplate.custom_screening.forEach((data, index) => {
          screeningCollection.push({
            label: data.custom_question,
            value: resolveQualifiedAnswerFromCustomQuestion(data.response_options),
          });
        });
      }

      return screeningCollection.length ? screeningCollection : null;
    }
  },
};
</script>
<style lang="scss">
#showing-profile-pre-screening {
  .detail {
    label {
      text-transform: capitalize;
      font-weight: $strong;
      margin: 0;
      line-height: 1rem;
    }

    span {
      display: block;
    }

    span {
      color: gray-color(darker);
    }
  }
}
</style>
