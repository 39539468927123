import Showing from '@/constants/Showing';

export const bedroomsOptions = ['Studio', '1', '2', '3', '4', '5', '6', '7', '8'];
export const bathroomsOptions = [0, 0.5, 0.75, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 11, 12];
export const DEFAULT_EMPTY_SCREENING_INPUT = 'Screening not required';
export const defaultUnitScreeningPayload = {
  move_in_cost: null,
  general_notes: null,
  availability_date: null,
  maximum_allowed_tenants: null,
  minimum_lease_length: null,
  move_in_date: null,
  screening_criteria: null,
};
export const editSectionsDefinitions = {
  property: {
    default: true,
    label: 'Property & Unit',
    name: 'property',
    isVisible: () => true,
  },
  general: {
    label: 'General',
    name: 'general',
    isVisible: ({ showingProfile }) => showingProfile,
  },
  prospects: {
    label: 'Prospects',
    name: 'prospects',
    isVisible: ({ showingProfile }) => showingProfile,
  },
  schedule: {
    label: 'Schedule',
    name: 'schedule',
    isVisible: ({ showingProfile }) => [Showing.TYPE_HIGH_AVAILABILITY, Showing.TYPE_RESTRICTED, Showing.TYPE_OPEN_HOUSE]
      .includes(showingProfile.type),
  },
  showings: {
    label: 'Showings',
    name: 'showings',
    isVisible: ({ showingProfile }) => showingProfile,
  },
};
