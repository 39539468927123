<template>
  <ElDialog
    id="condition-report-edit-dialog"
    :center="true"
    :visible.sync="isVisible"
    :width="$viewport.lt.md ? '100vw' : '500px'"
  >
    <div class="text-center">
      <div class="font-21 mb-2 font-weight-strong">
        Need to make any changes to report #{{ conditionReportRef.id }}?
      </div>
      <div class="details font-15 text-center">
        <div>
          To update any details for this report or cancel it,
        </div>
        <div>
          please contact our support team at
        </div>
        <div class="d-flex justify-content-center font-15">
          <SdEmailIndicator
            with-icon
            class="font-15"
            :email="Showdigs.SUPPORT_EMAIL"
          />
          &nbsp;or at&nbsp;
          <SdPhoneIndicator
            with-icon
            class="font-15"
            :phone-number="Showdigs.SUPPORT_PHONE_NUMBER_HREF"
            :formatted-phone-number="Showdigs.SUPPORT_PHONE_NUMBER"
          />
        </div>
      </div>
    </div>
  </ElDialog>
</template>
<script>

import { ref } from '@vue/composition-api';
import Showdigs from '@/constants/Showdigs';
import SdPhoneIndicator from '@/components/common/SdPhoneIndicator';
import SdEmailIndicator from '@/components/common/SdEmailIndicator';

export default {
  name: 'ConditionReportEditDialog',
  components: { SdEmailIndicator },
  props: {},
  setup(props, context) {
    const { $router, $route } = context.root;
    const isVisible = ref(false);
    const conditionReportRef = ref({});
    return {
      isVisible,
      open,
      conditionReportRef,
      Showdigs,
    };
    function open(conditionReport) {
      conditionReportRef.value = conditionReport;
      isVisible.value = true;
    }
  },
};
</script>

<style lang="scss">
#condition-report-edit-dialog {
  .details {
    line-height: 22px;
    letter-spacing: -0.02em;
  }
}
</style>
