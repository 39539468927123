<template>
  <div>
    <ElTooltip content="Prospects calling this number will be able to schedule tours at this property">
      <a
        :href="`tel:${showingProfile.formatted_phone_line}`"
        class="font-15"
      >
        <i class="sdicon-phone-fill text-green font-12" />
        {{ showingProfile.formatted_phone_line }}</a>
    </ElTooltip>
    <SdCopyToClipboard
      :copy="showingProfile.formatted_phone_line"
      class="font-12 text-secondary p-1 ml-1 copy-box"
      copied-class="copy-box-copied"
      input-id="unit-phone-line"
      text-copied="copied"
      text="copy"
      icon="sdicon-copy"
      icon-copied="sdicon-copy"
    />
  </div>
</template>

<script>

import SdCopyToClipboard from '@/components/common/SdCopyToClipboard';

export default {
  name: 'UnitPhoneLine',
  components: { SdCopyToClipboard },
  props: {
    showingProfile: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
  },
};
</script>

<style lang="scss">
.copy-box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: gray-color('lighter');
  width: 70px;
  height: 18px;
  border-radius: 4px;
  text-indent: 16px;
  position: relative;
  i {
    position: absolute;
    left: -10px;
  }
}

.copy-box-copied {
  i {
    color: theme-color('primary');
  }
}
</style>
