<template>
  <div class="row gutter-1">
    <div class="activity-date">
      {{ activity.date }}
    </div>
    <div class="col-auto">
      <div class="activity-icon">
        <i class="sdicon-phone" />
      </div>
    </div>
    <div :class="$viewport.lt.md ? 'col-12 pt-2' : 'col'">
      <h3 class="activity-title">
        Incoming Phone Line Call
      </h3>
      <div class="font-13">
        <div>
          <span
            v-if="activity.data.lead.full_name"
            class="font-weight-strong"
          >
            <ProspectProfileLink :prospect="activity.data.lead" />
          </span>
          <span
            v-else
            class="font-weight-strong"
          >
            (Unknown prospect)
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProspectProfileLink from '../../prospect/ProspectProfileLink.vue';

export default {
  components: { ProspectProfileLink },
  props: {
    activity: {
      required: true,
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped></style>
