<template>
  <el-alert
    type="warning"
    show-icon
    :closable="false"
  >
    Please check that the address you entered is correct
    <div v-if="suggestedAddress">
      Perhaps try instead: {{ suggestedAddress }}
    </div>
  </el-alert>
</template>

<script>
export default {
  name: 'PropertyInvalidAddressAlert',
  props: {
    suggestedAddress: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
