<template>
  <span
    v-if="!!email"
    class="sd-email-indicator font-13"
    :class="bgClass"
    @click.stop=""
  >
    <a
      v-if="withEmailText"
      :href="`mailto:${email}`"
      :title="email"
      class="d-flex align-items-center px-0 py-0 font-size-inherit"
    >
      <i
        v-if="withIcon"
        class="sdicon-mail position-relative font-size-inherit"
      />
      <span :class="ellipsisClass">{{ email }}</span>
    </a>
  </span>
</template>
<script>

export default {
  name: 'SdEmailIndicator',
  props: {
    withIcon: {
      type: Boolean,
      default: false,
    },
    withEmailText: {
      type: Boolean,
      default: true,
    },
    bgClass: {
      type: String,
      default: 'white',
    },
    email: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const ellipsisClass = props.email?.includes('@convo.zillow.com') ? 'elipsis elipsis-left' : 'elipsis';

    return {
      ellipsisClass,
    };
  },
};
</script>

<style lang="scss" scoped>
.sd-email-indicator {
  i {
    margin-right: 2px;
    transform: scale(0.9);
  }

  .prospect-verified {
    width: 12px;
    height: 12px;
  }

  a{
    position: relative;
    color: theme-color("primary");
    text-decoration: none;

    i{
      color: inherit;
    }

    &:hover {
      color: theme-color("primary-dark");
    }
  }
}
</style>
