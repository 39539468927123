<template>
  <div class="row gutter-1 property-deleted-activity">
    <div class="activity-date">
      {{ activity.date }}
    </div>
    <div class="col-auto">
      <div class="activity-icon">
        <i class="sdicon-trash text-white" />
      </div>
    </div>
    <div :class="$viewport.lt.md ? 'col-12 pt-2': 'col'">
      <h3>
        Property has been deleted
      </h3>
      <span>By {{ activity.causer_full_name }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activity: {
      required: true,
      type: Object,
    },
  },
};
</script>
<style lang="scss">
    .property-deleted-activity {

        h3,
        .activity-date{
            color: $white !important;
        }
    }
</style>
