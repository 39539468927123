<template>
  <div class="sd-unit-page-unit-hero">
    <section class="sd-unit-page-unit-hero-image-section">
      <div class="sd-unit-page-unit-hero-image-section">
        <div class="sd-unit-page-unit-hero-image-section-placeholder">
          <SdImageWithFallback
            v-if="unitPicture"
            :src="unitPicture"
            :alt="unit.property.short_address"
          >
            <template #loading-error>
              <i class="sdicon-home font-48" />
            </template>
          </SdImageWithFallback>
          <div
            v-if="activeDays"
            class="sd-unit-page-unit-hero-active-days-container d-flex flex-column text-white justify-content-center align-items-center"
          >
            <span class="font-weight-bold font-15">{{ activeDays }} </span>
            <span class="font-13">Active {{ $pluralize('day', activeDays) }} </span>
          </div>
        </div>
      </div>
    </section>
    <section class="d-flex flex-column sd-unit-page-unit-hero-main-section px-3 pt-2 justify-content-around">
      <div class="d-flex">
        <ElTooltip
          :visible-arrow="false"
          placement="bottom-end"
          :disabled="!isUnitShowing || $viewport.lt.md"
        >
          <template slot="content">
            Open property listing page
          </template>
          <h2
            class="mb-0"
            :class="[
              $viewport.lt.md ? 'font-18 mt-1' : '',
              !isUnitShowing ? 'text-gray-dark' : ''
            ]"
          >
            <a
              v-if="isUnitShowing"
              target="_blank"
              :href="`https://${unit.landing_page_url}`"
            >
              {{ unitName }}
            </a>
            <template v-else>
              {{ unitName }}
            </template>
          </h2>
        </ElTooltip>
        <ElTooltip
          v-if="get(unit, 'active_lease_period.has_identity_verification', false)"
          content="AI Identity Verification Enabled"
        >
          <i class="sdicon-fingerprint text-success font-26 mx-2" />
        </ElTooltip>
        <div
          v-if="$viewport.gt.md"
          class="d-flex mt-auto ml-auto"
        >
          <ElButton
            size="small"
            type="primary"
            @click="handleOrderAReportClick"
          >
            Order a Report
          </ElButton>
        </div>
      </div>
      <div>
        <div
          v-if="unit.active_lease_period"
          :class="[$viewport.lt.md ? 'font-15 mb-1' : 'font-17 mb-3']"
        >
          <UnitActivityCounters :unit="unit" />
        </div>
        <div
          :class="$viewport.lt.md ? 'sd-unit-hero-status-container-mobile' : 'sd-unit-hero-status-container'"
          class="d-flex mr-auto flex-wrap"
        >
          <VacancyStatus
            v-if="isUnitShowing && isShowingTypeNotPublishListing"
            :is-vacant="lastShowingProfile.is_vacant"
            class="mr-2 mb-2"
          />
          <SyncStatus
            v-if="unit.origin_id"
          />
          <UpcomingToursStatus
            v-if="isUnitShowing && isShowingTypeNotPublishListing"
            class="mr-2 mb-2"
            :count="upcomingToursCount"
          />
        </div>
      </div>
      <div
        v-if="$viewport.gt.md"
        class="sd-unit-hero-bottom-container d-flex justify-content-end align-items-center mt-1 pt-2 flex-wrap"
      >
        <UnitActionButtons
          :should-show-unit-activate-action-buttons="false"
          class="d-flex mb-2"
          :unit="unit"
          :is-unit-showing="isUnitShowing"
          :is-edit-visible="unit.deleted_at === null"
          @add-inquiry="emitAddInquiry"
          @stop-showing="emitStopShowing"
          @pause-showing="emitPauseShowing"
          @unpause-showing="emitUnpauseShowing"
          @start-showing="emitStartShowing"
          @edit-unit="emitEdit"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import get from 'lodash.get';
import pluralize from 'pluralize';
import SdImageWithFallback from '@/components/common/SdImageWithFallback';
import UnitActivityCounters from '@/components/unit/UnitActivityCounters';
import Showing from '@/constants/Showing';
import VacancyStatus from './VacancyStatus';
import UpcomingToursStatus from './UpcomingToursStatus';
import SyncStatus from './SyncStatus';
import UnitActionButtons from './UnitActionButtons';

export default {
  name: 'UnitHero',
  components: {
    UnitActivityCounters,
    VacancyStatus,
    UpcomingToursStatus,
    SyncStatus,
    UnitActionButtons,
    SdImageWithFallback,
  },
  props: {
    unit: {
      type: Object,
      required: true,
    },
    isUnitShowing: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const { unit } = props;
    const unitPicture = computed(computeUnitPicture);
    const upcomingToursCount = computed(computedUpcoming);
    const lastShowingProfile = unit.last_showing_profile || {};
    const isOpenHouse = unit.last_showing_profile?.is_open_house;
    const isShowingTypeNotPublishListing = get(unit, 'active_showing_profile.type') !== Showing.TYPE_PUBLISH_LISTING;
    const activeDays = get(unit, 'time_in_market');
    const unitName = `${unit.property.short_address}${unit.name ? ` - ${unit.name}` : ''}`;
    return {
      unitName,
      unitPicture,
      lastShowingProfile,
      upcomingToursCount,
      isOpenHouse,
      handleOrderAReportClick,
      emitAddInquiry,
      emitStopShowing,
      emitStartShowing,
      emitPauseShowing,
      emitUnpauseShowing,
      emitEdit,
      get,
      activeDays,
      isShowingTypeNotPublishListing,
    };

    function computeUnitPicture() {
      const unitPicture = get(unit, 'images[0]');
      if (unitPicture && unitPicture?.includes?.('http')) {
        return unitPicture;
      }
      return get(unit, 'property.picture_url');
    }

    function computedUpcoming() {
      const upcomingToursCount = get(unit, 'last_showing_profile.upcoming_tours_count', 0);
      const isOpenHouse = get(unit, 'last_showing_profile.is_open_house', 0);
      const text = isOpenHouse ? 'open house' : 'tour';
      const tourText = pluralize(text, upcomingToursCount);
      return `${upcomingToursCount || 'No'} Upcoming ${tourText}`;
    }

    function handleOrderAReportClick() {
      context.emit('order-report');
    }

    function emitEdit() {
      context.emit('edit-unit');
    }

    function emitAddInquiry() {
      context.emit('add-inquiry');
    }

    function emitStartShowing() {
      context.emit('start-showing');
    }

    function emitPauseShowing() {
      context.emit('pause-showing');
    }

    function emitUnpauseShowing() {
      context.emit('unpause-showing');
    }

    function emitStopShowing() {
      context.emit('stop-showing');
    }
  },
};
</script>

<style lang="scss" scoped>

.sd-unit-page-unit-hero-main-section {
  flex: 1;
}

.sd-unit-page-unit-hero-main-section-container {
  position: relative;
}

.sd-unit-hero-status-container {
  border-bottom: 1px solid gray-color('lighter');
}

.sd-unit-hero-status-container-mobile {
  padding-top: 0.5rem;
  border-top: 1px solid gray-color('lighter');
}

.sd-unit-hero-bottom-container {
  overflow-x: auto;
}

.sd-unit-page-unit-hero-image-section-placeholder {
  background-color: gray-color("light");
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: theme-color("primary-dark");
  }

  img {
    z-index: 1;
  }
}

.sd-unit-page-unit-hero {
  display: flex;
  background-color: $white;
  border-radius: 4px;
  overflow: hidden;

  .sd-unit-page-unit-hero-image-section {
    position: relative;
    width: 238px;
    height: 178px;

    img {
      width: 238px;
      height: 178px;
      object-fit: cover;
      object-position: top center;
    }

    .sd-unit-page-unit-hero-active-days-container {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 70px;
      height: 71px;
      background-color: rgba(gray-color("darker"), 0.75);
      border-radius: 4px;
    }
  }
}

@media (max-width: 768px) {
  .sd-unit-page-unit-hero {
    flex-direction: column;
  }

  .sd-unit-page-unit-hero .sd-unit-page-unit-hero-image-section {
    width: 100%;
  }

  .sd-unit-page-unit-hero-image-section .sd-unit-page-unit-hero-image-section img {
    width: 100%;
    height: 192px;
    object-fit: cover;
  }
}
</style>
