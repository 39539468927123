<template>
  <ElDialog
    id="move-in-condition-report-prompt"
    :center="true"
    top="30vh"
    :fullscreen="$viewport.lt.md"
    :show-close="false"
    :visible="visible"
    width="90%"
    @close="$emit('close')"
  >
    <div class="text-center">
      <div class="mb-4">
        <SdArt
          class="m-auto"
          size="106px"
          art="periodic"
        />
      </div>
      Now would be a good time to perform a <strong>move-in condition report</strong>.
      Our experienced agents can help with that.
    </div>
    <div slot="footer">
      <el-button
        size="medium"
        @click="$emit('close')"
      >
        Maybe later
      </el-button>
      <RouterLink :to="{name:'app.condition-report.move_in_out', params:{ id: unit.property_id, unit_id: unit.id}}">
        <el-button
          type="primary"
          size="medium"
          class="ml-3"
        >
          Order a report
        </el-button>
      </RouterLink>
    </div>
  </ElDialog>
</template>

<script>
import SdArt from '@/components/common/SdArt';

export default {
  components: { SdArt },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    unit: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    return {};
  },
};
</script>
<style lang="scss">
#move-in-condition-report-prompt {
    .el-dialog {
        max-width: 545px;

        .el-dialog__header {
            padding: 0;
        }

        .el-dialog__body {
            padding-bottom: 1rem;
        }
    }
}
</style>
