<template>
  <div class="row gutter-1">
    <div class="activity-date">
      {{ activity.date }}
    </div>
    <div class="col-auto">
      <div class="activity-icon">
        <i class="sdicon-user-plus" />
      </div>
    </div>
    <div :class="$viewport.lt.md ? 'col-12 pt-2' : 'col'">
      <h3 class="activity-title">
        New Inquiry
        <router-link
          v-if="activity.schedule_id"
          :to="{ name: 'app.tours.show', params: { id: activity.schedule_id } }"
          target="_blank"
          class="activity-id"
        >
          # {{ activity.schedule_id }}
        </router-link>
      </h3>
      <div
        v-if="activity.lead_full_name"
        class="activity-data "
      >
        <span class="label">
          Prospect
        </span>
        <span class="value">
          <ProspectProfileLink
            v-if="activity.lead_full_name && activity.lead_id"
            :key="`${activity.original_date.unix()}-${activity.type}`"
            :prospect="{
              id: activity.lead_id,
              full_name: activity.lead_full_name,
            }"
          />
        </span>
      </div>

      <div
        v-if="activity.lead_email"
        class="activity-data"
      >
        <span class="label">
          Mail
        </span>
        <SdEmailIndicator :email="activity.lead_email" />
      </div>
      <div
        v-if="activity.tour_at"
        class="activity-data"
      >
        <span class="label">
          Date
        </span>
        <span class="value">
          {{ activity.tour_at }}
        </span>
      </div>

      <div
        v-if="activity.lead_phone_number"
        class="activity-data"
      >
        <span class="label">
          Phone
        </span>
        <SdPhoneIndicator
          :phone-number="activity.lead_phone_number"
          :formatted-phone-number="activity.lead_formatted_phone_number"
          class="value"
        />
      </div>
      <div
        v-if="activity.comments"
        class="activity-comments"
      >
        "{{ activity.comments }}"
      </div>
    </div>
  </div>
</template>
<script>
import SdEmailIndicator from '@/components/common/SdEmailIndicator';
import SdPhoneIndicator from '@/components/common/SdPhoneIndicator';
import ProspectProfileLink from '../../prospect/ProspectProfileLink.vue';

export default {
  components: { ProspectProfileLink, SdPhoneIndicator, SdEmailIndicator },
  props: {
    activity: {
      required: true,
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
.property-activities .activity-card h3.activity-title {
  margin-bottom: 0.5rem;
}
</style>
