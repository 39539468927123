<template>
  <ElDialog
    id="unpause-showing-dialog"
    :center="true"
    top="30vh"
    :show-close="false"
    :fullscreen="$viewport.lt.md"
    :visible="visible"
    width="400px"
    @close="$emit('close')"
  >
    <div slot="title">
      <h2 class="mb-1">
        Delete property
      </h2>
    </div>
    <div class="row d-flex justify-content-center">
      <p class="mt-2 text-center font-weight-normal">
        Are you sure you want to delete<br> <b>{{ property.short_address }}</b>
      </p>
      <p class="mt-1 mb-0 text-center font-15">
        No reports, emails, and reminders will be generated about this property. Note that this is irreversible.
      </p>
    </div>
    <div slot="footer">
      <el-button
        size="medium"
        @click="$emit('close')"
      >
        Cancel
      </el-button>
      <el-button
        type="primary"
        size="medium"
        :loading="uiFlags.isLoading"
        @click="deleteProperty"
      >
        Yes, delete
      </el-button>
    </div>
  </ElDialog>
</template>

<script>
import { reactive } from '@vue/composition-api';

import { redirectToPropertiesPage } from '@/router';
import { showErrorNotify } from '../../utils/NotifyUtil';

export default {
  name: 'UnitDeletePropertyDialog',
  props: {
    property: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const dispatch = context.root.$store.dispatch;
    const uiFlags = reactive({ isLoading: false });
    return {
      uiFlags,
      deleteProperty,
    };

    async function deleteProperty() {
      uiFlags.isLoading = true;
      try {
        await dispatch('Property/destroy', props.property.id);
        context.emit('close', 'success');
        redirectToPropertiesPage(context);
      } catch (error) {
        showErrorNotify(context, error.message);
      } finally {
        uiFlags.isLoading = false;
      }
    }
  },
};
</script>
