import momentUtil from '@/utils/MomentUtil';
import { getSettingValueByKey } from '@/utils/SettingUtil';
import { MAX_OCCUPIED_UNIT_SHOWING_WINDOW_DURATION } from '@/constants/UnitShowingProfile';

export {
  getShowingWindowsFromAvailabilityDays,
  getWeekdaysOptions,
  shouldDisableShowingWindow,
};

const weekdays = momentUtil.weekdays().map((day, index) => ({
  label: day,
  value: index,
}));

export const getShowdigsAssigneeUser = (id) => ({
  id,
  full_name: 'Showdigs',
  verified: true,
  is_calendar_connected: false,
});

function shouldDisableShowingWindow(showingWindow, timezone, market_id) {
  const now = momentUtil(null, timezone);

  if (showingWindow.from_hour === null) {
    return false;
  }

 if (now.day() !== showingWindow.week_day) {
    return false;
  }

  const minimumEffectiveTimeSetting = getSettingValueByKey('pause/deactivation_minimum_effective_time', market_id);
  const minimumEffectiveTime = now.clone().add(minimumEffectiveTimeSetting, 'minutes');
  const formArr = showingWindow.from_hour.split(':');
  const from = now.clone().set('hour', formArr[0]).set('minute', formArr[1]);

  return from.unix() <= minimumEffectiveTime.unix() && now.unix() < from.unix();
}

export function getAssigneesOptions(assignees, disabledAssigneeIds = []) {
  return [
    ...assignees.filter((assignee) => assignee.verified).map((assignee) => ({
      value: assignee.id,
      label: assignee.full_name,
      isCalendarConnected: assignee.is_calendar_connected,
      disabled: disabledAssigneeIds.includes(assignee.id),
    })),
  ];
}

function getShowingWindowsFromAvailabilityDays(availabilityDays) {
  const options = [];
  availabilityDays.forEach((day) => {
    const from = momentUtil(day.from_hour, null, 'HH:mm:ss');
    const fromMinutes = parseInt(from.format('m'));
    const to = momentUtil(day.to_hour, null, 'HH:mm:ss');
    const toMinutes = parseInt(to.format('m'));
    if (fromMinutes > 0 && fromMinutes < 30) {
      from.minutes(30);
    } else if (fromMinutes > 30) {
      from.add(1, 'hour').minutes(0);
    }
    if (toMinutes > 0 && toMinutes < 30) {
      to.minutes(0);
    } else if (toMinutes > 30) {
      to.minutes(30);
    }

    const optionsCount = Math.floor(to.diff(from, 'minutes') / 30) + 1;
    options[day.week_day] = optionsCount > 0 ? [...Array(optionsCount).keys()].map(() => {
      const r = from.clone();
      from.add(30, 'minute');
      return {
        label: r.format('h:mm A'),
        value: r.format('HH:mm:ss'),
      };
    }) : [];
  });
  return options;
}

export function createHourValidator(name, options, showingWindow, allShowingWindows, maxDuration = null) {
  return {
    validator: (rule, value, callback) => {
      if (!value) {
        return callback(new Error(`${name} is required`));
      }

      if (showingWindow.week_day === null) {
        return callback();
      }

      const allowedOptions = options[showingWindow.week_day].map((option) => option.value);

      if (!allowedOptions.includes(value)) {
        return callback(new Error('Out of operating hours'));
      }

      const isToHourAfterFromHour = showingWindow.to_hour > showingWindow.from_hour;

      if (!isToHourAfterFromHour) {
        return callback(new Error('Invalid time window'));
      }

      if (maxDuration) {
        const from = momentUtil(showingWindow.from_hour, null, 'HH:mm');
        const to = momentUtil(showingWindow.to_hour, null, 'HH:mm');
        const durationInMinutes = to.diff(from, 'minutes', true);

        if (durationInMinutes > MAX_OCCUPIED_UNIT_SHOWING_WINDOW_DURATION) {
          return callback(new Error(`Window can't be longer than ${MAX_OCCUPIED_UNIT_SHOWING_WINDOW_DURATION / 60} hours`));
        }
      }

      const isOverlapping = allShowingWindows.some((otherWindow) => {
        if (otherWindow === showingWindow) {
          return false;
        }

        const isSameDay = otherWindow.week_day === showingWindow.week_day;
        const hasOverlap = showingWindow.from_hour < otherWindow.to_hour && showingWindow.to_hour > otherWindow.from_hour;

        return isSameDay && hasOverlap;
      });

      if (isOverlapping) {
        return callback(new Error('Overlapping hours'));
      }

      return callback();
    },
  };
}

export function getSortedShowingWindows(showingWindows) {
  // sort by day of week and then by from_hour
  return showingWindows
    .sort((a, b) => {
      const bothHaveFromHour = a.from_hour && b.from_hour;
      if (a.week_day === b.week_day) {
        return bothHaveFromHour ? a.from_hour?.localeCompare(b.from_hour) : 0;
      }
      return a.week_day - b.week_day;
    });
}

function getWeekdaysOptions() {
  return [...weekdays];
}
