<template>
  <router-link
    :to="isLinkable ? link : {}"
    :tag="isLinkable ?'a' :'div'"
    :target="opensNewTab ? '_blank' : '_self'"
  >
    <slot name="prefix" />
    {{ name }}
    <slot name="suffix" />
  </router-link>
</template>

<script>
import { ref } from '@vue/composition-api';
import get from 'lodash.get';

export default {
  name: 'ProspectProfileLink',
  components: {},
  props: {
    prospect: {
      type: Object,
      required: true,
    },
    opensNewTab: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const name = ref(
      get(props.prospect, 'full_name')
      || get(props.prospect, 'lead_name')
      || get(props.prospect, 'lead_full_name')
      || `${get(props.prospect, 'first_name') || ''} ${get(props.prospect, 'last_name') || ''}`
      ,
    );
    const link = `/prospects/${props.prospect.id || props.prospect.lead_id}?tab=activitiy`;
    return {
      name,
      link,
      isLinkable: props.prospect.id || props.prospect.lead_id,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
