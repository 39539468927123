<template>
  <el-alert
    type="warning"
    show-icon
    :closable="false"
  >
    {{ customMessage }}
  </el-alert>
</template>

<script>
export default {
  name: 'PropertyUnsupportedAreaAlert',
  props: {
    customMessage: {
      type: String,
      required: false,
      default: 'Our agent network is not yet available at this location. '
      + 'You can still use Showdigs to manage tours at this property using your in-house staff.',
    },
  },
};
</script>
