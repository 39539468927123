import vfsFontsFiraSans from '@/assets/fonts/vfs_fonts_firaSans.js';

const fonts = {
  FiraSans: {
    normal: 'FiraSans-Regular.ttf',
    bold: 'FiraSans-Medium.ttf',
    italics: '',
    bolditalics: '',
  },
};

const styles = {
  title: {
    fontSize: 15,
    bold: true,
  },
  defaultFont: {
    font: 'FiraSans',
  },
  defaultColor: {
    color: '#434449',
  },
  paragraph: {
    fontSize: 12,
  },
  center: {
    alignment: 'center',
  },
};

export {
  vfsFontsFiraSans,
  fonts,
  styles,
};
