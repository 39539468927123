<template>
  <div class="sd-collapse">
    <div
      class="header"
      @click="toggle"
    >
      <div
        class="row align-items-center h-100 my-0"
      >
        <div class="col py-0">
          <slot name="header" />
        </div>
        <div class="col-auto py-0">
          <i :class="iconClasses" />
        </div>
      </div>
    </div>
    <SdSlideTransition>
      <div
        v-show="value"
        ref="content"
      >
        <slot />
      </div>
    </SdSlideTransition>
  </div>
</template>

<script>
import { computed, watch } from '@vue/composition-api';
import SdSlideTransition from './SdSlideTransition';

export default {
  name: 'SdCollapse',
  components: {
    SdSlideTransition,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    accordion: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, context) {
    const iconClasses = computed(computeIconClasses);

    const id = randomId();

    context.root.$on('collapse-open', onCollapseOpen);

    watch(() => props.value, watchValue);

    return {
      iconClasses,
      toggle,
    };

    function randomId() {
      return Math.random().toString(36).substring(2, 9);
    }

    function computeIconClasses() {
      return props.value ? 'sdicon-chevron-up text-primary' : 'sdicon-chevron-down text-secondary';
    }

    function toggle() {
      context.emit('input', !props.value);
    }

    function close() {
      context.emit('input', false);
    }

    function watchValue(value) {
      if (value && props.accordion) {
        context.root.$emit('collapse-open', id, props.accordion);
      }
    }

    function onCollapseOpen(openedCollapseId, accordion) {
      if (!props.accordion || accordion !== props.accordion || id === openedCollapseId) {
        return;
      }

      close();
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 0.5rem 0;
  cursor: pointer;
  transition: $transition-base;

  &:hover {
    background: gray-color('light');
  }

  &:active {
    background: gray-color('dark');
  }
}
</style>
