<template>
  <div>
    <ElFormItem
      :prop="isEdit ? 'showing_profile.application_form_url' : 'application_form_url'"
      :rules="rules.application_form_url"
      class="mb-4"
    >
      <el-tooltip
        :disabled="$viewport.lt.md"
        :visible-arrow="false"
        placement="right-start"
        popper-class="popover-panel"
      >
        <template slot="content">
          <i class="sdicon-info-circle" />
          <p>{{ resolveApplicationFormUrlTooltipText() }}</p>
        </template>
        <SdFloatLabel label="Link to your application form">
          <ElInput
            v-model="payloadRef.application_form_url"
            :disabled="isSyncApplicationUrlEnabled"
            placeholder="Link to your application form"
            @input="emitPayloadChanged"
          />
        </SdFloatLabel>
      </el-tooltip>
    </ElFormItem>
    <ElFormItem>
      <SdFloatLabel>
        <ElInput
          v-model="generalNotesRef"
          :rows="4"
          maxlength="4096"
          placeholder="Anything you'd like to emphasise to prospects who are about to tour this property"
          type="textarea"
          @input="emitFormStateChanged"
        />
      </SdFloatLabel>
    </ElFormItem>
  </div>
</template>

<script>

import { ref } from '@vue/composition-api';
import Regex from '@/constants/Regex';
import { BusinessSourceTypes } from '@/constants/BusinessSource';
import { cleanAndMergeWithDefaultPayload } from '@/utils/payloadUtil';

const regexConstants = Regex;

export default {
  name: 'ShowingProfileNotesToProspect',
  props: {
    generalNotes: {
      type: String,
      required: false,
      default: '',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    showingProfile: {
      type: Object,
      default: null,
    },
    business: {
      type: Object,
      default: null,
    },
    payload: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const generalNotesRef = ref(props.generalNotes);
    const rules = getRules();
    const payloadRef = ref(cleanAndMergeWithDefaultPayload(props.showingProfile, defaultProfilePayload));

    if (props.payload?.application_form_url) {
      payloadRef.value.application_form_url = props.payload.application_form_url;
    }

    if (props.business.default_application_form_url && !payloadRef.value.application_form_url) {
      payloadRef.value.application_form_url = props.business.default_application_form_url;
    }
    const isSyncApplicationUrlEnabled = Boolean(resolveIsSyncApplicationUrlEnabled());
    emitPayloadChanged();
    return {
      emitFormStateChanged,
      generalNotesRef,
      rules,
      payloadRef,
      emitPayloadChanged,
      isSyncApplicationUrlEnabled,
      resolveApplicationFormUrlTooltipText,
    };
    function emitFormStateChanged() {
      context.emit('change', generalNotesRef);
    }

    function resolveIsSyncApplicationUrlEnabled() {
      return props.business.sync_application_url;
    }

    function getRules() {
      return {
        application_form_url: {
          validator: validateApplicationUrl,
        },
      };
    }

    function emitPayloadChanged() {
      const applicationFormUrl = payloadRef.value.application_form_url;
      if (applicationFormUrl && !applicationFormUrl.match(regexConstants.http_protocol)) {
        payloadRef.value.application_form_url = `https://${applicationFormUrl}`;
      }
      context.emit('update-showing-profile-payload', {
        application_form_url: payloadRef.value.application_form_url,
      });
    }

    function resolveApplicationFormUrlTooltipText() {
      if (!isSyncApplicationUrlEnabled) {
        return 'We\'ll follow up after each tour prompting prospects to apply using this link.';
      }
      const businessSource = context.root.$store.getters['BusinessSource/get'];
      const integrationType = businessSource?.propexo_integration_id || businessSource?.client_id ? BusinessSourceTypes[businessSource.source_type.toUpperCase()] : '';
      return `The application URL is synchronized with ${integrationType}.`;
    }

    function validateApplicationUrl(rule, value, callback) {
      if (!value) {
        return callback(new Error('Application URL is required'));
      }
      if (!value.match(regexConstants.url)) {
        return callback(new Error('Application URL is not valid'));
      }
      if (!value.match(regexConstants.http_protocol)) {
        payloadRef.value.application_form_url = `https://${value}`;
      }
      return callback();
    }
  },
};

const defaultProfilePayload = {
  application_form_url: '',
};
</script>

<style lang="scss"></style>
