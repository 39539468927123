<template>
  <div class="row">
    <div class="col-6">
      <SdFloatLabel>
        <ElSelect
          v-model="heatingTags"
          value-key="value"
          :reserve-keyword="true"
          filterable
          class="select-amenities"
          :disabled="isDisabled"
          :placeholder="resolveGroupLabel('HEATING_SYSTEM')"
          fit-input-width
          clearable
          @change="handleChange"
        >
          <ElOption
            v-for="tag in filteredGroupedTags('HEATING_SYSTEM')"
            :key="tag.key"
            :label="tag.label || tag.value"
            :value="tag"
          />
        </ElSelect>
      </SdFloatLabel>
    </div>
    <div class="col-6">
      <SdFloatLabel>
        <ElSelect
          v-model="coolingTags"
          value-key="value"
          :reserve-keyword="true"
          filterable
          class="select-amenities"
          :disabled="isDisabled"
          :placeholder="resolveGroupLabel('COOLING_SYSTEM')"
          fit-input-width
          clearable
          @change="handleChange"
        >
          <ElOption
            v-for="tag in filteredGroupedTags('COOLING_SYSTEM')"
            :key="tag.key"
            :label="tag.label || tag.value"
            :value="tag"
          />
        </ElSelect>
      </SdFloatLabel>
    </div>
    <div class="col-6">
      <SdFloatLabel>
        <ElSelect
          v-model="parkingTags"
          value-key="value"
          :reserve-keyword="true"
          filterable
          class="select-amenities"
          :disabled="isDisabled"
          :placeholder="resolveGroupLabel('PARKING_TYPE')"
          fit-input-width
          clearable
          @change="handleChange"
        >
          <ElOption
            v-for="tag in filteredGroupedTags('PARKING_TYPE')"
            :key="tag.key"
            :label="tag.label || tag.value"
            :value="tag"
          />
        </ElSelect>
      </SdFloatLabel>
    </div>
    <div class="col-6">
      <SdFloatLabel>
        <ElSelect
          v-model="laundryTags"
          value-key="value"
          :reserve-keyword="true"
          filterable
          class="select-amenities"
          :disabled="isDisabled"
          :placeholder="resolveGroupLabel('LAUNDRY_TYPE')"
          fit-input-width
          clearable
          @change="handleChange"
        >
          <ElOption
            v-for="tag in filteredGroupedTags('LAUNDRY_TYPE')"
            :key="tag.key"
            :label="tag.label || tag.value"
            :value="tag"
          />
        </ElSelect>
      </SdFloatLabel>
    </div>
    <div class="col-12">
      <SdFloatLabel :active="utilityTags.length > 0">
        <ElSelect
          v-model="utilityTags"
          value-key="value"
          :reserve-keyword="true"
          class="select-amenities"
          :placeholder="resolveGroupLabel('UTILITY')"
          :disabled="isDisabled"
          fit-input-width
          multiple
          @change="handleChange"
        >
          <ElOption
            v-for="tag in filteredGroupedTags('UTILITY')"
            :key="tag.key"
            :label="tag.label || tag.value"
            :value="tag"
          />
        </ElSelect>
      </SdFloatLabel>
    </div>
    <div class="col-12">
      <SdFloatLabel :active="amenityTags.length > 0">
        <ElSelect
          v-model="amenityTags"
          value-key="value"
          :reserve-keyword="true"
          class="select-amenities"
          :placeholder="resolveGroupLabel('AMENITY')"
          :disabled="isDisabled"
          fit-input-width
          multiple
          @change="handleChange"
        >
          <ElOption
            v-for="tag in filteredGroupedTags('AMENITY')"
            :key="tag.key"
            :label="tag.label || tag.value"
            :value="tag"
          />
        </ElSelect>
      </SdFloatLabel>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import groupBy from 'lodash.groupby';
import SdFloatLabel from '@/components/common/form/SdFloatLabel.vue';

const modes = { add: 'ADD', edit: 'EDIT', activate_showing: 'ACTIVATE_SHOWING' };

export default

 {
  name: 'UnitTags',
  components: { SdFloatLabel },
  props: {
    unit: {
      type: Object,
      required: true,
    },
    propertyUnitIndex: {
      type: Number,
      default: 0,
    },
    selectedTags: {
      type: Array,
      default: (() => []),
    },
    selectableTags: {
      type: Array,
      default: (() => []),
    },
    mode: {
      type: String,
      validator: (mode) => Object.values(modes).includes(mode),
      required: true,
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const tags = ref([...new Set(addTagKeys(props.selectedTags.map((tag) => ({ value: tag.label || tag.value || tag, type: tag.type || 'AMENITY' }))))]);
    const amenityTags = ref([]);
    const utilityTags = ref([]);
    const heatingTags = ref([]);
    const coolingTags = ref([]);
    const parkingTags = ref([]);
    const laundryTags = ref([]);
    const groupedTags = resortGroups();
    const isIntegrationEnabled = store.getters['BusinessSource/isIntegrationEnabled'];
    const isSyndicated = context.root.$store.getters['Auth/businessSyndicated'];
    const isDisabled = isIntegrationEnabled && !isSyndicated && (Boolean(props.unit.listing_identifier) || Boolean(props.unit.origin_id));
    init();
    handleChange();
    return {
      isDisabled,
      tags,
      groupedTags,
      resolveFormItemProp,
      handleChange,
      resolveGroupLabel,
      amenityTags,
      utilityTags,
      heatingTags,
      coolingTags,
      parkingTags,
      laundryTags,
      filteredGroupedTags,
    };
    function init() {
      Object.values(props.selectedTags).forEach((tag) => {
        switch (tag.type) {
          case 'HEATING_SYSTEM':
            heatingTags.value = tag;
            cleanFromDuplicated('HEATING_SYSTEM', tag);
            break;
          case 'COOLING_SYSTEM':
            coolingTags.value = tag;
            cleanFromDuplicated('COOLING_SYSTEM', tag);

            break;
          case 'LAUNDRY_TYPE':
            laundryTags.value = tag;
            cleanFromDuplicated('LAUNDRY_TYPE', tag);
            break;
          case 'PARKING_TYPE':
            parkingTags.value = tag;
            cleanFromDuplicated('PARKING_TYPE', tag);
            break;
          case 'UTILITY':
            utilityTags.value.push(tag);
            cleanFromDuplicated('UTILITY', tag);
            break;
          case 'AMENITY':
            amenityTags.value.push(tag);
            cleanFromDuplicated('AMENITY', tag);
            break;
        }
      });
    }

    function filteredGroupedTags(groupName) {
      if (groupedTags && groupedTags[groupName]) {
        return groupedTags[groupName].filter((tag) => tag !== undefined);
      }
      return [];
    }

    function cleanFromDuplicated(groupName, tag) {
      const keysToDelete = [];
      Object.keys(groupedTags[groupName]).forEach((key) => {
        if (groupedTags[groupName][key].value === tag.value) {
          keysToDelete.push(key);
        }
      });
      delete groupedTags[groupName][keysToDelete[0]];
    }

    // Add a key to each tag to prevent collisions in the select dropdown
    function addTagKeys(tags) {
      return tags.map((tag) => ({ ...tag, key: `${tag.type}-${tag.value}-${tag.label}` }));
    }

    function resolveFormItemProp(key) {
      if (props.mode === modes.add) {
        return `units.${props.propertyUnitIndex}.${key}`;
      }

      if (props.mode === modes.edit) {
        return `property.units.${props.propertyUnitIndex}.${key}`;
      }

      return key;
    }

    function uniteIntoTags() {
      // eslint-disable-next-line max-len
      tags.value = [...amenityTags.value, ...utilityTags.value, heatingTags.value, coolingTags.value, parkingTags.value, laundryTags.value]
        .filter((item) => Boolean(item) && item.value);
    }

    function handleChange(value) {
      uniteIntoTags();
      props.unit.tags = tags.value;
      context.emit('update-unit', { tags: tags.value });
    }

    function resolveGroupLabel(type) {
      let label = type.replace('_', ' ');
      label = label.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
      if (type === 'UTILITY') {
        label = 'Included Utilities';
      }
      if (type === 'AMENITY') {
        label = 'Amenities';
      }
      return label;
    }

    function resortGroups() {
      const groups = groupBy([...new Set([...addTagKeys(props.selectableTags), ...tags.value])], 'type');
      return {
        AMENITY: groups.AMENITY,
        COOLING_SYSTEM: groups.COOLING_SYSTEM,
        HEATING_SYSTEM: groups.HEATING_SYSTEM,
        UTILITY: groups.UTILITY,
        LAUNDRY_TYPE: groups.LAUNDRY_TYPE,
        PARKING_TYPE: groups.PARKING_TYPE,
      };
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-select__tags {

    .el-select-group__title {
      position: sticky;
      top: 0;
    }

    span:first-child {
      flex-wrap: wrap;
    }

    .el-tag {
    }
  }

  .el-select-dropdown__item {
    color: $gray-600;
  }

  .el-select-group__title {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    color: $gray-700;
    font-weight: 500;
    font-size: 16px;
  }
}

</style>
