import momentUtil from '@/utils/MomentUtil';
import has from 'lodash.has';
import get from 'lodash.get';

export const screenTypeKeys = {
  eviction_history: 'eviction_history',
  maximum_allowed_tenants: 'maximum_allowed_tenants',
  move_in_date: 'move_in_date',
  minimum_credit_score: 'minimum_credit_score',
  minimum_income_rent_ratio: 'minimum_income_rent_ratio',
  minimum_lease_length: 'minimum_lease_length',
  pet_policy: 'pet_policy',
  smoking_policy: 'smoking_policy',
  income_voucher: 'income_voucher',
  custom_question: 'custom_question',
};

export const screeningDefinition = {
  [screenTypeKeys.minimum_credit_score]: {
    label: 'Credit Score',
    icon: 'sdicon-dollar',
    iconSize: 17,
    question: 'What is your credit score?',
  },
  [screenTypeKeys.smoking_policy]: {
    label: 'Smoker',
    icon: 'sdicon-smoking',
    question: 'Do you smoke?',
    iconSize: 14,
  },
  [screenTypeKeys.pet_policy]: {
    label: 'Pets',
    icon: 'sdicon-paw',
    question: 'Do you have any pets?',
    iconSize: 17,
  },
  [screenTypeKeys.eviction_history]: {
    label: 'Eviction History',
    icon: 'sdicon-eviction',
    iconSize: 25,
    quesiton: 'Were you ever evicted from a property before?',
  },
  [screenTypeKeys.minimum_income_rent_ratio]: {
    label: 'Annual income',
    icon: 'sdicon-income',
    iconSize: 18,
  },
  [screenTypeKeys.minimum_lease_length]: {
    label: 'Min Lease Length',
    icon: 'sdicon-card',
    iconSize: 15,
    shouldHideInProfile: true,
  },
  [screenTypeKeys.maximum_allowed_tenants]: {
    label: 'Tenants',
    icon: 'sdicon-eviction',
    iconSize: 25,
    quesiton: 'How many tenants?',
    shouldHideInProfile: true,
  },
  [screenTypeKeys.move_in_date]: {
    label: 'Move In Date',
    icon: 'sdicon-eviction',
    iconSize: 25,
    quesiton: 'Move In Date',
    shouldHideInProfile: true,
  },
  [screenTypeKeys.income_voucher]: {
    label: 'Voucher',
    icon: 'sdicon-income',
    iconSize: 18,
  },
};

export function addAnswersAndFilterMissingKeys(screeningCriteria) {
  const result = {};

  for (const key in screenTypeKeys) {
    const screenTypeKey = screenTypeKeys[key];

    if (has(screeningCriteria, screenTypeKey)) {
      let answer = screeningCriteria[screenTypeKey];

      if (screenTypeKey === screenTypeKeys.move_in_date) {
        answer = answer.move_in_date;
      }

      result[key] = {
        ...screeningDefinition[key],
        answer: formatAnswerByKeyAndValue(key, answer),
      };
    }
  }

  return result;
}

function formatVoucherAnswer(criterion) {
  let answer = '';

  const metadata = get(criterion, `metadata.${screenTypeKeys.income_voucher}`, {});
  if (metadata.chosen_answer) {
    answer += `${metadata.label}: ${formatNumberToIncomeAmount(metadata.chosen_answer)}`;
  }

  return answer;
}

function formatNumberToIncomeAmount(value) {
  return `$${parseInt(value).toLocaleString()}`;
}

export function generateMinIncomeRentRatioAnswer(criterion) {
  const voucherAnswer = formatVoucherAnswer(criterion);
  return `${formatNumberToIncomeAmount(criterion.chosen_answer)} ${voucherAnswer !== '' ? `(${voucherAnswer})` : ''}`;
}

export function formatAnswerByKeyAndValue(key, answer) {
  if (!screeningDefinition[key] && key !== screenTypeKeys.custom_question) {
    return null;
  }

  if ([screenTypeKeys.minimum_income_rent_ratio, screenTypeKeys.income_voucher].includes(key)) {
    return formatNumberToIncomeAmount(answer);
  }

  return answer.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
}
