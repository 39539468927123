<template>
  <SdPage
    id="condition-reports-page"
    :style="isRenderedFromUnitPage ? 'padding: 0;' : ''"
  >
    <template v-if="!isRenderedFromUnitPage">
      <SdPageHeader
        :title="$viewport.lt.md ? 'Condition Reports' : ' ' "
      >
        <div :class="{ 'col-auto': !uiFlags.isMobile }">
          <SdActionButtons :actions="actions" />
        </div>
        <div class="cr-search-input-container">
          <ElInput
            v-model="searchQuery"
            placeholder="Search"
            size="small"
            clearable
            class="font-15"
          >
            <template #prefix>
              <i class="sdicon-magnifying-glass font-15" />
            </template>
          </ElInput>
        </div>
      </SdPageHeader>
      <ElTabs
        v-if="!searchQuery"
        :value="currentTab"
        stretch
        @tab-click="changeTab"
      >
        <ElTabPane
          v-for="(tab, index) in tabs"
          :key="index"
          :disabled="uiFlags.isLoadingConditionReports"
          :label="`${(tab.charAt(0).toUpperCase() + tab.slice(1)).replace('-', ' ')}`"
          :name="tab"
        />
      </ElTabs>
    </template>

    <div class="condition-reports-header d-flex justify-content-between">
      <ConditionReportsFilters
        v-model="filters"
        :disabled="uiFlags.isLoadingConditionReports"
        :with-statuses-filter="true"
        :has-sorting="true"
        :statuses-options="statusesOptions"
        @changeSort="changeReportsSorting"
        @init="$refs.loadingLayer.triggerInit()"
      />
    </div>

    <SdLoadingLayer
      ref="loadingLayer"
      :init="init"
    >
      <template #loaded>
        <div
          v-if="!filteredConditionReports.length && !uiFlags.isLoadingConditionReports && !searchQuery"
          class="row flex-column empty-reports mt-5"
          :class="{
            'mt-5': !isRenderedFromUnitPage && $viewport.lt.md,
            'mt-4': isRenderedFromUnitPage && $viewport.lt.md,
          }"
        >
          <img src="@/assets/properties-empty-state.svg">
          <div
            class="font-weight-bold text-center font-21 mt-5"
            :class="{
              'mt-5': !isRenderedFromUnitPage && $viewport.lt.md,
              'mt-3': isRenderedFromUnitPage && $viewport.lt.md,
              'font-21': $viewport.gt.md,
              'font-18': $viewport.lt.md
            }"
          >
            <template v-if="currentTab === 'requested'">
              No reports requested
            </template>
            <template v-else-if="currentTab === 'recently-completed'">
              No reports within the last month
            </template>
            <template v-else>
              No archived reports
            </template>
          </div>
          <div
            v-if="canOrderReports && !isRenderedFromUnitPage"
            class="text-center mt-2"
          >
            <ElTooltip
              content="Please renew your subscription"
              :disabled="!$store.getters['Auth/accountIsInactive']"
            >
              <ElButton
                type="primary"
                size="medium"
                :class="{
                  'mt-5': !isRenderedFromUnitPage && $viewport.lt.md,
                  'mt-3': isRenderedFromUnitPage && $viewport.lt.md,
                }"
                :disabled="$store.getters['Auth/accountIsInactive']"
                @click="orderReport"
              >
                Order a report
              </ElButton>
            </ElTooltip>
          </div>
        </div>
        <div
          v-if="uiFlags.isLoadingSearchResults"
          class="mt-5 text-center"
        >
          <ElSpinner color="primary" />
        </div>
        <div
          v-else-if="searchQuery && !filteredConditionReports.length"
          class="text-center mt-5"
        >
          <img src="@/assets/no-results-found.svg">
          <strong class="d-block mt-4 font-21">No results found for '{{ searchQuery }}'</strong>
        </div>
        <div v-else-if="filteredConditionReports.length">
          <ConditionReportListItem
            v-for="conditionReport in filteredConditionReports"
            :key="`condition-report-page-list-item-${conditionReport.id}-${conditionReport.created_at}`"
            :condition-report="conditionReport"
            :is-copied="uiFlags.copiedLinkId === conditionReport.id"
            @copy="copyReportLink"
            @open-edit-dialog="openEditDialog"
          />
          <div class="px-1 font-15 text-gray d-flex flex-wrap align-items-end mb-lg-0 mb-5 pb-lg-0 pb-4">
            <span>If you need to cancel a report or make any changes, please contact our support team at&nbsp;</span>
            <SdEmailIndicator
              class="font-15"
              :email="Showdigs.SUPPORT_EMAIL"
            />
            <span>&nbsp;or call us at&nbsp;</span>
            <SdPhoneIndicator
              class="font-15"
              :phone-number="Showdigs.SUPPORT_PHONE_NUMBER_HREF"
            />
          </div>
        </div>
      </template>
    </SdLoadingLayer>
    <ConditionReportEditDialog ref="conditionReportEditDialog" />
  </SdPage>
</template>

<script>
import {
  computed, reactive, ref, watch,
} from '@vue/composition-api';
import SdActionButtons from '@/components/common/SdActionButtons';
import ConditionReportListItem from '@/components/condition-report/ConditionReportListItem';
import Showdigs from '@/constants/Showdigs';
import ConditionReportEditDialog from '@/components/condition-report/ConditionReportEditDialog';
import momentUtil from '@/utils/MomentUtil';
import SdLoadingLayer from '@/components/common/SdLoadingLayer';
import SdEmailIndicator from '@/components/common/SdEmailIndicator';
import SdPhoneIndicator from '@/components/common/SdPhoneIndicator';
import ConditionReportsFilters from '@/components/condition-report/ConditionReportsFilters';
import { getReportsBadgeFormatStatus } from '@/utils/ConditionReportUtil';
import { getTabFromUrlAndLocalStorage, updateTabInUrlAndLocalStorage } from '@/utils/RouterUtil';
import get from 'lodash.get';

const tabs = ['requested', 'recently-completed', 'archived'];
const tabLabels = {
  requested: 'Requested',
  'recently-completed': 'Recently Completed',
  archived: 'Archived',
};
export default {
  name: 'ConditionReports',
  components: {
    SdEmailIndicator,
    SdPhoneIndicator,
    SdActionButtons,
    ConditionReportListItem,
    ConditionReportEditDialog,
    ConditionReportsFilters,
  },
  props: {
    isRenderedFromUnitPage: {
      type: Boolean,
    },
    canOrderReports: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const { $store, $route } = context.root;
    const isMobile = context.root.$viewport.lt.md;
    const uiFlags = reactive({
      isLoadingConditionReports: false,
      isLoadingSearchResults: false,
      isMobile,
      copiedLinkId: null,
      timeoutHolder: null,
    });
    const propertyUnitId = $route?.params?.unit_id;
    const propertyId = $route?.params?.id;
    const currentTab = ref(props.isRenderedFromUnitPage ? tabs[0] : getTabFromUrlAndLocalStorage({ router: context.root.$router, tabs }) || tabs[0]);
    const searchQuery = ref('');
    const actions = ref({});
    const conditionReports = ref([]);
    const filters = reactive({
      statuses: [],
      sorting: getDefaultSortByTab(currentTab.value),
    });
    const statusesOptions = computed(computeStatusesOptions);
    const filteredConditionReports = computed(computeFilteredReports);
    watch(searchQuery, runSearch);

    return {
      Showdigs,
      filters,
      filteredConditionReports,
      statusesOptions,
      uiFlags,
      actions,
      conditionReports,
      searchQuery,
      copyReportLink,
      orderReport,
      changeReportsSorting,
      tabs,
      tabLabels,
      changeTab,
      currentTab,
      SdLoadingLayer,
      init,
      openEditDialog,
    };

    async function init() {
      setTimeout(() => { context.emit('stop-fetching'); }, 2000);
      context.emit('start-fetching');
      uiFlags.isLoadingConditionReports = true;
      searchQuery.value = '';
      actions.value = {};
      const searchPayload = {};
      const monthAgo = momentUtil(null, momentUtil.tz.guess())
        .subtract(1, 'month')
        .toServerFormat();

      if (propertyUnitId) {
        searchPayload.units = [propertyUnitId];
      }

      if (!props.isRenderedFromUnitPage) {
        if (currentTab.value === 'requested') {
          searchPayload.scopes = {
            inProgress: true,
          };
        } else {
          searchPayload.scopes = {
            completed: true,
          };
          if (currentTab.value === 'recently-completed') {
            searchPayload.from_completed_date = monthAgo;
          }
          if (currentTab.value === 'archived') {
            searchPayload.to_completed_date = monthAgo;
          }
        }
      }

      if (filters.sorting) {
        searchPayload.order_by = filters.sorting;
      }

      const rawConditionReports = await $store.dispatch('ConditionReport/index', searchPayload);
      conditionReports.value = rawConditionReports.map(formatConditionReport);
      if (!props.isRenderedFromUnitPage || (props.isRenderedFromUnitPage && props.canOrderReports)) {
        actions.value = {
          orderReport: {
            buttonType: 'primary',
            label: 'Order a report',
            size: props.isRenderedFromUnitPage ? 'medium' : 'small',
            disabled: $store.getters['Auth/accountIsInactive'],
            tooltipText: $store.getters['Auth/accountIsInactive'] ? 'Please renew your subscription' : null,
            cb: () => orderReport(),
          },
        };
      }

      context.emit('stop-fetching');
      uiFlags.isLoadingConditionReports = false;
    }

    function openEditDialog(conditionReport) {
      context.refs.conditionReportEditDialog.open(conditionReport);
    }

    function getDefaultSortByTab(tabName) {
      const orderingPrefix = 'preferred_from';
      if (props.isRenderedFromUnitPage || ['recently-completed', 'archived'].includes(tabName)) {
        return `${orderingPrefix},desc`;
      }
      return `${orderingPrefix},asc`;
    }

    function orderReport() {
      context.root.$router.push({
        name: 'app.condition-report.add',
        params: {
          unit_id: propertyUnitId,
          id: propertyId,
        },
      });
    }

    async function runSearch() {
      uiFlags.isLoadingSearchResults = true;
      if (uiFlags.timeoutHolder) {
        clearTimeout(uiFlags.timeoutHolder);
      }
      uiFlags.timeoutHolder = setTimeout(async () => {
        filters.sorting = getDefaultSortByTab(currentTab.value);
        const rawConditionReports = await $store.dispatch('ConditionReport/index', {
          search_query: searchQuery.value,
        });
        conditionReports.value = rawConditionReports.map(formatConditionReport);
        uiFlags.isLoadingSearchResults = false;
      }, 500);
    }

    function copyReportLink(id) {
      const copyText = document.getElementById(`report-copy-input-${id}`);
      copyText.focus();
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      document.execCommand('copy');
      copyText.blur();
      uiFlags.copiedLinkId = id;
    }

    function formatConditionReport(conditionReport) {
      conditionReport.preferred_from = momentUtil(conditionReport.preferred_from || conditionReport.created_at, conditionReport.property.timezone);

      if (conditionReport.preferred_from && conditionReport.preferred_until) {
        const startTime = momentUtil(conditionReport.preferred_from, conditionReport.property.timezone).format('MMM Do YYYY h:mmA');
        const endTime = momentUtil(conditionReport.preferred_until, conditionReport.property.timezone).format('h:mmA');
        conditionReport.scheduled_showing_time = `${startTime}-${endTime}`;
      }

      return conditionReport;
    }

    function changeTab(tabComponent) {
      filters.statuses = [];
      if (props.isRenderedFromUnitPage) {
        currentTab.value = tabComponent;
        filters.sorting = getDefaultSortByTab(tabComponent);
        context.refs.loadingLayer.triggerInit();
      } else {
        const tab = tabComponent.name;
        currentTab.value = tab;
        filters.sorting = getDefaultSortByTab(tab);
        updateTabInUrlAndLocalStorage({ router: context.root.$router, tab });
        context.refs.loadingLayer.triggerInit();
      }
    }

    function changeReportsSorting() {
      const orderingPrefix = 'preferred_from';
      if (filters.sorting === `${orderingPrefix},desc`) {
        filters.sorting = `${orderingPrefix},asc`;
      } else {
        filters.sorting = `${orderingPrefix},desc`;
      }
      context.refs.loadingLayer.triggerInit();
    }

    function computeStatusesOptions() {
      const statusesOptions = conditionReports.value?.reduce((uniqueOptions, item) => {
        const label = get(getReportsBadgeFormatStatus(`condition-report-${item.state}`), 'label') || item.state?.capitalize?.();
        const value = item.state;
        const isDuplicate = uniqueOptions.some((option) => option.value === value);
        if (!isDuplicate) {
          uniqueOptions.push({ label, value });
        }
        return uniqueOptions;
      }, []);

      return statusesOptions;
    }

    function computeFilteredReports() {
      return conditionReports.value
        .filter((report) => {
          const statuses = filters.statuses;

          if (statuses.length === 0 || statuses.length === statusesOptions.value.length) {
            return true;
          }

          if (statuses.includes(report.state)) {
            return true;
          }

          return false;
        });
    }
  },
};
</script>

<style lang="scss">
@import '@/styles/utils';

#condition-reports-page {

  .condition-reports-header {
    display: flex;
    align-items: center;
    @include dropdown-select();
  }

  .loading-layer {
    margin-top: 10px;
  }

  .cr-search-input-container {
    margin-bottom: 4px;
    @include search-input-with-focus($with-mobile-small-icon-expander: true);
  }

  @include media-breakpoint-down(md) {
    @include generate-horizontal-tabs-mobile-view;
  }
}
</style>
