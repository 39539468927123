import get from 'lodash.get';
import map from 'lodash.map';

function searchTableData(dataTable, searchQuery, filters) {
  return dataTable.filter((row) => {
    if (searchQuery) {
      return searchTableRow(row, searchQuery, filters);
    }
    return true;
  });
}

function searchTableRow(row, searchQuery, filters) {
  let passed = false;
  for (const filter of filters) {
    const keys = filter.value.split('.*.');
    const filterField = get(row, keys[0]);
    if (filterField) {
      const lowerSearchQuery = searchQuery.toLowerCase();
      switch (filter.type) {
        case 'string':
          passed = filterField.toLowerCase().includes(lowerSearchQuery);
          break;
        case 'integer':
          passed = filterField.toString().toLowerCase().includes(lowerSearchQuery);
          break;
        case 'array':
          const plucked = map(filterField, keys[1]);
          passed = plucked.length && plucked.filter((item) => item.toLowerCase().includes(lowerSearchQuery)).length;
          break;
      }
      if (passed) {
        break;
      }
    }
  }
  return passed;
}

export { searchTableData, searchTableRow };
