export const PropertyType = {
  SingleFamilyHome: 'Single Family Home',
  Apartment: 'Apartment',
  Condo: 'Condo',
  Townhouse: 'Townhouse',
  Commercial: 'Commercial',
  Corporate: 'Corporate',
  Manufactured: 'Manufactured',
  MixedUse: 'Mixed-use',
  Student: 'Student',
  Vacation: 'Vacation',
  Affordable: 'Affordable',
  Senior: 'Senior',
  Other: 'Other',
};

export const BasicPropertyTypes = [
  PropertyType.SingleFamilyHome,
  PropertyType.Apartment,
  PropertyType.Condo,
  PropertyType.Townhouse,
];

export const ExtendedPropertyTypes = BasicPropertyTypes.concat([
  PropertyType.Commercial,
  PropertyType.Corporate,
  PropertyType.Manufactured,
  PropertyType.MixedUse,
  PropertyType.Student,
  PropertyType.Vacation,
  PropertyType.Affordable,
  PropertyType.Senior,
  PropertyType.Other,
]);
