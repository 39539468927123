<template>
  <ElDialog
    id="showing-report"
    ref="dialogContent"
    top="30vh"
    :custom-class="computedIsCongratsVersion ? `with-header` : ``"
    :center="true"
    :show-close="true"
    :visible="visible"
    :fullscreen="$viewport.lt.md"
    width="720px"
    @close="$emit('close')"
  >
    <h2
      ref="reportTitle"
      class="text-center font-28 mb-2"
      :class="computedIsCongratsVersion ? 'pt-3' : ''"
    >
      {{ computedIsCongratsVersion ? 'Great Work!' : defaultReportTitle }}
    </h2>
    <p
      ref="reportSubText"
      class="text-center font-15"
    >
      <span
        v-if="computedIsCongratsVersion"
      >
        Congratulations for finalizing the lease.
      </span>
      {{ defaultReportSubText }}
    </p>
    <div class="flex-column d-flex">
      <div class="report-card">
        <div
          class="highlights-card-content"
        >
          <div :class="$viewport.lt.md ? 'col p-0': 'col'">
            <p class="highlights-card-content-number font-32">
              {{ reportRef.active_days }}
            </p>
            <span
              class="text-gray-dark"
              :class="$viewport.lt.md ? 'font-11': 'font-15'"
            >Active {{ $pluralize('day', reportRef.active_days) }}</span>
          </div>
          <div :class="$viewport.lt.md ? 'col p-0': 'col'">
            <p class="highlights-card-content-number font-32">
              {{ reportRef.incoming_inquiries }}
            </p>
            <span
              class="text-gray-dark"
              :class="$viewport.lt.md ? 'font-11': 'font-15'"
            >Inquiries</span>
          </div>
          <div :class="$viewport.lt.md ? 'col p-0': 'col'">
            <p class="highlights-card-content-number font-32">
              {{ computedTotalTours }}
            </p>
            <span
              class="text-gray-dark"
              :class="$viewport.lt.md ? 'font-11': 'font-15'"
            >Total tours</span>
          </div>
        </div>
      </div>
      <ElDivider v-if="computedTotalTours !== 0 && reportRef.incoming_inquiries !== 0" />
      <div :class="$viewport.lt.md ? '': 'flex-row d-flex justify-content-between'">
        <div
          v-if="computedTotalTours !== 0"
          class="report-card"
          :class="reportRef.incoming_inquiries !== 0 && $viewport.gt.md ? '' : ''"
        >
          <div class="report-card-label">
            Who took tours
          </div>
          <div class="highlights-card-content mt-2">
            <div class="col">
              <Doughnut
                v-if="chartDataRef !== null"
                :chartdata="chartDataRef"
                :options="chartDataOptions"
              />
            </div>
          </div>
        </div>
        <ElDivider
          v-if="computedTotalTours !== 0 && reportRef.incoming_inquiries !== 0"
          :direction="$viewport.lt.md ? 'horizontal' :'vertical'"
        />
        <div
          v-if="reportRef.incoming_inquiries !== 0"
          class="report-card"
          :class="computedTotalTours !== 0 && $viewport.gt.md ? 'ml-3' : ''"
        >
          <div class="report-card-label">
            Top guest card sources
          </div>
          <div class="pr-2 pl-2 mt-2">
            <div class="row no-gutters justify-content-between font-15 text-gray-dark mb-2">
              <span>Source</span>
              <span>Inquiries</span>
            </div>
            <div
              v-for="(source, index) in computedInquirySources"
              :key="index"
              class="row no-gutters justify-content-between mb-2"
            >
              <span class="font-16 text-gray-dark font-weight-strong">{{ index+1 }}</span>
              <img
                :src="getInquirySourceImage(source.name)"
                class="source-icon ml-1"
              >
              <span class="font-15 ml-1 flex-grow-1">{{ source.name }}</span>
              <span class="font-16 mr-3 text-gray-darker font-weight-strong">{{ source.amountOfInquiries }}</span>
            </div>
          </div>
        </div>
      </div>
      <ElDivider v-if="reportRef.prospect_info" />
      <div
        v-if="reportRef.prospect_info"
        class="report-card"
      >
        <div class="report-card-label">
          Prospect info
        </div>
        <div class="pr-2 pl-2">
          <div class="row no-gutters justify-content-between">
            <div class="col-md-6 col-12 align-self-center">
              <span class="text-gray-dark font-13">Name</span>
              <p class="font-15">
                {{ reportRef.prospect_info.lead_full_name }}
              </p>
            </div>
            <div class="col-md-6 col-12 align-self-center">
              <span class="text-gray-dark font-13">Email</span>
              <p class="font-15">
                {{ reportRef.prospect_info.lead_email }}
              </p>
            </div>
          </div>
          <div class="row no-gutters mb-2">
            <div class="col-md-6 col-12 font-13">
              <span class="text-gray-dark">Source</span>
              <p class="font-15">
                {{ reportRef.prospect_info.source }}
              </p>
            </div>
            <div class="col-auto font-13">
              <span class="text-gray-dark">Agent</span>
              <div
                class="user-tag"
                :class="{'agent-user-tag': !reportRef.prospect_info.was_taken_over }"
              >
                <template v-if="reportRef.prospect_info.was_taken_over">
                  <img
                    v-if="reportRef.prospect_info.assignee_avatar"
                    class="user-avatar-small mr-1"
                    :src="reportRef.prospect_info.assignee_avatar"
                  >
                  <i
                    v-else
                    class="sdicon-user-tie user-tag-icon"
                  />
                </template>
                <img
                  v-else
                  class="user-avatar-small mr-1"
                  src="@/assets/icon.svg"
                >
                <span>{{ reportRef.prospect_info.assignee_name }}</span>
              </div>
            </div>
          </div>
          <TourCompletedFunnel
            v-if="reportRef && reportRef.prospect_info"
            :data="reportRef.prospect_info"
          />
        </div>
      </div>
    </div>
    <template
      slot="footer"
    >
      <ElButton
        type="primary"
        size="small"
        :disabled="uiFlags.isChartAnimationInProgress || uiFlags.isGeneratingReportPdf"
        :loading="uiFlags.isGeneratingReportPdf"
        icon="el-icon-download"
        @click="generateReportPdf"
      >
        Download Report
      </ElButton>
    </template>
  </ElDialog>
</template>

<script>
import pdfMake from 'pdfmake';
import { vfsFontsFiraSans, fonts, styles } from '@/utils/PDFUtil';
import { showErrorNotify } from '@/utils/NotifyUtil';
import { toJpeg } from 'html-to-image';
import {
    computed,
    onMounted,
    onBeforeUnmount,
    ref,
    reactive,
} from '@vue/composition-api';
import Doughnut from '../charts/Doughnut';
import { getInquirySourceImage } from '../../utils/InquirySourceUtil';
import TourCompletedFunnel from '../tour/TourCompletedFunnel';

export default {
  name: 'PropertyRentedDialog',
  components: {
    TourCompletedFunnel,
    Doughnut,
  },
  props: {
    showingProfileId: {
      type: Number,
      required: true,
      default: null,
    },
    firstTimeOpened: {
      type: Boolean,
      required: true,
      default: true,
    },
    isRented: {
      type: Boolean,
      required: true,
      default: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
  },

  setup(props, context) {
    const reportRef = ref({
      active_days: 0,
      incoming_inquiries: 0,
    });
    const toursAssigneesRef = ref(null);
    const computedInquirySources = computed(computeInquirySources);
    const computedTotalTours = computed(computeTotalTours);
    const computedIsCongratsVersion = computed(computeIsCongratsVersion);
    const chartDataRef = ref(null);
    const uiFlags = reactive({
      isGeneratingReportPdf: false,
      isChartAnimationInProgress: true,
    });
    const defaultReportTitle = 'Leasing Cycle Report';
    const defaultReportSubText = 'Here are some stats you might find interesting:';
    const chartDataOptions = {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 1,
      cutoutPercentage: 65,
      tooltips: {
        caretPadding: 0,
        caretSize: 0,
        callbacks: {
          label(tooltipItem, data) {
            const assignee = data.labels[tooltipItem.index] || '';
            const toursCount = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0;
            return `${assignee}: ${toursCount} tours`;
          },
        },
      },
      legend: {
        position: 'right',
        fullWidth: false,
        labels: {
          boxWidth: 7,
          usePointStyle: true,
          fontFamily: 'Fira Sans',
          fontSize: 15,
        },
      },
      animation: {
        onComplete(_) {
          uiFlags.isChartAnimationInProgress = false;
        },
      },
    };

    onMounted(async () => {
      window.addEventListener('unhandledrejection', handlePdfMakePromiseRejection);
      await getShowingReport();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('unhandledrejection', handlePdfMakePromiseRejection);
    });

    return {
      uiFlags,
      chartDataOptions,
      chartDataRef,
      reportRef,
      computedTotalTours,
      toursAssigneesRef,
      computedInquirySources,
      getInquirySourceImage,
      computedIsCongratsVersion,
      generateReportPdf,
      defaultReportTitle,
      defaultReportSubText,
    };

    async function getShowingReport() {
      reportRef.value = await context.root.$store.dispatch('ShowingProfile/showingReport', props.showingProfileId);
      prepareChartData(reportRef.value.tours);
    }

    async function generateReportPdf() {
      try {
        uiFlags.isGeneratingReportPdf = true;

        const options = {
          backgroundColor: 'white',
          type: 'image/jpeg',
          quality: 1,
          pixelRatio: 2,
          filter(node) {
            return !(
                [
                  context.refs.reportTitle,
                  context.refs.reportSubText,
                ].includes(node)
            );
          },
        };

        // Fix for "Safari browser version >= 16.6", as it does not render Base64 image correctly.
        // on the first try, only after 3 times, the image is rendered successfully.
        // Chrome, Firefox, Edge browser working as expected with a single Base64 image render.
        let jpegDataUrl = null;
        for (let i = 1; i <= 3; i++) {
          jpegDataUrl = await toJpeg(context.refs.dialogContent.$el.querySelector('.el-dialog__body'), options);
        }

        const marginX = 30;
        const marginY = 20;

        const content = [
          {
            text: props.title,
            style: ['defaultFont', 'title', 'center', 'defaultColor'],
          },
          {
            text: defaultReportTitle,
            style: ['defaultFont', 'title', 'center', 'defaultColor'],
            margin: [marginX, marginY, marginX, 0],
          },
          {
            text: defaultReportSubText,
            style: ['defaultFont', 'paragraph', 'center', 'defaultColor'],
            margin: [marginX, marginY / 2, marginX, 0],
          },
          {
            image: jpegDataUrl,
            width: 500,
            style: ['center'],
          },
        ];

        const docDefinition = {
          pageSize: 'LETTER',
          pageMargins: [marginX, marginY],
          styles,
          content,
        };

        pdfMake
          .createPdf(docDefinition, null, fonts, vfsFontsFiraSans.pdfMake.vfs)
          .download('leasing-cycle-report');
      } catch (error) {
        notifyGeneratingReportError(error);
      } finally {
        uiFlags.isGeneratingReportPdf = false;
      }
    }

    function notifyGeneratingReportError(error) {
      showErrorNotify(context, error);
    }

    function handlePdfMakePromiseRejection(event) {
      notifyGeneratingReportError(event.reason);
    }

    function computeIsCongratsVersion() {
      return props.firstTimeOpened && props.isRented;
    }

    function computeTotalTours() {
      let sum = 0;
      if (reportRef.value && reportRef.value.tours) {
        for (const property in reportRef.value.tours) {
          sum += reportRef.value.tours[property];
        }
      }
      return sum;
    }

    function computeInquirySources() {
      const sources = [];
      if (reportRef.value && reportRef.value.sources) {
        for (const sourceName in reportRef.value.sources) {
          sources.push({
            name: sourceName,
            amountOfInquiries: reportRef.value.sources[sourceName],
          });
        }
      }
      return sources.slice(0, 3);
    }

    function prepareChartData(tours) {
      const chartData = {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: [],
        }],
      };

      let index = 1;
      for (const tourAssignee in tours) {
        chartData.labels.push(tourAssignee);
        chartData.datasets[0].data.push(tours[tourAssignee]);
        const purpleColorFromPalette = `hsl(${260 - (index * 4)},${105 - (index * 5)}%,${65 + (index * 5)}%)`;
        chartData.datasets[0].backgroundColor.push(tourAssignee === 'Showdigs' ? '#00BE6D' : purpleColorFromPalette);
        index++;
      }
      uiFlags.isChartAnimationInProgress = !!chartData.labels.length;
      chartDataRef.value = chartData;
    }
  },
};
</script>

<style lang="scss">
    #showing-report {

        .el-dialog--center .el-dialog__body {
            padding: 2rem 1.5rem 2.5rem;
            @include media-breakpoint-down(md) {
                padding: 0.5rem;
            }
        }

        .el-divider--horizontal {
            margin: 1.5rem 0;
            @include media-breakpoint-down(md) {
                margin: 0.75rem 0;
            }
        }

        .el-divider--vertical {
            height: auto;
            width: 2px;
        }

        .el-dialog__header {
          padding-bottom: 2rem;
          border-bottom: 1px solid gray-color('light');
        }

        .with-header {
            .el-dialog__header {
                padding: 4rem;
                background-color: gray-color('lighter');
                background-image: url('../../assets/unit-leased.svg');
                background-position: bottom;
                background-repeat: no-repeat;
                background-position-y: 1.75rem;
            }
        }

        .el-dialog__footer {
          padding-top: 1.25rem;
          padding-bottom: 1.25rem;
          border-top: 1px solid gray-color('light');
        }

        .sdicon-chevron-down {
            transform: rotate(-90deg);
        }

        .report-card {
            display: flex;

            width: -webkit-fill-available;
            width: -moz-available;
            flex-flow: column;

            .report-card-label {
                padding: 0.25rem 0.5rem  0.5rem 0.5rem;
                text-align: left;
                font-weight: $strong;
                font-size: $--font-size-medium;
            }

            .highlights-card-content {
                display: flex;
                justify-content: space-around;

                .col {
                    text-align: center;

                    .highlights-card-content-number {
                        line-height: 2rem;
                        align-self: center;
                        margin: 0.5rem 0 0 0;
                        font-weight: $font-weight-bolder;
                    }
                }

                li {
                    list-style-type: none;
                }
            }
        }

        .source-icon {
            height: 1.75rem;
            width: 1.75rem;
            position: relative;
            top: -3px;
        }

        #doughnut-chart {
            max-height: 150px;
            max-width: 270px;
            margin: 0.5rem 0;
        }

        .user-tag {
            display: flex;
            font-size: $--font-size-base;
            font-weight: $strong;

            span {
                align-self: flex-end;
            }
        }

        .agent-user-tag {
            color: unset;
            font-weight: normal;
            background-color: unset;
            border: 1px solid gray();
            border-radius: $app-border-radius;
            padding-left: 0;
        }
    }
</style>
