<template>
  <div
    class="sd-showing-status d-flex align-items-center uppercase font-12 p-2"
  >
    {{ count }}
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: [Number, String, null],
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sd-showing-status {
  background-color: gray-color('lighter');
  color: gray-color("dark");
  border: 1px transparent solid;
  line-height: 0.35rem;
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: -0.3px;
}
</style>
