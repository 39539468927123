<template>
  <div
    :id="`custom-question-${index}`"
    class="row no-gutters justify-content-between mt-3"
  >
    <div class="col-11">
      <ElFormItem>
        <SdFloatLabel>
          <ElInput
            v-model="payloadRef.custom_question"
            :rows="4"
            maxlength="4096"
            placeholder="Custom question"
            type="textarea"
            @input="updatePayload"
          />
        </SdFloatLabel>
      </ElFormItem>
      <div
        v-for="(responseOption, responseOptionIndex) in payloadRef.response_options"
        :key="responseOptionIndex"
        class="row mt-2"
      >
        <div class="col-12 col-md-6">
          <ElFormItem>
            <SdFloatLabel>
              <ElInput
                v-model="payloadRef.response_options[responseOptionIndex].answer"
                placeholder="Response option"
              />
            </SdFloatLabel>
          </ElFormItem>
        </div>
        <div class="col-12 col-md-6">
          <ElFormItem>
            <SdFloatLabel>
              <ElSelect
                v-model="payloadRef.response_options[responseOptionIndex].qualified"
                placeholder="Is the prospect qualified?"
                @change="updatePayload"
              >
                <ElOption
                  v-for="(qualifiedOption, qualifiedOptionIndex) in qualifiedOptionsRef"
                  :key="qualifiedOptionIndex"
                  :label="qualifiedOption.label"
                  :value="qualifiedOption.value"
                />
              </ElSelect>
            </SdFloatLabel>
          </ElFormItem>
        </div>
      </div>
    </div>
    <i
      class="ml-2 sdicon-trash text-danger font-21 pointer"
      @click="$emit('delete', index)"
    />
  </div>
</template>

<script>

import SdFloatLabel from '@/components/common/form/SdFloatLabel.vue';
import { ref } from '@vue/composition-api';

export default {
  name: 'CustomQuestionTemplate',
  components: {
    SdFloatLabel,
  },
  props: {
    index: {
      type: Number,
      require: true,
      default: null,
    },
    customQuestionPayload: {
      type: Object,
      require: false,
      default: null,
    },
  },
  setup(props, context) {
    const payloadRef = ref({
      type: 'radio',
      custom_question: props.customQuestionPayload?.custom_question,
      response_options: props.customQuestionPayload?.response_options ?? [
        { answer: null, qualified: null },
        { answer: null, qualified: null },
      ],
    });

    const qualifiedOptionsRef = ref([
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ]);

    return {
      payloadRef,
      updatePayload,
      qualifiedOptionsRef,
    };
    function updatePayload() {
      context.emit('change', {
        payload: payloadRef.value,
        index: props.index,
      });
    }
  },
};
</script>

<style lang="scss">
</style>
