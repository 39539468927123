<template>
  <ElTooltip
    v-if="state"
    :content="state.content"
  >
    <i
      class="font-17"
      :class="state.icon"
    />
  </ElTooltip>
</template>
<script>
import { computed } from '@vue/composition-api';
import { ScheduleConfirmationStatuses } from '@/constants/Schedule';

export default {
  name: 'ProspectStatusIcon',
  props: {
    prospect: {
      type: Object,
      required: true,
    },
    isOpenHouse: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = computed(computeProspectState);

    const states = {
      [ScheduleConfirmationStatuses.CONFIRMED]: {
        content: `${LEAD_NAME_NEEDLE} confirmed`,
        icon: 'sdicon-confirmed text-success',
      },
      [ScheduleConfirmationStatuses.NOT_REQUIRED]: {
        content: props.isOpenHouse ? 'No confirmation required' : 'Short notice - No confirmation required',
        icon: 'sdicon-confirmation-not-required text-primary-dark',
      },
      [ScheduleConfirmationStatuses.NOT_CONFIRMED]: {
        content: `${LEAD_NAME_NEEDLE} didn't confirm yet`,
        icon: 'sdicon-didnt-confirm text-gray-dark',
      },
    };
    return { state };
    function computeProspectState() {
      const prospectState = states[props.prospect.confirmation_status];
      if (!prospectState) {
        return null;
      }
      prospectState.content = prospectState.content.replace(LEAD_NAME_NEEDLE, (props.prospect.full_name || props.prospect.lead_name));
      return prospectState;
    }
  },
};
const LEAD_NAME_NEEDLE = 'LEAD_NAME_NEEDLE';
</script>

<style lang="scss">

</style>
