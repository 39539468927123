<template>
  <ElDialog
    :custom-class="$viewport.lt.md ? 'services-dialog' : 'services-dialog center-dialog-vertically'"
    :center="true"
    :show-close="false"
    :fullscreen="$viewport.lt.md"
    visible
    width="500px"
    @close="$emit('close')"
  >
    <div slot="title">
      <h2 class="mb-1">
        Book a service
      </h2>
      <h3 class="text-gray-dark font-weight-normal">
        How can our agents help you?
      </h3>
    </div>
    <div class="row justify-content-center no-gutters">
      <ServicesMenu
        :property="property || (unit && unit.property)"
        :unit="unit"
        :is-radio="true"
        :is-showing="unit && unit.showing_enabled"
        size="small"
        :value="selectedServiceRef ? selectedServiceRef.value: undefined"
        @selected="serviceSelected"
      />
    </div>
    <div slot="footer">
      <el-button
        class="btn"
        size="medium"
        @click="$emit('close')"
      >
        Cancel
      </el-button>
      <component
        :is="selectedServiceRef.link.name ? 'router-link' : 'span'"
        v-if="selectedServiceRef"
        :to="selectedServiceRef.link.name ? selectedServiceRef.link : undefined"
        @click="nextClicked"
      >
        <el-button
          class="btn"
          type="primary"
          size="medium"
        >
          Next
        </el-button>
      </component>
      <el-button
        v-else
        class="btn"
        :disabled="true"
        type="primary"
        size="medium"
      >
        Next
      </el-button>
    </div>
  </ElDialog>
</template>

<script>
import { ref } from '@vue/composition-api';
import * as typeformEmbed from '@typeform/embed';

import ServicesMenu from './ServicesMenu';

export default {
  name: 'ServicesDialog',
  components: { ServicesMenu },
  props: {
    unit: {
      type: Object,
      default: null,
    },
    property: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const selectedServiceRef = ref(null);
    return {
      serviceSelected,
      selectedServiceRef,
      nextClicked,
    };

    function serviceSelected(event) {
      selectedServiceRef.value = event;
    }

    function nextClicked() {
      if (!selectedServiceRef.value.link.name) {
        const popup = typeformEmbed.makePopup(selectedServiceRef.value.link, { onReady: () => {} });
        popup.open();
      }
      context.emit('close');
    }
  },
};
</script>

<style lang="scss">
    .services-dialog {
        &.center-dialog-vertically {
            margin-top: calc(50vh - 295px) !important;
        }
        .btn {
            width: 7rem;
            margin: 0 0.5rem;
        }

    }
</style>
