<template>
  <ElDialog
    id="unpause-showing-dialog"
    :center="true"
    top="30vh"
    :fullscreen="$viewport.lt.md"
    :show-close="false"
    :visible="visible"
    width="90%"
    @close="$emit('close')"
  >
    <div slot="title">
      <h2 class="mb-1">
        Resume {{ uiFlags.isOpenHouse ? 'open house' : 'Showings' }}
      </h2>
      <h3 class="text-gray-dark font-weight-normal">
        For {{ `${unit.property.short_address}${unit.name ? ' - '+ unit.name : ''}` }}
      </h3>
    </div>
    <div
      v-if="!isAutoPause"
      class="text-center"
    >
      {{ computeText() }}
    </div>
    <div
      v-else
      class="text-center"
    >
      <div>
        Showings at this property were paused after reaching the limit you have set of
        <strong>{{ unit.last_showing_profile.max_budgeted_tours }}</strong>
        paid tours.
      </div>
      <div class="mt-3">
        Resume showing, and allow
      </div>
      <el-input-number
        v-model="tourBudgetAddition"
        class="mt-3"
        :min="1"
        :max="99"
      />
      <div class="mt-3">
        additional tours until pausing again (total {{ unit.last_showing_profile.max_budgeted_tours + tourBudgetAddition }} tours)
      </div>
    </div>
    <div slot="footer">
      <el-button
        size="medium"
        @click="$emit('close')"
      >
        Cancel
      </el-button>
      <el-button
        type="primary"
        size="medium"
        :loading="uiFlags.isUnpausing"
        @click="unpause"
      >
        Resume {{ uiFlags.isOpenHouse ? 'open house' : 'showings' }}
      </el-button>
    </div>
  </ElDialog>
</template>

<script>
import { ref, computed, reactive } from '@vue/composition-api';

export default {
  props: {
    unit: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const dispatch = context.root.$store.dispatch;
    const uiFlags = reactive({
      isUnpausing: false,
      isOpenHouse: props.unit.last_showing_profile.is_open_house,
    });
    const isAutoPause = computed(computeIsAutoPause);
    const tourBudgetAddition = ref(1);

    return {
      unpause,
      uiFlags,
      isAutoPause,
      tourBudgetAddition,
      computeText,
    };

    async function unpause() {
      uiFlags.isUnpausing = true;
      try {
        const payload = {
          showingProfileId: props.unit.last_showing_profile.id,
        };
        if (isAutoPause.value) {
          payload.max_budgeted_tours = props.unit.last_showing_profile.max_budgeted_tours + tourBudgetAddition.value;
        }
        await dispatch('ShowingProfile/unpause', payload);
      } catch (e) {
        context.emit('close');
      }
      uiFlags.isUnpausing = false;
      context.emit('close', 'success');
    }

    function computeText() {
      if (uiFlags.isOpenHouse) {
        return 'The open house will be resumed, and prospects will be able to participate in this open house immediately. Do you want to continue?';
      }
      return 'Showings will be resumed, and prospects will be able to schedule showings immediately. Do you want to continue?';
    }

    function computeIsAutoPause() {
      return props.unit.last_showing_profile.paused_until
                    && props.unit.last_showing_profile.max_budgeted_tours
                    && props.unit.last_showing_profile.paid_tours_count >= props.unit.last_showing_profile.max_budgeted_tours;
    }
  },
};
</script>
<style lang="scss">
    #unpause-showing-dialog {
        .el-dialog {
            max-width: 545px;
            .el-input__inner{
                padding-right: 1rem;
                padding-left: 1rem;
            }
            .el-input__prefix{
                display: none;
            }
        }

        .el-input-number {
            width: 160px;

            > [role="button"] {
                width: 36px;
                background: gray-color(light);
            }
        }
    }
</style>
