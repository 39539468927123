<template>
  <ElPopover
    placement="bottom"
    :visible-arrow="false"
    width="300"
    trigger="click"
  >
    <div
      slot="reference"
      class="unit-link text-gray-dark font-13"
    >
      <i class="sdicon-external-link font-22 ml-n2 mr-n1 text-orange" />
      Listing Link
    </div>
    <UnitLinkAndQrCodeDialog :unit="unit" />
  </ElPopover>
</template>

<script>
import UnitLinkAndQrCodeDialog from './UnitLinkAndQrCodeDialog.vue';

export default {
  name: 'UnitLink',
  components: { UnitLinkAndQrCodeDialog },
  props: {
    unit: {
      type: Object,
      required: true,
    },
  },
};
</script>
