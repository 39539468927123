<template>
  <div
    id="filters"
    class="d-flex align-items-baseline w-100"
  >
    <div
      v-if="withStatusesFilter"
      class="filter-item status-filter mr-2"
    >
      <ElSelect
        v-model="filters.statuses"
        multiple
        collapse-tags
        placeholder="All tours"
        :disabled="disabled"
      >
        <ElOption
          v-for="status in statusesOptions"
          :key="status.value"
          :label="status.label"
          :value="status.value"
        />
      </ElSelect>
    </div>

    <div
      v-if="withDatePicker"
      class="filter-item mt-2 mt-lg-0 ml-lg-0 ml-auto mr-2"
    >
      <ElDatePicker
        v-model="filters.dateRange"
        :picker-options="{ ...pickerOptions, disabledDate: isFutureDateOptionDisabled }"
        align="center"
        :editable="false"
        :range-separator="filters.dateRange ? 'to' : ''"
        start-placeholder="Tours from"
        end-placeholder="End date"
        type="daterange"
        :clearable="false"
        :disabled="disabled"
        @change="emitInit"
      />
    </div>

    <div
      v-if="withMyPropertiesCheckbox"
      id="show-only-my-properties"
      class="filter-item mt-2 mt-lg-0 ml-lg-0 ml-auto mr-2"
    >
      <ElCheckbox
        v-model="filters.myPropertiesToursOnly"
        :disabled="disabled"
        @input="storeMyPropertiesToursOnlySelectionInLocalStorage"
      >
        <span class="text-gray-dark font-13">
          Show only tours at my properties
        </span>
      </ElCheckbox>
    </div>
    <div
      v-if="withActiveLeaseCheckbox"
      id="show-only-active-lease"
      class="filter-item mt-2 mt-lg-0 ml-lg-0 ml-auto mr-2"
    >
      <ElCheckbox
        v-model="filters.activeLeaseToursOnly"
        :disabled="disabled || !isActiveLeasingPeriod"
      >
        <span class="text-gray-dark font-13">
          Current leasing period only
        </span>
      </ElCheckbox>
    </div>

    <div class="filter-item mt-2 mt-lg-0 ml-auto mr-2">
      <ElButton
        v-if="hasSorting"
        :disabled="disabled"
        type="text"
        size="small"
        class="pt-lg-0 pt-1 pb-0"
        :icon="filters.sorting === activitySortTypes.ASCENDING ? 'sdicon-sort-up' : 'sdicon-sort-down'"
        @click="disabled ? null : $emit('changeSort')"
      >
        Scheduled
        <strong class="ml-1">
          ({{ filters.sorting === activitySortTypes.ASCENDING ? "Old to New" : "New to Old" }})
        </strong>
      </ElButton>
    </div>
  </div>
</template>

<script>
import momentUtil from '@/utils/MomentUtil';
import { activitySortTypes } from '@/constants/Commons';
import { localStorageFiltersKey } from '@/pages/App/Tours/Tours';

const today = momentUtil(null, momentUtil.tz.guess())
  .startOf('day')
  .toDate();
const pickerOptions = {
  shortcuts: [
    { text: 'Last day', onClick: (picker) => getPickerRangeByLast(picker, 1, 'day') },
    { text: 'Last week', onClick: (picker) => getPickerRangeByLast(picker, 1, 'week') },
    { text: 'Last 2 weeks', onClick: (picker) => getPickerRangeByLast(picker, 2, 'week') },
    { text: 'Last 30 days', onClick: (picker) => getPickerRangeByLast(picker, 30, 'day') },
  ],
};
function getPickerRangeByLast(picker, amount, period) {
  const start = momentUtil(null, null)
    .subtract(amount, period)
    .startOf('day');
  const end = new Date();
  picker.$emit('pick', [start, end]);
}

export default {
  name: 'ToursFilters',
  model: {
    prop: 'filters',
    event: 'input',
  },
  props: {
    disabled: {
      type: Boolean,
    },
    filters: {
      type: Object,
      required: true,
    },
    hasSorting: {
      type: Boolean,
      required: true,
    },
    withMyPropertiesCheckbox: {
      type: Boolean,
      required: true,
      defualt: false,
    },
    withActiveLeaseCheckbox: {
      type: Boolean,
      required: true,
      defualt: false,
    },
    withDatePicker: {
      type: Boolean,
      required: false,
      default: false,
    },
    withStatusesFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    isActiveLeasingPeriod: {
      type: Boolean,
      required: false,
      default: false,
    },
    statusesOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    return {
      emitInit,
      pickerOptions,
      isFutureDateOptionDisabled,
      storeMyPropertiesToursOnlySelectionInLocalStorage,
      activitySortTypes,
    };

    function emitInit() {
      context.emit('init');
    }

    function isFutureDateOptionDisabled(date) {
      return date >= today;
    }

    function storeMyPropertiesToursOnlySelectionInLocalStorage() {
      window.localStorage.setItem(
        localStorageFiltersKey,
        JSON.stringify({
          myPropertiesToursOnly: props.filters.myPropertiesToursOnly,
        }),
      );
    }
  },
};
</script>

<style lang="scss">
#filters {
  label {
    margin-bottom: 0;
  }

  .el-date-editor {
    background: $white;
    height: 40px;
    top: -1px;
    padding: 0px 8px;
    border: 1px solid transparent;
    border: 1px solid gray-color("light");
    color: gray-color("darker");

    &:hover {
      border-color: $--border-color-lighter;
    }

    &:focus {
      border-color: $--color-primary;
    }

    &.is-disabled {
      border: 1px solid gray-color("light");
      color: gray-color("darker");
    }
  }

  @include media-breakpoint-down(md) {
    .filters-container {
      flex-direction: column;
    }

    .filter-item {
      display: flex;
      justify-content: flex-end;
    }

    .status-filter {
      display: inline-block;
      width: 100%;
    }

    .el-date-editor {
      width: 100%;
    }
  }
}

@include media-breakpoint-down(md) {
  #filters {
    display: flex;
    flex-direction: column;
  }

  .el-picker-panel {
    width: 323px !important;

    .el-picker-panel__sidebar {
      display: none;
    }

    .el-picker-panel__body {
      min-width: initial;
      margin: 0;

      .el-picker-panel__content.is-right {
        display: none;
      }

      .el-picker-panel__content.is-left {
        width: 100%;
      }
    }
  }
}
</style>
