var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"unit-link-popover"},[_c('div',{staticClass:"justify-content-center line-height-1.5 font-14",attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"sdicon-link ml-n1"}),_vm._v(" Listing Link ")]),_c('p',{staticClass:"font-12 text-gray-dark mb-2"},[_vm._v(" Send this link to any of your prospects so they can learn more about this property. ")]),_c('div',_vm._l((_vm.landingPageSources),function(ref){
var id = ref.id;
var name = ref.name;
var url = ref.url;
var iconClass = ref.iconClass;
return _c('div',{key:id,staticClass:"row no-gutters justify-content-between position-relative"},[_c('a',{staticClass:"col-7 font-13 line-height-1.5 unit-link-anchor",attrs:{"id":("unit-link-" + (_vm.unit.id) + "-" + id + "-link"),"href":url,"target":"_blank"}},[_c('i',{class:iconClass}),_vm._v(" "+_vm._s(name)+" ")]),_c('input',{staticClass:"position-absolute w-100 h-100 elipsis font-13 border-0 input-link pointer",attrs:{"id":("unit-link-" + (_vm.unit.id) + "-" + id + "-input"),"type":"text","spellcheck":"false"},domProps:{"value":url}}),_c('div',{staticClass:"col-5 text-right pointer",on:{"click":function($event){return _vm.copyLink(("unit-link-" + (_vm.unit.id) + "-" + id + "-input"))}}},[_c('span',{staticClass:"font-13"},[_c('i',{class:_vm.isLandingPageLinkCopied[("unit-link-" + (_vm.unit.id) + "-" + id + "-input")]
              ? 'sdicon-check font-16 text-success mr-n1'
              : 'sdicon-copy font-13 text-gray-dark'}),_vm._v(" "+_vm._s(_vm.isLandingPageLinkCopied[("unit-link-" + (_vm.unit.id) + "-" + id + "-input")] ? "Copied" : "Copy")+" ")])])])}),0),_c('div',{staticClass:"mt-2 mb-2 border-bottom"}),_c('UnitQrCode',{attrs:{"url":_vm.unit.landing_page_url},on:{"update:url":function($event){return _vm.$set(_vm.unit, "landing_page_url", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }