<template>
  <ElDialog
    :center="true"
    :top="top"
    :show-close="false"
    :fullscreen="$viewport.lt.md"
    :visible="visible"
    width="400px"
    @close="$emit('close')"
  >
    <div slot="title">
      <h2 class="mb-1">
        Delete unit
      </h2>
    </div>
    <div class="row d-flex justify-content-center">
      <p class="mt-2 text-center font-weight-normal">
        Are you sure you want to delete <strong>Unit {{ unit.name }}</strong>?
      </p>
      <p class="mt-1 mb-0 text-center font-15">
        Note that this is irreversible.
      </p>
    </div>
    <div slot="footer">
      <el-button
        size="medium"
        @click="$emit('close')"
      >
        Cancel
      </el-button>
      <el-button
        type="primary"
        size="medium"
        :loading="uiFlags.isLoading"
        @click="deleteUnit"
      >
        Yes, delete
      </el-button>
    </div>
  </ElDialog>
</template>

<script>
import { reactive } from '@vue/composition-api';

import { showErrorNotify } from '../../utils/NotifyUtil';

export default {
  name: 'UnitDeleteDialog',
  props: {
    unit: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    top: {
      type: String,
      required: false,
      default: '30vh',
    },
  },
  setup(props, context) {
    const dispatch = context.root.$store.dispatch;
    const uiFlags = reactive({ isLoading: false });
    return {
      uiFlags,
      deleteUnit,
    };

    async function deleteUnit() {
      uiFlags.isLoading = true;
      try {
        await dispatch('Unit/destroy', props.unit.id);
        context.emit('close', 'success');
      } catch (error) {
        showErrorNotify(context, error.message);
      } finally {
        uiFlags.isLoading = false;
      }
    }
  },
};
</script>
