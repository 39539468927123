<template>
  <el-alert
    type="warning"
    show-icon
    :closable="false"
    class="mt-3"
  >
    You already have a property set up at that address, added on
    {{ $momentUtil(duplicatedProperty.created_at, duplicatedProperty.timezone).toDateFormat() }}.
    <router-link :to="{name: 'app.units.show', params: {id:duplicatedProperty.id, unit_id: get(duplicatedProperty, 'units.0.id')}}">
      Link
    </router-link>
  </el-alert>
</template>

<script>
import get from 'lodash.get';

export default {
  name: 'PropertyDuplicatedAddressAlert',
  props: {
    duplicatedProperty: {
      type: Object,
      required: true,
      default: () => {
      },
    },
  },
  setup() {
    return {
      get,
    };
  },
};
</script>
