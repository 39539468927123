<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <ElFormItem>
        <SdFloatLabel>
          <ElSelect
            v-model.number="propertyScreeningPayloadRef.minimum_credit_score"
            placeholder="Minimum credit score"
            :disabled="disabled"
            @change="emitFormStateChanged"
          >
            <ElOption
              :label="DEFAULT_EMPTY_SCREENING_INPUT"
              :value="undefined"
            />
            <ElOption
              v-for="(item, key) in screeningCriteriaComponents.minimum_credit_score.options"
              :key="key"
              :label="item"
              :value="key"
            />
          </ElSelect>
        </SdFloatLabel>
      </ElFormItem>
    </div>
    <div class="col-12 col-md-6">
      <ElFormItem>
        <SdFloatLabel>
          <ElSelect
            v-model.number="propertyScreeningPayloadRef.minimum_income_rent_ratio"
            placeholder="Minimum income/rent ratio"
            :disabled="disabled"
            @change="emitFormStateChanged"
          >
            <ElOption
              :label="DEFAULT_EMPTY_SCREENING_INPUT"
              :value="undefined"
            />
            <ElOption
              v-for="key in Object.keys(screeningCriteriaComponents.minimum_income_rent_ratio.options).sort((a, b) => parseFloat(a) - parseFloat(b))"
              :key="key"
              :label="screeningCriteriaComponents.minimum_income_rent_ratio.options[key]"
              :value="key"
            />
          </ElSelect>
        </SdFloatLabel>
      </ElFormItem>
    </div>
    <div class="col-12">
      <ElFormItem>
        <SdFloatLabel>
          <ElSelect
            v-model="propertyScreeningPayloadRef.eviction_history"
            placeholder="Eviction history policy"
            :disabled="disabled"
            @change="emitFormStateChanged"
          >
            <ElOption
              :label="DEFAULT_EMPTY_SCREENING_INPUT"
              :value="undefined"
            />
            <ElOption
              v-for="(item, key) in filterOutEvictionHistoryDefaultOption(screeningCriteriaComponents.eviction_history.options)"
              :key="key"
              :label="item"
              :value="key"
            />
          </ElSelect>
        </SdFloatLabel>
      </ElFormItem>
    </div>
    <div class="col-12 col-md-6">
      <ElFormItem>
        <SdFloatLabel>
          <ElSelect
            v-model="propertyScreeningPayloadRef.smoking_policy"
            placeholder="Smoking policy"
            :disabled="disabled"
            @change="emitFormStateChanged"
          >
            <ElOption
              :label="DEFAULT_EMPTY_SCREENING_INPUT"
              :value="undefined"
            />
            <ElOption
              v-for="(item, key) in screeningCriteriaComponents.smoking_policy.options"
              :key="key"
              :label="item"
              :value="key"
            />
          </ElSelect>
        </SdFloatLabel>
      </ElFormItem>
    </div>
    <div class="col-12 col-md-6">
      <ElFormItem>
        <SdFloatLabel>
          <ElSelect
            v-model="propertyScreeningPayloadRef.pet_policy"
            placeholder="Pet policy"
            :disabled="isPetPolicyDisabled() || disabled"
            @change="emitFormStateChanged"
          >
            <ElOption
              :label="DEFAULT_EMPTY_SCREENING_INPUT"
              :value="undefined"
            />
            <ElOption
              v-for="(item, key) in screeningCriteriaComponents.pet_policy.options"
              :key="key"
              :label="item"
              :value="key"
            />
          </ElSelect>
        </SdFloatLabel>
      </ElFormItem>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { DEFAULT_EMPTY_SCREENING_INPUT } from '../../constants/Unit';

export default {
  name: 'ShowingProfilePropertyScreening',
  props: {
    screeningCriteria: {
      type: Object,
      required: false,
      default: null,
    },
    unit: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },

  setup(props, context) {
    const screeningCriteriaComponents = context.root.$store.getters['ScreeningCriteria/get'];
    const initialScreeningPayload = { ...propertyScreeningEmptyState, ...props.screeningCriteria };
    initialScreeningPayload.eviction_history = castOptionDefaultValueToNull(initialScreeningPayload.eviction_history, 'eviction_history');
    initialScreeningPayload.smoking_policy = castOptionDefaultValueToNull(initialScreeningPayload.smoking_policy, 'smoking_policy');
    initialScreeningPayload.pet_policy = castOptionDefaultValueToNull(initialScreeningPayload.pet_policy, 'pet_policy');

    const propertyScreeningPayloadRef = ref(initialScreeningPayload);
    emitFormStateChanged();

    return {
      DEFAULT_EMPTY_SCREENING_INPUT,
      screeningCriteriaComponents,
      propertyScreeningPayloadRef,
      emitFormStateChanged,
      generalNotesChanged,
      filterOutEvictionHistoryDefaultOption,
      defaultPropertyScreeningPayload,
      isPetPolicyDisabled,
    };

    function castOptionDefaultValueToNull(value, type) {
      return value === prevVersionPropertyScreeningEmptyState[type]
        ? null
        : value;
    }

    function filterOutEvictionHistoryDefaultOption(options) {
      const filteredOptions = {};

      for (const [key, value] of Object.entries(options)) {
        if (value !== screeningCriteriaComponents.eviction_history.options.with_eviction_history) {
          filteredOptions[key] = value;
        }
      }
      return filteredOptions;
    }

    function emitFormStateChanged() {
      context.emit('change', { ...setOptionToDefaultIfEmpty(propertyScreeningPayloadRef.value) });
    }

    function generalNotesChanged(data) {
      propertyScreeningPayloadRef.value.general_notes = data.general_notes;
      emitFormStateChanged();
    }

    function setOptionToDefaultIfEmpty({ ...options }) {
      for (const key in options) {
        options[key] = !options[key] ? defaultPropertyScreeningPayload[key] : options[key];
      }
      return options;
    }

    function isPetPolicyDisabled() {
      return false;
    }
  },
};

export const propertyScreeningEmptyState = {
  smoking_policy: null,
  pet_policy: null,
  minimum_credit_score: null,
  minimum_income_rent_ratio: null,
  eviction_history: null,
};

export const defaultPropertyScreeningPayload = {
  smoking_policy: null,
  pet_policy: null,
  minimum_credit_score: null,
  minimum_income_rent_ratio: null,
  eviction_history: null,
};

const prevVersionPropertyScreeningEmptyState = {
  eviction_history: 'with_eviction_history',
  smoking_policy: null,
  pet_policy: null,
};

</script>

<style lang="scss"></style>
