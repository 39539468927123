<template>
  <div class="row">
    <div
      :key="'unit-bedrooms-'+propertyUnitIndex"
      class="col-6 col-lg-4"
    >
      <ElFormItem
        :prop="resolveFormItemProp('bedrooms')"
        :rules="{ type: 'enum', enum: bedroomsOptions }"
      >
        <UnitListingsPMSTooltip
          type="bedrooms"
        >
          <template v-slot:field>
            <SdFloatLabel>
              <ElSelect
                v-model="unit.bedrooms"
                placeholder="Bedrooms"
                :disabled="isDisabled"
                clearable
                @input="(value) => $emit('update-unit', {bedrooms: value})"
              >
                <ElOption
                  v-for="(bedroomOption, bedroomOptionIndex) in bedroomsOptions"
                  :key="bedroomOptionIndex"
                  :label="bedroomOption"
                  :value="bedroomOption"
                />
              </ElSelect>
            </SdFloatLabel>
          </template>
        </UnitListingsPMSTooltip>
      </ElFormItem>
    </div>
    <div
      :key="'unit-bathrooms-'+propertyUnitIndex"
      class="col-6 col-lg-4"
    >
      <ElFormItem
        :prop="resolveFormItemProp('bathrooms')"
        :rules="{ validator: bathroomsValidator }"
      >
        <UnitListingsPMSTooltip
          type="bathrooms"
        >
          <template v-slot:field>
            <SdFloatLabel>
              <ElSelect
                v-model.number="unit.bathrooms"
                placeholder="Bathrooms"
                :disabled="isDisabled"
                clearable
                @input="(value) => $emit('update-unit', {bathrooms: handleNumberValue(value)})"
              >
                <ElOption
                  v-for="(bathroomOption, bathroomOptionIndex) in bathroomsOptions"
                  :key="bathroomOptionIndex"
                  :label="bathroomOption"
                  :value="bathroomOption"
                />
              </ElSelect>
            </SdFloatLabel>
          </template>
        </UnitListingsPMSTooltip>
      </ElFormItem>
    </div>
    <div
      :key="'unit-size-'+propertyUnitIndex"
      class="col-6 col-lg-4"
    >
      <ElFormItem
        :prop="resolveFormItemProp('size')"
        :rules="rules.size"
      >
        <UnitListingsPMSTooltip
          type="square feet"
        >
          <template v-slot:field>
            <SdFloatLabel label="Square feet">
              <ElInput
                v-if="isDisabled"
                v-model.number="unit.size"
                placeholder="Square feet"
                disabled
              />
              <ElInput
                v-else
                v-model.number="unit.size"
                v-no-scroll
                type="number"
                step="1"
                placeholder="Square feet"
                clearable
                @input="(value) => $emit('update-unit', {size: handleNumberValue(value)})"
              />
            </SdFloatLabel>
          </template>
        </UnitListingsPMSTooltip>
      </ElFormItem>
    </div>

    <div
      v-if="!isIntegrationEnabledIWithoutSyndication && mode === modes.edit"
      class="col-12"
    >
      <div class="row no-gutters">
        <div class="font-17 font-weight-normal">
          Listing
        </div>
      </div>
    </div>

    <div
      v-if="shouldShowPropertyUrlField"
      :key="'property-url-'+propertyUnitIndex"
      class="col-12"
    >
      <ElFormItem
        :prop="resolvePropertyUrlItemProp('property_url')"
        :rules="rules.property_url"
      >
        <el-tooltip
          :disabled="$viewport.lt.md"
          :visible-arrow="false"
          placement="right-start"
          popper-class="popover-panel"
        >
          <template slot="content">
            <i class="sdicon-info-circle" />
            <p>We will share this link with prospects and agents so they can learn more about your property.</p>
          </template>
          <SdFloatLabel label="Link to your external listing">
            <ElInput
              v-model="propertyUrlRef"
              placeholder="Link to your external listing"
              @input="updatePropertyUrl"
            />
          </SdFloatLabel>
        </el-tooltip>
      </ElFormItem>
    </div>

    <div
      v-if="shouldShowField && mode !== modes.add && !isIntegrationEnabledIWithoutSyndication"
      :key="'unit-rent-'+propertyUnitIndex"
      class="col-6 col-lg-4"
    >
      <ElFormItem
        :rules="{ type: 'integer', min: 0, max: 99999, message: 'May be empty or between 0 and 99999' }"
        :prop="resolveRentItemProp()"
      >
        <UnitListingsPMSTooltip
          type="rent"
        >
          <template v-slot:field>
            <SdFloatLabel
              label="Rent"
              width="50%"
            >
              <ElInput
                v-model.number="rent"
                v-no-scroll
                placeholder="Rent"
                type="number"
                min="0"
                max="99999"
                :disabled="(isDisabled && isSyndicated) || Boolean(unit.id !== currentUnitId)"
                @input="(value) => $emit('update-rent', {rent: handleNumberValue(value)})"
              >
                <template slot="append">
                  $
                </template>
              </ElInput>
            </SdFloatLabel>
          </template>
        </UnitListingsPMSTooltip>
      </ElFormItem>
    </div>
    <div
      v-if="mode !== modes.add && showMoveInCostField && !isIntegrationEnabledIWithoutSyndication"
      class="col-6 col-lg-4"
    >
      <ElFormItem>
        <SdFloatLabel>
          <ElInput
            v-model.number="moveInCost"
            v-no-scroll
            :disabled="isDisabled && !isSyndicated && mode !== modes.edit"
            type="number"
            minlength="0"
            maxlength="9999"
            placeholder="Security deposit"
            @input="(value) => $emit('update-move-in-cost', value)"
          >
            <template slot="append">
              $
            </template>
          </ElInput>
        </SdFloatLabel>
      </ElFormItem>
    </div>
    <div
      v-if="withAvailabilityDate && shouldShowField && mode !== modes.add && !isIntegrationEnabledIWithoutSyndication"
      :key="'availability-date-'+propertyUnitIndex"
      class="col-6 col-lg-4"
    >
      <ElFormItem>
        <SdFloatLabel>
          <ElDatePicker
            ref="datePicker"
            v-model="availabilityDate"
            class="availability-date-picker"
            :class="{ 'filled': availabilityDate }"
            type="date"
            :editable="false"
            :picker-options="{ disabledDate }"
            :disabled="isDisabled && !isSyndicated"
            align="center"
            format="dd MMMM"
            placeholder="Available from"
            @change="updateAvailabilityDate"
          />
        </SdFloatLabel>
      </ElFormItem>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import { bathroomsOptions, bedroomsOptions } from '@/constants/Unit';
import get from 'lodash.get';
import momentUtil from '@/utils/MomentUtil';
import Regex from '@/constants/Regex';
import UnitListingsPMSTooltip from '@/components/unit/UnitListingsPMSTooltip.vue';
import SdFloatLabel from '@/components/common/form/SdFloatLabel.vue';

const modes = { add: 'ADD', edit: 'EDIT', activate_showing: 'ACTIVATE_SHOWING' };
const startOfDay = momentUtil(null, momentUtil.tz.guess()).startOf('day').toDate();

const regexConstants = Regex;

export default {
  name: 'UnitListings',
  components: {
    SdFloatLabel,
    UnitListingsPMSTooltip,
  },
  props: {
    unit: {
      type: Object,
      required: true,
    },
    propertyUnitIndex: {
      type: Number,
      default: null,
    },
    listingDataPrefill: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      validator: (mode) => Object.values(modes).includes(mode),
      required: true,
    },
    payload: {
      required: false,
      type: Object,
      default: null,
    },
    business: {
      type: Object,
      required: true,
    },
    withAvailabilityDate: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    listingDataPrefill(newVal) {
      this.refreshData(newVal);
    },
  },
  setup(props, context) {
    const isIntegrationEnabled = context.root.$store.getters['BusinessSource/isIntegrationEnabled'];
    const isSyndicated = context.root.$store.getters['Auth/businessSyndicated'];
    const isUnitSyncedWithIntegration = isIntegrationEnabled && (Boolean(props.unit.listing_identifier) || Boolean(props.unit.origin_id));
    const isIntegrationEnabledIWithoutSyndication = ref(resolveIsIntegrationEnabledIWithoutSyndication());
    const isDisabled = isUnitSyncedWithIntegration;

    const shouldShowField = computed(computeShouldShowField);
    const shouldShowPropertyUrlField = computed(() => computeShouldShowPropertyUrlField(isUnitSyncedWithIntegration));

    const propertyUrlRef = ref(null);
    handlePropertyUrl();

    const showingRent = get(props, 'unit.last_showing_profile.rent', null);
    const listingRent = get(props, 'listingDataPrefill.rent', null);
    const rent = ref(listingRent || showingRent || props?.payload?.rent);
    context.emit('update-rent', { rent: handleNumberValue(rent.value) });

    const unitBedrooms = get(props, 'unit.bedrooms', null);
    const listingBedrooms = get(props, 'listingDataPrefill.bedrooms', null);
    props.unit.bedrooms = listingBedrooms || unitBedrooms;

    const unitBathrooms = get(props, 'unit.bathrooms', null);
    const listingBathrooms = get(props, 'listingDataPrefill.bathrooms', null);
    props.unit.bathrooms = listingBathrooms || unitBathrooms;

    const unitSize = get(props, 'unit.size', null);
    const listingSize = get(props, 'listingDataPrefill.size', null);
    props.unit.size = listingSize || unitSize;

    const availabilityDate = ref(null);
    handleAvailabilityDate();

    const moveInCost = ref(null);
    handleMoveInCost();

    const showMoveInCostField = get(props, 'unit.active_showing_profile', null) || props.mode !== modes.edit;

    const currentUnitId = Number(context.root.$route.params.unit_id);
    const rules = getRules();

    context.emit('update-units', {});

    return {
      bedroomsOptions,
      bathroomsOptions,
      rent,
      modes,
      shouldShowField,
      availabilityDate,
      currentUnitId,
      isDisabled,
      resolveFormItemProp,
      resolveRentItemProp,
      resolvePropertyUrlItemProp,
      disabledDate,
      updateAvailabilityDate,
      updatePropertyUrl,
      handleNumberValue,
      bathroomsValidator,
      rules,
      shouldShowPropertyUrlField,
      propertyUrlRef,
      isSyndicated,
      moveInCost,
      showMoveInCostField,
      isIntegrationEnabledIWithoutSyndication,
      refreshData,
    };

    function refreshData(freshData) {
      if (freshData.bedrooms) {
        props.unit.bedrooms = freshData.bedrooms;
      }
      if (freshData.bathrooms) {
        props.unit.bathrooms = freshData.bathrooms;
      }
      if (freshData.size) {
        props.unit.size = freshData.size;
      }
      if (freshData.rent) {
        rent.value = freshData.rent;
      }
      if (freshData.move_in_cost) {
        moveInCost.value = freshData.move_in_cost;
        handleMoveInCost();
      }
      if (freshData.availability_date) {
        availabilityDate.value = freshData.availability_date;
        handleAvailabilityDate();
      }
    }

    function resolveIsIntegrationEnabledIWithoutSyndication() {
      return isIntegrationEnabled && !isSyndicated;
    }

    function computeShouldShowField() {
      return props.unit.active_showing_profile || props.mode === modes.activate_showing;
    }

    function resolveFormItemProp(key) {
      const prop = `units.${props.propertyUnitIndex}.${key}`;

      if (props.mode === modes.add) {
        return prop;
      }
      if (props.mode === modes.edit) {
        return `property.${prop}`;
      }
      return key;
    }

    function resolveRentItemProp() {
      const prop = `units.${props.propertyUnitIndex}.active_showing_profile.rent`;

      if (props.mode === modes.add) {
        return prop;
      }
      if (props.mode === modes.edit) {
        return `property.${prop}`;
      }
      return 'rent';
    }

    function resolvePropertyUrlItemProp(key) {
      if (props.mode === modes.edit) {
        return `showing_profile.${key}`;
      }

      return key;
    }

    function disabledDate(date) {
      return date < startOfDay;
    }

    function updateAvailabilityDate() {
      context.emit('update-availability-date', availabilityDate.value);
    }
    function updatePropertyUrl() {
      context.emit('update-property-url', propertyUrlRef.value);
    }

    function handleAvailabilityDate() {
      const propertyTimezone = get(props, 'unit.property.timezone', null);
      let showingAvailabilityDate = get(props, 'unit.last_showing_profile.screening_criteria.availability_date', null);
      const listingAvailabilityDate = get(props, 'listingDataPrefill.availability_date', null);

      if (showingAvailabilityDate) {
        showingAvailabilityDate = momentUtil(showingAvailabilityDate, propertyTimezone).toDate();
      }

      availabilityDate.value = listingAvailabilityDate || showingAvailabilityDate || props?.payload?.screening_criteria?.availability_date;

      if (availabilityDate.value) {
        updateAvailabilityDate();
      }
    }

    function handleMoveInCost() {
      const showingMoveInCost = get(props, 'unit.last_showing_profile.screening_criteria.move_in_cost', null);
      const moveInCostPrefill = get(props, 'listingDataPrefill.move_in_cost', null);
      moveInCost.value = moveInCostPrefill || showingMoveInCost;
      if (moveInCost.value) {
        context.emit('update-move-in-cost', moveInCost.value);
      }
    }

    function handlePropertyUrl() {
      const propertyUrl = get(props, 'unit.active_showing_profile.property_url', props?.payload?.property_url);

      if (propertyUrl) {
        propertyUrlRef.value = propertyUrl;
        updatePropertyUrl();
      }
    }

    function handleNumberValue(value) {
      return value === '' ? null : Number(value);
    }

    function validatePropertyUrl(rule, value, callback) {
      if (!value) {
        return callback(new Error('Property URL is required'));
      }
      if (!value.match(regexConstants.url)) {
        return callback(new Error('Property URL is not valid'));
      }
      if (!value.match(regexConstants.http_protocol)) {
        propertyUrlRef.value = `https://${value}`;
      }
      return callback();
    }

    function validateSize(rule, value, callback) {
      if (value === '' || value === null) {
        callback();
      } else {
        const intValue = parseInt(value);
        if (Number.isInteger(intValue) && intValue >= 0 && intValue < 199999) {
          callback();
        } else {
          callback(new Error('May be empty or between 0 and 199999'));
        }
      }
    }

    function getRules() {
      return {
        property_url: {
          validator: validatePropertyUrl,
        },
        size: {
          validator: validateSize,
        },
      };
    }

    function computeShouldShowPropertyUrlField(isUnitSyncedWithIntegration) {
      const isActivationMode = props.mode === modes.activate_showing;
      const isEditMode = props.mode === modes.edit;
      const isUnitWithActiveShowingProfile = props.unit.active_showing_profile;
      const isIntegrationDisabled = !isUnitSyncedWithIntegration;
      const isSyndicationDisabled = Boolean(props.business.zillow_syndication_enabled_at) === false
          && Boolean(props.business.zumper_syndication_enabled_at) === false;

      return (
          (isEditMode && isUnitWithActiveShowingProfile && isIntegrationDisabled && isSyndicationDisabled)
          || (isActivationMode && isIntegrationDisabled && isSyndicationDisabled)
      );
    }

    function bathroomsValidator(_rule, value, callback) {
      if (value === null) {
        return callback();
      }
      const number = Number(value);
      if (!bathroomsOptions.includes(number)) {
        return callback(`Invalid bathroom number:${ value}`);
      }
      return callback();
    }
  },
};
</script>

<style lang="scss" scoped>
  .availability-date-picker {
    width: 100% !important;

    &.filled {
      ::v-deep .el-input__prefix {
        margin-top: 0.5rem;
      }
    }

    ::v-deep .el-input__inner {
      padding-left: 45px;
      padding-right: 15px;
    }

    ::v-deep .el-input__prefix {
      margin-left: 0.5rem;
    }
  }
</style>
