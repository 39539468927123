<template>
  <div class="unit-link-popover">
    <div
      slot="reference"
      class="justify-content-center line-height-1.5 font-14"
    >
      <i class="sdicon-link ml-n1" />
      Listing Link
    </div>
    <p class="font-12 text-gray-dark mb-2">
      Send this link to any of your prospects so they can learn more about this property.
    </p>
    <div>
      <div
        v-for="{id, name, url, iconClass} in landingPageSources"
        :key="id"
        class="row no-gutters justify-content-between position-relative"
      >
        <a
          :id="`unit-link-${unit.id}-${id}-link`"
          :href="url"
          target="_blank"
          class="col-7 font-13 line-height-1.5 unit-link-anchor"
        >
          <i :class="iconClass" />
          {{ name }}
        </a>
        <input
          :id="`unit-link-${unit.id}-${id}-input`"
          class="position-absolute w-100 h-100 elipsis font-13 border-0 input-link pointer"
          type="text"
          spellcheck="false"
          :value="url"
        >
        <div
          class="col-5 text-right pointer"
          @click="copyLink(`unit-link-${unit.id}-${id}-input`)"
        >
          <span class="font-13">
            <i
              :class="isLandingPageLinkCopied[`unit-link-${unit.id}-${id}-input`]
                ? 'sdicon-check font-16 text-success mr-n1'
                : 'sdicon-copy font-13 text-gray-dark'"
            />
            {{ isLandingPageLinkCopied[`unit-link-${unit.id}-${id}-input`] ? "Copied" : "Copy" }}
          </span>
        </div>
      </div>
    </div>
    <div class="mt-2 mb-2 border-bottom" />
    <UnitQrCode :url.sync="unit.landing_page_url" />
  </div>
</template>

<script>
import { set, reactive, computed } from '@vue/composition-api';

import UrlFormatter from '@/utils/UrlFormatterUtil';

import UnitQrCode from './UnitQrCode.vue';

export default {
  name: 'UnitLinkAndQrCodeDialog',
  components: { UnitQrCode },
  props: {
    unit: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isLandingPageLinkCopied = reactive({});

    const landingPageSources = computed(computeLandingPageSourceQueryParams);

    return {
      copyLink,
      isLandingPageLinkCopied,
      UrlFormatter,
      landingPageSources,
    };

    function copyLink(id) {
      const copyText = document.getElementById(id);
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      document.execCommand('copy');
      set(isLandingPageLinkCopied, id, true);
    }

    function computeLandingPageSourceQueryParams() {
      const url = props.unit.landing_page_url;

      if (!url) {
        return [];
      }

      const sourcesData = props.unit.landing_page_source_query_params;
      const sourceKey = sourcesData.key;
      const landingPage = UrlFormatter(url);
      const iconClassPrefix = 'sdicon';

      return [
        {
          id: 'manual',
          iconClass: `${iconClassPrefix}-home`,
          url: landingPage,
          name: 'Showdigs Listing',
        },
        ...Object.keys(sourcesData.values).map((name) => {
          const type = name.toLocaleLowerCase();
          return {
            id: type,
            iconClass: `${iconClassPrefix}-${type}`,
            url: `${landingPage}?${sourceKey}=${sourcesData.values[name]}`,
            name,
          };
        }),
      ];
    }
  },
};
</script>

<style lang="scss">
.el-popover__reference-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.unit-link {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.unit-link-popover {
  word-break: normal;

  .unit-link-anchor {
    z-index: 100;

    .sdicon-instagram {
      font-size: .7rem;
    }
  }

  // Hide the input from viewport but keep it in the DOM will allow to select and copy to clipboard using input controller API
  .input-link {
    opacity: 0;
  }
}
</style>
