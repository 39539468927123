<template>
  <el-alert
    v-if="visible"
    type="error"
    show-icon
    class="wraning-note-before-assign text-left"
    :closable="false"
  >
    <div class="font-17 font-weight-bold">
      Warning
    </div>
    <div class="mt-1">
      <div
        class="user-tag p-1"
        :class="user.avatar_file_url ? 'vertical-align-sub': ''"
      >
        <img
          v-if="user.avatar_file_url"
          class="user-avatar-small mr-1"
          :src="user.avatar_file_url"
        >
        <i
          v-else
          class="sdicon-user-tie user-tag-icon"
        />
        <span>{{ user.full_name }}</span>
      </div>
      <span v-if="user.futurePendingTour">
        is already assigned to a <router-link
          :to="{ name: 'app.tours.show', params: { id: user.futurePendingTour.schedule_id }}"
        >
          different tour
        </router-link> at the same time. It is recommended
        that you pick a new time, or cancel the other tour.
      </span>
      <span v-else>
        is not available at this time. It is recommended
        that you pick a new time.
      </span>
    </div>
  </el-alert>
</template>

<script>
export default {
  name: 'WarningNoteBeforeAssign',
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.wraning-note-before-assign{
  .user-tag {
    font-size: $--font-size-base;
    font-weight: $strong;
  }
}
</style>
