<template>
  <ElDialog
    visible
    :fullscreen="$viewport.lt.md"
    :destroy-on-close="true"
    @close="preClose"
  >
    <div
      slot="title"
      class="px-4"
    >
      <h2 class="text-black font-21 mb-0 text-center">
        Generate Title and Description
      </h2>
      <div
        v-if="uiFlags.isLoading"
        class="col-12 pt-1 text-center text-primary mt-5"
      >
        AI is generating your listing title and description, this might take a few seconds...
        <div class="d-flex flex-column justify-content-center  align-items-center mt-5">
          <SdSpinner />
        </div>
      </div>
      <div
        v-else
        class="col-12 pt-1 text-center text-secondary"
      >
        Use the space below to enter property highlights that should be included in the listing title and description.
        These can be as simple as a series of bullets or short phrases.
      </div>
    </div>
    <div
      v-if="!uiFlags.isLoading"
      class="px-4"
    >
      <ElForm
        ref="form"
      >
        <ElFormItem>
          <SdFloatLabel>
            <ElInput
              v-model="additionalInformation"
              type="textarea"
              :rows="10"
              :placeholder="placeHolder"
            />
          </SdFloatLabel>
        </ElFormItem>
      </ElForm>
    </div>
    <div
      slot="footer"
      class="row justify-content-center px-4"
    >
      <div
        class="col-md-4 col-12"
      >
        <ElButton
          class="w-100"
          size="medium"
          @click="preClose"
        >
          Cancel
        </ElButton>
      </div>
      <div
        class="col-6 col-md-4"
      >
        <ElButton
          class="w-100"
          size="medium"
          type="primary"
          :disabled="uiFlags.isLoading"
          @click="generate"
        >
          Generate
        </ElButton>
      </div>
    </div>
  </ElDialog>
</template>

<script>

import { reactive, ref } from '@vue/composition-api';
import SdFloatLabel from '@/components/common/form/SdFloatLabel.vue';
import { showErrorNotify } from '@/utils/NotifyUtil';
import SdSpinner from '@/components/common/SdSpinner.vue';

export default {
  name: 'SdGenerateAdDialog',
  components: {
    SdSpinner,
    SdFloatLabel,
  },
  props: {
    prevAdditionalInformation: {
      type: String,
      required: false,
      default: '',
    },
    unitIndex: {
      type: Number,
      required: false,
      default: 0,
    },
    payload: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const uiFlags = reactive({
      isLoading: false,
    });
    const placeHolder = 'Details to highlight in the generated description. E.g.\n'
       + 'In-ground pool\n'
       + 'Hardwood floors\n'
       + 'Close to the park\n'
       + 'Walking distance to a coffee shop';
    const additionalInformation = ref(props.prevAdditionalInformation);
    return {
      uiFlags,
      placeHolder,
      additionalInformation,
      generate,
      preClose,
    };

    function preClose() {
      context.emit('update-payload', { stopProcess: true });
    }
    async function generate() {
      const payload = props.payload;
      payload.additionalInformation = additionalInformation.value;
      uiFlags.isLoading = true;
      try {
        const response = await context.root.$store.dispatch('Listing/generateAd', payload);
        context.emit('update-payload', {
          description: response.listingDescription ?? '',
          short_description: response.listingTitle ?? '',
          additionalInformation: additionalInformation.value,
        });
      } catch (error) {
        showErrorNotify(context, error.message);
      }
      uiFlags.isLoading = false;
    }
  },
};
</script>
