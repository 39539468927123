<template>
  <SdPage
    id="unit-page"
    :class="[uiFlags.isNarrowView ? 'narrow' : '', unitRef && unitRef.deleted_at === null ? '' : 'archived-state']"
  >
    <Portal to="container-top">
      <SdBanner
        v-if="shouldShowBannerDetails && computedBannerDefinitions.isVisible"
        :bg-class="computedBannerDefinitions.class"
        :with-container-class="false"
      >
        <div class="container position-relative text-center">
          <div class="w-100">
            <div class="showing-details-indicators col-4 col-md-12 px-3">
              <template v-if="computedBannerDefinitions.isNotShowing">
                Unit is not showing
              </template>
              <template v-else-if="pausedUntil || futurePause">
                <span class="font-weight-strong">
                  {{ showingTypeName }} showings {{ pausedUntil ? "paused" : "pause scheduled" }}
                </span>
                <br>
                <span
                  v-if="futurePause"
                  :class="futurePause ? 'font-13' : ''"
                > from {{ futurePause.pause_from.toDisplayWithDayFormat() }} </span>
                <span :class="futurePause ? 'font-13' : ''">
                  until {{
                    pausedUntil ? pausedUntil.toDisplayWithDayFormat() : futurePause.paused_until.toDisplayWithDayFormat()
                  }}
                </span>
              </template>
              <template v-else>
                <span>{{ showingTypeName }} showings are <span class="font-weight-strong">active</span></span>
              </template>
              <div
                v-if="!computedBannerDefinitions.isNotShowing"
                class="font-13"
              >
                <span v-if="get(unitRef, 'last_showing_profile.pause_reason')">
                  Reason: {{ getPauseReasonLabel(unitRef.last_showing_profile.pause_reason) }}
                </span>
                <template
                  v-if="shouldShowSyndicationSource && computedBannerDefinitions.syndications && computedBannerDefinitions.syndications.length"
                >
                  Listing Networks:
                  <span
                    v-for="(syndication, index) in computedBannerDefinitions.syndications"
                    :key="syndication"
                  >
                    {{ syndication }}{{ index < computedBannerDefinitions.syndications.length - 1 ? "," : "" }}
                  </span>
                </template>
              </div>
            </div>
            <div
              id="showing-paused-buttons"
              class="d-flex justify-content-center mt-md-0 mt-1 pr-3"
            >
              <ElButton
                v-if="pausedUntil || futurePause"
                size="small"
                class="outline-white"
                @click="updatePauseTime(true)"
              >
                <span class="d-flex align-items-center">
                  <i class="sdicon-pencil mr-1" />
                  <span>Edit pause</span>
                </span>
              </ElButton>
              <ElButton
                v-if="!computedBannerDefinitions.isNotShowing"
                size="small"
                class="outline-white"
                @click="updateStopShowing"
              >
                <span class="d-flex align-items-center">
                  <i class="sdicon-stop mr-1" />
                  <span>Stop</span>
                </span>
              </ElButton>

              <ElButton
                v-if="!computedBannerDefinitions.isNotShowing && !futurePause && !pausedUntil"
                size="small"
                class="outline-white"
                @click="updatePauseTime(false)"
              >
                <span class="d-flex align-items-center">
                  <i class="sdicon-pause mr-1" />
                  <span>Pause</span>
                </span>
              </ElButton>
              <ElButton
                v-if="computedBannerDefinitions.isNotShowing || pausedUntil || futurePause"
                size="small"
                class="start-showing border-1"
                :class="computedBannerDefinitions.isNotShowing ? 'el-button--success' : 'outline-white'"
                @click="showProperty"
              >
                <span class="d-flex align-items-center">
                  <i class="sdicon-play mr-1" />
                  <span v-if="computedBannerDefinitions.isNotShowing">
                    Start Showing
                  </span>
                  <span v-else>
                    Resume
                  </span>
                </span>
              </ElButton>
            </div>
          </div>
        </div>
      </SdBanner>
    </Portal>

    <SdActionButtons
      v-if="uiFlags.isNarrowView"
      :actions="actionsRef"
      :force-floating-actions-buttons="true"
    />

    <SdLoadingLayer :init="init">
      <template v-slot:loaded>
        <router-link
          :to="backRoute"
          class="col-auto back-action"
        >
          <img src="@/assets/back-arrow.svg">
        </router-link>
        <div v-if="unitRef.showing_enabled">
          <UnitPauseDialog
            v-if="actionsRef.pauseShowing.uiFlag"
            :unit="unitRef"
            :visible="actionsRef.pauseShowing.uiFlag"
            :edit-data="actionsRef.pauseShowing.editData"
            @close="pauseDialogClosed"
          />
          <UnitUnpauseDialog
            v-if="uiFlags.isUnpauseDialogVisible"
            :unit="unitRef"
            :visible="uiFlags.isUnpauseDialogVisible"
            @close="unpauseDialogClosed"
          />
          <UnitStopDialog
            v-if="actionsRef.stopShowing.uiFlag"
            :unit="unitRef"
            :visible="actionsRef.stopShowing.uiFlag"
            @close="stopDialogClosed"
          />
          <UnitAddInquiryDialog
            v-if="actionsRef.addInquiry.uiFlag"
            :unit="unitRef"
            :lead="leadToScheduleTourFor"
            @close="addInquiryDialogClosed"
          />
          <ElDialog
            v-if="!uiFlags.isDesktop && actionsRef.qrCode.uiFlag"
            id="unit-link-dialog"
            :center="true"
            :show-close="true"
            :fullscreen="false"
            :destroy-on-close="true"
            :visible="actionsRef.qrCode.uiFlag"
            width="100%"
            @close="qrCodeDialogClosed"
          >
            <div slot="title">
              <h2 class="m-0">
                {{ unitRef.property && unitRef.property.short_address ? unitRef.property.short_address : "" }}
                {{ unitRef.name ? ` (${unitRef.name})` : "" }}
                Listing Link
              </h2>
            </div>
            <UnitLinkAndQrCodeDialog :unit="unitRef" />
          </ElDialog>
        </div>
        <template v-else>
          <UnitDeletePropertyDialog
            v-if="actionsRef.deleteProperty.uiFlag"
            :property="unitRef.property"
            :visible="actionsRef.deleteProperty.uiFlag"
            @close="deletePropertyDialogClosed"
          />
        </template>
        <ServicesDialog
          v-if="uiFlags.showBookAServiceDialog"
          :unit="unitRef"
          @close="uiFlags.showBookAServiceDialog = false"
        />
        <PropertyRentedDialog
          v-if="showPropertyRentedDialogRef"
          :title="`${unitRef.property.short_address}${unitRef.name ? ` - ${unitRef.name}` : ''}`"
          :first-time-opened="true"
          :is-rented="isRentedRef"
          :showing-profile-id="showingProfileId"
          :visible.sync="showPropertyRentedDialogRef"
          @close="propertyRentedDialogClosed"
        />
        <MoveInConditionReportPrompt
          :visible="uiFlags.isMoveInConditionReportPromptVisible"
          :unit="unitRef"
          @close="uiFlags.isMoveInConditionReportPromptVisible = false"
        />
        <UnitHero
          :key="unitRef.last_fetched_key"
          :is-unit-showing="unitRef.showing_enabled"
          :unit="unitRef"
          class="unit-hero"
          :class="uiFlags.isDesktop ? 'mb-3' : ''"
          @order-report="navigateToOrderReportPage"
          @edit-unit="navigateToEditListingTab"
          @add-inquiry="actionsRef.addInquiry.uiFlag = true"
          @pause-showing="actionsRef.pauseShowing.uiFlag = true"
          @unpause-showing="actionsRef.isUnpauseDialogVisible.uiFlag = true"
          @stop-showing="falseactionsRef.stopShowing.uiFlag = true"
          @start-showing="showProperty"
        />
        <ElTabs
          v-model="currentTab"
          :stretch="true"
          :class="uiFlags.isNarrowView ? 'px-2' : ''"
          @tab-click="changeTab"
        >
          <ElTabPane
            :key="`${tabRenderCounter.overview}`"
            v-touch:swipe="swipeHandler"
            :label="tabNames.overview"
            :disabled="uiFlags.isLoadingInnerPage"
            :name="getTabKey(tabNames.overview)"
            :lazy="true"
          >
            <section class="mt-1 mb-2 row justify-content-between">
              <div
                v-if="!uiFlags.isNarrowView"
                class="col-4 pt-0"
              >
                <UnitInfo
                  :key="unitRef.id"
                  :is-page="false"
                  :unit="unitRef"
                  :is-rendered-from-unit-page="isRenderedFromUnitPage"
                  @edit="navigateToEditListingTab"
                  @sync="syncUnit"
                />
              </div>
              <div class="col pt-0">
                <UnitActivities
                  v-if="showingProfileId"
                  :is-rendered-from-unit-page="isRenderedFromUnitPage"
                  :class="uiFlags.isDesktop ? 'col-lg-12 pt-0' : ''"
                  :unit="unitRef"
                  @reloadUnit="init"
                  @start-fetching="uiFlags.isLoadingInnerPage = true"
                  @stop-fetching="uiFlags.isLoadingInnerPage = false"
                />
                <div
                  v-if="!showingProfileId && unitRef.deleted_at === null"
                  class="col-lg-9"
                >
                  <div
                    class="row flex-column justify-content-center align-items-center"
                    :class="{ 'pl-4 pr-4': !uiFlags.isDesktop }"
                  >
                    <div class="mt-4">
                      <h3 class="text-center text-gray-darker font-21 mb-0 mt-0">
                        Book a service
                      </h3>
                      <ServicesMenu
                        class="empty-state-services"
                        size="small"
                        :unit="unitRef"
                        :property="unitRef.property"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </ElTabPane>

          <ElTabPane
            v-if="isShowingProfileNotPublishListing"
            v-touch:swipe="swipeHandler"
            :label="tabNames.tours"
            :disabled="uiFlags.isLoadingInnerPage"
            :name="getTabKey(tabNames.tours)"
            :lazy="true"
          >
            <Tours
              :key="`${tabRenderCounter.tours}`"
              :unit="unitRef"
              :is-rendered-from-unit-page="isRenderedFromUnitPage"
              @start-fetching="uiFlags.isLoadingInnerPage = true"
              @stop-fetching="uiFlags.isLoadingInnerPage = false"
            />
          </ElTabPane>

          <ElTabPane
            v-touch:swipe="swipeHandler"
            :label="tabNames['condition-reports']"
            :disabled="uiFlags.isLoadingInnerPage"
            :name="getTabKey(tabNames['condition-reports'])"
            :lazy="true"
          >
            <ConditionReports
              :key="`${tabRenderCounter['condition-reports']}`"
              :can-order-reports="unitRef.deleted_at === null"
              :is-rendered-from-unit-page="isRenderedFromUnitPage"
              @start-fetching="uiFlags.isLoadingInnerPage = true"
              @stop-fetching="uiFlags.isLoadingInnerPage = false"
            />
          </ElTabPane>

          <ElTabPane
            v-touch:swipe="swipeHandler"
            :label="tabNames.prospects"
            :disabled="uiFlags.isLoadingInnerPage"
            :name="getTabKey(tabNames.prospects)"
            :lazy="true"
          >
            <Prospects
              v-if="showingProfileId && !showPropertyRentedDialogRef"
              :key="`${tabRenderCounter.prospects}`"
              :unit="unitRef"
              @schedule-tour="scheduleTourForLead"
              @start-fetching="uiFlags.isLoadingInnerPage = true"
              @stop-fetching="uiFlags.isLoadingInnerPage = false"
            />
          </ElTabPane>

          <ElTabPane
            v-if="uiFlags.isNarrowView"
            v-touch:swipe="swipeHandler"
            :label="tabNames.info"
            :disabled="uiFlags.isLoadingInnerPage"
            :name="getTabKey(tabNames.info)"
            :lazy="true"
          >
            <UnitInfo
              :key="unitRef.id"
              class="col mt-1 pb-5"
              :unit="unitRef"
              :is-rendered-from-unit-page="isRenderedFromUnitPage"
              @edit="navigateToEditListingTab"
              @sync="syncUnit"
            />
          </ElTabPane>

          <ElTabPane
            v-touch:swipe="swipeHandler"
            :label="tabNames.listing"
            :disabled="uiFlags.isLoadingInnerPage"
            :name="getTabKey(tabNames.listing)"
            :lazy="true"
          >
            <el-alert
              v-if="unitRef.property.is_null_location"
              :center="true"
              type="warning"
              show-icon
              :closable="false"
              class="mt-1 mb-2"
            >
              {{ updateAddressMessageText }}
            </el-alert>
            <UnitListingSettings
              :key="`${tabRenderCounter.listing}`"
              :is-rendered-from-unit-page="isRenderedFromUnitPage"
              :is-loading-inner-page="uiFlags.isLoadingInnerPage"
              @start-fetching="uiFlags.isLoadingInnerPage = true"
              @stop-fetching="uiFlags.isLoadingInnerPage = false"
              @reloadUnit="init"
            />
          </ElTabPane>
        </ElTabs>
      </template>
    </SdLoadingLayer>
  </SdPage>
</template>

<script>
import {
  computed, reactive, ref, watch,
} from '@vue/composition-api';
import get from 'lodash.get';
import MoveInConditionReportPrompt from '@/components/unit/MoveInConditionReportPrompt';
import { Portal } from 'portal-vue';
import { getPauseReasonLabel, getUnitShowingTypeLabel } from '@/constants/UnitShowingProfile';
import Showing from '@/constants/Showing';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import PropertyRentedDialog from '@/components/property/PropertyRentedDialog';
import UnitInfo from '@/components/unit/UnitInfo';
import UnitActivities from '@/components/unit/UnitActivities';
import UnitPauseDialog from '@/components/unit/UnitPauseDialog';
import UnitStopDialog from '@/components/unit/UnitStopDialog';
import UnitUnpauseDialog from '@/components/unit/UnitUnpauseDialog';
import UnitAddInquiryDialog from '@/components/unit/UnitAddInquiryDialog';
import UnitDeletePropertyDialog from '@/components/unit/UnitDeletePropertyDialog';
import ServicesDialog from '@/components/service/ServicesDialog';
import ServicesMenu from '@/components/service/ServicesMenu';
import { getTabFromUrlAndLocalStorage, updateTabInUrlAndLocalStorage } from '@/utils/RouterUtil';
import SdActionButtons from '@/components/common/SdActionButtons';
import UnitLinkAndQrCodeDialog from '@/components/unit/UnitLinkAndQrCodeDialog';
import UnitListingSettings from '@/components/unit/UnitListingSettings';
import { isBusinessOnlyUsingAgentShowings } from '@/utils/BusinessUtil';
import { SHOWING_PROFILE_ROUTES } from '@/router/routes';
import UnitHero from './UnitHero';
import Prospects from '../Prospects/Prospects';
import Tours from '../Tours/Tours';
import ConditionReports from '../ConditionReports';

export default {
  name: 'Unit',
  components: {
    ConditionReports,
    Prospects,
    Tours,
    MoveInConditionReportPrompt,
    ServicesMenu,
    ServicesDialog,
    UnitActivities,
    UnitInfo,
    UnitHero,
    UnitPauseDialog,
    UnitStopDialog,
    UnitUnpauseDialog,
    UnitAddInquiryDialog,
    UnitDeletePropertyDialog,
    PropertyRentedDialog,
    Portal,
    SdActionButtons,
    UnitLinkAndQrCodeDialog,
    UnitListingSettings,
  },
  methods: { get },
  setup(props, context) {
    watch(() => context.root.$store.state.Property.property.updated_at, init);
    const isDesktop = context.root.$viewport.gt.lg;
    const isNarrowView = context.root.$viewport.lt.lg;
    const tabNames = {
      overview: 'Overview',
      tours: 'Tours',
      'condition-reports': 'Reports',
      prospects: 'Prospects',
      listing: 'Details',
    };

    if (!isDesktop) {
      tabNames.info = 'Info';
    }

    const updateAddressMessageText = 'Please update the address before booking a service.';
    const dispatch = context.root.$store.dispatch;
    const unitRef = ref(null);
    const shouldShowBannerDetails = ref(false);
    const showingProfileId = ref(null);
    const currentTab = ref(
        getTabFromUrlAndLocalStorage({
          router: context.root.$router,
          tabs: Object.keys(tabNames),
          shouldOmitLocalStorage: true,
        }) || 'overview',
    );
    const backRoute = computed(computeBackRoute);
    const pausedUntil = computed(computePausedUntil);
    const futurePause = computed(computeFuturePause);
    const actionsRef = ref({});
    const showPropertyRentedDialogRef = ref(false);
    const isRenderedFromUnitPage = ref(context.root.$route.name === 'app.units.show');
    const isRentedRef = ref(false);
    const uiFlags = reactive({
      isLoadingInnerPage: false,
      isUnpauseDialogVisible: false,
      showBookAServiceDialog: false,
      isMoveInConditionReportPromptVisible: false,
      isDesktop,
      isNarrowView,
    });
    const business = context.root.$store.getters['Auth/business'];
    const leadToScheduleTourFor = ref(null);
    const tabRenderCounter = reactive(Object.assign({}, ...Object.keys(tabNames).map((key) => ({ [key]: 0 }))));
    const isShowingProfileNotPublishListing = ref(true);
    const computedBannerDefinitions = computed(determineBannerVisibilityAndClass);
    const shouldShowSyndicationSource = false;
    const showingTypeName = computed(() => getUnitShowingTypeLabel(get(unitRef.value, 'active_showing_profile.type')));

    return {
      shouldShowBannerDetails,
      shouldShowSyndicationSource,
      computedBannerDefinitions,
      updateAddressMessageText,
      tabRenderCounter,
      navigateToEditListingTab,
      tabNames,
      currentTab,
      actionsRef,
      pausedUntil,
      futurePause,
      isRentedRef,
      uiFlags,
      unitRef,
      backRoute,
      showPropertyRentedDialogRef,
      showingProfileId,
      leadToScheduleTourFor,
      isRenderedFromUnitPage,
      toggleActionUiFlag,
      showingTypeName,
      swipeHandler,
      init,
      stopDialogClosed,
      pauseDialogClosed,
      unpauseDialogClosed,
      deletePropertyDialogClosed,
      addInquiryDialogClosed,
      showProperty,
      updatePauseTime,
      updateStopShowing,
      propertyRentedDialogClosed,
      qrCodeDialogClosed,
      getPauseReasonLabel,
      scheduleTourForLead,
      syncUnit,
      changeTab,
      getTabKey,
      navigateToOrderReportPage,
      isShowingProfileNotPublishListing,
    };

    function getTabKey(tabName) {
      const matchedTab = Object.entries(tabNames).find(([_, currTab]) => currTab === tabName);
      if (Array.isArray(matchedTab)) {
        return matchedTab[0];
      }
      return null;
    }

    function changeTab(tabComponent) {
      const tab = tabComponent.name;
      tabRenderCounter[tab] += 1;
      if (tab) {
        updateTabInUrlAndLocalStorage({ router: context.root.$router, tab, shouldOmitSaveToLocalStorage: true });
      }
    }

    function swipeHandler(direction) {
      if (uiFlags.isLoadingInnerPage || uiFlags.isDesktop) {
        return;
      }

      const tabKeys = Object.keys(tabNames);
      const currentTabIndex = tabKeys.findIndex((key) => key === currentTab.value);
      let nextTabIndex;
      if (direction === 'left') {
        nextTabIndex = (currentTabIndex + 1) % tabKeys.length;
        currentTab.value = tabKeys[nextTabIndex];
        changeTab({ name: currentTab.value });
      } else if (direction === 'right') {
        nextTabIndex = (currentTabIndex - 1 + tabKeys.length) % tabKeys.length;
        currentTab.value = tabKeys[nextTabIndex];
        changeTab({ name: currentTab.value });
      }
    }

    async function init() {
      unitRef.value = await dispatch('Unit/find', context.root.$route.params.unit_id);
      const unit = unitRef.value;
      // Since we using a nested object and we want to update the coresponding components when new data arrive - we must assign it a key
      unitRef.value.last_fetched_key = Date.now();
      document.title += ` | ${unit.property.short_address}${unit.name ? ` - ${unit.name}` : ''}`;
      showingProfileId.value = get(unitRef.value, 'last_showing_profile.id');
      isShowingProfileNotPublishListing.value = get(unitRef.value, 'active_showing_profile.type') !== Showing.TYPE_PUBLISH_LISTING;
      initActions();
      if (typeof context.root.$route.query['unpause-dialog'] !== 'undefined' && unitRef.value.paused_until) {
        showProperty();
      }

      shouldShowBannerDetails.value = true;
    }

    function initActions() {
      actionsRef.value = {
        service: {
          label: 'Book a service',
          visible: !uiFlags.isDesktop,
          disabled: context.root.$store.getters['Auth/accountIsInactive'],
          tooltipText: context.root.$store.getters['Auth/accountIsInactive'] ? 'Please renew your subscription' : null,
          buttonType: 'primary',
          icon: 'sdicon-calendar-plus',
          cb: showServicesDialog,
        },
        show: {
          label: 'Start showing',
          visible: !unitRef.value.showing_enabled && unitRef.value.deleted_at === null,
          disabled: context.root.$store.getters['Auth/accountIsInactive'],
          tooltipText: context.root.$store.getters['Auth/accountIsInactive'] ? 'Please renew your subscription' : null,
          buttonType: 'primary',
          icon: 'dot bg-green ml-1 mr-1',
          cb: showProperty,
        },
        addInquiry: {
          label: 'Add inquiry',
          visible: unitRef.value.showing_enabled,
          cb: () => {
            actionsRef.value.addInquiry.uiFlag = true;
          },
          uiFlag: false,
          buttonType: 'primary',
          icon: 'sdicon-user-plus',
        },
        edit: {
          label: 'Edit',
          visible: unitRef.value.deleted_at === null,
          cb: navigateToEditListingTab,
          icon: 'sdicon-pencil',
        },
        stopShowing: {
          label: get(unitRef.value.last_showing_profile, 'is_open_house') ? 'Stop open house' : 'Stop showing',
          visible: false && unitRef.value.showing_enabled,
          cb: () => {
            actionsRef.value.stopShowing.uiFlag = true;
          },
          uiFlag: false,
          icon: 'dot bg-red ml-1 mr-1',
        },
        pauseShowing: {
          label: get(unitRef.value.last_showing_profile, 'is_open_house') ? 'Pause open house' : 'Pause showing',
          visible: false && unitRef.value.showing_enabled && !pausedUntil.value && !futurePause.value,
          cb: () => {
            actionsRef.value.pauseShowing.uiFlag = true;
          },
          uiFlag: false,
          icon: 'sdicon-pause',
          editData: null,
        },
        deleteProperty: {
          visible: false,
          uiFlag: false,
        },
        qrCode: {
          label: 'QR code',
          visible: unitRef.value.showing_enabled,
          uiFlag: false,
          icon: 'sdicon-qrcode',
          editData: null,
          cb: () => {
            actionsRef.value.qrCode.uiFlag = true;
          },
        },
      };
    }

    function determineBannerVisibilityAndClass() {
      const definition = {
        isVisible: true,
        class: '',
      };
      if (pausedUntil.value) {
        definition.class = 'bg-red';
      } else if (futurePause.value) {
        definition.class = 'bg-orange';
      } else if (!get(unitRef.value, 'active_showing_profile')) {
        definition.isVisible = true;
        definition.class = 'bg-gray-darker';
        definition.isNotShowing = true;
      } else {
        const isZumpierEnabled = Boolean(business.zumper_syndication_enabled_at);
        const isZillowEnabled = Boolean(business.zillow_syndication_enabled_at);
        definition.class = 'bg-greeneyes';
        definition.syndications = [];
        if (isZumpierEnabled) {
          definition.syndications.push('Zumper');
        }
        if (isZillowEnabled) {
          definition.syndications.push('Zillow');
        }
      }

      return definition;
    }

    function navigateToEditListingTab() {
      const tabName = getTabKey(tabNames.listing);
      currentTab.value = tabName;
      changeTab({ name: tabName });
    }

    function navigateToOrderReportPage() {
      context.root.$router.push({
        name: 'app.condition-report.add',
        params: {
          id: context.root.$route.params.id,
          unit_id: context.root.$route.params.unit_id,
        },
      });
    }

    async function syncUnit() {
      try {
        await context.root.$store.dispatch('Unit/syncUnit', unitRef.value.id);
        await init();
        showSuccessNotify(context, 'Unit was synced successfully');
      } catch (error) {
        showErrorNotify(context, error && error.message ? error.message : 'Error occurred while syncing unit');
      }
    }

    function toggleActionUiFlag(actionKey) {
      actionsRef.value[actionKey].uiFlag = !actionsRef.value[actionKey].uiFlag;
    }

    function computeBackRoute() {
      const unitsCount = get(unitRef, 'value.property.units_showing.total_units_count', 0);
      if (unitsCount > 1) {
        return { name: 'app.units', params: { id: unitRef.value.property.id } };
      }
      return { name: 'app.properties' };
    }

    async function stopDialogClosed(status, isRentedWithShowdigs) {
      actionsRef.value.stopShowing.uiFlag = false;
      if (status === 'success') {
        await init();
        showSuccessNotify(context, 'Showing profile deactivated successfully');
        showPropertyRentedDialogRef.value = true;
        isRentedRef.value = isRentedWithShowdigs;
      }
    }

    async function pauseDialogClosed(data) {
      const isEdit = actionsRef.value.pauseShowing.editData;
      actionsRef.value.pauseShowing.uiFlag = false;
      actionsRef.value.pauseShowing.editData = null;
      if (data) {
        await init();
        let message = !isEdit ? 'Showing profile paused successfully' : 'Showing profile pause end time updated successfully';
        if (data.pauseFrom) {
          message = !isEdit ? 'Showing pause successfully scheduled' : 'Scheduled pause updated successfully';
        }
        showSuccessNotify(context, message);
      }
    }

    async function unpauseDialogClosed(status) {
      uiFlags.isUnpauseDialogVisible = false;
      if (status === 'success') {
        await init();
        showSuccessNotify(context, 'Showing profile resumed successfully');
      }
    }

    async function deletePropertyDialogClosed(status) {
      actionsRef.value.deleteProperty.uiFlag = false;
      if (status === 'success') {
        await init();
        showSuccessNotify(
            context,
            `${unitRef.value.property.short_address} deleted successfully`,
            { dangerouslyUseHTMLString: true },
            'Property Deleted',
        );
      }
    }

    async function addInquiryDialogClosed(status) {
      actionsRef.value.addInquiry.uiFlag = false;
      leadToScheduleTourFor.value = null;
      if (status === 'success') {
        await init();
      }
    }

    async function qrCodeDialogClosed(status) {
      actionsRef.value.qrCode.uiFlag = false;
    }

    function showProperty() {
      const isUnpause = (pausedUntil.value || futurePause.value) && unitRef.value.showing_enabled;
      if (isUnpause) {
        uiFlags.isUnpauseDialogVisible = true;
      } else {
        context.root.$router.push({
          name: isBusinessOnlyUsingAgentShowings() ? SHOWING_PROFILE_ROUTES.AGENT_SHOWING_PAGE : SHOWING_PROFILE_ROUTES.TYPE_SELECTION_PAGE,
          params: {
            id: context.root.$route.params.id,
            unit_id: context.root.$route.params.unit_id,
          },
        });
      }
    }

    function computePausedUntil() {
      const pausedUntil = get(unitRef, 'value.last_showing_profile.paused_until');
      return pausedUntil ? context.root.$momentUtil(pausedUntil, unitRef.value.property.timezone) : null;
    }

    function computeFuturePause() {
      const futurePause = get(unitRef, 'value.last_showing_profile.future_pause');
      if (!futurePause) {
        return null;
      }
      return {
        paused_until: context.root.$momentUtil(futurePause.paused_until, unitRef.value.property.timezone),
        pause_from: context.root.$momentUtil(futurePause.pause_from, unitRef.value.property.timezone),
      };
    }

    function updateStopShowing() {
      actionsRef.value.stopShowing.uiFlag = true;
    }

    function updatePauseTime(isEdit) {
      actionsRef.value.pauseShowing.uiFlag = true;
      if (isEdit) {
        actionsRef.value.pauseShowing.editData = {
          pausedUntil: pausedUntil.value || get(futurePause.value, 'paused_until'),
          pauseFrom: get(futurePause.value, 'pause_from'),
          pauseReason: unitRef.value.last_showing_profile.pause_reason,
        };
      }
    }

    function showServicesDialog() {
      uiFlags.showBookAServiceDialog = true;
    }

    function propertyRentedDialogClosed() {
      showPropertyRentedDialogRef.value = false;
      if (!unitRef.value.last_showing_profile.bundled_inspection && get(unitRef.value.property.market, 'inspection_agents_available')) {
        uiFlags.isMoveInConditionReportPromptVisible = true;
      }
    }

    function scheduleTourForLead(lead) {
      leadToScheduleTourFor.value = lead;
      actionsRef.value.addInquiry.uiFlag = true;
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/utils";

#unit-page {
  position: relative;

  &.archived-state {
    .property-info-card,
    .activity-card,
    .lead-card {
      pointer-events: none;
      opacity: 0.4;

      .view-report-btn {
        display: none;
      }
    }

    .show-more {
      display: none !important;
    }

    .deleted-tag {
      margin-bottom: 0.25rem;
      background-color: $danger;
      display: inline-flex;
      vertical-align: middle;
    }

    // first-child will be always a Deleted Property activity
    .activity-card:first-child {
      background-color: gray-color("darker");
      opacity: 0.9 !important;
      color: $white !important;
    }
  }

  .services-menu ~ .bottom-text {
    text-align: center;
  }

  .back-action {
    position: absolute;
    display: flex;
    left: -65px;
    z-index: 1;

    @media (max-width: 1439px) {
      top: 15px;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before {
        z-index: -1;
        content: "";
        position: absolute;
        width: 40px;
        height: 40px;
        background: white;
        border-radius: 50%;
      }
    }
  }
}

.property-dialog {
  .el-dialog {
    max-width: 545px;
  }
}

#unit-page.narrow {
  @include generate-horizontal-tabs-mobile-view;

  padding: 0;

  .unit-hero {
    border-bottom: 1px solid gray-color("light");
    margin-bottom: 4px;
  }

  .el-card {
    border-radius: unset;
  }

  .el-table {
    font-size: $font-size-sm;

    .cell {
      padding: 0 2rem;

      span {
        white-space: nowrap;
      }
    }

    th,
    td {
      padding: 10px 0;
    }
  }

  @media (max-width: 540px) {
    .tour-page-row {
      margin: -0.8rem;
    }
  }
}

.showing-details-indicators {
  @media (max-width: 1200px) {
    text-align: left;
  }
  @media (max-width: 767px) {
    text-align: center;
    margin-bottom: 0.5rem;
  }
}

#showing-paused-buttons {
  @include media-breakpoint-up(md) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  > button.outline-white {
    background: transparent;
    color: $white;
    border-color: $white;

    &:hover {
      background: $white;
      color: $black;
    }

    &:active {
      border-color: $white;
    }
  }
}

.empty-state-services {
  margin-top: 1rem;

  .services-menu-item.small {
    .services-menu-item-content {
      padding: 1.75rem 0.5rem;
    }
  }
}

#unit-link-dialog {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;

  .el-dialog {
    margin-top: 0 !important;
    height: auto;
    border-radius: 20px;
  }
}
</style>
