<template>
  <router-link
    :to="{ name: 'app.prospect.show', params: { id: prospect.id } }"
    class="prospect row no-gutters"
  >
    <div class="col-lg-5 col-12 align-self-center pl-2 pl-lg-3 pt-3 pb-0 py-lg-3">
      <div class="row no-gutters align-items-center mb-1">
        <div class="mr-1">
          {{ prospect.full_name }}
        </div>
        <HotLeadBadge v-if="definition.is_hot_lead || definition.is_likely_to_apply" />
      </div>
      <div class="row no-gutters">
        <SdPhoneIndicator
          v-if="prospect.phone_number"
          with-icon
          class="mb-1"
          :phone-number="prospect.phone_number"
          :formatted-phone-number="prospect.formatted_phone_number"
        />
        <span class="mx-1" />
        <SdEmailIndicator
          v-if="prospect.email"
          with-icon
          class="mb-1"
          :email="prospect.email"
        />
      </div>
    </div>
    <div class="col-lg col-12 align-self-center pl-2 pl-lg-3">
      <div
        v-if="!isRenderedFromUnitPage"
        class="row no-gutters"
      >
        <span>
          {{ `${prospect.recent_inquiry.property.short_address} ${prospect.recent_inquiry.unit &&
            prospect.recent_inquiry.unit.name ? " - " + prospect.recent_inquiry.unit.name : ""}` }}
        </span>
      </div>

      <div class="row no-gutters">
        <div class="col-12 activity-id-title text-gray-dark mr-1 mb-1">
          <component
            :is="definition.scheduleInquiryId ? 'router-link' : 'div'"
            :to="definition.scheduleInquiryId ? { name: 'app.tours.show', params: { id: definition.scheduleInquiryId } } : null"
          >
            <i
              v-if="definition.is_open_house"
              class="sdicon-group font-14"
            />
            <i
              v-if="definition.is_self_showing"
              class="sdicon-key font-14"
            />
            <span v-if="withActivityId">
              {{ definition.activityIdText }}
            </span>
            <i
              v-if="withActivityId"
              class="dot mx-1 bg-gray-light font-13"
            />
            <span>{{ definition.activityText }}</span>
          </component>
        </div>

        <div
          v-if="prospect.recent_inquiry.source && prospect.recent_inquiry.source.name"
          class="col-12 font-14 text-gray-dark"
        >
          Source: <span class="text-gray-darker">{{ prospect.recent_inquiry.source.name }}</span>
        </div>
      </div>
    </div>
    <div class="col-lg-auto col-12 ml-auto align-self-center mt-2 mt-lg-1 mb-3 mb-lg-0 pl-0 pl-lg-3 pr-3">
      <div class="row no-gutters">
        <router-link
          v-if="definition.shouldShowViewReport"
          :to="{ name: 'app.tours.show', params: { id: definition.scheduleInquiryId } }"
          class="mr-2"
        >
          <ElButton
            class="action-button"
            size="small"
          >
            View report
          </ElButton>
        </router-link>
        <div
          v-if="definition.shouldShowScheduleTourButton"
          class="mr-2"
        >
          <ElButton
            class="action-button"
            size="small"
            @click.prevent="$emit('schedule-tour')"
          >
            Schedule tour
          </ElButton>
        </div>
        <SdStatusBadge
          fixed-width="75px"
          :type="definition.badge"
          class="m-0"
        />
      </div>
    </div>
  </router-link>
</template>

<script>
import SdStatusBadge from '@/components/common/SdStatusBadge';
import SdPhoneIndicator from '@/components/common/SdPhoneIndicator';
import SdEmailIndicator from '@/components/common/SdEmailIndicator';
import Inquiry from '@/constants/Inquiry';
import HotLeadBadge from '@/components/prospect/badges/HotLeadBadge.vue';
import Tour from '@/constants/Tour';
import get from 'lodash.get';
import { computed } from '@vue/composition-api';
import { getTourStatusDescription, isTourStatusIssue } from '@/utils/TourUtil';
import { getInquiryActivityDescription, getInquiryStatus } from '@/utils/InquiryUtils';
import momentUtil from '@/utils/MomentUtil';

export default {
  name: 'ProspectListItem',
  components: {
    SdPhoneIndicator,
    SdStatusBadge,
    SdEmailIndicator,
    HotLeadBadge,
  },
  props: {
    isRenderedFromUnitPage: {
      type: Boolean,
    },
    prospect: {
      type: Object,
      required: true,
    },
    withActivityId: {
      type: Boolean,
      required: false,
      default: false,
    },
    withScheduleTourAction: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const definition = computed(() => getItemDefinition(props.prospect.recent_inquiry));

    return {
      definition,
    };

    function getProspectTourField(inquiry, field) {
      if (inquiry?.status === Inquiry.STATUS_NEW) { // New inquries shouldn't show anything regarding tours
        return null;
      }
      return get(inquiry, `schedule_inquiry.tour${field ? `.${field}` : ''}`) || null;
    }

    function getItemDefinition(inquiry) {
      const tour = getProspectTourField(inquiry);
      const isOpenHouse = tour?.is_open_house;
      const isSelfShowing = tour?.is_self_showing;
      const tourId = tour?.id;
      const tourStatus = tour?.status;
      const isTourIssue = isTourStatusIssue(tour);
      const shouldShowScheduleTourButton = props.withScheduleTourAction && [Inquiry.STATUS_NEW, Inquiry.STATUS_PENDING]
        .includes(props.prospect.recent_inquiry && props.prospect.recent_inquiry.status);
      const shouldShowViewReport = tour?.survey_result_id;
      const scheduleInquiryId = inquiry?.schedule_inquiry?.schedule_id;

      function getActivityId() {
        let tourType = 'Tour';
        if (!tourId) {
          return `Inquiry #${inquiry.id}`;
        }
        if (isSelfShowing) {
          tourType = 'Self Showing';
        }
        if (isOpenHouse) {
          tourType = 'Open House';
        }

        return `${tourType} #${tourId}`;
      }

      function getProspectActivityText() {
        if (tour) {
          const localTzTourDate = momentUtil(tour.tour_at, inquiry.property?.timezone);
          return getTourStatusDescription(
            tourStatus,
            localTzTourDate,
            isSelfShowing,
            isOpenHouse,
          );
        }

        return getInquiryActivityDescription(inquiry.created_at, inquiry.property?.timezone);
      }

      function getStatusBadge() {
        const lead = inquiry?.lead;
        const tour = inquiry?.schedule_inquiry?.tour;
        const lastShowingProfile = inquiry?.unit?.last_showing_profile;

        const status = getInquiryStatus(inquiry, lead, tour, lastShowingProfile);

        return `inquiry-${status}`;
      }

      return {
        activityIdText: getActivityId(),
        activityText: getProspectActivityText(),
        badge: getStatusBadge(),
        is_tour_issue: isTourIssue,
        is_likely_to_apply: tour?.is_likely_to_apply,
        is_hot_lead: props.prospect.is_hot_lead,
        is_open_house: isOpenHouse,
        is_self_showing: isSelfShowing,
        shouldShowScheduleTourButton,
        shouldShowViewReport,
        scheduleInquiryId,
      };
    }
  },
};
</script>

<style lang="scss">
.prospect {
  min-height: 92px;
  position: relative;
  background: $white;
  border-radius: $app-border-radius;
  font-size: $--font-size-base;
  transition: $app-transition;
  margin-bottom: 0.5rem;

  &,
  &:hover {
    color: $--color-text-primary;
  }

  &:hover {
    box-shadow: 4px 2px 6px rgba(0, 0, 0, 0.15);
  }

  >div {
    &:nth-child(1) {
      border-right: 1px solid gray-color(light);
      margin-right: 10px;
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;

    >div {
      &:nth-child(1) {
        border-right: 1px solid transparent;
      }
    }
  }

  .dot {
    position: relative;
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  padding: 0;
  height: 32px;
}
</style>
