<template>
  <ElDialog
    top="30vh"
    :center="true"
    :show-close="false"
    :visible="visible"
    width="545px"
    :fullscreen="$viewport.lt.md"
    @close="$emit('close')"
  >
    <div slot="title">
      <h2 class="mb-1">
        Restart {{ uiFlags.isOpenHouse ? 'open house' : 'showing' }}
      </h2>
    </div>
    <div class="text-center">
      {{ computedText() }}
      Are you sure?
    </div>
    <span
      slot="footer"
      class="row justify-content-center"
    >
      <div class="col-auto">
        <ElButton
          class="w-100"
          size="medium"
          @click="$emit('close')"
        >
          Cancel
        </ElButton>
      </div>
      <div class="col-auto">
        <ElButton
          class="w-100"
          size="medium"
          type="primary"
          :disabled="uiFlags.isLoading"
          :loading="uiFlags.isLoading"
          @click="restartShowing"
        >
          Restart {{ uiFlags.isOpenHouse ? 'open house' : 'showing' }}
        </ElButton>
      </div>
    </span>
  </ElDialog>
</template>

<script>
import { reactive } from '@vue/composition-api';
import { showErrorNotify } from '@/utils/NotifyUtil';
import Showing from '@/constants/Showing';

export default {
  name: 'UnitRestartShowingDialog',
  components: {},
  props: {
    showingProfileId: {
      type: Number,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    unit: {
      required: true,
      type: Object,
    },
  },
  setup(props, context) {
    const $store = context.root.$store;
    const uiFlags = reactive({
      isLoading: false,
      isOpenHouse: props.unit?.last_showing_profile?.type === Showing.TYPE_OPEN_HOUSE,
      isPublishListing: props.unit?.last_showing_profile?.type === Showing.TYPE_PUBLISH_LISTING,
    });
    return {
      restartShowing,
      uiFlags,
      computedText,
    };

    function computedText() {
      if (uiFlags.isPublishListing) {
        return '';
      }

      if (uiFlags.isOpenHouse) {
        return 'Prospects will be able to schedule an open house for this property again\n.\n'
          + 'Previously scheduled open houses will not be restored.';
      }
      return 'Prospects will be able to schedule tours for this property again.\n'
        + 'Previously scheduled tours will not be restored.';
    }

    async function restartShowing() {
      uiFlags.isLoading = true;
      try {
        await $store.dispatch('ShowingProfile/reactivate', props.showingProfileId);
        context.emit('activate-showing');
      } catch (error) {
        showErrorNotify(context, error.message);
        context.emit('close');
      } finally {
        uiFlags.isLoading = false;
      }
    }
  },
};
</script>
