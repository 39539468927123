const TABS_LOCAL_HISTORY_KEY_SUFFIX = '_current_tab';
export function getTabFromUrlAndLocalStorage({ router, tabs, shouldOmitLocalStorage }) {
  const currentTabFromUrl = router?.currentRoute?.query?.tab;
  const isTabPresentedInTheUrl = currentTabFromUrl && tabs.includes(currentTabFromUrl);
  if (isTabPresentedInTheUrl) {
    updateTabInUrlAndLocalStorage({ router, tab: currentTabFromUrl, shouldOmitSaveToLocalStorage: shouldOmitLocalStorage });
    return currentTabFromUrl;
  }

  if (!shouldOmitLocalStorage) {
    const localStorageCurrentTabKey = `${router.currentRoute.name}${TABS_LOCAL_HISTORY_KEY_SUFFIX}`;
    const tabNameFromLocalStorage = window.localStorage.getItem(localStorageCurrentTabKey);
    const isTabPresentedInLocalStorage = tabNameFromLocalStorage && tabs.includes(tabNameFromLocalStorage);
    if (isTabPresentedInLocalStorage) {
      updateTabInUrlAndLocalStorage({ router, tab: tabNameFromLocalStorage });
      return tabNameFromLocalStorage;
    }
  }

  return null;
}

export function updateTabInUrlAndLocalStorage({
 router, tab, useVueRouterReplace, shouldOmitSaveToLocalStorage,
}) {
  if (tab) {
    if (!shouldOmitSaveToLocalStorage) {
      const localStorageCurrentTabKey = `${router?.currentRoute?.name}${TABS_LOCAL_HISTORY_KEY_SUFFIX}`;
      window.localStorage.setItem(localStorageCurrentTabKey, tab);
    }
    if (useVueRouterReplace) {
      router.replace({ query: { tab } });
    } else {
      replaceQueryParams(tab);
    }
  }
}

// We use window.history and not vue-router repalce function to avoid re-renders
// caused by the router reflecting the new route.query.name to other compoennts
function replaceQueryParams(tabName) {
  const params = new URLSearchParams(window.location.search);
  params.set('tab', tabName);
  window.history.replaceState({}, '', decodeURIComponent(`${window.location.pathname}?${params}`));
}
