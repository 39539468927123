<template>
  <div>
    <div
      slot="reference"
      class="justify-content-center line-height-1.5 font-14"
    >
      <i class="sdicon-qrcode ml-n1" />
      QR Code
    </div>
    <div>
      <p class="font-12 text-gray-dark mb-2">
        Prospects can scan the QR code to access the link, this option is ideal for flyers, rent signs and more.
      </p>
    </div>
    <div
      ref="qrCode"
      class="row justify-content-center align-items-center h-100 mt-0 mb-2"
    />

    <ElButton
      class="w-100 mb-0"
      size="medium"
      type="primary"
      @click="downloadQrCode"
    >
      Download Code
    </ElButton>
  </div>
</template>

<script>
import { onMounted, onUpdated } from '@vue/composition-api';
import showdigsQrCodeLogo from '@/assets/showdigs_qrcode_logo.svg';
import QRCodeStyling from 'qr-code-styling';
import UrlFormatter from '../../utils/UrlFormatterUtil';

export default {
  name: 'UnitQrCode',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const refs = context.refs;
    const qrCodeOptions = {
      width: 150,
      height: 150,
      type: 'svg',
      data: UrlFormatter(props.url),
      // image: showdigsQrCodeLogo, // logo was removed due to request from Nomad saying zillow does not allow images with logos
      dotsOptions: {
        color: '#000000',
        type: 'rounded',
      },
      backgroundOptions: {
        color: '#ffffff',
      },
    };
    const qrCode = new QRCodeStyling(qrCodeOptions);

    onMounted(() => {
      qrCode.append(refs.qrCode);
    });

    onUpdated(() => {
      qrCode.append(refs.qrCode);
    });

    return {
      close,
      downloadQrCode,
    };

    function close() {
      context.emit('close');
    }

    function downloadQrCode() {
      const randomStr = (Math.random()).toString(20).substring(2);
      const downloadOptions = Object.assign(qrCodeOptions, {
        width: 600,
        height: 600,
      });
      (new QRCodeStyling(downloadOptions)).download({
        name: `qr-${randomStr}`,
        extension: 'jpg',
      });
      close();
    }
  },
};
</script>
