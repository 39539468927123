<template>
  <el-alert
    type="warning"
    show-icon
    :closable="false"
  >
    {{ customMessage }}
  </el-alert>
</template>

<script>
export default {
  name: 'UnitDuplicateNameAlert',
  props: {
    customMessage: {
      type: String,
      required: false,
      default: 'Multiple units in the property share the same name. Please make sure you are updating the correct unit.',
    },
  },
};
</script>
