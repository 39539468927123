export default {
  outcome: {
    key: 'outcome',
  },
  apply: {
    key: 'prospect_is_likely_to_apply',
    answers: {
      yes: 'Likely to apply',
      no: 'Unlikely to apply',
      maybe: 'Might apply',
    },
  },
};
