<template>
  <SdFloatLabel>
    <ElSelect
      v-model="selectedTemplateId"
      @change="handleChange"
    >
      <ElOption
        v-for="item in screeningTemplates"
        :key="item.id"
        :label="item.title"
        :value="item.id"
      />
    </ElSelect>
  </SdFloatLabel>
</template>

<script>
export default {
  name: 'ScreeningTemplateDropdown',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    screeningTemplates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedTemplateId: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.selectedTemplateId = newValue;
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    },
  },
};
</script>
