<template>
  <ElDialog
    id="take-over-dialog"
    :center="true"
    top="30vh"
    :show-close="false"
    :visible="visible"
    class="property-dialog"
    width="545px"
    :fullscreen="$viewport.lt.md"
    @close="$emit('close')"
  >
    <div slot="title">
      <h2 class="mb-1">
        Are you sure you want to take over this showing?
      </h2>
    </div>
    <div class="col-12 mt-1">
      <SdFloatLabel>
        <ElSelect
          v-model="assigneeRef"
          value-key="label"
          placeholder="Assign tour to"
          @change="selectedAssignee"
        >
          <ElOption
            v-for="(assignee, i) in assigneesListRef"
            :key="i"
            :label="`${assignee.label}`"
            :value="assignee.value"
          />
        </ElSelect>
      </SdFloatLabel>
    </div>
    <div>
      <div
        v-if="isVideo"
        class="col-12 mt-3"
      >
        <ElAlert
          type="info"
          show-icon
          :closable="false"
        >
          <p class="text-left mb-0 font-15">
            Note that this is a <b>live video tour</b>, and the prospect will not be there in person
          </p>
        </ElAlert>
      </div>
      <ul class="mt-3">
        <li class="mt-2">
          <p class="d-inline-block">
            <span v-if="selfShowing">
              Instead of a self-showing tour, you'll meet the prospect at the property
            </span>
            <span v-else>
              Our agents will not be utilized, and you will not be charged
            </span>
          </p>
        </li>
        <li class="mt-2">
          <p class="d-inline-block">
            We'll confirm the tour with the prospect.
            We will update if there are any changes or cancellations at
          </p>
          <div class="d-flex font-13 align-items-center mt-1 mb-3">
            <SdEmailIndicator
              class="font-16"
              :email="assigneeRef.value.email"
            />
            <div
              v-if="assigneeRef.value.formatted_public_phone_number"
              class="dot bg-gray"
            />
            <SdPhoneIndicator
              v-if="assigneeRef.value.formatted_public_phone_number"
              class="font-16"
              :phone-number="assigneeRef.value.public_phone_number"
              :formatted-phone-number="assigneeRef.value.formatted_public_phone_number"
            />
          </div>
        </li>
        <li class="mt-2">
          <p class="d-inline-block">
            You'll be able to cancel the tour if you see you can't make it
          </p>
        </li>
      </ul>
      <WarningNoteBeforeAssign
        :visible="assigneeWarningNoteVisible"
        :user.sync="tourAssigneeResultsRef.user"
      />
    </div>
    <div slot="footer">
      <el-button
        :class="{ 'w-100 mb-2': $viewport.lt.md }"
        size="medium"
        @click.stop
        @click="$emit('close')"
      >
        Cancel
      </el-button>
      <el-button
        :class="$viewport.lt.md ? 'w-100 ml-0' : ''"
        type="primary"
        size="medium"
        :loading="loadingRef"
        @click.stop
        @click="takeOver"
      >
        Take over
      </el-button>
    </div>
  </ElDialog>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api';

import WarningNoteBeforeAssign from '@/components/tour/WarningNoteBeforeAssign';
import { getTourByDateAndAssignee } from '@/utils/TourUtil';
import SdPhoneIndicator from '@/components/common/SdPhoneIndicator';
import SdEmailIndicator from '@/components/common/SdEmailIndicator';
import { showErrorNotify, showSuccessNotify } from '../../utils/NotifyUtil';
import Tour from '../../constants/Tour';
import momentUtil from '../../utils/MomentUtil';

export default {
  name: 'TourTakeOverDialog',
  components: {
    WarningNoteBeforeAssign,
    SdEmailIndicator,
    SdPhoneIndicator,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    scheduleId: {
      type: Number,
      required: true,
    },
    schedule: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    isVideo: {
      type: Boolean,
      required: false,
      default: false,
    },
    selfShowing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const assigneeWarningNoteVisible = ref(false);
    const tourAssigneeResultsRef = ref({ user: {} });
    const user = context.root.$store.state.Auth.user;
    const assigneeRef = ref({
      value: {
        id: user.id,
        formatted_public_phone_number: user.formatted_public_phone_number,
        label: user.full_name,
        email: user.email,
      },
      label: user.full_name,
    });
    const assigneesListRef = ref([]);
    const loadingRef = ref(false);

    showAssigneeWarningNote(props.schedule);
    onMounted(() => {
      getAssigneeList();
    });

    return {
      assigneeRef,
      assigneesListRef,
      loadingRef,
      selectedAssignee,
      takeOver,
      showAssigneeWarningNote,
      assigneeWarningNoteVisible,
      tourAssigneeResultsRef,
    };

    async function showAssigneeWarningNote(schedule) {
      assigneeWarningNoteVisible.value = false;
      const result = await getTourByDateAndAssignee(schedule, assigneeRef.value.value.id);

      if (Object.keys(result).length > 0) {
        tourAssigneeResultsRef.value = {
          user: result,
        };
        assigneeWarningNoteVisible.value = true;
      }
    }

    async function takeOver() {
      loadingRef.value = true;
      const takeOverData = {
        assignee_id: assigneeRef.value.value.id,
        assignee_name: assigneeRef.value.value.label,
        assignee_avatar: assigneeRef.value.value.avatar,
        taken_over_by: context.root.$store.getters['Auth/userId'],
        can_be_taken_over: false,
        tour_status: Tour.STATUS_SCHEDULED_TAKEN_OVER,
      };
      try {
        await context.root.$store.dispatch('Schedule/takeOver',
          {
            id: props.scheduleId,
            assignee_id: takeOverData.assignee_id,
          });
        const schedule = await context.root.$store.dispatch('Business/getTour', props.scheduleId);
        context.emit('close', takeOverData);
        showSuccessNotify(context, generateSuccessMessage(takeOverData, schedule), { dangerouslyUseHTMLString: true });
      } catch (error) {
        if (!(error instanceof Error)) {
          return; // Validator failed
        }
        showErrorNotify(context, error.message);
      } finally {
        loadingRef.value = false;
      }
    }

    async function getAssigneeList() {
      const response = await context.root.$store.dispatch('Auth/getTeamMembers', {
        paginate: 0,
        'scopes[verified]': 1,
      });
      assigneesListRef.value = response.map((user) => ({
        value: {
          id: user.id,
          formatted_public_phone_number: user.formatted_public_phone_number,
          label: user.full_name,
          email: user.email,
          avatar: user.avatar_file_url,
        },
        label: user.full_name,
      }));
    }

    async function selectedAssignee(assignee) {
      assigneeRef.value = assigneesListRef.value.filter((user) => user.value.id === assignee.id)[0];
      await showAssigneeWarningNote(props.schedule);
    }

    function generateSuccessMessage(takeOverData, schedule) {
      const property = schedule.property_address;
      const tourAt = momentUtil(schedule.tour_at, schedule.property_timezone).toDisplayFormat();
      const assignee = takeOverData.assignee_name;
      const link = `/tours/${props.scheduleId}`;
      return `Tour at ${property} on ${tourAt} assigned to <strong>${assignee}</strong>.<br/><a target="_blank" href="${link}">view more details</a>`;
    }
  },
};
</script>

<style lang="scss" scoped>
.lead-name,
.lead-tour-at {
  line-height: 1rem;
}
</style>
<style lang="scss">
#take-over-dialog {
  .el-dialog--center .el-dialog__body {
    padding: 1rem 0.5rem;
  }

  ul {
    list-style: none;
  }

  ul li::before {
    content: "\2022";
    color: theme-color(primary);
    font-weight: bold;
    display: inline-block;
    width: 1rem;
    line-height: 1.5rem;
    font-size: 1.5rem;
    margin-left: -1.5rem;
    vertical-align: top;
  }
}
</style>
