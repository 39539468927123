<template>
  <div class="row gutter-1">
    <div class="activity-date">
      {{ activity.date }}
    </div>
    <div class="col-auto">
      <div
        class="activity-icon border-0"
        :class="{
          'bg-green': ['SCAM_REMOVED'].includes(activity.type),
          'bg-red': ['SCAM_DETECTED'].includes(activity.type)}"
      >
        <i :class="activityIconClass" />
      </div>
    </div>
    <div :class="$viewport.lt.md ? 'col-12 pt-2': 'col'">
      <h3
        v-if="activity.type==='SCAM_DETECTED'"
        class="activity-title"
      >
        Possible scam listing detected
      </h3>
      <h3
        v-if="activity.type==='SCAM_REMOVED'"
        class="activity-title"
      >
        Possible scam listing removed
      </h3>
      <div
        v-if="activity.platform && activity.url"
        class="activity-data "
      >
        <span class="label">
          Site
        </span>
        <span class="value">
          <a
            :href="activity.url"
            target="_blank"
            class="activity-id"
          >
            {{ activity.platform }}
          </a>
        </span>
      </div>
    </div>
  </div>
</template>
<script>

import { computed } from '@vue/composition-api';

export default {
  components: {
  },
  props: {
    activity: {
      required: true,
      type: Object,
    },
    unit: {
      required: true,
      type: Object,
    },
  },
  setup(props, context) {
    const activityIconClass = computed(computeIconClass);
    return {
      activityIconClass,
    };
    function computeIconClass() {
      switch (props.activity.type) {
        case 'SCAM_DETECTED':
          return 'sdicon-scam-detected text-white';
        default:
          return 'sdicon-scam-removed text-white';
      }
    }
  },
};
</script>
<style lang="scss" scoped>
  .property-activities .activity-card h3.activity-title {
    margin-bottom: 0.5rem;
  }
</style>
