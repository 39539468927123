import Regex from '@/constants/Regex';

const regexConstants = Regex;

const PmsCodeBasicValidator = (value, callback) => {
  value = value.trim();

  if (value.length > 0 && value.length < 3) {
    return callback(
        new Error('Property code must be at least three characters long, if used'),
    );
  }

  if (value.match(regexConstants.url)) {
    return callback(
        new Error('Property code cannot be a URL'),
    );
  }

  if (value.length > 100) {
    return callback(
        new Error('Property code is too long'),
    );
  }

  return callback();
};

export const PmsCodeRule = {
  validator: (rule, value, callback) => {
    if (value) {
      return PmsCodeBasicValidator(value, callback);
    }

    return callback();
  },
};

export const PmsCodeRequiredRule = {
  validator: (rule, value, callback) => {
    if (!value || !value.trim()) {
      return callback(new Error('Property code is required'));
    }

    return PmsCodeBasicValidator(value, callback);
  },
};
