<template>
  <ElDialog
    id="add-inquiry-dialog"
    class="d-flex justify-content-center align-items-center"
    :center="true"
    top="30vh"
    :show-close="false"
    visible
    width="700px"
    :fullscreen="$viewport.lt.md"
    :destroy-on-close="true"
    @close="close"
  >
    <div slot="title">
      <h2 class="mb-1">
        Add Inquiry
      </h2>
      <h3
        v-if="unit"
        class="text-gray-dark font-weight-normal"
      >
        For {{ `${unit.property.short_address}${unit.name ? " - " + unit.name : ""}` }}
      </h3>
    </div>
    <div class="row d-flex justify-content-center mt-1 mb-3">
      <ElForm
        ref="addInquiryForm"
        :model="payload"
        :rules="formValidations"
        class="row align-items-center"
        @submit.native.prevent="add"
      >
        <div class="col-12 col-md-6">
          <SdFloatLabel>
            <ElFormItem prop="first_name">
              <ElInput
                v-model="payload.first_name"
                :disabled="isDisabledLeadEdit && !!lead && !!lead.full_name"
                name="first_name"
                placeholder="Prospect first name"
              />
            </ElFormItem>
          </SdFloatLabel>
        </div>
        <div class="col-12 col-md-6">
          <SdFloatLabel with-optional>
            <ElFormItem prop="last_name">
              <ElInput
                v-model="payload.last_name"
                :disabled="isDisabledLeadEdit && !!lead && !!lead.full_name"
                name="last_name"
                placeholder="Prospect last name"
              />
            </ElFormItem>
          </SdFloatLabel>
        </div>
        <div class="col-12 col-md-6">
          <SdFloatLabel with-optional>
            <ElFormItem
              :rules="{ validator: validateEmail }"
              prop="email"
            >
              <ElInput
                v-model="payload.email"
                :disabled="isDisabledLeadEdit && !!lead && !!lead.email"
                name="email"
                type="email"
                placeholder="Prospect email"
              />
            </ElFormItem>
          </SdFloatLabel>
        </div>
        <div class="col-12 col-md-6">
          <SdFloatLabel :with-optional="prospectPhoneIsOptional">
            <ElFormItem
              :rules="{ validator: validatePhoneNumber }"
              prop="phone_number"
            >
              <ElInput
                v-model="payload.phone_number"
                :disabled="isDisabledLeadEdit && !!lead && !!lead.phone_number"
                name="phone_number"
                placeholder="Prospect phone number"
              />
            </ElFormItem>
          </SdFloatLabel>
        </div>
        <div
          v-if="!unit"
          class="col-12"
        >
          <SdFloatLabel>
            <ElFormItem prop="unit_id">
              <UnitAutoComplete
                :only-showing="true"
                :unit-id="payload.unit_id"
                @set-unit="unitSelected"
              />
            </ElFormItem>
          </SdFloatLabel>
        </div>
      </ElForm>
      <ElDivider class="mb-4" />
      <p
        v-if="!withScheduleTourAction"
        class="text-gray-dark font-weight-normal"
      >
        When the showing type is updated to include tour availability, you will be able to notify prospects that tours are available.
      </p>
      <ElTooltip
        v-if="withScheduleTourAction"
        :disabled="selectedUnitRef !== null || payload.unit_id !== null"
        popper-class="popover-panel"
        placement="top"
        content="Choose a property first in order to schedule a tour"
      >
        <ElCheckbox
          v-model="uiFlags.isSchedulingChecked"
          :disabled="selectedUnitRef === null && payload.unit_id === null"
          label="Schedule a tour for this property (optional)"
          class="col-12 pt-0 pl-0 mb-3 text-gray-dark font-weight-strong"
        />
      </ElTooltip>
      <UnitScheduleTour
        v-if="uiFlags.isSchedulingChecked && (selectedUnitRef || payload.unit_id)"
        :unit="selectedUnitRef || unit"
        @time-selected="setSchedule"
      />
    </div>
    <span
      slot="footer"
      class="row justify-content-center"
    >
      <div class="col-6 col-md-3">
        <ElButton
          class="w-100"
          size="medium"
          @click="close"
        >
          Cancel
        </ElButton>
      </div>
      <div class="col-6 col-md-3">
        <ElButton
          class="w-100"
          size="medium"
          type="primary"
          :disabled="uiFlags.isAdding || (!payload.tour_at && uiFlags.isSchedulingChecked)"
          :loading="uiFlags.isAdding"
          @click="add"
        >
          Add
        </ElButton>
      </div>
    </span>
  </ElDialog>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';

import Showing from '@/constants/Showing';
import regex from '@/constants/Regex';
import UnitScheduleTour from './UnitScheduleTour';

import UnitAutoComplete from './UnitAutoComplete';

export default {
  name: 'UnitAddInquiryDialog',
  components: { UnitAutoComplete, UnitScheduleTour },
  props: {
    unit: {
      type: Object,
      required: false,
      default: null,
    },
    lead: {
      type: Object,
      default: null,
    },
    isDisabledLeadEdit: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, context) {
    const dispatch = context.root.$store.dispatch;
    const payload = ref({ ...defaultPayload });
    const withScheduleTourAction = ref(true);
    if (props.unit) {
      payload.value.unit_id = props.unit.id;
      withScheduleTourAction.value = isUnitShowingProfileNotPublishListing(props.unit);
    }
    const selectedUnitRef = ref(null);
    const uiFlags = reactive({ isAdding: false, isSchedulingChecked: withScheduleTourAction.value });
    const prospectPhoneIsOptional = computed(() => !uiFlags.isSchedulingChecked);

    if (props.lead) {
      setLeadForSchedule();
    }

    return {
      add,
      close,
      uiFlags,
      payload,
      formValidations,
      validatePhoneNumber,
      prospectPhoneIsOptional,
      validateEmail,
      unitSelected,
      selectedUnitRef,
      setSchedule,
      withScheduleTourAction,
    };

    function close(emit) {
      payload.value = { ...defaultPayload };
      context.emit('close', emit);
    }

    function isUnitShowingProfileNotPublishListing(unit) {
      return unit?.last_showing_profile?.type !== Showing.TYPE_PUBLISH_LISTING;
    }

    function unitSelected(unit) {
      payload.value.unit_id = unit.id;
      selectedUnitRef.value = unit;
      const isNotPublishListing = isUnitShowingProfileNotPublishListing(unit);
      uiFlags.isSchedulingChecked = isNotPublishListing;
      withScheduleTourAction.value = isNotPublishListing;
    }

    async function add() {
      uiFlags.isAdding = true;
      try {
        const withSchedule = uiFlags.isSchedulingChecked && payload.value.tour_at !== null;
        if (withSchedule) {
          payload.value.with_schedule = true;
        }
        await context.refs.addInquiryForm.validate();
        let inquiry = await dispatch('Inquiry/add', payload.value);

        if (withSchedule && inquiry.id) {
          try {
            inquiry = await dispatch('Inquiry/schedule', {
              payload: {
                inquiry_id: inquiry.id,
                tour_at: payload.value.tour_at,
                is_video: payload.value.is_video,
              },
            });
            const prospectFullName = payload.value.first_name + (payload.value.last_name ? ` ${payload.value.last_name}` : '');
            const successMessage = `We sent ${prospectFullName} a text message. Once they click the attached link and verify their phone number the tour will be scheduled`;
            showInquiryCreatedSuccessNotification(inquiry, successMessage);
            close();
          } catch (error) {
            uiFlags.isAdding = false;
            showErrorNotify(context, error.message);
          }
        } else {
          showInquiryCreatedSuccessNotification(inquiry, 'Inquiry created successfully');
          close('success');
        }
      } catch (error) {
        uiFlags.isAdding = false;
        if (!(error instanceof Error)) {
          return; // Validator failed
        }
        showErrorNotify(context, error.message);
      }
      uiFlags.isAdding = false;
    }

    function showInquiryCreatedSuccessNotification(inquiry, message) {
      /* eslint-disable no-undef */
      const notificationOptions = { duration: 0, dangerouslyUseHTMLString: process.env.VUE_APP_ENV !== 'production' };
      if (process.env.VUE_APP_ENV !== 'production' && inquiry && (inquiry.short_url || inquiry.phone_verification_url)) {
        const url = inquiry.phone_verification_url || inquiry.short_url;
        message += `<a class="d-block" target="_blank" href="//${url}">${url}</a>`;
      }
      showSuccessNotify(context, message, notificationOptions);
    }

    function validatePhoneNumber(rule, value, callback) {
      if (!value && uiFlags.isSchedulingChecked) {
        callback("Prospect's phone number is required");
      } else if (!payload.value.email && !value && !uiFlags.isSchedulingChecked) {
        callback('Either phone number or email are required');
      } else if (payload.value.email && !value && !uiFlags.isSchedulingChecked) {
        callback();
      } else if (!value.match(regex.phone_number)) {
        callback('Invalid phone number');
      } else {
        context.refs.addInquiryForm.clearValidate('email');
        callback();
      }
    }

    function setSchedule(payloadData) {
      payload.value.tour_at = payloadData.time;
      payload.value.is_video = payloadData.is_video;
    }

    function validateEmail(rule, value, callback) {
      if (payload.value.phone_number && !value) {
        context.refs.addInquiryForm.clearValidate('phone_number');
        callback();
      } else if (!value) {
        callback('Either phone number or email are required');
      } else if (!value.match(regex.email)) {
        callback('Invalid email');
      } else {
        context.refs.addInquiryForm.clearValidate('phone_number');
        callback();
      }
    }

    function setLeadForSchedule() {
      payload.value = {
        ...payload.value,
        first_name: props.lead.first_name || props.lead.full_name.split(' ')[0],
        last_name: props.lead.last_name || props.lead.full_name.split(' ')[props.lead.full_name.split(' ').length - 1],
        email: props.lead.email,
        phone_number: props.lead.phone || props.lead.phone_number,
        with_schedule: true,
      };
      if (props.isDisabledLeadEdit) {
        uiFlags.isSchedulingChecked = true;
      }
    }
  },
};

const defaultPayload = {
  first_name: '',
  last_name: null,
  email: '',
  phone_number: null,
  unit_id: null,
  tour_at: null,
  with_schedule: false,
  is_video: false,
};
const formValidations = {
  first_name: [
    { required: true, trigger: 'blur', message: "Prospect's first name is required" },
    {
      min: 2,
      max: 16,
      trigger: 'blur',
      message: "Prospect's first name must be between 2-16 characters",
    },
  ],
  last_name: [
    {
      min: 2,
      max: 16,
      trigger: 'blur',
      message: "Prospect's last name must be between 2-16 characters",
    },
  ],
  unit_id: [{ required: true, trigger: 'blur', message: 'Property is required' }],
};
</script>
<style lang="scss">
#add-inquiry-dialog {
  .el-dialog {
    max-width: 744px;
    max-height: 95vh;
    margin: 0 !important;
    overflow-y: auto;
  }

  .el-checkbox__input {
    vertical-align: top;
  }

  .el-checkbox__label {
    white-space: normal;
  }
}
</style>
