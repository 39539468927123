export {
  cleanAndMergeWithDefaultPayload,
};

function cleanAndMergeWithDefaultPayload(initialPayload, defaultPayload) {
  if (!initialPayload) {
    return { ...defaultPayload };
  }
  const pl = {};
  for (const key in defaultPayload) {
    if (initialPayload[key] !== undefined && initialPayload[key] !== null) {
      pl[key] = initialPayload[key];
    } else {
      pl[key] = defaultPayload[key];
    }
  }
  return pl;
}
