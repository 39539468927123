<template>
  <div class="row gutter-1">
    <div class="activity-date">
      {{ activity.date }}
    </div>
    <div class="col-auto">
      <div class="activity-icon">
        <i class="sdicon-close text-red" />
      </div>
    </div>
    <div :class="$viewport.lt.md ? 'col-12 pt-2' : 'col'">
      <h3 class="activity-title">
        Inquiry Deleted
      </h3>
      <div
        v-if="activity.lead_email"
        class="activity-data"
      >
        <span class="label">
          Mail
        </span>
        <SdEmailIndicator :email="activity.lead_email" />
      </div>
      <div
        v-if="activity.lead_full_name"
        class="activity-data "
      >
        <span class="label">
          Prospect
        </span>
        <span class="value">
          <ProspectProfileLink
            v-if="activity.lead_full_name && activity.lead_id"
            :key="`${activity.date}-${activity.type}`"
            :prospect="{
              id: activity.lead_id,
              full_name: activity.lead_full_name,
            }"
          />
        </span>
      </div>
      <div
        v-if="activity.lead_phone_number"
        class="activity-data"
      >
        <span class="label">
          Phone
        </span>
        <SdPhoneIndicator
          :phone-number="activity.lead_phone_number"
          :formatted-phone-number="activity.lead_formatted_phone_number"
          class="value"
        />
      </div>
      <div
        v-if="activity.comments"
        class="activity-comments"
      >
        "{{ activity.comments }}"
      </div>
    </div>
  </div>
</template>
<script>
import SdPhoneIndicator from '@/components/common/SdPhoneIndicator';
import SdEmailIndicator from '@/components/common/SdEmailIndicator';
import ProspectProfileLink from '../../prospect/ProspectProfileLink.vue';

export default {
  components: {
    SdEmailIndicator,
    ProspectProfileLink,
    SdPhoneIndicator,
  },
  props: {
    activity: {
      required: true,
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
.property-activities .activity-card {
  h3.activity-title {
    margin-bottom: 0.5rem;
  }

  .activity-icon i {
    line-height: 37px;
  }
}
</style>
