<template>
  <transition
    name="slide"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <slot />
  </transition>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SdSlideTransition',
  setup() {
    return {
      beforeEnter,
      enter,
      afterEnter,
      beforeLeave,
      leave,
      afterLeave,
    };

    /* eslint-disable no-return-assign */
    function beforeEnter(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = '0';
        }

        element.style.display = null;
      });
    }

    function enter(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => element.style.height = `${element.scrollHeight}px`);
      });
    }

    function afterEnter(element) {
      element.style.height = null;
    }

    function beforeLeave(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = `${element.offsetHeight}px`;
        }
      });
    }

    function leave(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => element.style.height = '0');
      });
    }

    function afterLeave(element) {
      element.style.height = null;
    }
    /* eslint-enable no-return-assign */
  },
});
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  overflow: hidden;
  transition: $transition-collapse;
}
</style>
