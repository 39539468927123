<template>
  <ElCard
    class="property-info-card p-3"
    :body-style="{ padding: '5px 0', 'flex-direction': 'column' }"
  >
    <div
      v-if="uiFlags.isLoadingEstimate"
      class="d-flex align-items-center"
    >
      <ElSpinner
        color="primary"
        :radius="50"
      />
      <span class="ml-3 font-13 text-gray-dark text-center">Finding comparable properties and analyzing market trends...</span>
    </div>
    <div
      v-else-if="isValidEstimate"
      class="d-flex justify-content-around"
    >
      <div class="d-flex flex-column align-items-center justify-content-center">
        <span class="font-21 font-weight-strong">{{ formatMoney(actualRent) }}</span>
        <span
          v-if="actualRent < rentEstimate.lower"
          class="text-danger"
        >Your rent is low</span>
        <span
          v-else-if="actualRent > rentEstimate.upper"
          class="text-danger"
        >Your rent is high</span>
        <span v-else>Your rent is on target</span>
      </div>
      <div class="d-flex flex-column align-items-center">
        <span>Suggested: {{ formatMoney(estimatedRent) }} <a
          target="_blank"
          class="text-gray"
          href="https://help.showdigs.com/kb/guide/en/how-do-we-calculate-your-propertys-rent-tQz7uFejNm/Steps/3478096"
        >
          <i class="sdicon-info-circle text-primary" />
        </a></span>
        <span>Range:
          {{ formatMoney(rentEstimate.lower) }} - {{ formatMoney(rentEstimate.upper) }}
        </span>
        <span>
          Confidence:
          <span class="capitalize">{{ rentEstimate.confidence }}</span>
        </span>
      </div>
    </div>
    <div
      v-else
      class="d-flex"
    >
      <span class="m-auto text-center font-13">Unable to find enough comparable properties and market details to make a reliable analysis.</span>
    </div>
  </ElCard>
</template>
<script>
import { formatMoney } from '@/utils/NumberUtil';
import {
  computed, onBeforeMount, reactive, ref,
} from '@vue/composition-api';

export default {
  name: 'UnitRentAnalysisCard',
  props: {
    unit: {
      type: Object,
      required: true,
    },
  },
  methods: { formatMoney },
  setup(props, context) {
    const actualRent = props.unit.active_showing_profile?.rent;
    const rentEstimate = ref(props.unit.active_lease_period?.rent_estimate);

    const estimatedRent = computed(() => rentEstimate.value.estimated_rent);
    const rentEstimateDifference = computed(() => actualRent - estimatedRent);
    const isValidEstimate = computed(() => actualRent && rentEstimate.value.confidence !== 'unknown');

    const uiFlags = reactive({
      isLoadingEstimate: false,
    });

    onBeforeMount(() => {
      if (!rentEstimate.value) {
        uiFlags.isLoadingEstimate = true;
        context.root.$store.dispatch('Unit/getRentEstimate', props.unit.id).then((r) => {
          rentEstimate.value = r;
        }).finally(() => {
          uiFlags.isLoadingEstimate = false;
        });
      }
    });

    return {
      uiFlags,
      actualRent,
      rentEstimate,
      estimatedRent,
      isValidEstimate,
      rentEstimateDifference,
    };
  },
};
</script>

<style lang="scss" scoped>
.property-info-header {
  display: flex;
  align-items: center;
  height: 40px;
}
</style>
