<template>
  <ElDialog
    v-if="uiFlags.isVisible"
    id="tour-cancellations-dialog"
    :center="true"
    :show-close="false"
    :close-on-click-modal="false"
    :visible.sync="uiFlags.isVisible"
    :width="$viewport.lt.md ? '100vw': '500px'"
  >
    <div slot="title">
      <div class="font-21 font-weight-strong">
        Change Showing Times
      </div>
    </div>
    <div class="row justify-content-center gutters-2 mb-n3">
      <div class="col-12 font-15 px-1 py-3">
        <span v-if="uiFlags.isOpenHouse">
          Note that any existing scheduled open houses will be canceled if its showing window is changed or removed.
        </span>
        <span v-else>
          Note that tours scheduled outside the newly set showing times will be cancelled.
        </span>
      </div>
      <div class="soft-wrapping py-3 px-1">
        <div
          v-if="impactRef.cancellable.length > 0"
          class="col-12"
        >
          <div class="col-15 font-weight-strong px-1">
            {{ impactRef.cancellable.length }} planned {{ uiFlags.isOpenHouse ? 'open houses' : 'tours' }}
            will be canceled:
          </div>
          <div
            class="col-12 scroll-holder py-3 px-0"
          >
            <div
              v-for="tour in impactRef.cancellable"
              :key="tour.id"
              class="col-12 font-15 py-1 px-0"
            >
              <li>
                <span class="bullet">
                  {{ tour.leads.map(lead => lead.full_name).join(', ') }}, {{ tour.displayTourAt }}
                </span>
              </li>
            </div>
          </div>
        </div>
        <div
          v-if="impactRef.nonCancellable.length > 0"
          class="col-12 pt-0"
        >
          <div class="col-15 font-weight-strong ml-n2 mt-2">
            <i class="sdicon-exclamation-triangle text-orange font-21" />
            {{ impactRef.nonCancellable.length }} {{ $pluralize('Tour', impactRef.nonCancellable.length) }}
            happening shortly and cannot be cancelled:
          </div>
          <div
            class="col-12 scroll-holder pt-3 pb-1 px-0"
          >
            <div
              v-for="tour in impactRef.nonCancellable"
              :key="tour.id"
              class="col-12 font-15 py-1 px-0"
            >
              <li>
                <span class="bullet">
                  {{ tour.leads.map(lead => lead.full_name).join(', ') }}, {{ tour.displayTourAt }}
                </span>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      slot="footer"
      class="row justify-content-center"
    >
      <div class="col-md-4 col">
        <ElButton
          class="w-100"
          size="medium"
          @click="cancelSubmit"
        >
          Cancel
        </ElButton>
      </div>
      <div class="col-md-4 col">
        <ElButton
          class="w-100"
          size="medium"
          type="primary"
          @click="confirmSubmit"
        >
          Confirm
        </ElButton>
      </div>
    </div>
  </ElDialog>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';

import { formatImpact } from '@/utils/ShowingProfileFormatImpact';

export default {
  name: 'TourCancellationsDialog',
  components: {},
  setup(props, context) {
    const uiFlags = reactive({
      isVisible: false,
      isOpenHouse: null,
    });
    const cancellableSchedules = ref({});
    const nonCancellableSchedules = ref({});
    const propertyRef = ref({});
    const impactRef = ref({ ...defaultImpact });

    return {
      uiFlags,
      cancellableSchedules,
      nonCancellableSchedules,
      open,
      confirmSubmit,
      cancelSubmit,
      propertyRef,
      impactRef,
    };

    async function open(data, property, isOpenHouse) {
      propertyRef.value = property;
      impactRef.value = formatImpact(context, data);
      uiFlags.isOpenHouse = isOpenHouse;
      uiFlags.isVisible = true;
    }

    function confirmSubmit() {
      uiFlags.isVisible = false;
      context.emit('confirm-submit');
    }

    function cancelSubmit() {
      uiFlags.isVisible = false;
      context.emit('cancel-submit');
    }
  },
};

const defaultImpact = {
  nonCancellable: [],
  cancellable: [],
};
</script>

<style lang="scss">
#tour-cancellations-dialog {
  .scroll-holder {
    max-height: 15vh;
    overflow: auto;
  }
}
</style>
