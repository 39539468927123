import AbodoSourceImg from '../assets/inquiry-sources/Abodo.png';
import ApartamentscomSourceImg from '../assets/inquiry-sources/Apartmentscom.png';
import DefaultSourceImg from '../assets/inquiry-sources/Default.png';
import HotpadsSourceImg from '../assets/inquiry-sources/Hotpads.png';
import LovelySourceImg from '../assets/inquiry-sources/Lovely.png';
import ManualSourceImg from '../assets/inquiry-sources/Manual.png';
import RealtorSourceImg from '../assets/inquiry-sources/Realtor.png';
import RentalscomSourceImg from '../assets/inquiry-sources/Rentalscom.png';
import TruliaSourceImg from '../assets/inquiry-sources/Trulia.png';
import UnitLandingPageSourceImg from '../assets/inquiry-sources/UnitLandingPage.png';
import UnknownSourceImg from '../assets/inquiry-sources/Unknown.png';
import WebsiteSourceImg from '../assets/inquiry-sources/Website.png';
import ZillowSourceImg from '../assets/inquiry-sources/Zillow.png';
import ZillowRentalNetworkSourceImg from '../assets/inquiry-sources/ZillowRentalNetwork.png';
import ZumperSourceImg from '../assets/inquiry-sources/Zumper.png';

const sourcesImages = {
  AbodoSourceImg,
  ApartamentscomSourceImg,
  DefaultSourceImg,
  HotpadsSourceImg,
  LovelySourceImg,
  ManualSourceImg,
  RealtorSourceImg,
  TruliaSourceImg,
  RentalscomSourceImg,
  UnitLandingPageSourceImg,
  UnknownSourceImg,
  WebsiteSourceImg,
  ZillowSourceImg,
  ZillowRentalNetworkSourceImg,
  ZumperSourceImg,
};

function getInquirySourceImage(sourceName) {
  sourceName = sourceName.replace(/[\s.]/g, '');
  return sourcesImages[`${sourceName.trim()}SourceImg`] ?? DefaultSourceImg;
}

export { getInquirySourceImage };
