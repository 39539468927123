<template>
  <transition name="fade">
    <div>
      <div
        v-if="impact.nonCancellable.length"
        class="mt-1 mb-3 justify-content-center text-center"
      >
        {{ `You cannot ${uiFlags.isPause ? 'pause' : 'stop'} showings because you have a scheduled tour happening shortly.` }}
      </div>
      <div
        v-if="withImpactSection"
        class="impact"
      >
        <div
          v-if="uiFlags.isLoading"
          class="text-center"
        >
          <ElSpinner
            color="primary"
            :radius="50"
          />
        </div>
        <div
          v-else-if="impact.nonCancellable.length || impact.cancellable.length"
          class="impact-list"
        >
          <div class="row no-gutters">
            <div class="col-auto">
              <i
                class="sdicon-exclamation-triangle text-orange mr-2 mt-n1 font-24"
              />
            </div>
            <div class="col">
              You have planned tours for this property:
              <div
                v-if="impact.nonCancellable.length"
                class="mt-2"
              >
                <strong>
                  {{ impact.nonCancellable.length }} {{ $pluralize('tours', impact.nonCancellable.length) }}
                  happening shortly and cannot be cancelled:
                </strong>
                <ul>
                  <li
                    v-for="tour in impact.nonCancellable"
                    :key="tour.id"
                  >
                    {{ tour.leads.map(lead => lead.full_name).join(', ') }}, {{ tour.displayTourAt }}
                  </li>
                </ul>
              </div>
              <div
                v-if="impact.cancellable.length && !impact.nonCancellable.length"
                class="mt-2"
              >
                <strong>
                  {{ impact.cancellable.length }} {{ $pluralize('tours', impact.cancellable.length) }} that will be canceled:
                </strong>
                <ul>
                  <li
                    v-for="tour in impact.cancellable"
                    :key="tour.id"
                  >
                    {{ tour.leads.map(lead => lead.full_name).join(', ') }}, {{ tour.displayTourAt }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="no-impact text-center"
        >
          No scheduled tours will be affected.
        </div>
      </div>
      <div
        v-if="impact.nonCancellable.length"
        class="mt-3 justify-content-center text-center"
      >
        {{ `If you have an urgent need to ${uiFlags.isPause ? 'pause' : 'stop'} showings,
    please contact our operations team (${Showdigs.SUPPORT_PHONE_NUMBER} | ${Showdigs.SUPPORT_EMAIL}).` }}
      </div>
    </div>
  </transition>
</template>
<script>
import {
 onMounted, reactive, ref, watch,
} from '@vue/composition-api';
import get from 'lodash.get';
import momentUtil from '@/utils/MomentUtil';

import { showErrorNotify } from '@/utils/NotifyUtil';
import { formatImpact } from '@/utils/ShowingProfileFormatImpact';
import Showdigs from '@/constants/Showdigs';
import Showing from '../../constants/Showing';

const defaultImpact = {
  nonCancellable: [],
  cancellable: [],
};
export default {
  name: 'UnitPauseStopImpact',
  props: {
    showingProfileId: {
      type: Number,
      required: true,
    },
    pauseFrom: {
      type: [Date, String],
      default: null,
    },
    pausedUntil: {
      type: [Date, String],
      default: null,
    },
    pauseOrStop: {
      type: String,
      required: true,
    },
    withImpactSection: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const dispatch = context.root.$store.dispatch;
    const impact = ref({ ...defaultImpact });
    const uiFlags = reactive({
      isLoading: false,
      isPause: props.pauseOrStop === 'pause',
    });

    watch(() => props.pauseFrom, getImpact);
    watch(() => props.pausedUntil, getImpact);

    onMounted(() => {
      getImpact();
    });

    return {
      getImpact,
      impact,
      Showing,
      uiFlags,
      clear,
      Showdigs,
    };
    async function getImpact() {
      uiFlags.isLoading = true;
      const method = props.pauseOrStop === 'stop' ? 'deactivationImpact' : 'pauseImpact';
      const data = {
        showingProfileId: props.showingProfileId,
      };

      if (props.pauseFrom) {
        const pauseFromDate = momentUtil(props.pauseFrom, null);
        if (pauseFromDate > momentUtil(null, null)) {
          data.pauseFrom = props.pauseFrom;
        }
      }

      if (props.pausedUntil) {
        data.pausedUntil = props.pausedUntil;
      }

      try {
        const impactResponse = await dispatch(`ShowingProfile/${method}`, data);
        impact.value = formatImpact(context, impactResponse);
        uiFlags.isLoading = false;

        const eventData = {
          tours: {
            hasNoneCancellable: impact.value.nonCancellable.length > 0,
            hasCancellable: impact.value.cancellable.length > 0,
          },
        };
        context.emit('impact-fetched', eventData);
      } catch (error) {
        showErrorNotify(context, error.message);
      }
    }
    function clear() {
      impact.value = { ...defaultImpact };
    }
  },
};
</script>
<style lang="scss" scoped>
.impact {
    padding: 0.75rem;
    background: theme-color('warning-light');
    border-radius: $app-border-radius;

    ul {
        margin: 0;
        padding-left: 19px;
    }
}
</style>
