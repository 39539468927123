<template>
  <ElDialog
    id="pause-showing-dialog"
    :center="true"
    :show-close="false"
    :fullscreen="$viewport.lt.md"
    :visible="visible"
    width="90%"
    @close="$emit('close')"
  >
    <div slot="title">
      <h2 class="mb-1">
        {{ isEdit ? 'Edit Pause' : 'Pause' }} {{ showingTypeText }}
      </h2>
      <h3 class="text-gray-dark font-weight-normal">
        For {{ `${unit.property.short_address}${unit.name ? ' - ' + unit.name : ''}` }}
      </h3>
    </div>
    <div class="row">
      <div class="col-4 align-self-center">
        Pause reason
      </div>
      <div class="col-8">
        <SdFloatLabel>
          <ElSelect
            v-model="pauseReason"
            placeholder="Select a reason"
          >
            <ElOption
              v-for="(item, key) in pauseReasonsOptions"
              :key="key"
              :label="item.label"
              :value="item.value"
            />
          </ElSelect>
        </SdFloatLabel>
      </div>
      <div
        v-if="pauseReason && pauseReason !== 'other' && !uiFlags.hasNonCancellableTours"
        class="col-12"
      >
        <el-alert
          type="info"
          show-icon
          :closable="false"
          class="mt-3"
        >
          If no showing slots are available, the reason for the pause will be displayed to prospects.
        </el-alert>
      </div>
      <div
        class="col-4"
      >
        Start pause on
      </div>
      <div
        class="col-8"
      >
        <ElRadioGroup
          v-model="pauseFromData.isNow"
          class="row"
        >
          <ElRadio
            class="col"
            :label="true"
          >
            Now
          </ElRadio>
          <ElRadio
            class="col"
            :label="false"
          >
            Specific date
          </ElRadio>
        </ElRadioGroup>
        <div
          v-if="!pauseFromData.isNow"
          class="row gutter-1"
        >
          <div class="col-md-6 col-12">
            <SdFloatLabel>
              <ElDatePicker
                v-model="pauseFromData.clientDateString"
                type="date"
                :picker-options="{ disabledDate: isFutureDateOptionDisabled }"
                :clearable="false"
                align="center"
                format="MM/dd/yy"
                placeholder="Date"
                @change="setDefaultPausedFromTime"
              />
            </SdFloatLabel>
          </div>
          <div class="col-md-6 col-12">
            <SdFloatLabel with-optional>
              <ElSelect
                v-model="pauseFromData.localDateString"
                value-key="date"
                placeholder="Time"
              >
                <ElOption
                  v-for="item in pauseFromTimes"
                  :key="item.label"
                  :label="item.label"
                  :value="item.date"
                />
              </ElSelect>
            </SdFloatLabel>
          </div>
        </div>
      </div>
      <div class="col-4">
        Pause until
      </div>
      <div class="col-8">
        <ElRadioGroup
          v-model="pauseUntilData.isFor30Days"
          class="row"
        >
          <ElRadio
            class="col"
            :label="false"
          >
            Specific date
          </ElRadio>
          <ElRadio
            class="col"
            :label="true"
          >
            For 30 days
          </ElRadio>
        </ElRadioGroup>
        <div
          v-if="!pauseUntilData.isFor30Days"
          class="row gutter-1"
        >
          <div class="col-md-6 col-12">
            <SdFloatLabel>
              <ElDatePicker
                v-model="pauseUntilData.clientDateString"
                type="date"
                :picker-options="{ disabledDate: isDateOptionDisabled }"
                :clearable="false"
                :disabled="pauseFrom === null"
                align="center"
                format="MM/dd/yy"
                placeholder="Date"
                @change="setDefaultPausedUntilTime"
              />
            </SdFloatLabel>
          </div>
          <div class="col-md-6 col-12">
            <SdFloatLabel>
              <ElSelect
                v-model="pauseUntilData.localDateString"
                :disabled="pauseUntilData.localDateString === null"
                value-key="date"
                placeholder="Time"
              >
                <ElOption
                  v-for="item in pauseUntilTimes"
                  :key="item.label"
                  :label="item.label"
                  :value="item.date"
                />
              </ElSelect>
            </SdFloatLabel>
          </div>
        </div>
      </div>
      <div class="col-12">
        <el-alert
          v-if="pauseUntilData.isFor30Days && !uiFlags.hasNonCancellableTours"
          type="info"
          show-icon
          :closable="false"
          class="mt-3"
        >
          {{ unit.last_showing_profile.is_open_house ? 'Open house' : 'Showings' }}
          will be paused until {{ pausedUntilFormatted }}.
          You can choose to resume showings at any time
        </el-alert>
      </div>
      <div
        v-if="isPauseUntilBeforePauseFrom"
        class="col text-center text-danger"
      >
        The pause end time cannot be earlier than the start time
      </div>
    </div>
    <div
      v-if="pausedUntil && !isPauseUntilBeforePauseFrom"
      class="impact mt-4"
    >
      <UnitPauseStopImpact
        v-if="visible"
        ref="stopImpact"
        pause-or-stop="pause"
        :pause-from="pauseFrom === now ? null : pauseFrom"
        :paused-until="pausedUntil"
        :showing-profile-id="unit.last_showing_profile.id"
        @impact-fetched="impactFetched"
      />
    </div>
    <div slot="footer">
      <el-button
        size="medium"
        @click="$emit('close')"
      >
        Cancel
      </el-button>
      <el-button
        type="primary"
        :disabled="isDisabledPauseButton"
        size="medium"
        :loading="uiFlags.isPausing"
        @click="pause"
      >
        {{ isEdit ? 'Save' : 'Pause' }} {{ isEdit ? '' : showingTypeText }}
      </el-button>
    </div>
  </ElDialog>
</template>

<script>
import momentUtil from '@/utils/MomentUtil';
import { computed, reactive, ref } from '@vue/composition-api';
import { showErrorNotify } from '@/utils/NotifyUtil';
import { getTimeSelectRange } from '@/utils/DatetimeUtil';
import { pauseReasonsOptions } from '@/constants/UnitShowingProfile';
import UnitPauseStopImpact from '@/components/unit/UnitPauseStopImpact';
import moment from 'moment';

export default {
  name: 'UnitPauseDialog',
  components: {
    UnitPauseStopImpact,
  },
  props: {
    unit: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    editData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props, context) {
    const timezone = props.unit.property.timezone;
    const localNow = momentUtil(new Date(), timezone);
    const localNowStartOfDay = localNow.clone().local().startOf('day');

    const uiFlags = reactive({
      isPausing: false,
      isFetchingImpact: false,
      hasNonCancellableTours: false,
    });

    /**
     * The <ElDatePicker> component returns a date in the client's timezone. We use "clientDateString" to store it.
     * This client date is only used for display purposes.
     * To avoid dates changing when we change the time to property's timezone,
     * we store a copy of the picker date in local format: "localDateString".
     */

    const pauseFromData = reactive({
      isNow: !props.editData?.pauseFrom,
      clientDateString: props.editData?.pauseFrom ? moment(props.editData.pauseFrom).startOf('day').toISOString() : null,
      localDateString: props.editData?.pauseFrom ? moment(props.editData.pauseFrom).tz(timezone).toISOString() : null,
    });

    const pauseUntilData = reactive({
      isFor30Days: false,
      clientDateString: props.editData?.pausedUntil ? moment(props.editData.pausedUntil).startOf('day').toISOString() : null,
      localDateString: props.editData?.pausedUntil ? moment(props.editData.pausedUntil).tz(timezone).toISOString() : null,
    });

    function setDefaultPausedFromTime(date) {
      /**
       * When the "pause from" picker date is changed, we only care about updating the "localDateString".
       * We try to set the time to 7AM the same day, or 30 minutes after the current time if 7AM has already passed.
       */
      const localDefaultTime = momentUtil.changeTz(date, timezone).add(7, 'hour');
      const localNextBestSlot = momentUtil.roundUpToNearestMinutes(localNow, 30);

      pauseFromData.localDateString = localDefaultTime.isSameOrAfter(localNow)
          ? localDefaultTime.toISOString()
          : localNextBestSlot.toISOString();
    }

    const pauseFromTimes = computed(() => {
      /**
       * When we generate "pause from" hours options, we check if the selected day is today
       * in order to hide hours that have already passed.
       * E.g if today is Jan 20th 5PM, we hide hours up to Jan 20th 5:30PM. (next available slot)
       */
      if (pauseFromData.localDateString === null) {
        return [];
      }

      const fromDate = momentUtil(pauseFromData.localDateString, timezone);
      const isToday = fromDate.isSame(localNow, 'day');
      const earliestTime = isToday ? localNow : fromDate.clone().startOf('day');

      return getTimeSelectRange(timezone, earliestTime);
    });

    function setDefaultPausedUntilTime(date) {
      /**
       * When the "pause until" picker date is changed, we only care about updating the "localDateString".
       * If 7 AM on the "pause until" day is earlier than the "pause from" time,
       * we set the "pause until" time to 30 minutes after "pause from" time.
       */
      const localFromDate = momentUtil(pauseFromData.localDateString, timezone);
      const localUntilDate = momentUtil.changeTz(date, timezone);
      const localDefaultUntilTime = localUntilDate.add(7, 'hour');
      const localNextBestUntilTime = localFromDate.add(30, 'minutes');

      pauseUntilData.localDateString = localDefaultUntilTime.isAfter(localFromDate)
          ? localDefaultUntilTime.toISOString()
          : localNextBestUntilTime.toISOString();
    }

    const pauseUntilTimes = computed(() => {
      /**
       * When we generate "pause until" hours options, we check if the selected day
       * is the same as the "pause from" day in order to hide hours up to the "pause from" time + 30 minutes.
       * E.g. if pause from = 20th Jan 8PM, we hide hours up to 20th Jan 8:30PM. (next available slot)
       */
      if (pauseUntilData.localDateString === null) {
        return [];
      }

      const localFromDate = momentUtil(pauseFromData.localDateString ?? localNow.toISOString(), timezone);
      const localUntilDate = momentUtil(pauseUntilData.localDateString, timezone);
      const sameDay = localFromDate.isSame(localUntilDate, 'day');

      return getTimeSelectRange(timezone, sameDay ? localFromDate.add(1, 'hour') : localUntilDate.startOf('day'));
    });

    /**
     * Computed properties that hold the final date values,
     * either custom selected or by setting (e.g. "Now" or "For 30 days").
     */
    const pauseFrom = computed(computePausedFrom);
    const pausedUntil = computed(computePausedUntil);

    const clientPauseFromStartOfDayString = computed(() => moment(pauseFrom.value).tz(moment.tz.guess()).startOf('day').toISOString());

    const pausedUntilFormatted = computed(computePausedUntilFormatted);
    const isDisabledPauseButton = computed(computeIsDisabledPauseButton);
    const isPauseUntilBeforePauseFrom = computed(computeIsPauseUntilBeforePauseFrom);
    const isEdit = computed(computeIsEdit);
    const showingTypeText = computed(computeShowingType);
    const operatingHours = [];
    const pauseReason = ref(props.editData?.pauseReason);

    fetchOperatingHours();

    return {
      uiFlags,
      pauseFromData,
      pauseUntilData,
      isFutureDateOptionDisabled,
      isDateOptionDisabled,
      pauseFrom,
      pausedUntil,
      isPauseUntilBeforePauseFrom,
      isEdit,
      now: localNow,
      pauseReason,
      pauseReasonsOptions,
      pausedUntilFormatted,
      pause,
      showingTypeText,
      isDisabledPauseButton,
      impactFetched,
      setDefaultPausedFromTime,
      setDefaultPausedUntilTime,
      pauseFromTimes,
      pauseUntilTimes,
    };

    async function fetchOperatingHours() {
      try {
        (await context.root.$store.dispatch('AvailabilityDay/platform')).forEach((operatingHour) => {
          operatingHours[operatingHour.week_day] = operatingHour.from_hour;
        });
      } catch (e) {
      }
    }

    function computePausedFrom() {
      if (pauseFromData.isNow) {
        return momentUtil(localNow, timezone)
          .toISOString();
      }

      return pauseFromData.localDateString;
    }

    function computePausedUntil() {
      if (pauseUntilData.isFor30Days) {
        return momentUtil(pauseFrom.value, timezone)
          .startOf('day')
          .add(30, 'days')
          .add(7, 'hours')
          .toISOString();
      }

      return pauseUntilData.localDateString;
    }

    function computePausedUntilFormatted() {
      return momentUtil(pausedUntil.value, timezone).toDisplayMonthAndDayFormat();
    }

    function computeIsPauseUntilBeforePauseFrom() {
      if (!pauseFrom.value || !pausedUntil.value) {
        return false;
      }

      return momentUtil(pauseFrom.value, null) >= momentUtil(pausedUntil.value, null);
    }

    function computeIsEdit() {
      return Boolean(props.editData);
    }

    function computeShowingType() {
      if (props.unit.last_showing_profile.is_open_house) {
        return 'Open house';
      }
      return 'showing';
    }

    function isFutureDateOptionDisabled(date) {
      return date < localNowStartOfDay;
    }

    function isDateOptionDisabled(date) {
      if (!pauseFrom.value) {
        return true;
      }

      return date.toISOString() < clientPauseFromStartOfDayString.value;
    }

    async function pause() {
      uiFlags.isPausing = true;
      const data = {
        showingProfileId: props.unit.last_showing_profile.id,
        pausedUntil: momentUtil(pausedUntil.value, 'UTC').toServerFormat(),
        pauseReason: pauseReason.value,
      };
      const pauseFromDate = momentUtil(pauseFrom.value, null);
      if (pauseFromDate > momentUtil(null, null)) {
        data.pauseFrom = pauseFromDate.toServerFormat();
      }
      try {
        await context.root.$store.dispatch('ShowingProfile/pause', data);
        context.emit('close', data);
        uiFlags.isPausing = false;
      } catch (error) {
        uiFlags.isPausing = false;
        showErrorNotify(context, error.message);
      }
    }

    function computeIsDisabledPauseButton() {
      return (
          uiFlags.hasNonCancellableTours
          || uiFlags.isPausing
          || !pauseFrom.value
          || !pausedUntil.value
          || uiFlags.isFetchingImpact
          || !pauseReason.value
      );
    }

    function impactFetched(data) {
      uiFlags.isFetchingImpact = false;
      uiFlags.hasNonCancellableTours = data.tours.hasNoneCancellable;
    }
  },
};
</script>
<style lang="scss">
#pause-showing-dialog {
  .el-dialog {
    max-width: 545px;

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 100%;
    }

    .el-input__inner {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .el-input__prefix {
      display: none;
    }
  }

  .el-input.is-disabled .el-input__inner {
    border-color: transparent;
    background-color: $--input-background-color;
  }
}
</style>
