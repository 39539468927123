<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <ElFormItem>
        <SdFloatLabel>
          <ElSelect
            v-model="showingScreeningPayloadRef.move_in_date"
            placeholder="Move in date"
            @change="emitFormStateChanged"
          >
            <ElOption
              :label="DEFAULT_EMPTY_SCREENING_INPUT"
              :value="undefined"
            />
            <ElOption
              v-for="(item, key) in screeningCriteriaComponents.move_in_date.options"
              :key="key"
              :label="item"
              :value="key"
            />
          </ElSelect>
        </SdFloatLabel>
      </ElFormItem>
    </div>
    <div
      v-if="showMaxAllowedTenants"
      class="col-12 col-md-6"
    >
      <ElFormItem>
        <SdFloatLabel>
          <ElSelect
            v-model="showingScreeningPayloadRef.maximum_allowed_tenants"
            placeholder="Maximum allowed tenants"
            @change="emitFormStateChanged"
          >
            <ElOption
              :label="DEFAULT_EMPTY_SCREENING_INPUT"
              :value="undefined"
            />
            <ElOption
              v-for="(item, key) in screeningCriteriaComponents.maximum_allowed_tenants.options"
              :key="key"
              :label="item"
              :value="key"
            />
          </ElSelect>
        </SdFloatLabel>
      </ElFormItem>
    </div>
    <div class="col-12 col-md-6">
      <ElFormItem>
        <SdFloatLabel>
          <ElSelect
            v-model="showingScreeningPayloadRef.minimum_lease_length"
            placeholder="Minimal leasing period"
            @change="emitFormStateChanged"
          >
            <ElOption
              :label="DEFAULT_EMPTY_SCREENING_INPUT"
              :value="undefined"
            />
            <ElOption
              v-for="(item, key) in screeningCriteriaComponents.minimum_lease_length.options"
              :key="key"
              :label="item"
              :value="key"
            />
          </ElSelect>
        </SdFloatLabel>
      </ElFormItem>
    </div>
    <div
      class="col-12"
    >
      <CustomQuestionTemplate
        v-for="(customQuestion, index) in customScreeningPayload"
        :key="index"
        :index="index"
        :custom-question-payload="customQuestion"
        @change="updateCustomQuestionPayload"
        @delete="customQuestionIndex => removeCustomQuestionsFromArray(customQuestionIndex)"
      />
      <ElButton
        class="mt-4"
        size="small"
        @click="addCustomQuestion"
      >
        <i class="sdicon-plus" /> Add Custom question
      </ElButton>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api';
import CustomQuestionTemplate from '@/components/settings/BusinessSettingsShowings/CustomQuestionTemplate.vue';
import has from 'lodash.has';
import { cleanAndMergeWithDefaultPayload } from '@/utils/payloadUtil';
import { DEFAULT_EMPTY_SCREENING_INPUT, defaultUnitScreeningPayload } from '@/constants/Unit';

export default {
  name: 'ShowingProfileUnitScreening',
  components: {
    CustomQuestionTemplate,
  },
  props: {
    screeningCriteria: {
      type: Object,
      required: false,
      default: null,
    },
    listingData: {
      type: Object,
      required: false,
      default: null,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMaxAllowedTenants: {
      type: Boolean,
      required: false,
      default: true,
    },
    customScreening: {
      type: Array,
      required: false,
      default: null,
    },
  },
  setup(props, context) {
    const isIntegrationEnabled = context.root.$store.getters['BusinessSource/isIntegrationEnabled'];
    const screeningCriteriaComponents = context.root.$store.getters['ScreeningCriteria/get'];

    const formattedDefaultUnitScreeningPayload = removeAvailabilityDateFromDefaultUnitScreeningPayload(defaultUnitScreeningPayload);
    let initialScreeningPayload = cleanAndMergeWithDefaultPayload(props.screeningCriteria, formattedDefaultUnitScreeningPayload);

    if (props.listingData) {
      const listingData = {};
      initialScreeningPayload = { ...initialScreeningPayload, ...listingData };
    }

    const showingScreeningPayloadRef = ref(initialScreeningPayload);
    const customScreeningPayload = ref(props.customScreening ?? []);

    onMounted(emitFormStateChanged);
    return {
      DEFAULT_EMPTY_SCREENING_INPUT,
      showingScreeningPayloadRef,
      screeningCriteriaComponents,
      emitFormStateChanged,
      generalNotesChanged,
      isIntegrationEnabled,
      removeCustomQuestionsFromArray,
      updateCustomQuestionPayload,
      addCustomQuestion,
      customScreeningPayload,
    };

    function emitFormStateChanged(event) {
      context.emit('change', setUndefinedAsNull({ ...showingScreeningPayloadRef.value }));
    }

    function generalNotesChanged(data) {
      showingScreeningPayloadRef.value.general_notes = data.general_notes;
      emitFormStateChanged();
    }

    function setUndefinedAsNull(options) {
      for (const key in options) {
        if (has(options, key)) {
          options[key] = options[key] === undefined || options[key] === '' ? null : options[key];
        }
      }
      return options;
    }

    function removeAvailabilityDateFromDefaultUnitScreeningPayload(defaultUnitScreeningPayload) {
      defaultUnitScreeningPayload = { ...defaultUnitScreeningPayload };
      delete defaultUnitScreeningPayload.availability_date;
      return defaultUnitScreeningPayload;
    }

    function removeCustomQuestionsFromArray(questionIndex) {
      // eslint-disable-next-line max-len
      customScreeningPayload.value = customScreeningPayload.value.filter((value, index) => index !== questionIndex);
      context.emit('custom-question-update', { ...customScreeningPayload.value });
    }

    function updateCustomQuestionPayload(customQuestion) {
      customScreeningPayload.value[customQuestion.index] = customQuestion.payload;
      context.emit('custom-question-update', [...customScreeningPayload.value]);
    }

    function addCustomQuestion() {
      customScreeningPayload.value.push({});
    }
  },
};

</script>

<style lang="scss">
  .multiple-select {
    .el-input__inner {
      min-height: $--input-height;
    }
  }

  .input-symbol-prefix {
    position: relative;

    input {
      padding-left: 1.5rem;
    }

    &:before {
      color: gray-color(dark);
      font-weight: $font-weight-bold;
      position: absolute;
      top: 8px;
      content: "$";
      left: 16px;
      z-index: 1;
    }
  }
</style>
