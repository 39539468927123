<template>
  <div class="property-collapsable-details">
    <el-collapse v-model="activeTabs">
      <el-collapse-item
        v-if="showingWindows.length"
        name="weekly-showing"
      >
        <template slot="title">
          <i class="sdicon-calendar-plus text-gray-dark font-21 mr-2" />
          <div class="double-line-header">
            <div>{{ showingProfile.is_open_house ? "Weekly Open House Schedule" : "Weekly Showings Schedule" }}</div>
          </div>
        </template>
        <div class="collapse-content">
          <div class="mt-2 position-relative">
            <div class="detail flex-wrap d-flex">
              <label>Current Schedule</label>
              <div class="ml-auto">
                <a
                  :style="'font-size: 13px;'"
                  href="#"
                  @click.prevent="$emit('edit')"
                >
                  <i class="font-13 sdicon-pencil" />
                  Edit
                </a>
              </div>
            </div>
            <ul class="grey-box px-3 mt-2 mb-0">
              <!-- eslint-disable vue/no-v-html -->
              <li
                v-for="(showingWindow, key) in showingWindows"
                :key="key"
                class="ml-2 pl-1 my-1 font-13"
                v-html="showingWindow"
              />
              <!-- eslint-enable vue/no-v-html -->
            </ul>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        v-if="showingProfile"
        name="listing-owner"
      >
        <template slot="title">
          <i class="sdicon-user-tie text-gray-dark font-21 mr-2" />
          <div class="double-line-header">
            <div>Listing owner</div>
            <span v-if="listingOwnerDetails.name">{{ listingOwnerDetails.name }}</span>
          </div>
        </template>
        <div class="collapse-content">
          <div
            v-if="listingOwnerDetails.name"
            class="detail"
          >
            <label>Name</label>
            <span>{{ listingOwnerDetails.name }}</span>
          </div>
          <div
            v-if="listingOwnerDetails.phone_formatted"
            class="detail"
          >
            <label>Phone</label>
            <SdPhoneIndicator
              with-icon
              :formatted-phone-number="listingOwnerDetails.phone_formatted"
              :phone-number="listingOwnerDetails.phone"
            />
          </div>
          <div
            v-if="listingOwnerDetails.email"
            class="detail"
          >
            <label>Email</label>
            <SdEmailIndicator
              with-icon
              :email="listingOwnerDetails.email"
            />
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        v-if="isSyndicated && activeShowingProfile && activeShowingProfile.syndication_status"
        name="syndication"
      >
        <template slot="title">
          <i class="sdicon-eye text-gray-dark font-21 mr-2" />
          <div class="double-line-header">
            <div>Syndication</div>
            <span>
              <i
                class="font-12 dot mr-2"
                :class="isLiveOnAllSyndicatedPortals ? 'bg-green' : 'bg-orange'"
              />
              <span>{{ isLiveOnAllSyndicatedPortals ? 'Live' : 'Pending' }}</span>
            </span>
          </div>
        </template>
        <div class="collapse-content text-gray-dark">
          <ul class="list-unstyled m-0">
            <template v-for="status in activeShowingProfile.syndication_status">
              <li
                v-if="isSyndicationPortalEnabled(status.portal)"
                :key="status.portal"
              >
                <span class="text-capitalize font-weight-strong">{{ status.portal }}</span>
                <div class="d-flex align-items-center">
                  <span>
                    <i
                      class="font-12 dot mr-2 ml-0"
                      :class="[status.live || status.status === 'Live' ? 'bg-green' : 'bg-orange']"
                    />
                    <a
                      v-if="(status.live || status.status === 'Live') && status.url"
                      :href="status.url"
                      target="_blank"
                    >Live</a>
                    <span v-else>{{ status.status }}</span>
                  </span>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </el-collapse-item>
      <el-collapse-item name="unit-details">
        <template slot="title">
          <i class="sdicon-home text-gray-dark font-21 mr-2" />
          Unit Details
        </template>
        <div class="collapse-content">
          <div
            v-if="property.short_address"
            class="detail"
          >
            <label>Address</label>
            <span v-if="get(property, 'address.data.url')">
              <a
                class="elipsis"
                :href="get(property, 'address.data.url')"
                target="_blank"
              >
                {{ property.short_address }}
              </a>
            </span>
            <span v-else>
              {{ property.short_address }}
            </span>
          </div>
          <div
            v-if="property.pms_code"
            class="detail"
          >
            <label>AppFolio property code</label>
            <span>{{ property.pms_code }}</span>
          </div>
          <template v-if="showingProfile">
            <div class="detail">
              <label>Listing Link</label>
              <span>
                <a
                  target="_blank"
                  class="elipsis"
                  :href="UrlFormatter(showingProfile.property_url || unit.landing_page_url)"
                >
                  {{ showingProfile.property_url || unit.landing_page_url }}
                </a>
              </span>
            </div>
            <div
              v-if="showingProfile.application_form_url"
              class="detail"
            >
              <label>Application Link</label>
              <span>
                <a
                  target="_blank"
                  class="elipsis"
                  :href="UrlFormatter(showingProfile.application_form_url)"
                >{{
                  showingProfile.application_form_url
                }}</a>
              </span>
            </div>
            <div
              v-if="showingProfile.deleted_at === null && showingProfile.rent"
              class="detail"
            >
              <label>Rent</label>
              <span> ${{ showingProfile.rent }} </span>
            </div>
          </template>
          <div
            v-if="unit.pms_code"
            class="detail"
          >
            <label>Internal ID</label>
            <span>
              {{ unit.pms_code }}
            </span>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        v-if="screeningCriteria"
        name="screening-criteria"
      >
        <template slot="title">
          <i class="sdicon-magnifying-glass-user text-gray-dark font-21 mr-2" />
          Pre-screening Criteria
        </template>
        <div class="collapse-content">
          <div
            v-for="sc in screeningCriteria"
            :key="sc.key"
            class="detail"
          >
            <label>{{ sc.label }}</label>
            <span class="text-prewrap">{{ sc.value }}</span>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        v-if="showingProfile && !isShowingProfilePublishListing"
        name="vacancy"
      >
        <template slot="title">
          <i class="sdicon-mailbox text-gray-dark font-21 mr-2" />
          <div class="double-line-header">
            <div>Vacancy</div>
            <i
              class="dot mr-2"
              :class="[showingProfile.is_vacant ? 'bg-green' : 'bg-red']"
            />
            <span>{{ showingProfile.is_vacant ? "Vacant" : "Occupied" }}</span>
          </div>
        </template>
        <div class="collapse-content">
          <div class="detail">
            <label>Vacancy status</label>
            <span>
              <i
                class="dot ml-0 mr-1"
                :class="[showingProfile.is_vacant ? 'bg-green' : 'bg-red']"
              />
              {{ showingProfile.is_vacant ? "Vacant" : "Occupied" }}</span>
          </div>
          <div
            v-if="showingProfile.tenants && showingProfile.tenants.length"
            class="detail"
          >
            <label class="mb-2">Tenants</label>
            <div
              v-for="(tenant, index) in showingProfile.tenants"
              :key="`tenant_${index}`"
              class="mb-3 line-height-1"
            >
              <div class="font-weight-strong mb-1">
                {{ tenant.full_name }}
              </div>
              <SdPhoneIndicator
                v-if="tenant.formatted_phone_number"
                class="mb-1 ml-n1"
                :phone-number="tenant.phone_number"
                :formatted-phone-number="tenant.formatted_public_phone_number"
                with-icon
              />
              <SdEmailIndicator
                v-if="tenant.email"
                with-icon
                class="mb-1 ml-n1"
                :email="tenant.email"
              />
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        v-if="activeShowingProfile && !isShowingProfilePublishListing"
        name="access-notes"
      >
        <template slot="title">
          <i class="sdicon-key text-gray-dark font-21 mr-2" />
          Access Notes
        </template>
        <div class="collapse-content">
          <div class="detail">
            <label>{{ accessHardwareLabel }}</label>
            <span v-if="activeShowingProfile.device">#{{ activeShowingProfile.device.serial_number }}</span>
            <span v-else>{{ activeShowingProfile.lock_box_code }}</span>
          </div>
          <div
            v-if="activeShowingProfile.comments"
            class="detail"
          >
            <label>Additional instructions</label>
            <span>{{ activeShowingProfile.comments }}</span>
          </div>
          <div
            v-if="property.common_agent_notes"
            class="detail"
          >
            <label>Property-wide instructions</label>
            <span>{{ property.common_agent_notes }}</span>
          </div>
        </div>
      </el-collapse-item>

      <el-collapse-item
        v-if="unitDevice && (unitDevice.name || unitDevice.serial_number) && activeShowingProfile"
        name="codebox"
      >
        <template slot="title">
          <i class="sdicon-lock text-gray-dark font-16 mr-2 ml-1" />
          &nbsp;Smart Device
        </template>
        <div class="collapse-content">
          <div class="detail">
            <div>
              <label>Smart Device</label>
              <span>
                {{ unitDevice.provider }}
                <strong v-if="unitDevice.name">{{ unitDevice.name }}</strong> (#{{ unitDevice.serial_number }}) is <strong>active</strong>
              </span>
            </div>
          </div>
          <div class="detail">
            <div>
              <strong
                class="d-flex align-items-center"
                @click.prevent="$emit('open-dialog')"
              >
                <i class="sdicon-gear font-13 ml-n1" />
                <a href="#"> Generate access code </a>
              </strong>
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        v-if="showingProfile && showingProfile.twilio_phone_number && !showingProfile.deleted_at"
        name="phone-line"
      >
        <template slot="title">
          <i class="sdicon-phone text-gray-dark font-21 mr-2" />
          Phone Line
        </template>
        <div class="collapse-content">
          <UnitPhoneLine :showing-profile="showingProfile" />
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api';
import { AccessHardware, AccessHardwareOptions } from '@/constants/AccessHardware';
import get from 'lodash.get';
import UrlFormatter from '@/utils/UrlFormatterUtil';
import momentUtil from '@/utils/MomentUtil';
import { truncateText } from '@/utils/StringUtil';
import SdEmailIndicator from '@/components/common/SdEmailIndicator';
import SdPhoneIndicator from '@/components/common/SdPhoneIndicator';
import { DEFAULT_EMPTY_SCREENING_INPUT } from '@/constants/Unit';
import Showing from '@/constants/Showing';
import { SyndicationPortals } from '@/constants/Syndication';
import { resolveQualifiedAnswerFromCustomQuestion } from '@/utils/ScreeningCriteriaUtil';
import UnitPhoneLine from './UnitPhoneLine';

export default {
  name: 'UnitCollapsableDetails',
  components: { UnitPhoneLine, SdPhoneIndicator, SdEmailIndicator },
  props: {
    unitDevice: {
      type: Object,
      required: false,
      default: null,
    },
    unit: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const activeTabs = reactive([]);
    const property = computed(() => props.unit.property);
    const showingProfile = computed(() => props.unit.last_showing_profile);
    const activeShowingProfile = computed(() => props.unit.active_showing_profile);
    const isShowingProfilePublishListing = computed(() => showingProfile.value?.type === Showing.TYPE_PUBLISH_LISTING);
    const isUnitDetailsEmpty = computed(() => !screeningCriteria && !showingWindows);
    const showingWindows = computed(computeShowingWindows);
    const screeningCriteria = computed(computeScreeningCriteria);
    const accessHardwareLabel = computed(computeAccessHardwareLabel);
    const screeningCriteriaComponentsRef = ref(null);
    const isSyndicated = store.getters['Auth/businessSyndicated'];
    const listingOwnerDetails = computed(computedListingOwner);
    const isLiveOnAllSyndicatedPortals = computed(() => props.unit.active_showing_profile.syndication_status?.every((status) => status.live));
    const enabledSyndicationPortals = [
      { portal: SyndicationPortals.ZILLOW, enabled: store.getters['Auth/isZillowSyndicationEnabled'] },
      { portal: SyndicationPortals.ZUMPER, enabled: store.getters['Auth/isZumperSyndicationEnabled'] },
      { portal: SyndicationPortals.APARTMENTS_DOT_COM, enabled: store.getters['Auth/isApartmentsDotComSyndicationEnabled'] },
    ];

    getScreeningComponents();
    return {
      isUnitDetailsEmpty,
      listingOwnerDetails,
      property,
      showingProfile,
      activeShowingProfile,
      activeTabs,
      showingWindows,
      screeningCriteria,
      get,
      UrlFormatter,
      accessHardwareLabel,
      AccessHardware,
      isShowingProfilePublishListing,
      isSyndicated,
      isSyndicationPortalEnabled,
      isLiveOnAllSyndicatedPortals,
    };
    async function getScreeningComponents() {
      screeningCriteriaComponentsRef.value = store.getters['ScreeningCriteria/get'] || (await store.dispatch('ScreeningCriteria/load'));
    }

    function isSyndicationPortalEnabled(portal) {
      return enabledSyndicationPortals.some((sp) => sp.portal === portal && sp.enabled);
    }

    function computeAccessHardwareLabel() {
      const accessHardwareType = showingProfile.value.access_hardware;
      if (['codebox', 'sentrilock'].includes(accessHardwareType)) {
        return 'Smart Device Serial Number';
      }
      if (accessHardwareType) {
        const relevantType = AccessHardwareOptions.find(type => type.value === accessHardwareType);
        return `${relevantType.label} Code`;
      }

      return 'Code';
    }

    function computedListingOwner() {
      if (property.value.business.should_show_contact) {
        return {
          name: property.value.business.name,
          email: property.value.business.contact_email,
          phone_formatted: property.value.business.formatted_contact_phone,
          phone: `${property.value.business.contact_phone}${property.value.business.contact_phone_extension ? `,${property.value.business.contact_phone_extension}` : ''}`,
        };
      }
      return {
        name: property.value.listing_manager.full_name,
        email: property.value.listing_manager.email,
        phone: `${property.value.listing_manager.public_phone_number}${property.value.listing_manager.public_phone_number_extension ? `,${property.value.listing_manager.public_phone_number_extension}` : ''}`,
        phone_formatted: property.value.listing_manager.formatted_public_phone_number,
      };
    }

    function computeShowingWindows() {
      const windows = get(showingProfile.value, 'showing_windows');

      if (!windows) {
        return [];
      }
      return windows.map((showingWindow) => {
        const day = context.root.$momentUtil.weekdays()[showingWindow.week_day].substring(0, 3);
        const from = context.root.$momentUtil(showingWindow.from_hour, null, 'HH:mm:ss').toDisplayShortTimeFormat();
        const to = context.root.$momentUtil(showingWindow.to_hour, null, 'HH:mm:ss').toDisplayShortTimeFormat();
        const assigneeName = showingWindow.assignee ? showingWindow.assignee.full_name : 'Showdigs';
        return `${day} ${from}-${to} - <strong>${assigneeName}</strong>`;
      });
    }

    function computeScreeningCriteria() {
      if (!showingProfile.value
        || !screeningCriteriaComponentsRef.value
        || !!showingProfile.value.pre_screening_disabled_at
        || !showingProfile.value.screening_template_id) {
        return undefined;
      }
      const screeningCollection = [];

      const screeningTemplate = showingProfile.value.screening_template;
      Object.keys(screeningCriteriaComponentsRef.value).forEach((scComponent) => {
        let value;
        if (screeningCriteriaComponentsRef.value[scComponent].model === 'Property') {
          value = screeningTemplate.title === 'Custom'
            ? get(props.unit, `property.screening_criteria.${scComponent}`)
            : get(screeningTemplate, `property_screening.${scComponent}`);
        } else {
          value = screeningTemplate.title === 'Custom'
            ? get(showingProfile.value, `screening_criteria.${scComponent}`)
            : get(screeningTemplate, `showing_screening.${scComponent}`);
        }
        const options = screeningCriteriaComponentsRef.value[scComponent].options;
        if (options) {
          value = options[value];
        }
        if (!value) {
          return;
        }
        let label = '';
        if (scComponent === 'move_in_cost') {
          label = 'Security Deposit';
        } else {
          label = scComponent.split('_').join(' ');
        }

        // values exceptions:
        if (scComponent === 'availability_date') {
          const timeZone = props.unit.origin_id || props.unit.listing_identifier ? null : property.value.timezone;
          value = momentUtil(value, timeZone).toDisplayMonthAndDayFormat();
        }

        if (scComponent === 'move_in_cost') {
          value = `$${value}`;
        }

        if (scComponent === 'eviction_history') {
          label += ' policy';
        }

        if (scComponent === 'additional_criteria') {
          value = truncateText(value);
        }

        if (scComponent === 'smoking_policy' && value === DEFAULT_EMPTY_SCREENING_INPUT) {
          return;
        }
        screeningCollection.push({ label, value, key: scComponent });
      });

      if (showingProfile.value.custom_screening) {
        showingProfile.value.custom_screening.forEach((data, index) => {
          screeningCollection.push({
            label: data.custom_question,
            value: resolveQualifiedAnswerFromCustomQuestion(data.response_options),
          });
        });
      }

      if (screeningTemplate.custom_screening) {
        screeningTemplate.custom_screening.forEach((data, index) => {
          screeningCollection.push({
            label: data.custom_question,
            value: resolveQualifiedAnswerFromCustomQuestion(data.response_options),
          });
        });
      }

      return screeningCollection.length ? screeningCollection : null;
    }
  },
};
</script>

<style lang="scss">
.property-collapsable-details {
  .double-line-header {
    line-height: 1rem;

    div {
      margin-top: 3px;
      padding-bottom: 3px;
    }

    i {
      font-size: 10px;
      line-height: 1rem;
      margin-left: -3px;
    }

    span {
      position: relative;
      font-size: $--font-size-small;
      color: gray-color(dark);
    }

    .dot {
      margin-left: 2px;
    }
  }

  .collapse-content {
    background: gray-color(lighter);
    border-radius: $border-radius;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }

  .detail {
    label {
      font-size: $--font-size-small;
      color: gray-color(dark);
      font-weight: $strong;
      margin: 0;
      line-height: 1rem;
      text-transform: capitalize;
    }

    span,
    a {
      font-size: $--font-size-base;
      display: block;
    }

    span {
      color: gray-color(darker);
    }
  }

  .el-collapse {
    border-top: none;
    border-bottom: none;

    .el-collapse-item {
      .el-collapse-item__header {
        font-weight: normal;
        height: 3.75rem;
        line-height: 3.75rem;

        .el-collapse-item__arrow {
          position: relative;
        }
      }

      .el-collapse-item__wrap {
        border-bottom: 0;
      }

      .el-collapse-item__content {
        padding-bottom: 1rem;
      }

      &:last-child {
        .el-collapse-item__header {
          border-bottom: none;
        }

        .el-collapse-item__content {
          padding-bottom: 0.5rem;
        }
      }
    }
  }
}
</style>
