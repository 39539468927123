<template>
  <component
    :is="isFormField ? 'ElFormItem' : 'div'"
    :prop="!isAddPropertyForm ? 'property.property_type' : 'property_type'"
    :rules="{ type: 'enum', enum: propertyTypes, validator: propertyTypeValidator }"
  >
    <SdFloatLabel :class="innerClass">
      <ElSelect
        v-model="selectedPropertyType"
        placeholder="Type"
        @input="handleChange"
      >
        <ElOption
          v-for="(propertyType, propertyTypeIndex) in propertyTypes"
          :key="propertyTypeIndex"
          :label="propertyType"
          :value="propertyType"
        />
      </ElSelect>
    </SdFloatLabel>
  </component>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import { BasicPropertyTypes, ExtendedPropertyTypes, PropertyType } from '@/constants/Property';

export default {
  name: 'PropertyTypeField',
  props: {
    unit: {
      type: Object,
      default: null,
    },
    property: {
      type: Object,
      required: true,
    },
    isAddPropertyForm: {
      type: Boolean,
      default: false,
    },
    isFormField: {
      type: Boolean,
      required: false,
      default: true,
    },
    innerClass: {
      type: String,
      default: '',
      required: false,
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const isIntegrationEnabled = store.getters['BusinessSource/isIntegrationEnabled'];
    const selectedPropertyType = ref(props.property.property_type || PropertyType.SingleFamilyHome);
    const propertyTypes = isIntegrationEnabled ? ExtendedPropertyTypes : BasicPropertyTypes;

    if (
        !propertyTypes.includes(selectedPropertyType.value)
        && ExtendedPropertyTypes.includes(selectedPropertyType.value)
    ) {
      // if the property has an "extended" property type but the integration is disabled,
      // we still add it to the list to prevent forcing them to change it
      propertyTypes.push(selectedPropertyType.value);
    }

    return {
      propertyTypes,
      propertyTypeValidator,
      selectedPropertyType,
      handleChange,
    };

    function handleChange(value) {
      props.property.property_type = value;
      context.emit('update', { property_type: value });
    }

    // eslint-disable-next-line consistent-return
    function propertyTypeValidator(_rule, value, callback) {
      if (!selectedPropertyType.value) {
        return callback('Property type must be specified');
      }
      callback();
    }
  },
};
</script>

<style lang="scss" scoped></style>
