<template>
  <div>
    <p>
      We found {{ inquiries.length }} recent  {{ $pluralize('inquiry', inquiries.length) }} for this property.
      Would you like us to reach out to
      {{ $pluralize('this', inquiries.length) }}
      {{ $pluralize('prospect', inquiries.length) }}
      to schedule a showing?
    </p>
    <div class="inquiries-wrapper">
      <div
        v-for="(inquiry, index) in inquiries"
        :key="index"
        class="row inquiry-item"
      >
        <div class="inquiry-date col-12">
          {{ $momentUtil(inquiry.created_at, property.timezone).toDisplayMonthAndDayFormat() }} -
          {{ inquiry.lead.full_name }}
          <span v-if="inquiry.lead.formatted_phone_number">
            ({{ inquiry.lead.formatted_phone_number }})
          </span>
          <span v-else-if="inquiry.lead.email">
            ({{ inquiry.lead.email }})
          </span>
        </div>
      </div>
    </div>
    <div
      slot="footer"
      class="dialog-footer container"
    >
      <div class="row justify-content-between mt-4">
        <ElButton
          size="medium"
          type="info"
          @click="closeDialog"
        >
          Skip
        </ElButton>
        <ElButton
          size="medium"
          :loading="loadingRef"
          @click="recycleInquiries"
        >
          Reach out
        </ElButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import DateTime from '../../constants/DateTime';

export default {
  name: 'ShowingProfileRecycleInquiriesDialog',
  props: {
    property: {
      type: Object,
      required: true,
    },
    inquiries: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const loadingRef = ref(false);
    return {
      DateTime,
      loadingRef,
      closeDialog,
      recycleInquiries,
    };

    function closeDialog() {
      context.emit('close');
    }

    async function recycleInquiries() {
      loadingRef.value = true;
      try {
        const response = await context.root.$store.dispatch('Unit/recycleInquiries', context.root.$route.params.unit_id);
        let msg = `Contacted ${props.inquiries.length} prospects and informed them showings are now available at ${response.property_address}`;
        if (response.unit_name) {
          msg += ` - ${response.unit_name}`;
        }
        showSuccessNotify(context, msg);
        context.emit('close');
      } catch (error) {
        loadingRef.value = false;
        showErrorNotify(context, error.message);
      }
    }
  },
};

</script>
<style lang="scss">
    .inquiries-wrapper {
        margin: 2rem -2rem 0;
        padding: 1rem 2rem;
        background: gray-color('lighter');
        border-style: solid;
        border-width: 1px 0;
        border-color: gray-color('light');
    }
    .inquiry-item{
        font-size: $--font-size-base;
        color: gray-color('darker');
        line-height: 2rem;
        .lead-name{
            i{
                margin-left: -1rem;
                color: gray-color('dark');
            }
        }
    }
</style>
