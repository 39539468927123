<template>
  <div

    class="sd-vacancy-status d-flex align-items-center uppercase font-12 p-2"
  >
    {{ isVacant ? Vacancy.STATUS_VACANT : Vacancy.STATUS_OCCUPIED }}
  </div>
</template>

<script>
import Vacancy from '@/constants/Vacancy';

export default {
  props: {
    isVacant: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    return {
      Vacancy,
    };
  },
};
</script>

<style lang="scss" scoped>
.sd-vacancy-status {
  background-color: theme-color("primary");
  color: gray-color("dark");
  border: 1px transparent solid;
  line-height: 0.35rem;
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: -0.3px;
  border-color: theme-color("primary");
  color: $white;
}
</style>
