import Showing from '@/constants/Showing';
import get from 'lodash.get';

function formatImpact(context, impactResponse) {
  const nonCancellable = formatImpactType(context, impactResponse, Showing.IMPACT_TYPE_NOT_CANCELLABLE);
  const cancellable = formatImpactType(context, impactResponse, Showing.IMPACT_TYPE_CANCELLABLE);
  return { nonCancellable, cancellable };
}

function formatImpactType(context, rawImpact, type) {
  return rawImpact[type].map((item) => {
    item.displayTourAt = displayableTourAt(context, item.tour_at, item.property.timezone);
    item.leads = item.schedule_inquiries.map((scheduleInquiry) => get(scheduleInquiry, 'inquiry.lead'));
    return item;
  });
}

function displayableTourAt(context, tourAt, timezone) {
  return context.root.$momentUtil(tourAt, timezone).toDisplayFormatMonthWithDayAndShortTimeFormat();
}

export { formatImpact };
