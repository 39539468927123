<template>
  <div class="row">
    <div
      v-if="shouldShowPmsCodeField"
      class="col-12"
    >
      <ElFormItem
        class="pms-code"
        :rules="isPropertyCodeRequiredRef ? rules.pms_code_required : rules.pms_code"
        prop="pms_code"
      >
        <el-tooltip
          :disabled="$viewport.lt.md"
          :visible-arrow="false"
          placement="right-start"
          popper-class="popover-panel"
        >
          <template slot="content">
            <i class="sdicon-info-circle" />
            <p>The property name or code you use in your AppFolio account</p>
          </template>
          <SdFloatLabel
            :with-optional="!isPropertyCodeRequiredRef"
            label="AppFolio property code"
          >
            <ElInput
              v-model="generalInfoPropertyPayloadRef.pms_code"
              placeholder="AppFolio property code"
              @input="emitPropertyPayloadChanged"
            />
          </SdFloatLabel>
        </el-tooltip>
      </ElFormItem>
    </div>
    <div
      v-if="isIframeEmbedEnabled"
      class="col-12"
    >
      <ElFormItem
        prop="unit_pms_code"
        :rules="rules.unit_pms_code"
      >
        <SdFloatLabel label="Internal ID">
          <ElInput
            v-model="generalInfoProfilePayloadRef.unit_pms_code"
            placeholder="Internal ID"
            @input="emitProfilePayloadChanged"
          />
        </SdFloatLabel>
      </ElFormItem>
    </div>
    <div class="col-12">
      <p
        v-if="property && property.units.length > 1"
        class="text-gray-dark"
      >
        Changing the listing owner will apply to all the units in this property
      </p>
      <SdFloatLabel label="Listing owner">
        <ElFormItem
          :prop="isEdit ? 'property.listing_manager_id' : 'listing_manager_id'"
          :rules="rules.listing_manager_id"
        >
          <el-tooltip
            :disabled="$viewport.lt.md"
            :visible-arrow="false"
            placement="right-start"
            popper-class="popover-panel"
          >
            <template slot="content">
              <i class="sdicon-info-circle" />
              <p>The point of contact for updates, reports, and questions regarding this listing</p>
            </template>
            <ElSelect
              v-model="generalInfoPropertyPayloadRef.listing_manager_id"
              placeholder="Listing owner"
              value-key="value"
              @change="emitSelectedListingManagerChanged"
              @input="emitPropertyPayloadChanged"
            >
              <ElOption
                v-for="item in listingOwnersOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </ElSelect>
          </el-tooltip>
        </ElFormItem>
        <div
          v-if="business.should_show_contact"
          class="p-2 font-14 text-gray-dark"
        >
          Prospects will see {{ business.name }} and will be able to contact you via
          <template v-if="business.formatted_contact_phone && business.contact_email">
            {{ business.formatted_contact_phone }} and {{ business.contact_email }}
          </template>
          <template v-else-if="business.formatted_contact_phone">
            {{ business.formatted_contact_phone }}
          </template>
          <template v-else>
            {{ business.contact_email }}
          </template>.
        </div>
        <div
          v-else-if="property.listing_manager.full_name"
          class="p-2 font-14 text-gray-dark"
        >
          Prospects will see {{ property.listing_manager.full_name }} and will be able to contact you via
          <template v-if="property.listing_manager.formatted_public_phone_number && property.listing_manager.email">
            {{ property.listing_manager.formatted_public_phone_number }} and {{ property.listing_manager.email }}
          </template>
          <template v-else-if="property.listing_manager.formatted_public_phone_number">
            {{ property.listing_manager.formatted_public_phone_number }}
          </template>
          <template v-else>
            {{ property.listing_manager.email }}
          </template>.
        </div>
      </SdFloatLabel>
    </div>
    <div
      v-if="withShowingProfileOwnerReportSettings && !isEdit && selectedListingManager"
      class="col-12"
    >
      <ShowingProfileOwnerReportSettings
        :listing-owner-email="selectedListingManager.email"
        :owners-options="listingOwnersOptions"
        :property="property"
        @update-owners-emails="updateOwnersEmails"
        @update-send-owner-reports="updateSendOwnerReports"
      />
    </div>
    <div class="col-12">
      <slot name="unit-vacancy" />
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import get from 'lodash.get';

import { showErrorNotify } from '@/utils/NotifyUtil';
import { PmsCodeRule, PmsCodeRequiredRule } from '@/validator/PmsCodeRules';
import { cleanAndMergeWithDefaultPayload } from '@/utils/payloadUtil';
import SdFloatLabel from '@/components/common/form/SdFloatLabel.vue';
import { BusinessSourceTypes } from '@/constants/BusinessSource';
import Regex from '../../constants/Regex';

import ShowingProfileOwnerReportSettings from './ShowingProfileOwnerReportSettings';

const regexConstants = Regex;

export default {
  name: 'ShowingProfileGeneralInfo',
  components: { SdFloatLabel, ShowingProfileOwnerReportSettings },
  props: {
    showingProfile: {
      type: Object,
      default: null,
    },
    property: {
      type: Object,
      default: null,
    },
    unit: {
      type: Object,
      default: null,
    },
    business: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    withShowingProfileOwnerReportSettings: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const user = computed(() => context.root.$store.state.Auth.user);
    const initialPropertyPayload = {
      listing_manager_id: get(props, 'property.listing_manager_id', null),
      pms_code: get(props, 'property.pms_code', null),
    };
    const initialProfilePayload = cleanAndMergeWithDefaultPayload(props.showingProfile, defaultProfilePayload);
    const generalInfoProfilePayloadRef = ref(initialProfilePayload);
    const generalInfoPropertyPayloadRef = ref(initialPropertyPayload);
    const listingOwnersOptions = ref([]);
    const isNewIntegrationEnabled = ref(Boolean(get(props, 'business.source.client_id')));
    const selectedListingManager = computed(
      () => listingOwnersOptions.value.find((option) => option.value === generalInfoPropertyPayloadRef.value.listing_manager_id),
    );
    const isPropertyCodeRequiredRef = ref(Boolean(props.unit.listing_identifier));
    const rules = getRules();
    const isIframeEmbedEnabled = Boolean(props.business && props.business.embed_frame_sources !== null);

    if (props.isEdit === false) {
      generalInfoProfilePayloadRef.value.unit_pms_code = props.unit && props.unit.pms_code;
    }

    const shouldShowPmsCodeField = ref(
        !props.property.pms_code
        && !isNewIntegrationEnabled.value
        && Boolean(get(props.business, 'source.synced_at'))
        && !isIframeEmbedEnabled.value
        && get(props.business, 'source.source_type') === BusinessSourceTypes.APPFOLIO,
    );

    getListingOwners();
    emitProfilePayloadChanged();

    return {
      generalInfoProfilePayloadRef,
      selectedListingManager,
      generalInfoPropertyPayloadRef,
      isIframeEmbedEnabled,
      emitProfilePayloadChanged,
      emitPropertyPayloadChanged,
      emitSelectedListingManagerChanged,
      updateSendOwnerReports,
      updateOwnersEmails,
      listingOwnersOptions,
      isNewIntegrationEnabled,
      rules,
      get,
      isPropertyCodeRequiredRef,
      shouldShowPmsCodeField,
    };

    function emitProfilePayloadChanged() {
      context.emit('update-showing-profile-payload', {
        unit_pms_code: generalInfoProfilePayloadRef.value.unit_pms_code,
      });
    }

    function emitPropertyPayloadChanged() {
      context.emit('update-property-payload', {
        pms_code: generalInfoPropertyPayloadRef.value.pms_code,
        listing_manager_id: generalInfoPropertyPayloadRef.value.listing_manager_id,
      });
    }

    function emitSelectedListingManagerChanged() {
      context.emit('update-selected-listing-manager', selectedListingManager.value.email);
    }

    function updateSendOwnerReports(value) {
      context.emit('update-showing-profile-payload', { send_owner_reports: value });
    }

    function updateOwnersEmails(value) {
      context.emit('update-property-payload', { owners_emails: value });
    }

    async function getListingOwners() {
      try {
        const listingOwners = await context.root.$store.dispatch('Auth/getTeamMembers', {
          paginate: 0,
          'scopes[verified]': 1,
        });

        listingOwnersOptions.value = listingOwners.map((user) => ({
          value: user.id,
          email: user.email,
          label: getListingOwnerLabel(user),
        }));

        if (!generalInfoPropertyPayloadRef.value.listing_manager_id) {
          generalInfoPropertyPayloadRef.value.listing_manager_id = user.value.id;
        }

        emitPropertyPayloadChanged();
      } catch (error) {
        showErrorNotify(context, error.message);
      }
    }

    function getListingOwnerLabel(user) {
      return user.full_name;
    }

    function getRules() {
      return {
        pms_code: [PmsCodeRule],
        pms_code_required: [PmsCodeRequiredRule],
        unit_pms_code: [{
          validator: (rule, value, callback) => {
            if (!value || !value.trim()) {
              return callback(new Error('Internal ID is required'));
            }

            value = value.trim();

            if (value.match(regexConstants.url)) {
              return callback(
                new Error('Internal ID cannot be a URL'),
              );
            }

            if (value.length > 100) {
              return callback(
                new Error('Internal ID is too long'),
              );
            }

            return callback();
          },
        }],
        listing_manager_id: {
          type: 'integer',
          required: true,
          message: 'Listing owner is required',
        },
      };
    }
  },
};

const defaultProfilePayload = {
  unit_pms_code: null,
};
</script>

<style lang="scss" scoped>
.pms-code {
  ::v-deep .input-under-label {
     top: 17px !important;
    }
  }
</style>
