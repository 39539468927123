<template>
  <div>
    <template v-if="imagePresent && uiFlags.removeBtn === true">
      <img
        class="w-100"
        :src="imagePresent"
      >
    </template>
    <div
      v-else
      class="sd-image-field"
    >
      <croppa
        v-model="img"
        :disabled="disabled"
        class="d-flex"
        :auto-sizing="true"
        :show-remove-button="false"
        :disable-rotation="true"
        :prevent-white-space="preventWhiteSpace"
        :file-size-limit="fileSizeLimitInByte"
        initial-size="natural"
        :height="height"
        :quality="quality"
        :accept="acceptTypes"
        placeholder=""
        @new-image-drawn="imgChange"
        @zoom="imgChange"
        @move="imgChange"
        @file-type-mismatch="fileTypeMismatch"
        @file-size-exceed="fileSizeExceed"
      >
        <div class="default-slot">
          <div
            v-if="!hasImage"
            class="row h-100 no-gutters justify-content-center align-items-center"
          >
            <div class="text-center">
              <img
                src="@/assets/icons/icon-image.svg"
                class="icon-image"
              >
              <p
                class="text-center font-17 col-12 pt-2"
                :class="{'text-gray-dark': disabled}"
              >
                {{ placeholderText }}<br>
                {{ smallHintText }}
              </p>
            </div>
          </div>
          <div
            v-if="hasImage"
            class="row h-100 no-gutters justify-content-center align-items-end text-white font-15 drag-and-scroll"
          >
            <span><strong>Drag & Scroll</strong> to adjust the image</span>
          </div>
        </div>
      </croppa>
    </div>
    <div
      v-if="!disabled && uiFlags.removeBtn === true && removeButtonLocation === 'top-right'"
      class="remove-img-btn-top-right"
      @click="removeImage"
    >
      <i class="sdicon-trash font-17 text-white" />
    </div>
    <ElButton
      v-if="!disabled && uiFlags.removeBtn === true && removeButtonLocation === 'separate'"
      size="medium"
      class="remove-img-btn mt-3 mb-4"
      @click="removeImage"
    >
      Remove image
    </ElButton>
  </div>
</template>

<script>
import { reactive, ref, computed } from '@vue/composition-api';

import { showErrorNotify } from '@/utils/NotifyUtil';

export default {
  name: 'SdImageField',
  props: {
    src: {
      type: String,
      default: null,
    },
    placeholderText: {
      type: String,
      required: true,
    },
    smallHintText: {
      type: String,
      required: false,
      default: '(Drop image here or click to browse)',
    },
    removeButtonLocation: {
      type: String,
      required: false,
      default: 'top-right',
    },
    imageOutputQuality: {
      type: String,
      default: null,
      required: false,
    },
    acceptTypes: {
      type: String,
      default: 'image/*',
      required: false,
    },
    height: {
      type: Number,
      default: 300,
      required: false,
    },
    quality: {
      type: Number,
      default: 2,
      required: false,
    },
    fileTypeMismatchMessage: {
      type: String,
      default: 'Selected file type is not an image',
      required: false,
    },
    imageOutputType: {
      type: String,
      default: null,
      required: false,
    },
    fileSizeLimit: {
      type: Number,
      required: false,
      default: 10,
    },
    preventWhiteSpace: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const img = ref(null);
    const fileSizeLimitInByte = props.fileSizeLimit * 1024 * 1024;
    const uiFlags = reactive({ removeBtn: false });
    const hasImage = computed(() => img.value && img.value.hasImage());
    const imagePresent = ref(props.src);
    if (imagePresent.value) {
      uiFlags.removeBtn = true;
    }
    return {
      img,
      uiFlags,
      hasImage,
      imgChange,
      fileTypeMismatch,
      fileSizeExceed,
      fileSizeLimitInByte,
      removeImage,
      imagePresent,
    };

    function imgChange() {
      if (hasImage.value) {
        if (props.imageOutputQuality && props.imageOutputType) {
          img.value.generateBlob((blob) => {
            context.emit('set-image-field', blob);
          }, props.imageOutputType, props.imageOutputQuality);
        } else {
          img.value.generateBlob((blob) => {
            context.emit('set-image-field', blob);
          });
        }
        this.uiFlags.removeBtn = true;
        this.imagePresent = null;
      }
    }
    function removeImage() {
      this.uiFlags.removeBtn = false;
      if (hasImage.value) {
        img.value.remove();
      }
      context.emit('unset-image-field');
    }
    function fileTypeMismatch() {
      showErrorNotify(context, props.fileTypeMismatchMessage);
    }
    function fileSizeExceed() {
      showErrorNotify(context, `Picture size can not exceed ${props.fileSizeLimit}MB`);
    }
  },
};
</script>

<style lang="scss">
.sd-image-field {
  width: 100%;
  height: 100%;
  border-radius: $app-border-radius;
  background-color: $--input-background-color;
  position: relative;
  .croppa-container{
    cursor: pointer;
    border-radius: $app-border-radius;
    border: 1px solid $--input-background-color;
    &:hover{
      border: 1px solid gray-color('light');
    }
    &.croppa--has-target {
      cursor: move;
    }
    &.croppa--disabled {
      cursor: default;
    }
  }
  .default-slot {
    border-radius: $app-border-radius;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
    .drag-and-scroll{
      text-shadow: 1px 1px 1px #000000;
    }
  }
}

.remove-img-btn-top-right {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    top: 30px;
    right: 20px;
    background-color: theme-color('primary');
    transition: background-color .25s ease-out;
    cursor: pointer;
    &:hover, &:active {
      background-color: rgba(theme-color('primary-dark'), 0.85);
    }
  }
</style>
