<template>
  <div class="search-input-container">
    <ElInput
      v-model="query"
      :placeholder="placeholder"
      size="small"
      clearable
      class="font-15"
      @input="(q) => $emit('input', q)"
    >
      <template #prefix>
        <i class="sdicon-magnifying-glass font-15" />
      </template>
    </ElInput>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
  name: 'SdSearchInput',
  props: {
    placeholder: {
      type: String,
      default: 'Search',
    },
  },
  setup() {
    const query = ref('');
    return {
      query,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/utils';
.search-input-container {
  @include search-input-with-focus(
      $with-mobile-small-icon-expander: false,
      $desktop-input-height: 40px,
  );
}
</style>
