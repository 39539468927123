import { BusinessSourceTypes } from '@/constants/BusinessSource';
import store from '@/store';

export function getBusinessSourceType() {
  const businessSource = store.getters['BusinessSource/get'];
  return businessSource?.propexo_integration_id || businessSource?.client_id ? BusinessSourceTypes[businessSource.source_type.toUpperCase()] : 'Listings';
}

export function isDataPrefill() {
  const isAFIntegration = store.getters['BusinessSource/isAppfolioIntegration'];
  const isSyndicated = store.getters['Auth/businessSyndicated'];
  if (isAFIntegration) {
    return true;
  }
  return getBusinessSourceType() && !isSyndicated;
}
