<template>
  <div class="owner-report-settings">
    <div class="row">
      <div class="col">
        <div class="mb-1 font-18 text-gray-darker">
          Send weekly report to owners
        </div>
        <div class="text-gray-dark">
          We will cc the owner on the weekly property report we send you.
        </div>
      </div>
      <div class="col-auto">
        <ElTooltip
          :visible-arrow="false"
          popper-class="popover-panel"
          placement="right-start"
          :disabled="!isBasicPlan"
        >
          <template
            v-if="isBasicPlan"
            slot="content"
          >
            <i class="sdicon-info-circle" />
            <div>This feature is only available in our Standard plan.</div>
            <div>
              <a
                target="_blank"
                href="/billing?modify=true"
              >
                Upgrade Plan
              </a>
            </div>
          </template>
          <ElSwitch
            v-model="generalInfoProfilePayloadRef.send_owner_reports"
            class="col-auto d-block"
            :width="44"
            :disabled="isBasicPlan"
            @change="emitSendOwnerReportsChanged"
          />
        </ElTooltip>
      </div>
      <div
        v-if="generalInfoProfilePayloadRef.send_owner_reports"
        class="col-12 pt-2"
      >
        <ElFormItem
          :prop="showingProfile ? 'property.owners_emails' : 'owners_emails'"
          :rules="{ validator: validateOwnerEmails, trigger: 'blur' }"
        >
          <PropertyOwnersSelect
            v-model="generalInfoPropertyPayloadRef.owners_emails"
            :current-listing-manager="generalInfoPropertyPayloadRef.listing_manager"
            :disabled="!generalInfoProfilePayloadRef.send_owner_reports"
            @input="emitOwnersEmailsChanged"
          />
        </ElFormItem>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import Plan from '@/constants/Plan';
import Regex from '@/constants/Regex';
import PropertyOwnersSelect from '@/components/property/PropertyOwnersSelect';

export default {
  name: 'ShowingProfileOwnerReportSettings',
  components: { PropertyOwnersSelect },
  props: {
    showingProfile: {
      type: Object,
      default: null,
    },
    property: {
      type: Object,
      default: null,
    },
    listingOwnerEmail: {
      type: String,
      default: null,
    },
    ownersOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const uiFlags = reactive({ isBudget: false });
    const generalInfoProfilePayloadRef = ref(props.showingProfile || { send_owner_reports: false });
    const generalInfoPropertyPayloadRef = ref(props.property || { owners_emails: null, listing_manager: null });
    const plan = context.root.$store.getters['Auth/plan'];
    const isBasicPlan = plan.name === Plan.BASIC;

    context.emit('update-owners-emails', generalInfoPropertyPayloadRef.value.owners_emails);

    return {
      uiFlags,
      generalInfoProfilePayloadRef,
      generalInfoPropertyPayloadRef,
      emitSendOwnerReportsChanged,
      emitOwnersEmailsChanged,
      validateOwnerEmails,
      isBasicPlan,
    };

    function emitSendOwnerReportsChanged() {
      context.emit('update-send-owner-reports', generalInfoProfilePayloadRef.value.send_owner_reports);
    }

    function emitOwnersEmailsChanged() {
      context.emit('update-owners-emails', generalInfoPropertyPayloadRef.value.owners_emails);
    }

    function validateOwnerEmails(rule, value, callback) {
      if (!generalInfoProfilePayloadRef.value.send_owner_reports) {
        return callback();
      }
      const ownersEmail = generalInfoPropertyPayloadRef.value.owners_emails;
      if (!ownersEmail || ownersEmail.length === 0) {
        return callback(new Error('Missing owner email'));
      }
      const isValid = ownersEmail.every((email) => email.match(Regex.email));
      if (!isValid) {
        return callback(new Error('Invalid email provided'));
      }
      return callback();
    }
  },
};

</script>

<style lang="scss">
  .owner-report-settings {
    .budget-switch {
      height: 62px;
    }
  }
</style>
