<template>
  <div class="row gutter-1">
    <div class="activity-date">
      {{ activity.date }}
    </div>
    <div class="col-auto">
      <div
        class="activity-icon border-0"
        :class="{
          'bg-primary':['SHOWING_ACTIVATED', 'SHOWING_REACTIVATED', 'SHOWING_SWAP'].includes(activity.type),
          'bg-green': ['SHOWING_UNPAUSED', 'SHOWING_PAUSE_OVER', 'SHOWING_LEASED', 'SHOWING_STOPPED'].includes(activity.type),
          'bg-red': ['SHOWING_PAUSED', 'SHOWING_FUTURE_PAUSED', 'SHOWING_FUTURE_PAUSED_ACTIVATED', 'SHOWING_PAUSED_UPDATE'].includes(activity.type)}"
      >
        <i :class="activityIconClass" />
      </div>
    </div>
    <div :class="$viewport.lt.md ? 'col-12 pt-2': 'col'">
      <h3 v-if="activity.type==='SHOWING_SWAP'">
        Showing type changed to {{ getShowingType(activity.showing_profile_type, false) }} by {{ activity.causer_full_name }}
      </h3>
      <h3 v-if="activity.type==='SHOWING_ACTIVATED'">
        {{ getShowingType(activity.showing_profile_type) }} initiated by {{ activity.causer_full_name }}
      </h3>
      <h3 v-if="activity.type==='SHOWING_PAUSED'">
        {{ getShowingType(activity.showing_profile_type) }} paused by {{ activity.causer_full_name }}
      </h3>
      <h3 v-if="activity.type==='SHOWING_FUTURE_PAUSED'">
        {{ getShowingType(activity.showing_profile_type) }} pause scheduled by {{ activity.causer_full_name }}
      </h3>
      <h3 v-if="activity.type==='SHOWING_FUTURE_PAUSED_ACTIVATED'">
        {{ getShowingType(activity.showing_profile_type) }} paused as previously scheduled by {{ activity.causer_full_name }}
      </h3>
      <h3 v-if="activity.type==='SHOWING_PAUSED_UPDATE'">
        {{ getShowingType(activity.showing_profile_type) }} pause updated by {{ activity.causer_full_name }}
      </h3>
      <h3 v-if="activity.type==='SHOWING_PAUSE_OVER'">
        {{ getShowingType(activity.showing_profile_type) }} resumed after pause
      </h3>
      <h3 v-if="activity.type==='SHOWING_LEASED'">
        <span>Property leased and {{ getShowingType(activity.showing_profile_type, false) }} stopped</span>
      </h3>
      <h3 v-if="activity.type==='SHOWING_STOPPED'">
        {{ getShowingType(activity.showing_profile_type) }} stopped by {{ activity.causer_full_name }}
      </h3>
      <h3 v-if="activity.type === 'SHOWING_UNPAUSED'">
        {{ getShowingType(activity.showing_profile_type) }} unpaused By {{ activity.causer_full_name }}
      </h3>
      <h3 v-if="activity.type === 'SHOWING_REACTIVATED'">
        {{ getShowingType(activity.showing_profile_type) }} restarted by {{ activity.causer_full_name }}
      </h3>
      <div class="activity-summary">
        <ul />
      </div>
      <div
        v-if="activity.leads && activity.type !== 'SHOWING_LEASED'"
        class="activity-data "
      >
        <span class="label">
          {{ $pluralize('Prospect', activity.leads.length) }}
        </span>
        <span class="value">
          <ProspectProfileLink
            v-for="(lead) in activity.leads"
            :key="lead.id"
            :prospect="lead"
          />
        </span>
      </div>
      <div
        v-if="activity.tour_at && activity.type === 'SHOWING_LEASED'"
        class="activity-data"
      >
        <span class="label">
          Date
        </span>
        <span class="value">
          {{ activity.tour_at }}
        </span>
      </div>
      <div
        v-if="activity.pause_from"
        class="activity-data"
      >
        <span class="label">
          Pause on
        </span>
        <span class="value">
          {{ activity.pause_from }}
        </span>
      </div>
      <div
        v-if="activity.paused_until"
        class="activity-data"
      >
        <span class="label">
          Paused until
        </span>
        <span class="value">
          {{ activity.paused_until }}
        </span>
      </div>
      <div
        v-if="activity.pause_reason"
        class="activity-data"
      >
        <span class="label">
          Reason
        </span>
        <span class="value">
          {{ getPauseReasonLabel(activity.pause_reason) }}
        </span>
      </div>
      <div
        v-if="['SHOWING_STOPPED', 'SHOWING_LEASED'].includes(activity.type) && isLastShowingProfile"
        style="float: right"
      >
        <ElTooltip
          :content="restartTooltipText"
          :disabled="allowRestartShowings"
        >
          <div class="d-inline-block">
            <ElButton
              v-if="unit.last_showing_profile.reactivatable"
              size="small"
              class="mt-2 mr-2"
              :disabled="!allowRestartShowings"
              @click.stop="showUnitRestartDialogDialogRef = true"
            >
              Undo and restart {{ tourType.toLowerCase() }}
            </ElButton>
          </div>
        </ElTooltip>
        <ElButton
          size="small"
          class="mt-2 view-report-btn"
          @click.stop
          @click="showPropertyRentedDialogRef = true"
        >
          View report
        </ElButton>
        <PropertyRentedDialog
          v-if="showPropertyRentedDialogRef"
          :title="`${unit.property.short_address}${unit.name ? ` - ${unit.name}` : ''}`"
          :first-time-opened="false"
          :is-rented="activity.type === 'SHOWING_LEASED'"
          :showing-profile-id="unit.last_showing_profile.id"
          :visible.sync="showPropertyRentedDialogRef"
          @close="showPropertyRentedDialogRef = false"
        />
        <UnitRestartShowingDialog
          v-if="showUnitRestartDialogDialogRef"
          :showing-profile-id="unit.last_showing_profile.id"
          :unit="unit"
          :visible.sync="showUnitRestartDialogDialogRef"
          @activate-showing="showingActivated"
          @close="closeRestartDialogDialog"
        />
        <ElDialog
          :center="true"
          top="30vh"
          :show-close="false"
          :visible.sync="showingProfileRecyclingDialogVisibleRef"
          :width="$viewport.lt.md ? 'auto': '700px'"
          :fullscreen="$viewport.lt.md"
        >
          <ShowingProfileRecycleInquiriesDialog
            :property="unit.property"
            :inquiries="inquiriesToRecycleRef"
            @close="closeRecycleDialog"
          />
        </ElDialog>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from '@vue/composition-api';
import Plan from '@/constants/Plan';
import PlanLimits from '@/constants/PlanLimits';
import UnitRestartShowingDialog from '@/components/unit/UnitRestartShowingDialog';
import { getPauseReasonLabel } from '@/constants/UnitShowingProfile';
import ShowingProfileRecycleInquiriesDialog from '@/components/showing-profile/ShowingProfileRecycleInquiriesDialog';
import Showing from '@/constants/Showing';
import PropertyRentedDialog from '../../property/PropertyRentedDialog';
import ProspectProfileLink from '../../prospect/ProspectProfileLink.vue';

export default {
  components: {
    UnitRestartShowingDialog,
    PropertyRentedDialog,
    ShowingProfileRecycleInquiriesDialog,
    ProspectProfileLink,
},
  props: {
    activity: {
      required: true,
      type: Object,
    },
    unit: {
      required: true,
      type: Object,
    },
  },
  setup(props, context) {
    const accountIsActive = !context.root.$store.getters['Auth/accountIsInactive'];
    const store = context.root.$store;
    const splitDate = computed(computeSplitDate);
    const showPropertyRentedDialogRef = ref(false);
    const showUnitRestartDialogDialogRef = ref(false);
    const activityIconClass = computed(computeIconClass);
    const allowRestartShowings = ref(accountIsActive);
    const restartTooltipText = ref('Please renew your subscription');
    const inquiriesToRecycleRef = ref([]);
    const showingProfileRecyclingDialogVisibleRef = ref(false);
    const propertyRef = ref(null);
    const tourType = computed(computeTourType);
    const isLastShowingProfile = computed(computeIsLastShowingProfile);
    init();
    return {
      showPropertyRentedDialogRef,
      showUnitRestartDialogDialogRef,
      showingProfileRecyclingDialogVisibleRef,
      splitDate,
      activityIconClass,
      getPauseReasonLabel,
      allowRestartShowings,
      restartTooltipText,
      inquiriesToRecycleRef,
      showingActivated,
      propertyRef,
      closeRestartDialogDialog,
      closeRecycleDialog,
      tourType,
      getShowingType,
      isLastShowingProfile,
    };

    async function init() {
      propertyRef.value = await context.root.$store.dispatch(
        'Property/find',
        context.root.$route.params.id,
      );

      if (accountIsActive) {
        const planDoesntAllowAdditionalActivations = propertyRef.value.business.subscription.plan.name === Plan.BASIC
          && propertyRef.value.business.activated_units_count >= PlanLimits.ListingActivationsIncludedInBasicPlan;

        if (planDoesntAllowAdditionalActivations) {
          restartTooltipText.value = 'You have reached the limit for activated units in the Basic plan. Please upgrade to the Standard plan in the billing page.';
        }

        allowRestartShowings.value = !planDoesntAllowAdditionalActivations;
      }
    }

    function computeIsLastShowingProfile() {
      const lastShowingProfile = props.unit.last_showing_profile;
      if (!lastShowingProfile.deleted_at) {
        return false;
      }
      const activityDate = new Date(props.activity.original_date);
      const showingProfileDeletedAt = new Date(lastShowingProfile.deleted_at);
      return activityDate >= showingProfileDeletedAt;
    }

    function getShowingType(type, firstCharCapitalize = true) {
      if (firstCharCapitalize) {
        const typeSplit = type.split('_');
        type = typeSplit.map((word) => {
          const firstLetter = word.charAt(0).toUpperCase();
          const rest = word.slice(1).toLowerCase();
          return firstLetter + rest;
        });
        return type.join(' ');
      }
      return type.replace('_', ' ');
    }

    function computeTourType() {
      const unit = props.unit;
      if (unit.active_showing_profile) {
        return unit.active_showing_profile.is_open_house ? 'Open House' : 'Showing';
      }
      return unit.last_showing_profile.is_open_house ? 'Open House' : 'Showing';
    }

    function computeSplitDate() {
      return props.activity.date.split('|');
    }

    function computeIconClass() {
      switch (props.activity.type) {
        case 'SHOWING_LEASED':
        case 'SHOWING_STOPPED':
          return 'sdicon-key text-white';
        default:
          return 'sdicon-home text-white';
      }
    }

    function closeRestartDialogDialog() {
      showUnitRestartDialogDialogRef.value = false;
      context.emit('reloadUnit');
    }

    function closeRecycleDialog() {
      showingProfileRecyclingDialogVisibleRef.value = false;
      context.emit('reloadUnit');
    }

    async function showingActivated() {
      const lastShowingProfile = props.unit.last_showing_profile;
      const isShowingProfileNotPublishListing = lastShowingProfile?.type !== Showing.TYPE_PUBLISH_LISTING;

      if (isShowingProfileNotPublishListing) {
        inquiriesToRecycleRef.value = await store.dispatch('Unit/checkFailedInquiries', context.root.$route.params.unit_id);
      }

      if (inquiriesToRecycleRef.value.length) {
        showingProfileRecyclingDialogVisibleRef.value = true;
      } else {
        closeRestartDialogDialog();
      }
    }
  },
};
</script>
