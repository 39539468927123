<template>
  <div class="sd-showing-slot-input">
    <SdCollapse
      ref="dateOptionsAccordion"
      v-model="uiFlags.isDateCollapseOpen"
      class="mt-6"
      accordion="choose-tour-time"
    >
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-auto label text-secondary">
            Day
          </div>
          <div class="col">
            <div
              v-if="chosenDateOption"
              class="chosen-option"
            >
              {{ chosenDateOption.month }}
              {{ chosenDateOption.dayOfMonth + chosenDateOption.dayOfMonthOrdinal }}
              -
              {{ chosenDateOption.dayOfWeek.toLowerCase() }}
            </div>
          </div>
        </div>
      </div>
      <div
        id="date-options"
        class="row gutters-14 mt-1 flex-wrap"
      >
        <div
          v-for="dateOption in dateOptions"
          :key="dateOption.date"
          class="col-md-3 col-4 py-2"
        >
          <div
            class="date-option"
            :class="{
              chosen: chosenDateOption && chosenDateOption.date === dateOption.date,
            }"
            :disabled="dateOption.disabled"
            @click="chooseDate(dateOption)"
          >
            <div class="month">
              {{ dateOption.month }}
            </div>
            <div>
              <h1 class="day-of-month">
                {{ dateOption.dayOfMonth }}
              </h1>
              <div class="day-of-month-ordinal">
                {{ dateOption.dayOfMonthOrdinal }}
              </div>
            </div>
            <div class="day-of-week">
              {{ dateOption.dayOfWeek }}
            </div>
          </div>
        </div>
      </div>
    </SdCollapse>
    <SdCollapse
      v-if="chosenDateOption"
      ref="timeOptionsAccordion"
      v-model="uiFlags.isTimeCollapseOpen"
      class="mt-2"
      accordion="choose-tour-time"
    >
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-auto label text-secondary">
            Time
          </div>
          <div class="col">
            <div
              v-if="chosenTimeOption"
              slot="header-center"
              class="chosen-option"
            >
              {{ chosenTimeOption.time }}
            </div>
          </div>
        </div>
      </div>
      <div
        id="time-options"
        class="row gutters-14 mt-1 flex-wrap"
      >
        <div
          v-for="timeOption in timeOptions"
          :key="timeOption.datetime"
          class="col-md-3 col-4 py-2"
        >
          <div
            class="time-option"
            :class="{
              chosen: chosenTimeOption && chosenTimeOption.datetime === timeOption.datetime,
            }"
            :disabled="timeOption.disabled"
            @click="chooseTime(timeOption)"
          >
            <div class="time">
              {{ timeOption.time }}
            </div>
          </div>
        </div>
      </div>
    </SdCollapse>
  </div>
</template>

<script>
import moment from 'moment';
import {
  computed, reactive, ref, watch, defineComponent,
} from '@vue/composition-api';
import installSmoothScrollPolyfill from '@/utils/installSmoothScrollPolyfill';
import * as Sentry from '@/utils/SentryUtil';
import SdCollapse from './SdCollapse';

export default defineComponent({
  name: 'SdShowingSlotInput',
  components: {
    SdCollapse,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    timezone: {
      type: String,
      required: true,
    },
    showingSlots: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, context) {
    const { $nextTick } = context.root;

    const isInitiallySelected = !!props.value;
    const timezone = props.timezone;
    const chosenDateOption = ref(null);
    const chosenTimeOption = ref(null);

    const uiFlags = reactive({
      isDateCollapseOpen: true,
      isTimeCollapseOpen: false,
    });

    const dateOptions = getDateOptions();
    const timeOptions = computed(computeTimeOptions);

    watch(chosenTimeOption, chosenTimeOptionChanged);

    installSmoothScrollPolyfill();

    if (isInitiallySelected) {
      tryPopulateInitialTimeSlot(props.value);
    }

    return {
      dateOptions,
      timeOptions,
      chosenDateOption,
      chooseDate,
      chosenTimeOption,
      chooseTime,
      uiFlags,
      clear,
    };

    function tryPopulateInitialTimeSlot(datetime) {
      try {
        const selectedDateSlot = props.showingSlots.find((dateSlot) => datetime.includes(dateSlot.date));
        if (selectedDateSlot?.time_slots) {
          const selectedTimeSlot = selectedDateSlot?.time_slots.find((timeOption) => timeOption?.date === datetime);
          const selextedDateFormatted = formatToDateOption(selectedDateSlot);
          const selectedTimeFormatted = formatToTimeOption(selectedTimeSlot);
          chooseDate(selextedDateFormatted);
          chooseTime(selectedTimeFormatted);
        }
      } catch (error) {
        Sentry.captureException("Couldn't populate selected date slot", {
          extraData: error,
        });
      }
    }

    function clear() {
      uiFlags.isDateCollapseOpen = true;
      uiFlags.isTimeCollapseOpen = false;
      chosenDateOption.value = null;
      chosenTimeOption.value = null;
    }

    function getDateOptions() {
      const dateSlots = props.showingSlots;
      const firstAvailableDateSlot = dateSlots.findIndex((dateSlot) => dateSlot.isAvailable);
      const lastAvailableDateSlot = dateSlots.length
        - dateSlots
          .slice(0)
          .reverse()
          .findIndex((dateSlot) => dateSlot.isAvailable);

      return dateSlots.slice(firstAvailableDateSlot, lastAvailableDateSlot).map(formatToDateOption);
    }

    function formatToDateOption(dateSlot) {
      const date = moment.tz(dateSlot.date, timezone);
      const dayOfMonthOrdinal = date.format('Do');
      const dayOfMonthOrdinalLength = dayOfMonthOrdinal.length;

      return {
        date: dateSlot.date,
        month: date.format('MMMM'),
        dayOfMonth: date.format('D'),
        dayOfMonthOrdinal: dayOfMonthOrdinal.substring(dayOfMonthOrdinalLength - 2, dayOfMonthOrdinalLength),
        dayOfWeek: date.calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'ddd',
          sameElse: 'ddd',
        }),
        disabled: !dateSlot.isAvailable,
        timeSlots: dateSlot.timeSlots,
      };
    }

    function computeTimeOptions() {
      if (!chosenDateOption.value) {
        return [];
      }
      const timeSlots = chosenDateOption.value.timeSlots;
      const firstAvailableTimeSlot = timeSlots.findIndex((dateSlot) => !dateSlot.disabled);
      const lastAvailableTimeSlot = timeSlots.length
        - timeSlots
          .slice(0)
          .reverse()
          .findIndex((dateSlot) => !dateSlot.disabled);

      return timeSlots.slice(firstAvailableTimeSlot, lastAvailableTimeSlot).map(formatToTimeOption);
    }

    function formatToTimeOption(timeSlot) {
      const time = moment(timeSlot.date).tz(timezone);
      return {
        time: time.format('h:mmA'),
        datetime: timeSlot.date,
        disabled: timeSlot.disabled,
      };
    }

    function chooseDate(dateOption) {
      if (dateOption.disabled) {
        return;
      }

      chosenTimeOption.value = null;

      if (chosenDateOption.value !== null && chosenDateOption.value.date === dateOption.date) {
        chosenDateOption.value = null;
        return;
      }

      chosenDateOption.value = dateOption;
      uiFlags.isDateCollapseOpen = false;
      context.emit('date-choose');

      $nextTick(() => {
        uiFlags.isTimeCollapseOpen = true;
        $nextTick(() => {
          const timeOptionsAccordionTop = context.refs.timeOptionsAccordion.$el.offsetTop;
          const dateOptionsAccordionContentHeight = context.refs.dateOptionsAccordion.$el.children[1].offsetHeight;
          window.scrollTo({
            top: timeOptionsAccordionTop - dateOptionsAccordionContentHeight,
            behavior: 'smooth',
          });
        });
      });
    }

    function chooseTime(timeOption) {
      if (timeOption.disabled) {
        return;
      }

      if (chosenTimeOption.value !== null && chosenTimeOption.value.datetime === timeOption.datetime) {
        chosenTimeOption.value = null;
        return;
      }

      chosenTimeOption.value = timeOption;
      uiFlags.isTimeCollapseOpen = false;
      context.emit('time-choose');
    }

    function chosenTimeOptionChanged() {
      if (chosenTimeOption.value === null) {
        context.emit('input', '');
      } else {
        context.emit('input', chosenTimeOption.value.datetime);
      }
    }
  },
});
</script>

<style lang="scss" scoped>
$gutter: 14px;

.sd-showing-slot-input ::v-deep .header {
  height: 61px;
  margin: 0 -1rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.label {
  width: 70px;
}

.chosen-option {
  display: inline-block;
  padding: 0.5rem;
  color: $white;
  font-weight: bold;
  background: theme-color(primary);
  border-radius: $border-radius;
}

#date-options,
#time-options {
  > [class*='col'] {
    position: relative;
  }

  .date-option,
  .time-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
    text-align: center;
    background: $white;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow;
    cursor: pointer;
    transition: $transition-base;

    &.chosen {
      background: theme-color(primary);

      &,
      * {
        color: $white !important;
      }
    }

    &[disabled] {
      background: gray-color('light');
      cursor: not-allowed;

      &,
      * {
        color: gray-color('dark') !important;
      }
    }
  }
}

#date-options {
  .date-option {
    .month {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }

    .day-of-month {
      display: inline-block;
      color: $black;
    }

    .day-of-month-ordinal {
      display: inline-block;
      margin-left: 2px;
      font-weight: bold;
      font-size: 17px;
      line-height: 24px;
    }

    .day-of-week {
      margin-top: 2px;
      color: theme-color(secondary);
      font-size: 18px;
      line-height: 24px;
    }
  }
}

#time-options {
  .time-option {
    height: 66px;

    .time {
      font-weight: bold;
      font-size: 16px;
    }

    .leads-count {
      color: theme-color(primary);
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
</style>
