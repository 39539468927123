<template>
  <div class="condition-report card-item-with-hover-shadow font-14 line-height-1.5 row no-gutters">
    <div class="col-lg-4 col-12 align-self-center pl-3 pt-3 pb-0 py-lg-3 mr-0">
      <div v-if="conditionReport.scheduled_showing_time">
        <span>{{ conditionReport.scheduled_showing_time }}</span>
      </div>
      <router-link
        :to="{ name: 'app.units.show', params: { id: conditionReport.property.id, unit_id: conditionReport.unit.id } }"
        class="d-flex align-items-center flex-wrap mt-1"
      >
        {{ conditionReport.property.short_address }}
        <span
          v-if="conditionReport.unit && conditionReport.unit.name"
          class="text-gray-dark mx-1 p-0.5"
        >
          (UNIT {{ conditionReport.unit.name }})
        </span>
      </router-link>
    </div>
    <div class="col-lg col-12 align-self-center pl-3 pt-1 pt-lg-3 pb-0 py-lg-3 border-left">
      <div class="d-flex align-items-center">
        <span>{{ conditionReport.displayable_type.replace(/condition report/i, '') }} #{{ conditionReport.id }}</span>
      </div>
      <div class="d-flex align-items-center flex-wrap">
        <div>
          <span class="text-gray-dark">
            Requested
          </span>
          <span>
            {{ conditionReport.associated_business_user.first_name }}
            {{ conditionReport.associated_business_user.last_name }}
          </span>
          <div>
            <span class="text-gray-dark">
              On
            </span>
            <span>
              {{ $momentUtil(conditionReport.created_at, conditionReport.property.timezone).toDisplayWithDayFormatAndYear() }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-auto col-12 ml-auto align-self-center mt-2 mt-lg-0 mb-2 mb-lg-0 pl-3 pr-1 align-items-center">
      <a
        v-if="scheduledUrl"
        target="_blank"
        :href="scheduledUrl"
      >
        <ElButton
          class="mr-2 button-external-link"
          size="small"
        >
          Tenant link
        </ElButton>
      </a>
      <a
        v-if="reportUrl"
        :href="reportUrl"
        target="_blank"
      >
        <ElButton
          class="mr-2 button-external-link"
          size="small"
        >
          View report
        </ElButton>
      </a>
      <ElButton
        v-if="reportUrl"
        size="small"
        class="mr-2 button-external-link"
        :class="isCopied ? 'border-green text-green bg-white': ''"
        @click="copyReportLink"
      >
        {{ isCopied ? "Copied" : "Copy link" }}
      </ElButton>
      <input
        :id="`report-copy-input-${conditionReport.id}`"
        type="text"
        class="copyable-input position-absolute"
        :value="reportUrl"
      >
      <SdStatusBadge
        class="mr-3"
        :type="ConditionReportStates[conditionReport.state.toUpperCase()]"
      />
    </div>
  </div>
</template>

<script>
import SdStatusBadge from '@/components/common/SdStatusBadge';
import UrlFormatterUtil from '@/utils/UrlFormatterUtil';
import { ConditionReport, ConditionReportStates, ConditionReportStateTypes } from '@/constants/ConditionReport';

export default {
  name: 'ConditionReportListItem',
  components: { SdStatusBadge },
  props: {
    conditionReport: {
      type: Object,
      required: true,
    },
    isCopied: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    return {
      reportUrl: getReportUrl(),
      scheduledUrl: getScheduleUrl(),
      copyReportLink,
      ConditionReport,
      ConditionReportStates,
      ConditionReportStateTypes,
    };

    function copyReportLink() {
      context.emit('copy', props.conditionReport.id);
    }

    function getReportUrl() {
      if (!props.conditionReport.report_url) {
        return null;
      }
      return UrlFormatterUtil(props.conditionReport.report_url);
    }

    function getScheduleUrl() {
      if (!props.conditionReport.tenant_scheduling_url || !isPeriodicReport() || isInspectionBeingReviewedOrCompleted()) {
        return null;
      }
      return UrlFormatterUtil(props.conditionReport.tenant_scheduling_url);
    }

    function isPeriodicReport() {
      return props.conditionReport.type === ConditionReport.REPORT_TYPE_PERIODIC;
    }

    function isInspectionBeingReviewedOrCompleted() {
      return [ConditionReportStateTypes.COMPLETED, ConditionReportStateTypes.REVIEWING].includes(props.conditionReport.state);
    }
  },
};
</script>

<style lang="scss">
.condition-report {
  .button-external-link {
    width: 90px;
    padding-right: 0;
    padding-left: 0;
  }

  .copyable-input {
    width: 0.75rem;
    height: 2px;
    border: none;
    text-indent: 2rem;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}
</style>
