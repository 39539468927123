<template>
  <div class="tenants-form">
    <div
      v-for="(tenant, index) in tenants"
      :key="`tenant_${index}_${tenant.full_name}_${tenants.length}`"
      class="row tenant-row mt-0"
    >
      <div class="col-12 col-md-6">
        <ElFormItem
          :prop="`${propPath}.${index}.first_name`"
          :rules="tenantFormRules.first_name"
        >
          <SdFloatLabel label="Tenant first name">
            <ElInput
              v-model="tenant.first_name"
              placeholder="Tenant first name"
              @input="update"
            />
          </SdFloatLabel>
        </ElFormItem>
      </div>
      <div :class="index > 0 ? 'col-11 col-md-5' : 'col-12 col-md-6'">
        <ElFormItem :prop="`${propPath}.${index}.last_name`">
          <SdFloatLabel label="Tenant last name">
            <ElInput
              v-model="tenant.last_name"
              placeholder="Tenant last name"
              @input="update"
            />
          </SdFloatLabel>
        </ElFormItem>
      </div>
      <div
        v-if="index > 0"
        class="col-1 justify-content-center align-items-center d-flex pl-0"
      >
        <i
          class="sdicon-trash font-24 text-gray-dark pointer"
          @click="tenants.splice(index, 1)"
        />
      </div>
      <div class="col-12 col-md-6">
        <ElFormItem
          :prop="`${propPath}.${index}.phone_number`"
          :rules="getTenantContactFieldRule(index, 'phone_number')"
        >
          <SdFloatLabel label="Tenant phone">
            <ElInput
              v-model="tenant.phone_number"
              placeholder="Tenant phone"
              @input="update"
            />
          </SdFloatLabel>
        </ElFormItem>
      </div>
      <div class="col-12 col-md-6">
        <ElFormItem
          :prop="`${propPath}.${index}.email`"
          :rules="getTenantContactFieldRule(index, 'email')"
        >
          <SdFloatLabel label="Tenant email">
            <ElInput
              v-model="tenant.email"
              placeholder="Tenant email"
              @input="update"
            />
          </SdFloatLabel>
        </ElFormItem>
      </div>
      <ElDivider class="mx-2" />
    </div>
    <div
      v-if="tenants.length < 10"
      class="mt-4"
    >
      <ElButton
        v-if="lead"
        icon="sdicon-plus"
        type="primary"
        size="medium"
        :disabled="isLeadPopulated"
        @click="setLeadAsTenant"
      >
        {{ `Add ${lead.full_name}` }}
      </ElButton>
      <ElButton
        icon="sdicon-plus"
        size="medium"
        @click="tenants.push({ ...defaultNewTenant })"
      >
        Add Another Tenant
      </ElButton>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import regexConstants from '@/constants/Regex';
import get from 'lodash.get';

export default {
  name: 'TenantsForm',
  components: {},
  props: {
    tenants: {
      type: Array,
      default: () => [],
    },
    lead: {
      type: Object,
      required: false,
      default: null,
    },
    propPath: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const isLeadPopulated = ref(false);

    return {
      tenantFormRules,
      update,
      defaultNewTenant,
      getTenantContactFieldRule,
      get,
      setLeadAsTenant,
      isLeadPopulated,
    };

    function update() {
      context.emit('updated', props.tenants);
    }

    function setLeadAsTenant() {
      props.tenants.unshift({ ...props.lead });
      props.tenants.pop();
      isLeadPopulated.value = true;
    }

    function getTenantContactFieldRule(index, contactField) {
      const baseRule = [...tenantFormRules[contactField]];
      const displayableFieldName = contactField.replace('_', ' ');
      const requiredRule = {
        required: true,
        message: `Primary tenant ${displayableFieldName} is required.`,
      };

      if (index > 0) {
        const isPhoneAndEmptyEmail = contactField === 'phone_number' && !get(props.tenants, `${index}.email`);
        const isEmailAndEmptyPhone = contactField === 'email' && !get(props.tenants, `${index}.email`);
        if (isPhoneAndEmptyEmail || isEmailAndEmptyPhone) {
          requiredRule.required = true;
          requiredRule.message = 'At least one tenant contact detail is required';
        } else {
          requiredRule.required = false;
        }
      }

      baseRule.unshift(requiredRule);
      return baseRule;
    }
  },
};

const tenantFormRules = {
  first_name: [
    {
      required: true,
      type: 'string',
      message: 'Tenant first name is required',
    },
  ],
  phone_number: [
    {
      message: 'Tenant phone number is required',
    },
    {
      pattern: regexConstants.phone_number,
      message: 'Enter a valid tenant phone number',
    },
  ],
  email: [
    {
      type: 'email',
      message: 'Enter a valid tenant email',
    },
  ],
};

export const defaultNewTenant = {
  first_name: '',
  last_name: '',
  phone_number: null,
  email: null,
};
</script>
