<template>
  <div
    id="filters"
    class="d-flex align-items-baseline w-100"
  >
    <div
      v-if="withStatusesFilter"
      class="filter-item status-filter mr-2"
    >
      <ElSelect
        v-model="filters.statuses"
        multiple
        collapse-tags
        placeholder="All reports"
        :disabled="disabled || !statusesOptions.length || statusesOptions.length < 2"
      >
        <ElOption
          v-for="status in statusesOptions"
          :key="status.value"
          :label="status.label"
          :value="status.value"
        />
      </ElSelect>
    </div>

    <div class="filter-item mt-2 mt-lg-0 ml-auto mr-2">
      <ElButton
        v-if="hasSorting"
        :disabled="disabled"
        type="text"
        size="small"
        :icon="filters.sorting === ASCENDING_ORDER ? 'sdicon-sort-up' : 'sdicon-sort-down'"
        @click="disabled ? null : $emit('changeSort')"
      >
        Time
        <strong class="ml-1">
          ({{ filters.sorting === ASCENDING_ORDER ? "Old to New" : "New to Old" }})
        </strong>
      </ElButton>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConditionReportsFilters',
  model: {
    prop: 'filters',
    event: 'input',
  },
  props: {
    disabled: {
      type: Boolean,
    },
    filters: {
      type: Object,
      required: true,
    },
    hasSorting: {
      type: Boolean,
      required: true,
    },
    withStatusesFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    statusesOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    return {
      emitInit,
      ASCENDING_ORDER: 'preferred_from,asc',
    };
    function emitInit() {
      context.emit('init');
    }
  },
};
</script>

<style lang="scss">
@include media-breakpoint-down(md) {
  #filters {
    display: flex;
    flex-direction: column;

    .filter-item {
      display: flex;
      justify-content: flex-end;
    }

    .status-filter {
      display: inline-block;
      width: 100%;
    }
  }
}
</style>
