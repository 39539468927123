<template>
  <el-tooltip
    :disabled="$viewport.lt.md || !isIntegrationEnabled"
    :visible-arrow="false"
    placement="right"
    popper-class="popover-panel"
  >
    <template slot="content">
      <i class="sdicon-info-circle" />
      <p>You can update the {{ type }} in {{ integrationType }} and it will automatically synchronize into Showdigs.</p>
    </template>
    <slot name="field" />
  </el-tooltip>
</template>

<script>

import { capitalizeFirstLatter } from '@/utils/StringUtil';
import { getBusinessSourceType } from '@/utils/BusinessSourceUtil';

export default {
  name: 'UnitListingsPMSTooltip',
  components: {},
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const isIntegrationEnabled = context.root.$store.getters['BusinessSource/isIntegrationEnabled'];
    const integrationType = capitalizeFirstLatter(getBusinessSourceType());
    return {
      integrationType,
      isIntegrationEnabled,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
