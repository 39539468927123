<template>
  <div
    id="showing-profile-vacancy-check-wrapper"
    class="row"
  >
    <div class="col-12 col-md-12">
      <div :class="{'row': layout === LAYOUTS.COLUMN}">
        <div :class="{'col': layout === LAYOUTS.COLUMN}">
          <p
            v-if="withTitle"
            class="mb-1 font-18 text-gray-darker"
          >
            Vacancy
          </p>
          <p class="text-gray-dark">
            The current vacancy status.
          </p>
        </div>
        <div :class="{'col-auto': layout === LAYOUTS.COLUMN}">
          <ElFormItem prop="is_vacant">
            <ElRadioGroup
              v-model="payload.is_vacant"
              @input="toggleVacancyCheck"
            >
              <ElRadioButton :label="true">
                Vacant
              </ElRadioButton>
              <ElRadioButton
                :label="false"
                :disabled="showingProfile.is_self_showing || showingProfile.is_open_house"
              >
                Occupied
              </ElRadioButton>
            </ElRadioGroup>
            <ElAlert
              v-if="showingProfile.is_self_showing"
              type="warning"
              show-icon
              :closable="false"
              class="mt-3 vacancy-check-alert"
            >
              <template>
                Occupied properties are not eligible for self-showings.
              </template>
            </ElAlert>
          </ElFormItem>
        </div>
      </div>
      <template v-if="!payload.is_vacant">
        <h2 class="font-weight-light font-21 line-height-28 mt-4">
          Tenants information
        </h2>
        <p class="mb-4">
          We will be notifying tenants 24 hours before any showing window.
          2 hours before showing takes place we'll message tenants
          about the final status - whether the tour takes place or not.
        </p>
        <TenantsForm
          :lead="showingProfile.lead"
          :tenants="payload.tenants"
          :prop-path="showingProfile.id ? 'showing_profile.tenants' : 'tenants'"
          @updated="updateTenants"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { reactive } from '@vue/composition-api';
import { nullableOrBoolean } from '@/utils/ExtendedValidatorsUtil';
import TenantsForm, { defaultNewTenant } from '@/components/tenant/TenantsForm';

export const LAYOUTS = {
  ROW: 'row',
  COLUMN: 'column',
};

export default {
  name: 'ShowingProfileVacancy',
  components: { TenantsForm },
  props: {
    withTitle: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String,
      default: LAYOUTS.ROW,
      validator(layout) {
        return [LAYOUTS.ROW, LAYOUTS.COLUMN].includes(layout);
      },
    },
    showingProfile: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const payload = reactive({ ...props.showingProfile });

    return {
      payload,
      toggleVacancyCheck,
      emitFormStateChanged,
      updateTenants,
      LAYOUTS,
    };

    function emitFormStateChanged() {
      context.emit('update-showing-profile-payload', {
        is_vacant: payload.is_vacant,
        tenants: payload.tenants,
      });
    }
    function toggleVacancyCheck() {
      if (payload.is_vacant === false && payload.tenants.length === 0) {
        payload.tenants = [{ ...defaultNewTenant }];
      }
      emitFormStateChanged();
    }

    function updateTenants(tenants) {
      payload.tenants = tenants;
      emitFormStateChanged();
    }
  },
};

const rules = {
  is_vacant: [
    {
      validator: nullableOrBoolean,
      message: 'You should choose an option',
    },
  ],
};
</script>

<style lang="scss">
#showing-profile-vacancy-check-wrapper {
  @media (min-width: 768px) {
    .mb-md-4_5 {
      margin-bottom: 2rem !important;
    }
  }

  .vacancy-check-alert {
    .el-alert__content {
      padding-top: 12px;
    }
  }

  .tenant-row {
    //border-bottom: 1px solid gray-color('light');
  }
}
</style>
