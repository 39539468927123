export { percentOff, withCommas };

function percentOff(number, percent) {
  return Math.round(number - (number * (percent / 100)));
}

function withCommas(number) {
  if (Number.isNaN(number)) {
    return number;
  }

  return number.toLocaleString('en');
}

export function formatMoney(number, withDecimals = false) {
  const formatted = Intl.NumberFormat(
    'en-US',
    {
      style: 'currency',
      currency: 'USD',
    },
  )
    .format(number);

  return withDecimals ? formatted : formatted.replace(/\.\d{2}$/, '');
}
