<template>
  <ElSelect
    v-model="selectedOwners"
    class="multiple-select"
    multiple
    placeholder="Owners email"
    filterable
    allow-create
    default-first-option
    :disabled="disabled"
    @input="emitOwnersEmailsChanged"
  >
    <ElOption
      v-for="(owner, key) in ownersOptions"
      :key="'owner_'+key"
      :label="owner.email"
      :value="owner.email"
    />
  </ElSelect>
</template>

<script>
import { ref } from '@vue/composition-api';
import { sortUsersByEmail } from '@/utils/UserUtil';

export default {
  name: 'PropertyOwnersSelect',
  props: {
    // eslint-disable-next-line vue/require-prop-types,vue/require-default-prop
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    currentListingManager: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const selectedOwners = ref(props.value);
    const ownersOptions = ref(null);
    init();

    return {
      selectedOwners,
      ownersOptions,
      emitOwnersEmailsChanged,
    };

    async function init() {
      const teamMembers = await store.dispatch('Auth/getTeamMembers', { paginate: 0, 'scopes[verified]': 1 });
      ownersOptions.value = sortUsersByEmail(teamMembers).filter((user) => user.id !== props.currentListingManager?.id);
    }

    function emitOwnersEmailsChanged() {
      context.emit('input', selectedOwners.value && selectedOwners.value.length ? selectedOwners.value : null);
    }
  },
};
</script>
