<template>
  <div>
    <el-alert
      v-if="property.is_null_location"
      type="warning"
      show-icon
      :closable="false"
      class="mt-1 mb-3"
    >
      Please update the address before booking a service.
    </el-alert>
    <div class="row services-menu">
      <ElTooltip
        v-for="(item, key) in menuItems"
        :key="key"
        content="Please renew your subscription"
        :disabled="isAccountActive"
      >
        <component
          :is="item.componentType"
          class="services-menu-item col-6 col-md-6"
          :class="{ small: size === 'small', active: item.active, selected: item.selected }"
          :to="item.link.name && isAccountActive ? item.link : undefined"
          :disabled="item.disabled"
          @click="selectService(key)"
        >
          <div class="services-menu-item-content">
            <SdArt
              size="96px"
              :art="key"
            />
            <div class="label">
              {{ item.label }}
            </div>
            <div class="text-center d-none mt-1">
              <span class="active-icon bg-primary rounded-circle">
                <i class="sdicon-check text-white" />
              </span>
              <span class="font-italic text-primary font-13 pl-1">Active</span>
            </div>
          </div>
        </component>
      </ElTooltip>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import get from 'lodash.get';
import * as typeformEmbed from '@typeform/embed';
import SdArt from '@/components/common/SdArt';
import { typeFormPath, postNoticeIdentifier, randomRequestIdentifier } from '@/constants/typeForm';
import { SHOWING_PROFILE_ROUTES } from '@/router/routes';
import { isBusinessOnlyUsingAgentShowings } from '@/utils/BusinessUtil';

export default {
  name: 'ServicesMenu',
  components: {
    SdArt,
  },
  props: {
    isRadio: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
    property: {
      type: Object,
      required: true,
    },
    unit: {
      type: Object,
      required: false,
      default: null,
    },
    isShowing: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const menuItems = computed(computeMenuItems);
    const authUser = context.root.$store.state.Auth.user;
    const isAccountInactive = context.root.$store.getters['Auth/accountIsInactive'];
    const isAccountActive = !isAccountInactive;

    return {
      menuItems,
      selectService,
      typeFormPath,
      randomRequestIdentifier,
      isAccountActive,
    };

    function isServiceDisabled() {
      return isAccountInactive || Boolean(props.property.is_null_location);
    }

    function computeMenuItems() {
      const propertyId = get(props, 'property.id', '');
      const address = get(props, 'property.address.value', '');
      const params = {
        business_id: authUser.business.id,
        property_id: propertyId,
        unit_id: props?.unit?.id ?? '',
        unit_name: props?.unit?.name ?? '',
        email: authUser.email,
        address,
        name: authUser.full_name || '',
      };

      let showingPage = SHOWING_PROFILE_ROUTES.TYPE_READY_TO_START_PAGE;
      if (props?.unit?.id) {
        showingPage = isBusinessOnlyUsingAgentShowings() ? SHOWING_PROFILE_ROUTES.AGENT_SHOWING_PAGE : SHOWING_PROFILE_ROUTES.TYPE_SELECTION_PAGE;
      }

      const items = {
        showing: {
          label: 'Showings',
          page: showingPage,
        },
        'move-in-out': {
            label: 'Condition Report',
            page: 'app.condition-report.add',
        },
      };

      items.showing.active = Boolean(props.isShowing);

      for (const key in items) {
        items[key].componentType = resolveComponentType(items[key]);
        items[key].selected = key === props.value;
        items[key].disabled = items[key].disabled || isServiceDisabled();

        if (typeof items[key].link === 'string') {
          const cleanLink = items[key].link.split('?')[0];
          items[key].link = `${cleanLink}?${new URLSearchParams(params)}`;
        } else {
          items[key].link = {
            name: items[key].page,
            params: {
              id: params.property_id,
              unit_id: params.unit_id,
              address: params.address,
            },
          };
        }
      }
      return items;
    }

    function resolveComponentType(menuItem) {
      if (menuItem.link && menuItem.link.name && props.isRadio === false) {
        return 'router-link';
      }
      return 'div';
    }

    function selectService(key) {
      if (menuItems.value[key].disabled) {
        return;
      }
      if (props.isRadio === false) {
        if (!menuItems.value[key].link.name) {
          const popup = typeformEmbed.makePopup(menuItems.value[key].link, { onReady: () => {} });
          popup.open();
        } else {
          context.root.$router.push(menuItems.value[key].link);
        }
      }
      if (!menuItems.value[key].active) {
        context.emit('selected', { link: menuItems.value[key].link, value: key });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.services-menu {
  justify-content: center;
  .services-menu-item {
    margin: 2rem 0 0 0;
    cursor: pointer;
    border-radius: $app-border-radius;
    transition: all ease-in 0.3s;
    .services-menu-item-content {
      background: $white;
      padding: 1.5rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: $app-border-radius;
      &:hover {
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
        .sd-art {
          background-position: bottom left;
        }
      }
    }
    &[disabled] {
      .services-menu-item-content .sd-art {
        background-position: top left;
      }
    }
    .label {
      margin-top: 0.5rem;
      text-align: center;
      line-height: 1.25rem;
      color: gray-color("darker");
    }
    &.small {
      margin: 0;
      .services-menu-item-content {
        padding: 1rem 0.5rem;
      }
      .label {
        font-size: $--font-size-base;
      }
    }
    &.active {
      cursor: default;
      .label {
        color: gray-color();
      }
      .active {
        display: block;
        .active-icon {
          width: $--font-size-base;
          height: $--font-size-base;
          display: inline-block;
          i {
            display: block;
            margin-top: -2px;
            margin-left: -1px;
            font-size: $--font-size-small;
            font-weight: $strong;
          }
        }
      }
    }
    &.selected {
      .services-menu-item-content {
        background: theme-color("primary");
      }
      .label {
        color: $white;
      }
    }
    &[disabled] {
      cursor: not-allowed !important;
      opacity: 0.7;
    }
  }
}
</style>
