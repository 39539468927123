var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SdPage',{style:(_vm.isRenderedFromUnitPage ? 'padding: 0;' : ''),attrs:{"id":"condition-reports-page"}},[(!_vm.isRenderedFromUnitPage)?[_c('SdPageHeader',{attrs:{"title":_vm.$viewport.lt.md ? 'Condition Reports' : ' '}},[_c('div',{class:{ 'col-auto': !_vm.uiFlags.isMobile }},[_c('SdActionButtons',{attrs:{"actions":_vm.actions}})],1),_c('div',{staticClass:"cr-search-input-container"},[_c('ElInput',{staticClass:"font-15",attrs:{"placeholder":"Search","size":"small","clearable":""},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('i',{staticClass:"sdicon-magnifying-glass font-15"})]},proxy:true}],null,false,2730677320),model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)]),(!_vm.searchQuery)?_c('ElTabs',{attrs:{"value":_vm.currentTab,"stretch":""},on:{"tab-click":_vm.changeTab}},_vm._l((_vm.tabs),function(tab,index){return _c('ElTabPane',{key:index,attrs:{"disabled":_vm.uiFlags.isLoadingConditionReports,"label":("" + ((tab.charAt(0).toUpperCase() + tab.slice(1)).replace('-', ' '))),"name":tab}})}),1):_vm._e()]:_vm._e(),_c('div',{staticClass:"condition-reports-header d-flex justify-content-between"},[_c('ConditionReportsFilters',{attrs:{"disabled":_vm.uiFlags.isLoadingConditionReports,"with-statuses-filter":true,"has-sorting":true,"statuses-options":_vm.statusesOptions},on:{"changeSort":_vm.changeReportsSorting,"init":function($event){return _vm.$refs.loadingLayer.triggerInit()}},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1),_c('SdLoadingLayer',{ref:"loadingLayer",attrs:{"init":_vm.init},scopedSlots:_vm._u([{key:"loaded",fn:function(){return [(!_vm.filteredConditionReports.length && !_vm.uiFlags.isLoadingConditionReports && !_vm.searchQuery)?_c('div',{staticClass:"row flex-column empty-reports mt-5",class:{
          'mt-5': !_vm.isRenderedFromUnitPage && _vm.$viewport.lt.md,
          'mt-4': _vm.isRenderedFromUnitPage && _vm.$viewport.lt.md,
        }},[_c('img',{attrs:{"src":require("@/assets/properties-empty-state.svg")}}),_c('div',{staticClass:"font-weight-bold text-center font-21 mt-5",class:{
            'mt-5': !_vm.isRenderedFromUnitPage && _vm.$viewport.lt.md,
            'mt-3': _vm.isRenderedFromUnitPage && _vm.$viewport.lt.md,
            'font-21': _vm.$viewport.gt.md,
            'font-18': _vm.$viewport.lt.md
          }},[(_vm.currentTab === 'requested')?[_vm._v(" No reports requested ")]:(_vm.currentTab === 'recently-completed')?[_vm._v(" No reports within the last month ")]:[_vm._v(" No archived reports ")]],2),(_vm.canOrderReports && !_vm.isRenderedFromUnitPage)?_c('div',{staticClass:"text-center mt-2"},[_c('ElTooltip',{attrs:{"content":"Please renew your subscription","disabled":!_vm.$store.getters['Auth/accountIsInactive']}},[_c('ElButton',{class:{
                'mt-5': !_vm.isRenderedFromUnitPage && _vm.$viewport.lt.md,
                'mt-3': _vm.isRenderedFromUnitPage && _vm.$viewport.lt.md,
              },attrs:{"type":"primary","size":"medium","disabled":_vm.$store.getters['Auth/accountIsInactive']},on:{"click":_vm.orderReport}},[_vm._v(" Order a report ")])],1)],1):_vm._e()]):_vm._e(),(_vm.uiFlags.isLoadingSearchResults)?_c('div',{staticClass:"mt-5 text-center"},[_c('ElSpinner',{attrs:{"color":"primary"}})],1):(_vm.searchQuery && !_vm.filteredConditionReports.length)?_c('div',{staticClass:"text-center mt-5"},[_c('img',{attrs:{"src":require("@/assets/no-results-found.svg")}}),_c('strong',{staticClass:"d-block mt-4 font-21"},[_vm._v("No results found for '"+_vm._s(_vm.searchQuery)+"'")])]):(_vm.filteredConditionReports.length)?_c('div',[_vm._l((_vm.filteredConditionReports),function(conditionReport){return _c('ConditionReportListItem',{key:("condition-report-page-list-item-" + (conditionReport.id) + "-" + (conditionReport.created_at)),attrs:{"condition-report":conditionReport,"is-copied":_vm.uiFlags.copiedLinkId === conditionReport.id},on:{"copy":_vm.copyReportLink,"open-edit-dialog":_vm.openEditDialog}})}),_c('div',{staticClass:"px-1 font-15 text-gray d-flex flex-wrap align-items-end mb-lg-0 mb-5 pb-lg-0 pb-4"},[_c('span',[_vm._v("If you need to cancel a report or make any changes, please contact our support team at ")]),_c('SdEmailIndicator',{staticClass:"font-15",attrs:{"email":_vm.Showdigs.SUPPORT_EMAIL}}),_c('span',[_vm._v(" or call us at ")]),_c('SdPhoneIndicator',{staticClass:"font-15",attrs:{"phone-number":_vm.Showdigs.SUPPORT_PHONE_NUMBER_HREF}})],1)],2):_vm._e()]},proxy:true}])}),_c('ConditionReportEditDialog',{ref:"conditionReportEditDialog"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }