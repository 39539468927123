export function createEnumValidator(name, options, message = null) {
  return {
    validator: (rule, value, callback) => {
      if (value == null) {
        return callback(new Error(`${name} is required`));
      }

      if (!options.includes(value)) {
        return callback(new Error(message ?? `Invalid ${name.toLowerCase()} selected`));
      }

      return callback();
    },
  };
}
