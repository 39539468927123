<template>
  <div>
    <div>
      <span>
        {{ `${leasePeriodInquiriesCount} ${$pluralize('Inquiry', leasePeriodInquiriesCount)}` }}
      </span>
      <span
        v-if="isShowingTypeNotPublishListing"
      >
        {{ `| ${leasePeriodCompletedToursCount} ${$pluralize(isOpenHouse ? 'Open House' : 'Tour', leasePeriodCompletedToursCount)}` }}
      </span>
    </div>
    <div
      v-if="showingProfileMaxBudgetedTours"
      class="font-13"
      :class="[showPaidToursBudgetWarning ? 'text-danger' : 'text-gray-dark']"
    >
      {{ remainingPaidToursCount > 0 ? `Will pause after ${remainingPaidToursCount} more paid tours` : 'Reached tour limit' }}
    </div>
  </div>
</template>

<script>
import get from 'lodash.get';
import { isPaidToursCloseToMaxBudget } from '@/utils/TourUtil';
import Showing from '@/constants/Showing';

export default {
  name: 'UnitActivityCounters',
  props: {
    unit: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const isOpenHouse = !!get(props.unit, 'active_showing_profile.is_open_house');
    const leasePeriodInquiriesCount = get(props.unit, 'active_lease_period.inquiries_count', 0);

    const completedToursCount = get(props.unit, 'active_lease_period.completed_tours_count', 0);
    const toursCount = get(props.unit, 'active_lease_period.tours_count', 0);
    const leasePeriodCompletedToursCount = completedToursCount || toursCount;

    const showingProfilePaidToursCount = get(props.unit, 'active_showing_profile.paid_tours_count', 0);
    const showingProfileMaxBudgetedTours = get(props.unit, 'active_showing_profile.max_budgeted_tours');
    const showPaidToursBudgetWarning = isPaidToursCloseToMaxBudget(showingProfilePaidToursCount, showingProfileMaxBudgetedTours);
    const remainingPaidToursCount = showingProfileMaxBudgetedTours - showingProfilePaidToursCount;
    const isShowingTypeNotPublishListing = get(props.unit, 'active_showing_profile.type') !== Showing.TYPE_PUBLISH_LISTING;

    return {
      isOpenHouse,
      leasePeriodInquiriesCount,
      leasePeriodCompletedToursCount,
      showingProfilePaidToursCount,
      showingProfileMaxBudgetedTours,
      showPaidToursBudgetWarning,
      remainingPaidToursCount,
      isShowingTypeNotPublishListing,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
