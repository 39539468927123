<template>
  <div class="row">
    <div class="col-12 pt-4">
      <ElFormItem
        :prop="resolveFormItemProp('short_description')"
      >
        <ElTooltip
          :visible-arrow="false"
          :disabled="$viewport.lt.md"
          popper-class="popover-panel"
          placement="right-start"
        >
          <template slot="content">
            <i class="sdicon-info-circle" />
            <p>
              The title will reflect based on your chosen syndication settings
            </p>
          </template>
          <SdFloatLabel>
            <ElInput
              v-model="unit.short_description"
              type="text"
              maxlength="255"
              placeholder="Listing title"
              :disabled="isDisabled"
              @input="(value) => $emit('update-unit', { short_description: value })"
            />
          </SdFloatLabel>
        </ElTooltip>
      </ElFormItem>
    </div>
    <div class="col-12 pt-4">
      <ElFormItem
        :prop="resolveFormItemProp('description')"
      >
        <SdFloatLabel>
          <ElInput
            v-model="unit.description"
            type="textarea"
            rows="10"
            placeholder="Listing description"
            :disabled="isDisabled"
            @input="(value) => $emit('update-unit', { description: value })"
          />
        </SdFloatLabel>
      </ElFormItem>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get';
import { reactive, ref } from '@vue/composition-api';

const modes = { add: 'ADD', edit: 'EDIT', activate_showing: 'ACTIVATE_SHOWING' };

export default {
  name: 'UnitTitleAndDescription',
  props: {
    unit: {
      type: Object,
      required: false,
      default: null,
    },
    propertyUnitIndex: {
      type: Number,
      default: null,
    },
    mode: {
      type: String,
      validator: (mode) => Object.values(modes).includes(mode),
      required: true,
    },
    listingDataPrefill: {
      type: Object,
      default: null,
    },
  },
  watch: {
    listingDataPrefill(newVal) {
      this.refreshData(newVal);
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const unitShortDescription = get(props, 'unit.short_description', null);
    const listingShortDescription = get(props, 'listingDataPrefill.short_description', null);
    props.unit.short_description = listingShortDescription || unitShortDescription;
    const unitDescription = get(props, 'unit.description', null);
    const listingDescription = get(props, 'listingDataPrefill.description', null);
    props.unit.description = listingDescription || unitDescription;
    const isIntegrationEnabled = store.getters['BusinessSource/isIntegrationEnabled'];
    const isSyndicated = context.root.$store.getters['Auth/businessSyndicated'];
    const isDisabled = isIntegrationEnabled && !isSyndicated && (Boolean(props.unit.listing_identifier) || Boolean(props.unit.origin_id));

    const uiFlags = reactive({
      openGenerateAdDialog: false,
    });

    return {
      resolveFormItemProp,
      isDisabled,
      uiFlags,
      refreshData,
    };

    function refreshData(freshData) {
      if (freshData.short_description) {
        props.unit.short_description = freshData.short_description;
      }
      if (freshData.description) {
        props.unit.description = freshData.description;
      }
    }

    function resolveFormItemProp(key) {
      if (props.mode === modes.add) {
        return `units.${props.propertyUnitIndex}.${key}`;
      }

      if (props.mode === modes.edit) {
        return `property.units.${props.propertyUnitIndex}.${key}`;
      }

      return key;
    }
  },
};

</script>
