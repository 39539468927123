<template>
  <SdPage
    id="tours-page"
    :style="isRenderedFromUnitPage ? 'padding: 0;' : ''"
  >
    <template v-if="!isRenderedFromUnitPage">
      <SdPageHeader
        :title="$viewport.lt.md ? 'Tours' : ' ' "
      >
        <div class="tours-search-input-container">
          <ElInput
            v-model="filters.searchQuery"
            placeholder="Search"
            size="small"
            clearable
            class="font-15"
          >
            <template #prefix>
              <i class="sdicon-magnifying-glass font-15" />
            </template>
          </ElInput>
        </div>
      </SdPageHeader>

      <ElTabs
        :value="currentTab"
        stretch
        @tab-click="changeTab"
      >
        <ElTabPane
          v-for="(tab, index) in tabs"
          :key="index"
          :disabled="uiFlags.isLoadingTours"
          :label="`${tabLabels[tab]} ${toursCounters[tab] !== null ? `(${toursCounters[tab]})` : ''}`"
          :name="tab"
        />
      </ElTabs>
    </template>
    <div class="tours-header d-flex justify-content-between">
      <ToursFilters
        v-model="filters"
        :disabled="uiFlags.isLoadingTours"
        :with-date-picker="!isRenderedFromUnitPage && currentTab === 'archive'"
        :has-sorting="true"
        :with-statuses-filter="true"
        :with-my-properties-checkbox="!isRenderedFromUnitPage"
        :with-active-lease-checkbox="isRenderedFromUnitPage"
        :is-active-leasing-period="get(unit, 'last_showing_profile.deleted_at') === null"
        :statuses-options="statusesOptions"
        @changeSort="changeToursSorting"
        @init="$refs.loadingLayer.triggerInit()"
      />
    </div>
    <SdLoadingLayer
      ref="loadingLayer"
      :init="init"
    >
      <template #loaded>
        <div
          v-if="!paginatedTours.length"
          class="text-center mt-5"
        >
          <img src="@/assets/properties-empty-state.svg">
          <div class="font-weight-bold font-21 mt-5">
            <template v-if="currentTab === 'future'">
              No planned tours
            </template>
            <template v-else-if="currentTab === 'archive'">
              No tours in the specific period
            </template>
            <template v-else>
              No tours for {{ currentTab }}
            </template>
          </div>
        </div>
        <TourListItem
          v-for="tour in paginatedTours"
          :key="`tour-page-item-${tour.id}-${tour.created_at}`"
          :is-rendered-from-unit-page="isRenderedFromUnitPage"
          :tour="tour"
          :short-date-format="!isRenderedFromUnitPage && currentTab === 'today'"
          @show-dialog="showDialog(...arguments, tour)"
        />
        <div
          v-if="pagination.total && currentTab === 'archive'"
          class="text-center text-gray-dark"
        >
          <strong class="text-black"> {{ pagination.from }}-{{ pagination.to }} </strong>
          of
          <strong class="text-black mr-3">{{ pagination.total }}</strong>
          <span
            class="mr-2"
            :class="pagination.isPreviousPageAvailable ? 'pointer' : 'not-allowed text-gray'"
            @click="previousPage"
          >
            <i class="sdicon-chevron-left" />
          </span>
          <span
            :class="pagination.isNextPageAvailable ? 'pointer' : 'not-allowed text-gray'"
            @click="nextPage"
          >
            <i class="sdicon-chevron-right" />
          </span>
        </div>
      </template>
    </SdLoadingLayer>

    <TourRescheduleDialog
      v-if="chosenTour"
      :schedule="chosenTour"
      :visible.sync="uiFlags.isRescheduleDialogVisible"
      @close="dialogClosed('isRescheduleDialogVisible', ...arguments)"
    />
    <TourTakeOverDialog
      v-if="chosenTour"
      :schedule-id="chosenTour.id"
      :schedule="chosenTour"
      :self-showing="chosenTour.is_self_showing"
      :is-video="chosenTour.is_video"
      :visible.sync="uiFlags.isTakeOverDialogVisible"
      @close="dialogClosed('isTakeOverDialogVisible', ...arguments)"
    />
    <SelfTourBecomeRegularTourDialog
      v-if="chosenTour"
      :schedule-id="chosenTour.id"
      :visible.sync="uiFlags.isBecomeRegularTourDialogVisible"
      @close="dialogClosed('isBecomeRegularTourDialogVisible', ...arguments)"
    />
    <BecomeSelfShowingTourDialog
      v-if="chosenTour"
      :schedule-id="chosenTour.id"
      :is-taken-over="chosenTour.is_taken_over"
      :visible.sync="uiFlags.isBecomeSelfTourDialogVisible"
      @close="dialogClosed('isBecomeSelfTourDialogVisible', ...arguments)"
    />
    <TourReassignDialog
      v-if="chosenTour"
      :schedule="chosenTour"
      :visible.sync="uiFlags.isReassignDialogVisible"
      @close="dialogClosed('isReassignDialogVisible', ...arguments)"
    />
    <TourCancelDialog
      v-if="chosenTour"
      :schedule="chosenTour"
      :visible.sync="uiFlags.isCancelDialogVisible"
      @close="dialogClosed('isCancelDialogVisible', ...arguments)"
    />
  </SdPage>
</template>

<script>
import {
  computed, reactive, ref, watch,
} from '@vue/composition-api';
import SdLoadingLayer from '@/components/common/SdLoadingLayer';
import momentUtil from '@/utils/MomentUtil';
import Tour from '@/constants/Tour';
import { ScheduleStatuses } from '@/constants/Schedule';
import TourRescheduleDialog from '@/components/tour/TourRescheduleDialog';
import TourTakeOverDialog from '@/components/tour/TourTakeOverDialog';
import SelfTourBecomeRegularTourDialog from '@/components/tour/SelfTourBecomeRegularTourDialog';
import BecomeSelfShowingTourDialog from '@/components/tour/BecomeSelfShowingTourDialog';
import TourReassignDialog from '@/components/tour/TourReassignDialog';
import TourCancelDialog from '@/components/tour/TourCancelDialog';
import { searchTableRow } from '@/utils/SearchTableUtil';
import ToursFilters from '@/components/tour/ToursFilters';
import TourListItem from '@/components/tour/TourListItem';
import get from 'lodash.get';
import { getTabFromUrlAndLocalStorage, updateTabInUrlAndLocalStorage } from '../../../utils/RouterUtil';
import { activitySortTypes } from '../../../constants/Commons';

const timezone = momentUtil.tz.guess();
const localStorageFiltersKey = 'sd_tours_filters';
const tabs = ['today', 'future', 'archive'];
const tabLabels = {
  today: 'Today',
  future: 'Upcoming',
  archive: 'Past',
};

const defaultDateRanges = {
  today: {
    from: momentUtil(null, timezone).startOf('day'),
    to: momentUtil(null, timezone).endOf('day'),
  },
  future: {
    from: momentUtil(null, timezone)
      .add(1, 'day')
      .startOf('day'),
  },
  archive: {
    from: momentUtil(null, timezone)
      .subtract(2, 'weeks')
      .startOf('day'),
    to: momentUtil(null, timezone)
      .subtract(1, 'days')
      .endOf('day'),
  },
};
const TOURS_PER_PAGE = 25;
const searchFields = [
  { type: 'integer', value: 'id' },
  { type: 'array', value: 'leads.*.lead_name' },
  { type: 'string', value: 'assignee_name' },
  { type: 'string', value: 'property_address' },
];
const pendingStatuses = [
  Tour.STATUS_SCHEDULED_TAKEN_OVER,
  ScheduleStatuses.STATUS_SCHEDULED,
  Tour.STATUS_PENDING,
  Tour.STATUS_CANCELLED_SURRENDERED,
  ScheduleStatuses.STATUS_SCHEDULED,
  ScheduleStatuses.STATUS_PENDING,
  ScheduleStatuses.STATUS_LOOKING_FOR_AGENT,
];

export default {
  components: {
    TourListItem,
    ToursFilters,
    TourCancelDialog,
    TourReassignDialog,
    TourTakeOverDialog,
    TourRescheduleDialog,
    SelfTourBecomeRegularTourDialog,
    BecomeSelfShowingTourDialog,
    SdLoadingLayer,
  },
  props: {
    isRenderedFromUnitPage: {
      type: Boolean,
    },
    unit: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const { $route } = context.root;

    const propertyUnitId = $route?.params?.unit_id;
    const currentTab = ref(props.isRenderedFromUnitPage ? tabs[0] : getTabFromUrlAndLocalStorage({ router: context.root.$router, tabs }) || tabs[0]);
    const tours = ref([]);
    const toursCounters = ref({
      [tabs[0]]: null,
      [tabs[1]]: null,
      [tabs[2]]: null,
    });
    const statusesOptions = computed(computeStatusesOptions);
    const filtersLocalStorage = JSON.parse(window.localStorage.getItem(localStorageFiltersKey) || '{}');
    const filters = reactive({
      dateRange: getInitialDateRange(),
      statuses: [],
      searchQuery: '',
      myPropertiesToursOnly: filtersLocalStorage.myPropertiesToursOnly || false,
      activeLeaseToursOnly: get(props.unit, 'active_lease_period') !== null && props.isRenderedFromUnitPage,
      sorting: getDefaultSortByTab(currentTab.value),
    });
    const filteredTours = computed(computeFilteredTours);
    const paginatedTours = computed(computePaginatedTours);
    const pagination = reactive({
      page: 1,
      from: null,
      to: null,
      total: null,
      isPreviousPageAvailable: null,
      isNextPageAvailable: null,
    });
    const uiFlags = reactive({
      isLoadingTours: false,
      isRescheduleDialogVisible: false,
      isTakeOverDialogVisible: false,
      isReassignDialogVisible: false,
      isCancelDialogVisible: false,
      isBecomeRegularTourDialogVisible: false,
      isBecomeSelfTourDialogVisible: false,
    });
    const chosenTour = ref();
    watch(paginatedTours, calculatePagination);
    watch(() => filters.activeLeaseToursOnly, (nextActiveLeaseToursOnly, currActiveLeaseToursOnly) => {
      if (currActiveLeaseToursOnly !== nextActiveLeaseToursOnly) {
        filters.statuses = [];
        filters.sorting = getDefaultSortByTab(currentTab.value);
        context.refs.loadingLayer.triggerInit();
      }
    });

    return {
      currentTab,
      tabs,
      tabLabels,
      changeTab,
      init,
      tours,
      paginatedTours,
      pagination,
      toursCounters,
      filters,
      statusesOptions,
      previousPage,
      nextPage,
      uiFlags,
      chosenTour,
      dialogClosed,
      showDialog,
      changeToursSorting,
      get,
    };

    async function init() {
      setTimeout(() => { context.emit('stop-fetching'); }, 2000);
      context.emit('start-fetching');
      uiFlags.isLoadingTours = true;

      try {
        if (!props.isRenderedFromUnitPage && toursCounters.value[tabs[0]] === null) {
          fetchToursCounters();
        }
        await fetchTours();
      } finally {
        context.emit('stop-fetching');
        uiFlags.isLoadingTours = false;
      }
    }

    async function fetchTours() {
      const payload = {};
      if (propertyUnitId) {
        payload.units = [propertyUnitId];
      }

      if (props.isRenderedFromUnitPage) {
        if (filters.activeLeaseToursOnly) {
          payload.from = momentUtil(props.unit?.active_lease_period?.created_at, timezone).utc().toServerFormat();
        } else {
          payload.from = null;
        }
      } else {
        let dateRange = filters.dateRange;
        if (Array.isArray(dateRange)) {
          dateRange = {
            from: momentUtil(dateRange[0], timezone),
            to: momentUtil(dateRange[1], timezone).endOf('day'),
          };
        }

        if (dateRange.from) {
          payload.from = dateRange.from.utc().toServerFormat();
        }
        if (dateRange.to) {
          payload.to = dateRange.to.utc().toServerFormat();
        }
      }

      if (filters.sorting) {
        payload.sort = filters.sorting;
      }

      tours.value = (await context.root.$store.dispatch('Business/getTours', payload)).map((tour) => ({
        ...tour,
        tour_at: momentUtil(tour.tour_at, tour.property_timezone),
        tour_until: momentUtil(tour.tour_until, tour.property_timezone),
      }));
    }

    async function fetchToursCounters() {
      const counters = await context.root.$store.dispatch('Business/getToursCounters', {
        todayFrom: defaultDateRanges.today.from
          .clone()
          .tz('utc')
          .toServerFormat(),
        todayTo: defaultDateRanges.today.to
          .clone()
          .tz('utc')
          .toServerFormat(),
        ...(props.isRenderedFromUnitPage ? { units: [propertyUnitId] } : {}),
      });
      Object.entries(counters).forEach(([tab, count]) => {
        toursCounters.value[tab] = count;
      });
    }

    function changeTab(tabComponent) {
      const tabName = tabComponent.name || tabComponent;
      filters.dateRange = defaultDateRanges[tabName];
      filters.statuses = [];
      filters.sorting = getDefaultSortByTab(tabName);
      pagination.page = 1;

      if (tabName === 'archive') {
        filters.dateRange = [filters.dateRange.from.toDate(), filters.dateRange.to.toDate()];
      }

      if (props.isRenderedFromUnitPage) {
        currentTab.value = tabComponent;
        context.refs.loadingLayer.triggerInit();
      } else {
        const tab = tabComponent.name;
        currentTab.value = tab;
        updateTabInUrlAndLocalStorage({ router: context.root.$router, tab });
        context.refs.loadingLayer.triggerInit();
      }
    }

    function getInitialDateRange() {
      let dateRange = defaultDateRanges[currentTab.value];

      if (currentTab.value === 'archive') {
        dateRange = [dateRange.from.toDate(), dateRange.to.toDate()];
      }

      return dateRange;
    }

    function changeToursSorting() {
      if (filters.sorting === activitySortTypes.DESCENDING) {
        filters.sorting = activitySortTypes.ASCENDING;
      } else {
        filters.sorting = activitySortTypes.DESCENDING;
      }
      context.refs.loadingLayer.triggerInit();
    }

    function computeStatusesOptions() {
      const statusesOptions = [
        {
          label: 'Cancelled',
          value: 'cancelled',
        },
      ];

      if (['today', 'future'].includes(currentTab.value)) {
        statusesOptions.unshift({
          label: 'Upcoming',
          value: 'pending',
        });
      }

      if (['today', 'archive'].includes(currentTab.value)) {
        statusesOptions.unshift({
          label: 'Completed',
          value: 'completed',
        });
        statusesOptions.push(
          {
            label: 'No show',
            value: 'no_show',
          },
          {
            label: 'Access issue',
            value: 'technical_issue',
          },
        );
      }

      return statusesOptions;
    }

    function computeFilteredTours() {
      return tours.value
        .filter((tour) => {
          if (filters.searchQuery) {
            if (!searchTableRow(tour, filters.searchQuery, searchFields)) {
              return false;
            }
          }

          if (filters.myPropertiesToursOnly && tour.property_listing_manager_id !== context.root.$store.getters['Auth/userId']) {
            return false;
          }

          const statuses = filters.statuses;
          if (statuses.length === 0 || statuses.length === statusesOptions.value.length) {
            return true;
          }

          if (statuses.includes(tour.tour_status)) {
            return true;
          }

          const isFuture = tour.tour_at.isAfter(momentUtil(null, null));
          if (statuses.includes('pending') && pendingStatuses.includes(tour.tour_status) && isFuture) {
            return true;
          }

          const isCancelled = tour.tour_status.includes('cancelled') && tour.tour_status !== Tour.STATUS_CANCELLED_SURRENDERED;
          if (statuses.includes('cancelled') && (ScheduleStatuses.STATUS_AUTO_DELETED === tour.tour_status || isCancelled)) {
            return true;
          }

          return false;
        });
    }

    function computePaginatedTours() {
      if (currentTab.value !== 'archive') {
        return filteredTours.value;
      }
      return filteredTours.value.filter((tour, index) => index >= TOURS_PER_PAGE * (pagination.page - 1) && index < TOURS_PER_PAGE * pagination.page);
    }

    function getDefaultSortByTab(tabName) {
      if (props.isRenderedFromUnitPage) {
        return 'DESC';
      }

      return ['today', 'future'].includes(tabName)
        ? activitySortTypes.ASCENDING
        : activitySortTypes.DESCENDING;
    }

    function calculatePagination() {
      pagination.total = filteredTours.value.length;
      pagination.from = TOURS_PER_PAGE * (pagination.page - 1) + 1;
      pagination.to = Math.min(TOURS_PER_PAGE * pagination.page, pagination.total);
      pagination.isPreviousPageAvailable = pagination.page > 1;
      pagination.isNextPageAvailable = pagination.to < pagination.total;
    }

    function previousPage() {
      if (!pagination.isPreviousPageAvailable) {
        return;
      }
      pagination.page--;
      filters.sorting = getDefaultSortByTab(currentTab.value);
    }

    function nextPage() {
      if (!pagination.isNextPageAvailable) {
        return;
      }
      pagination.page++;
      filters.sorting = getDefaultSortByTab(currentTab.value);
    }

    function dialogClosed(visibilityProperty, success) {
      uiFlags[visibilityProperty] = false;
      chosenTour.value = null;
      if (success) {
        context.refs.loadingLayer.triggerInit();
      }
    }

    function showDialog(visibilityProperty, tour) {
      chosenTour.value = tour;
      uiFlags[visibilityProperty] = true;
    }
  },
};

export { localStorageFiltersKey };
</script>

<style lang="scss">
@import '@/styles/utils';

#tours-page {
  .loading-layer {
    margin-top: 10px;
  }

  .tours-header {
    display: flex;
    align-items: center;
    @include dropdown-select();
  }

  .tours-search-input-container {
    @include search-input-with-focus($with-mobile-small-icon-expander: false);
  }

  @include media-breakpoint-down(md) {
    @include generate-horizontal-tabs-mobile-view;
  }
}
</style>
