<template>
  <ElDialog
    id="reschedule-tour-dialog"
    :center="true"
    top="30vh"
    :show-close="false"
    :visible="visible"
    :fullscreen="$viewport.lt.md"
    width="545px"
    title="Reschedule Tour"
    @close="$emit('close')"
  >
    <div
      v-if="dialogState.isLoading"
      class="text-center"
    >
      <ElSpinner
        color="primary"
        :radius="50"
      />
    </div>
    <template v-else>
      <ElForm
        v-if="showingSlotsRef && showingSlotsRef.length"
        id="reschedule-tour-form"
        ref="rescheduleForm"
        :model="payload"
        class="row"
      >
        <div class="col-12 col-md-6">
          <SdFloatLabel>
            <ElSelect
              v-model="pickerDatetime.dateIndex"
              value-key="date"
              placeholder="Date"
              @change="dateChanged"
            >
              <ElOption
                v-for="(item, index) in showingSlotsRef"
                :key="item.label"
                :label="item.label"
                :value="index"
              />
            </ElSelect>
          </SdFloatLabel>
        </div>
        <div class="col-12 col-md-6">
          <SdFloatLabel>
            <ElSelect
              v-model="pickerDatetime.time"
              :disabled="pickerDatetime.dateIndex === null"
              value-key="date"
              placeholder="Time"
              @change="timeSelected"
            >
              <ElOption
                v-for="item in relatedTimeSlots"
                :key="item.label"
                :label="item.label"
                :value="item"
              />
            </ElSelect>
          </SdFloatLabel>
        </div>
      </ElForm>
      <div slot="footer">
        <div
          v-if="warningsRef.length !== 0"
          class="col-12 pb-3 p-0 font-15"
        >
          <ElAlert
            type="info"
            show-icon
            :closable="false"
          >
            <div>
              <p class="text-left font-weight-strong mb-0 mt-1">
                Please note
              </p>
              <p
                v-for="(warning, index) in warningsRef"
                :key="index"
                class="text-left"
                :class="index+1 === warningsRef.length ? 'mb-0' : 'mb-3'"
              >
                {{ warning }}
              </p>
            </div>
          </ElAlert>
        </div>
        <ElDivider class="mb-2 mt-2" />
        <div class="text-left font-17 pt-3">
          <div
            class="user-tag "
            :class="schedule.assignee_avatar ? 'vertical-align-sub': ''"
          >
            <img
              v-if="schedule.assignee_avatar"
              class="user-avatar-small mr-1"
              :src="schedule.assignee_avatar"
            >
            <i
              v-else
              class="sdicon-user-tie user-tag-icon"
            />
            <span>{{ schedule.assignee_name }}</span>
          </div>
          <span>
            Will stay assigned for the tour.  We assume you've coordinated the new time with the prospect
          </span>
        </div>
        <WarningNoteBeforeAssign
          class="mb-3 mt-3"
          :visible="assigneeWarningNoteVisible"
          :user.sync="tourAssigneeResultsRef.user"
        />
        <div class="row justify-content-center mt-4">
          <ElButton
            size="medium"
            @click="$emit('close')"
          >
            Cancel
          </ElButton>
          <ElButton
            size="medium"
            type="primary"
            :loading="dialogState.isBtnLoading"
            :disabled="dialogState.isBtnLoading || !pickerDatetime.time"
            @click="tryToReschedule"
          >
            Reschedule
          </ElButton>
        </div>
      </div>
    </template>
  </ElDialog>
</template>
<script>
import {
  computed, reactive, ref, watch,
} from '@vue/composition-api';

import WarningNoteBeforeAssign from '@/components/tour/WarningNoteBeforeAssign';
import { getTourByDateAndAssignee } from '@/utils/TourUtil';
import { showErrorNotify, showSuccessNotify } from '../../utils/NotifyUtil';
import momentUtil from '../../utils/MomentUtil';
import { getSettingValueByKey } from '../../utils/SettingUtil';
import { hoursAndMinutesStringFromMinutes } from '../../utils/DatetimeUtil';

export default {
  name: 'TourRescheduleDialog',
  components: {
    WarningNoteBeforeAssign,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    schedule: {
      type: Object,
      required: true,
      default: () => {
      },
    },
  },
  setup(props, context) {
    const assigneeWarningNoteVisible = ref(false);
    const tourAssigneeResultsRef = ref({ user: {} });
    const dispatch = context.root.$store.dispatch;
    const dialogState = reactive(defaultDialogState);
    const payloadRef = ref({
      id: props.schedule.id,
      allowed_to_proceed: true,
      tour_at: null,
    });
    const showingSlotsRef = ref(null);
    const payload = ref({ ...defaultPayload });
    const pickerDatetime = reactive({ dateIndex: null, time: null });
    const warningsRef = ref([]);
    const relatedTimeSlots = computed(
      () => (showingSlotsRef.value[pickerDatetime.dateIndex] ? showingSlotsRef.value[pickerDatetime.dateIndex].timeSlots : []),
    );
    watch(() => props.schedule.unit_id, () => {
      pickerDatetime.dateIndex = null;
      pickerDatetime.time = null;
      warningsRef.value = [];
      getShowingSlots();
    }, { immediate: true });

    return {
      payloadRef,
      dialogState,
      tryToReschedule,
      relatedTimeSlots,
      showingSlotsRef,
      payload,
      pickerDatetime,
      warningsRef,
      getShowingSlots,
      dateChanged,
      timeSelected,
      showAssigneeWarningNote,
      assigneeWarningNoteVisible,
      tourAssigneeResultsRef,
    };

    async function showAssigneeWarningNote(tourAt) {
      assigneeWarningNoteVisible.value = false;
      const result = await getTourByDateAndAssignee(props.schedule, props.schedule.assignee_id, tourAt);

      if (Object.keys(result).length > 0) {
        tourAssigneeResultsRef.value = {
          user: result,
        };
        assigneeWarningNoteVisible.value = true;
      }
    }

    async function getShowingSlots() {
      try {
        const showingSlotsResp = await dispatch('Unit/showingSlots', props.schedule.unit_id);
        showingSlotsRef.value = showingSlotsResp.slots.map((slot) => ({
          date: slot.date,
          label: context.root.$momentUtil(slot.date, null).toDateFormat(),
          timeSlots: slot.time_slots.map((timeSlot) => ({
            date: timeSlot.tour_at,
            label: context.root.$momentUtil(timeSlot.tour_at, props.schedule.property_timezone).toDisplayShortTimeFormat(),
            warnings: getTimeSlotWarnings(timeSlot),
          })),
        }));
        dialogState.isLoading = false;
      } catch (error) {
        showErrorNotify(context, error.message);
      }
    }

    function getTimeSlotWarnings(timeSlot) {
      const warnings = [];
      if (timeSlot.notices) {
        timeSlot.notices.forEach((notice) => {
          warnings.push(resolveNoticeMessage(notice));
        });
      }
      if (timeSlot.violated_restrictions) {
        timeSlot.violated_restrictions.forEach((restriction) => {
          if (!ignoredRestrictions.includes(restriction.restriction.match(/[^\\]+$/)[0])) {
            warnings.push(resolveRestrictionMessage(restriction));
          }
        });
      }
      return warnings;
    }

    async function tryToReschedule() {
      dialogState.isBtnLoading = true;
      payloadRef.value.tour_at = pickerDatetime.time.date;
      try {
        const response = await context.root.$store.dispatch('Schedule/reschedule', payloadRef.value);
        context.emit('close', 'success');
        showSuccessNotify(
          context,
          generateSuccessMessage(payloadRef.value.tour_at, props.schedule.property_timezone),
          { dangerouslyUseHTMLString: true },
        );
        await context.root.$router.push({
          name: 'app.tours.show',
          params: {
            id: response.new_schedule_id,
          },
        });
      } catch (error) {
        if (!(error instanceof Error)) {
          return; // Validator failed
        }
        showErrorNotify(context, error.message);
      } finally {
        dialogState.isBtnLoading = false;
      }
    }

    function generateSuccessMessage(tourAt, tz) {
      const formattedTourAt = momentUtil(tourAt, tz).toDisplayFormat();
      return `<strong>Tour has been Rescheduled!</strong><br/> The tour time has changed to ${formattedTourAt}.`;
    }

    function timeSelected(val) {
      showAssigneeWarningNote(val.date);
      warningsRef.value = val.warnings || [];
      context.emit('time-selected', val);
    }

    function dateChanged() {
      pickerDatetime.time = null;
      warningsRef.value = [];
    }

    function resolveRestrictionMessage(restrictionObj) {
      const className = restrictionObj.restriction.match(/[^\\]+$/)[0];
      switch (className) {
        case 'ConcurrentTourNotice':
          return 'There are already prospects scheduled to visit the property at that time ({…prospect_names})';
        case 'MinTimeRestriction':
          return `This tour happens in less than ${hoursAndMinutesStringFromMinutes(getSettingValueByKey('min_time_to_schedule_tour', props.schedule.property_market_id))}`;
        case 'PausePeriodRestriction':
        case 'PausePeriodBufferRestriction':
          return 'Showings are paused for this property at that time';
        case 'TenantNoticeRestriction':
        case 'TenantShowingWindowsNoticeRestriction':
          return 'This tour is in an occupied property and the tenant has not been notified in advance';
        default:
          return restrictionObj.constraint_message;
      }
    }

    function resolveNoticeMessage(noticeObj) {
      const className = noticeObj.type.match(/[^\\]+$/)[0];
      switch (className) {
        case 'ConcurrentTourNotice':
          return `There are already prospects scheduled to visit the property at that time (${noticeObj.data.lead_names.join(', ')})`;
        default:
          return noticeObj.message;
      }
    }
  },
};

const ignoredRestrictions = ['InAutoDeletePeriodRestriction', 'OperatingHoursRestriction', 'OccupiedSlotRestriction'];

const defaultDialogState = {
  isLoading: true,
  isBtnLoading: false,
};
const defaultPayload = {
  tour_at: null,
};
</script>
<style lang="scss">
    #reschedule-tour-dialog {
        .user-tag {
            font-size: $--font-size-base;
            padding: 0.25rem;
        }
    }

</style>
