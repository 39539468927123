<template>
  <div :class="propClass">
    <SdGenerateAdDialog
      v-if="uiFlags.openGenerateAdDialog"
      :prev-additional-information="additionalInformation"
      :payload="unitPayload"
      @update-payload="value => updatePayload(value)"
      @close="uiFlags.openGenerateAdDialog = false"
    />
    <el-tooltip
      :disabled="$viewport.lt.md"
      :visible-arrow="false"
      placement="right-start"
      popper-class="popover-panel"
    >
      <template slot="content">
        <i class="sdicon-info-circle" />
        <p>Please enter the number of bedrooms, bathrooms, size, and rent before generating a description</p>
      </template>
      <ElButton
        type="text"
        size="medium"
        class="pt-lg-0 pt-1 pb-0"
        :disabled="isDisabled"
        @click.native="uiFlags.openGenerateAdDialog = true"
      >
        <i class="sdicon-magic" />
        Generate title and description with AI
      </ElButton>
    </el-tooltip>
  </div>
</template>

<script>

import SdGenerateAdDialog from '@/components/common/form/SdGenerateAdDialog.vue';
import { reactive, ref } from '@vue/composition-api';

export default {
  name: 'UnitGenerateAd',
  components: {
    SdGenerateAdDialog,
  },
  props: {
    propClass: {
      type: String,
      required: false,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
    isEditForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    property: {
      type: Object,
      required: true,
    },
    unit: {
      type: Object,
      required: true,
    },
    payload: {
      type: Object,
      required: false,
      default: null,
    },
    additionalInformation: {
      type: String,
      required: true,
    },
    rent: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup(props, context) {
    const uiFlags = reactive({
      openGenerateAdDialog: false,
    });
    const unitPayload = reactive({
      unitId: props.unit.id,
      shortAddress: props.property.short_address,
      vicinity: props.property.address.data.vicinity,
      rent: props.isEditForm ? props.rent ?? props.unit.last_showing_profile.rent : props.payload.rent,
      bedrooms: props.isEditForm ? props.unit.bedrooms : props.payload.bedrooms,
      bathrooms: props.isEditForm ? props.unit.bathrooms : props.payload.bathrooms,
      size: props.isEditForm ? props.unit.size : props.payload.size,
      schedulingUrl: props.isEditForm ? props.unit.last_showing_profile.property_url : props.payload.property_url,
      amenities: props.isEditForm ? props.unit.tags : props.payload.tags,
    });
    const stopProcess = ref(false);
    return {
      uiFlags,
      unitPayload,
      updatePayload,
    };

    function updatePayload(data) {
      uiFlags.openGenerateAdDialog = false;
      if (data?.additionalInformation) {
        context.emit('update-additional-information', data.additionalInformation);
      }
      if (data.stopProcess || stopProcess.value) {
        stopProcess.value = true;
        return;
      }
      context.emit('update-payload', data);
    }
  },
};
</script>
