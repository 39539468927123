export const pauseReasonsOptions = [
  {
    label: 'Pre-leasing period',
    value: 'pre_leasing_period',
  },
  {
    label: 'Maintenance',
    value: 'maintenance',
  },
  {
    label: 'Reviewing applications',
    value: 'reviewing_applications',
  },
  {
    label: 'Budget reached',
    value: 'budget_reached',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const getPauseReasonLabel = (pauseReason) => pauseReasonsOptions.find((option) => option.value === pauseReason).label;

export const ShowingTypes = {
  OPEN_HOUSE: 'open_house',
  HIGH_AVAILABILITY: 'high_availability',
  RESTRICTED: 'restricted',
  SELF_SHOWING: 'self_showing',
  PUBLISH_LISTING: 'publish_listing',
};

export const getUnitShowingTypeLabel = (type) => {
  const map = {
    [ShowingTypes.OPEN_HOUSE]: 'Open house',
    [ShowingTypes.HIGH_AVAILABILITY]: 'Agent',
    [ShowingTypes.RESTRICTED]: 'Agent',
    [ShowingTypes.SELF_SHOWING]: 'Self',
    [ShowingTypes.PUBLISH_LISTING]: 'Third-party',
  };

  return map[type] || '';
};

export const MAX_OCCUPIED_UNIT_SHOWING_WINDOWS = 5;
export const MAX_OCCUPIED_UNIT_SHOWING_WINDOW_DURATION = 60 * 4;
export const MAX_OCCUPIED_UNIT_OPEN_HOUSE_WINDOWS = 2;
