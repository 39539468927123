var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[(_vm.mode !== _vm.modes.add)?_c('ElFormItem',{staticClass:"col-12",attrs:{"prop":_vm.resolveFormItemProp('video'),"rules":{ validator: _vm.validateVideoUrl }}},[_c('SdFloatLabel',[_c('ElTooltip',{attrs:{"visible-arrow":false,"disabled":_vm.$viewport.lt.md,"popper-class":"popover-panel","placement":"right-start"}},[_c('template',{slot:"content"},[_c('i',{staticClass:"sdicon-info-circle"}),_c('p',[_vm._v("This link will be used when enabling properties for showing.")])]),_c('SdFloatLabel',[_c('ElInput',{attrs:{"placeholder":"Video URL","disabled":_vm.isDisabled},on:{"input":function (value) { return _vm.$emit('update-unit', {video: value}); }},model:{value:(_vm.unit.video),callback:function ($$v) {_vm.$set(_vm.unit, "video", $$v)},expression:"unit.video"}})],1)],2)],1)],1):_vm._e(),(!_vm.isDisabled && _vm.imageGallery.length > 0)?_c('div',{staticClass:"col-12",class:_vm.mode === _vm.modes['activate_showing'] ? 'mt-4': ''},[_c('div',{staticClass:"font-17 font-weight-bold"},[_vm._v(" Image Gallery ")]),_c('div',[_vm._v(" You can drop (or browse to select) multiple photos at a time using the uploader below. Once uploaded, you can drag and drop the images to change their order. ")])]):_vm._e(),(_vm.imageGallery.length > 0)?_c('Draggable',{key:("media-gallery-" + (_vm.isUploadingGallery ? 'uploading' : 'upload')),staticClass:"col-12",class:[
      _vm.isUploadingGallery ? 'draggable-uploading' : 'draggable-upload',
      _vm.isDisabled ? 'draggable-disabled' : '',
      _vm.mode === _vm.modes['activate_showing'] ? 'pt-4 mt-3' : 'mt-2'
    ],attrs:{"animation:":500,"disabled":_vm.isUploadingGallery || _vm.imageGallery.length < 2,"ghost-class":_vm.isUploadingGallery ? '' : 'dragging-ghost-class'},on:{"start":function($event){_vm.dragging = true},"end":_vm.onEndDrag},model:{value:(_vm.imageGallery),callback:function ($$v) {_vm.imageGallery=$$v},expression:"imageGallery"}},[_c('transition-group',{staticClass:"row",attrs:{"type":"transition","name":!_vm.dragging ? 'reorder' : null}},_vm._l((_vm.imageGallery),function(image,imageIndex){return _c('div',{key:("image-" + (image.src)),class:[
          _vm.isDisabled ? 'image-container-disabled' : '',
          'image-container',
          'd-flex',
          'align-items-center',
          'justify-content-center',
          {
            'image-container-draggable': !_vm.isUploadingGallery && _vm.imageGallery.length > 1,
            'col-4 col-lg-4': !_vm.showHeroImage || imageIndex > 0,
            'col-12': _vm.showHeroImage && imageIndex === 0
          }
        ]},[_c('img',{attrs:{"src":image.src}}),(image.isUploading)?_c('div',{staticClass:"image-upload-spinner d-flex text-center justify-content-center align-items-center font-15 text-white"},[_c('ElSpinner',{attrs:{"radius":36,"color":"primary"}})],1):_vm._e(),(image.uploadError)?_c('div',{staticClass:"image-upload-error d-flex text-center justify-content-center align-items-center font-15 text-white"},[_vm._v(" "+_vm._s("Upload Error\nPlease try re-uploading this image")+" ")]):_vm._e(),(image.uploadResponse)?_c('div',{staticClass:"image-upload-success d-flex text-center justify-content-center align-items-center font-15 text-white"},[_c('i',{staticClass:"sdicon-check font-42 text-white"})]):_vm._e(),(!_vm.isDisabled)?_c('div',{staticClass:"remove-img-btn-top-right",on:{"click":function () { return _vm.removeImage(imageIndex); }}},[_c('i',{staticClass:"sdicon-trash font-17 text-white"})]):_vm._e()])}),0)],1):_vm._e(),(_vm.imageGallery.length < _vm.maxImages && !_vm.isDisabled)?_c('div',{staticClass:"col-12 image-input-wrapper",class:[_vm.mode === _vm.modes['activate_showing'] ? 'mt-4' : ''],on:{"drop":function($event){$event.preventDefault();return (function (event) { return _vm.handleDrop(0, event); }).apply(null, arguments)}}},[_c('input',{staticClass:"image-input",attrs:{"type":"file","multiple":"","accept":"image/png, image/jpeg, image/jpg"},on:{"change":function (event) { return _vm.handleFiles(event, 0, 'browse'); }}}),_c('div',{staticClass:"image-upload-placeholder p-3 text-center"},[_c('i',{staticClass:"font-42 text-gray-dark sdicon-plus image-upload-plus-icon"}),_c('div',{staticClass:"text-center font-17"},[_vm._v(" "+_vm._s("Add images for listing\n(Drop images here or click to browse)")+" ")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }