<template>
  <div class="row gutter-1">
    <div class="activity-date">
      {{ activity.date }}
    </div>
    <div class="col-auto">
      <div class="activity-icon">
        <i class="sdicon-mailbox" />
      </div>
    </div>
    <div :class="$viewport.lt.md ? 'col-12 pt-2' : 'col'">
      <h3 class="activity-title">
        Weekly owner report email sent
      </h3>
      <div
        v-if="activity.recipients"
        class="activity-data"
      >
        <span class="label">
          Recipients
        </span>
        <div>
          <SdEmailIndicator
            v-for="(recipient, index) in activity.recipients"
            :key="index"
            :email="recipient"
            class="value"
          >
            {{ recipient }}
          </sdemailindicator>
        </div>
      </div>

      <div
        v-if="activity.cc"
        class="activity-data"
      >
        <span class="label">
          CC
        </span>
        <SdEmailIndicator
          v-for="(cc, index) in activity.cc"
          :key="index"
          :email="cc"
          class="value"
        >
          {{ cc }}
        </sdemailindicator>
      </div>
    </div>
  </div>
</template>
<script>
import SdEmailIndicator from '@/components/common/SdEmailIndicator';

export default {
  components: { SdEmailIndicator },
  props: {
    activity: {
      required: true,
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
.property-activities .activity-card h3.activity-title {
  margin-bottom: 0.5rem;
}
</style>
