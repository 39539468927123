<template>
  <el-tooltip
    effect="light"
    :disabled="!small"
    content="Live Video Tour"
    placement="top"
  >
    <div
      class="live-video-tag"
      :class="{'small': small, 'disabled': disabled}"
    >
      <i class="sdicon-video" />
      {{ small ? '' : 'Live Video Tour' }}
    </div>
  </el-tooltip>
</template>
<script>
export default {
  name: 'LiveVideoTourTag',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
    .live-video-tag{
        display: inline-block;
        $video-color: theme-color('primary-light');
        border-radius: $app-border-radius;
        padding: 0 0.5rem;
        background: rgba($video-color, 0.15);
        color: $video-color;
        font-size: 1rem;
        .sdicon-video {
            .sdicon-info-circle:before {
                line-height: 2.5rem;
                font-size: $--font-size-medium-large;
            }
        }
        &.small {
            font-size: 8px;
            padding: 0.25rem 0.5rem;
            margin: 0 0.5rem;
        }
        &.disabled {
            background: gray-color('light');
            color: gray-color('dark');
        }
    }
</style>
