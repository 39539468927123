<template>
  <SdPage
    id="unit-activities"
    :style="isRenderedFromUnitPage ? 'padding: 0;' : ''"
  >
    <div class="unit-header mb-4">
      <div class="unit-header-title font-17 font-weight-strong">
        Recent Activity
      </div>
      <div class="unit-activities-dropdown-container">
        <ElSelect
          v-model="filteredActivityStatuses"
          multiple
          collapse-tags
          :disabled="uiFlags.isLoadingActivities"
          placeholder="All Activities"
          class="light-background"
          popper-class="unit-activities-dropdown"
          @change="computeFilteredActivities"
        >
          <ElOption
            v-for="status in activityStatusesOptions"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </ElSelect>
      </div>
    </div>
    <div
      v-if="uiFlags.isLoadingActivities"
      class="text-center"
    >
      <ElSpinner
        color="primary"
        :radius="50"
      />
    </div>
    <div class="property-activities">
      <ElCard
        v-for="(activity) in filteredActivities.slice(0, paginationCount)"
        :key="`${activity.original_date.unix()}-${activity.type}`"
        class="activity-card"
      >
        <component
          :is="activity.componentName"
          :activity="activity"
          :unit="unit"
          @reloadUnit="reloadUnit"
        />
      </ElCard>
      <div
        v-if="filteredActivities.length > paginationCount"
        class="show-more hover"
        @click="paginationCount += activitiesPage"
      >
        Show more
      </div>
    </div>
  </SdPage>
</template>

<script>
import {
  computed, reactive, ref, watch,
} from '@vue/composition-api';

import Tour from '@/constants/Tour';
import activitiesComponents from './unit-activities';
import { activityTypes } from '../../constants/Commons';

const dateAttributes = ['tour_at', 'paused_until', 'pause_from', 'date'];
const activitiesPage = 5;

export default {
  components: { ...activitiesComponents },
  props: {
    isRenderedFromUnitPage: {
      type: Boolean,
    },
    unit: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const uiFlags = reactive({ isLoadingActivities: false });
    const activities = ref([]);
    const filteredActivities = computed(computeFilteredActivities);
    const filteredActivityStatuses = ref([]);
    const activityStatusesOptions = getActivityStatusOptions();
    const paginationCount = ref(activitiesPage);
    watch(
      () => props.unit,
      () => {
        fetchActivities();
      },
      { immediate: true },
    );
    return {
      activities,
      uiFlags,
      paginationCount,
      activitiesPage,
      filteredActivityStatuses,
      activityStatusesOptions,
      filteredActivities,
      computeFilteredActivities,
      reloadUnit,
    };

    function reloadUnit() {
      context.emit('reloadUnit');
    }

    async function fetchActivities() {
      setTimeout(() => { context.emit('stop-fetching'); }, 2000);
      context.emit('start-fetching');
      uiFlags.isLoadingActivities = true;
      if (props.unit.id) {
        const rawActivities = await context.root.$store.dispatch('Unit/activities', props.unit.id);
        activities.value = filterCancelByAgentAndCancelSurrendered(rawActivities.map(formatActivity));
      }
      uiFlags.isLoadingActivities = false;
    }

    function filterCancelByAgentAndCancelSurrendered(activities) {
      return activities.filter((activity) => {
        const isTourCancelled = activity.type === activityTypes.TOUR_CANCELLED;
        const invalidStatuses = [Tour.STATUS_CANCELLED_UNMATCHED_BY_ADMIN, Tour.STATUS_CANCELLED_SURRENDERED, Tour.STATUS_CANCELLED_BY_AGENT];

        return !(invalidStatuses.includes(activity.status) && isTourCancelled);
      });
    }

    function formatActivity(activity) {
      activity.componentName = activityComponentNameByType(activity.type);
      formatActivityDates(activity);
      return activity;
    }

    /**
     * resolving the relevant activity component according to the activity type
     * each activity component is responsible for all activities of the same group:
     * inquiry, tour, showing
     * @param activityType
     * @returns {string}
     */
    function activityComponentNameByType(activityType) {
      const activityGroup = activityType.toLowerCase().split('_')[0];
      // pascal case the group name
      const componentName = activityGroup.charAt(0).toUpperCase() + activityGroup.slice(1);
      return `UnitActivity${componentName}`;
    }

    function formatActivityDates(activity) {
      dateAttributes.forEach((attribute) => {
        if (activity[attribute]) {
          const timezone = attribute === 'date' ? context.root.$momentUtil.tz.guess() : props.unit.property.timezone;
          activity.original_date = context.root.$momentUtil(activity[attribute], timezone);
          const momentDate = context.root.$momentUtil(activity[attribute], timezone);
          activity[attribute] = momentDate.toDisplayFormat();
        }
      });
    }

    function computeFilteredActivities() {
      return activities.value.filter((activity) => {
        if (!filteredActivityStatuses.value[0]) {
          return true;
        }

        if (filteredActivityStatuses.value.includes('completed_tours')) {
          if (
            [
              activityTypes.TOUR_COMPLETED,
              activityTypes.TOUR_COMPLETED_SELF_SHOWING,
              activityTypes.TOUR_NO_SHOW,
              activityTypes.TOUR_TECHNICAL_ISSUE,
            ].includes(activity.type)
          ) {
            return true;
          }
        }

        if (filteredActivityStatuses.value.includes('scheduled_tours')) {
          if ([activityTypes.TOUR_SCHEDULED, activityTypes.TOUR_SCHEDULED_SELF_SHOWING].includes(activity.type)) {
            return true;
          }
        }

        if (filteredActivityStatuses.value.includes('new_inquiries')) {
          if ([activityTypes.INQUIRY_CREATED].includes(activity.type)) {
            return true;
          }
        }

        if (filteredActivityStatuses.value.includes('cancellations')) {
          if ([activityTypes.TOUR_CANCELLED, activityTypes.TOUR_NO_AGENT_FOUND].includes(activity.type)) {
            return true;
          }
        }

        if (filteredActivityStatuses.value.includes('takeovers')) {
          if ([activityTypes.TOUR_TAKEN_OVER, activityTypes.TOUR_SURRENDERED].includes(activity.type)) {
            return true;
          }
        }

        if (filteredActivityStatuses.value.includes('lifecycle_activities')) {
          if (
            [
              activityTypes.SHOWING_PAUSED,
              activityTypes.SHOWING_PAUSED_UPDATE,
              activityTypes.SHOWING_UNPAUSED,
              activityTypes.SHOWING_ACTIVATED,
              activityTypes.OWNER_REPORTED,
              activityTypes.SHOWING_REACTIVATED,
              activityTypes.SHOWING_LEASED,
              activityTypes.SHOWING_STOPPED,
              activityTypes.TOUR_BUDGET_REACHED,
              activityTypes.SHOWING_SWAP,
              'SCAM_DETECTED',
              'SCAM_REMOVED',
              activityTypes.SHOWING_FUTURE_PAUSED,
            ].includes(activity.type)
          ) {
            return true;
          }
        }

        if (filteredActivityStatuses.value.includes('qualification_results')) {
          if ([activityTypes.DISQUALIFIED_LEAD, activityTypes.QUALIFIED_LEAD].includes(activity.type)) {
            return true;
          }
        }

        if (filteredActivityStatuses.value.includes('incoming_call')) {
          if ([activityTypes.INCOMING_CALL].includes(activity.type)) {
            return true;
          }
        }

        if (filteredActivityStatuses.value.includes('deleted_inquiries')) {
          if ([activityTypes.DELETED_INQUIRY].includes(activity.type)) {
            return true;
          }
        }

        if (filteredActivityStatuses.value.includes('identification_verification')) {
          if ([activityTypes.IDENTIFICATION_VERIFICATION].includes(activity.type)) {
            return true;
          }
        }

        return false;
      });
    }

    function getActivityStatusOptions() {
      return [
        {
          label: 'Completed Tours',
          value: 'completed_tours',
        },
        {
          label: 'Scheduled Tours',
          value: 'scheduled_tours',
        },
        {
          label: 'New Inquiries',
          value: 'new_inquiries',
        },
        {
          label: 'Cancellations',
          value: 'cancellations',
        },
        {
          label: 'Take-overs',
          value: 'takeovers',
        },
        {
          label: 'Lifecycle Activities',
          value: 'lifecycle_activities',
        },
        {
          label: 'Qualification results',
          value: 'qualification_results',
        },
        {
          label: 'Incoming Phone Line Calls',
          value: 'incoming_call',
        },
        {
          label: 'Deleted Inquiries',
          value: 'deleted_inquiries',
        },
        {
          label: 'Identification Verification',
          value: 'identification_verification',
        },
      ];
    }
  },
};
</script>

<style lang="scss">
@import '@/styles/utils';

.unit-activities-dropdown {
  @media (min-width: 541px) {
    width: 250px;
  }
}

#unit-activities {
  .unit-activities-dropdown-container {
    cursor: pointer;
    width: 100%;
    max-width: 250px;
    margin-left: auto;
    @include dropdown-select($select-width: auto);
  }

  .unit-header {
    display: flex;
    align-items: center;
    height: 40px;
  }
}

.light-background {
  .el-input .el-input__inner {
    background: $white;
  }
}

.property-activities {
  .show-more {
    color: theme-color("primary");
    font-size: $--font-size-base;
    text-align: center;
    padding: 1rem 0;
    cursor: pointer;
  }

  .activity-card {
    margin-bottom: 2px;

    @media (max-width: 540px) {
      box-shadow: 0 0 15px rgba(13, 136, 220, 0.14);
      margin-top: 1rem;
      border: none;
    }

    .el-card__body {
      padding: 1rem;
      position: relative;
    }

    .activity-date {
      position: absolute;
      top: 16px;
      right: 16px;
      line-height: 1.5rem;
      font-size: $--font-size-extra-small;
      color: gray-color();
    }

    .activity-icon {
      border-radius: 50%;
      border-color: gray-color("light");
      border-width: 1px;
      border-style: solid;
      width: 40px;
      height: 40px;
      text-align: center;

      i {
        line-height: 40px;
        font-size: 24px;
        color: gray-color("dark");

        @media (max-width: 540px) {
          font-size: $--font-size-base;
          line-height: unset;
        }

        &.sdicon-group {
          font-size: $--font-size-small;
        }
      }

      @media (max-width: 540px) {
        width: 24px;
        height: 24px;
      }
    }

    h3 {
      font-size: $--font-size-medium;
      width: 75%;
      color: gray-color("darker");
      font-weight: $strong;
      line-height: 1.5rem;
      margin-bottom: 0;

      span.activity-id {
        color: gray-color("dark");
      }
    }

    .activity-data {
      font-size: $--font-size-small;
      display: flex;
      color: gray-color("darker");
      margin-bottom: 0.5rem;
      line-height: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      .label {
        min-width: 5rem;
        color: gray-color("dark");
      }
    }

    .activity-comments {
      border-left: 1px solid gray-color("dark");
      padding: 0 0.5rem;
      font-size: $--font-size-small;
      color: gray-color("darker");
    }

    .activity-summary {
      font-size: $--font-size-base;
      color: gray-color("darker");
      line-height: 1rem;
      margin-bottom: 1rem;
      margin-top: 0.2rem;
    }
  }
}
</style>
