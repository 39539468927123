<template>
  <div>
    <ElFormItem>
      <SdFloatLabel>
        <ElInput
          v-model="additionalUnitScreeningRef"
          :rows="4"
          maxlength="4096"
          placeholder="Custom pre-screening criteria for the prospect to review"
          type="textarea"
          @input="emitFormStateChanged"
        />
      </SdFloatLabel>
    </ElFormItem>
  </div>
</template>

<script>

import { ref } from '@vue/composition-api';

export default {
  name: 'ShowingProfileAdditionalUnitScreening',
  props: {
    additionalUnitScreening: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, context) {
    const additionalUnitScreeningRef = ref(props.additionalUnitScreening);
    return {
      emitFormStateChanged,
      additionalUnitScreeningRef,
    };
    function emitFormStateChanged() {
      context.emit('change', additionalUnitScreeningRef.value);
    }
  },
};
</script>
