<template>
  <div
    class="row no-gutters"
    :class="$viewport.lt.md ? 'mt-4 mb-3' : '' "
  >
    <svg
      v-if="$viewport.lt.md"
      class="rectangle"
    />
    <div class="completed-tour-funnel mt-2">
      <div class="funnel-step flex-column d-inline-flex  ">
        <span class="text-gray-darker font-15">
          {{ computedFirstInquiryTime }}
        </span>
        <small class="text-gray-dark font-13">First Inquiry</small>
      </div>
      <div class="funnel-time-step font-13">
        <span class="p-1">
          {{ computedInquiryToScheduleDiffTime }}
        </span>
      </div>
      <i class="sdicon-chevron-down d-flex font-14" />
      <div class="funnel-step flex-column d-inline-flex  ">
        <span class="text-gray-darker font-15">
          {{ computedTourScheduledTime }}
        </span>
        <small class="text-gray-dark font-13">Booked a tour</small>
      </div>
      <div class="funnel-time-step font-13">
        <span class="p-1">
          {{ computedScheduleToTourTimeDiffTime }}
        </span>
      </div>
      <i class="sdicon-chevron-down d-flex font-14" />
      <div class="funnel-step flex-column d-inline-flex  ">
        <span class="text-gray-darker font-15">
          {{ computedTourAtTime }}
        </span>
        <small class="text-gray-dark font-13">Tour time</small>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';

import { diffInHumanFormat } from '../../utils/DatetimeUtil';

export default {
  name: 'TourCompletedFunnel',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const computedFirstInquiryTime = computed(() => funnelDate('inquiry_created_at').toDisplayFormatMonthWithDayAndShortTimeFormat());
    const computedTourScheduledTime = computed(() => funnelDate('schedule_created_at').toDisplayFormatMonthWithDayAndShortTimeFormat());
    const computedTourAtTime = computed(() => funnelDate('schedule_tour_at').toDisplayFormatMonthWithDayAndShortTimeFormat());
    const computedInquiryToScheduleDiffTime = computed(computeInquiryToScheduleDiffTime);
    const computedScheduleToTourTimeDiffTime = computed(computeScheduleToTourTimeDiffTime);

    return {
      computedFirstInquiryTime,
      computedTourScheduledTime,
      computedInquiryToScheduleDiffTime,
      computedScheduleToTourTimeDiffTime,
      computedTourAtTime,
    };

    function computeInquiryToScheduleDiffTime() {
      let diff = 0;
      if (props.data) {
        diff = diffInHumanFormat(funnelDate('inquiry_created_at'), funnelDate('schedule_created_at'));
      }
      return diff;
    }

    function computeScheduleToTourTimeDiffTime() {
      let diff = 0;
      if (props.data) {
        diff = diffInHumanFormat(funnelDate('schedule_created_at'), funnelDate('schedule_tour_at'));
      }
      return diff;
    }

    function funnelDate(field) {
      let momentDate = context.root.$momentUtil(null, null);
      if (props.data) {
        momentDate = context.root.$momentUtil(
          props.data[field],
          props.data.property_timezone,
        );
      }
      return momentDate;
    }
  },
};
</script>

<style lang="scss">
    .completed-tour-funnel {
        display: flex;
        width: -moz-available;
        width: -webkit-fill-available;
        background-color: theme-color('light-grey');
        border: 1px solid gray-color('light');
        justify-content: space-between;
        border-radius: 100px;

        .funnel-step {
            padding: 0.25rem;
            text-align: center;

            &:first-child {
                margin-left: 0.5rem;
            }

            &:last-child {
                margin-right: 0.5rem;
            }
        }

        i {
            align-self: center;
        }

        .funnel-time-step {
            background-color: theme-color(primary-dark);
            color: $white;
            font-weight: $strong;
            border-radius: 100px;
            padding: 0.25rem;
            min-width: 3.25rem;
            text-align: center;
            align-self: center;

            span {
              -webkit-font-smoothing: antialiased;
              align-self: center;
            }
        }

        @include media-breakpoint-down(md) {
            border: none;
            flex-direction: column;
            background-color: unset;
            margin-left: 2rem;
            .sdicon-chevron-down{
                transform:rotate(0deg) !important;
            }
            .funnel-step {
                text-align: left;
                align-self: start;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
            i {
                align-self: start;
                transform: rotate(0);
                margin-left: 1rem;
            }
            .funnel-time-step {
                text-align: left;
                align-self: start;
            }
        }
    }
    .rectangle {
        background-color: theme-color('light-grey');
        border-radius: 100px;
        height: 250px;
        width: 12px;
        position: absolute;
        display: -webkit-box;
    }
</style>
