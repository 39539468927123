<template>
  <ElDialog
    id="stop-showing-dialog"
    :center="true"
    top="30vh"
    :show-close="false"
    :visible="visible"
    :fullscreen="$viewport.lt.md"
    class="property-dialog"
    width="90%"
    @close="$emit('close')"
  >
    <div slot="title">
      <h2 class="mb-1">
        {{ unit.last_showing_profile.is_open_house ? "Stop open house" : "Stop showing" }}
      </h2>
      <h3 class="text-gray-dark font-weight-normal">
        For {{ `${unit.property.short_address}${unit.name ? " - " + unit.name : ""}` }}
      </h3>
    </div>
    <div v-if="!uiFlags.isFetchingImpact">
      <div v-if="!uiFlags.hasNonCancellableTours">
        <div
          v-if="!prospect"
          class="row justify-content-center mt-1"
        >
          <ElRadio
            v-model="deactivationPayload.deactivation_type"
            :label="Showing.DEACTIVATION_TYPE_RENTED_WITH_SHOWDIGS"
          >
            Rented
          </ElRadio>
          <ElRadio
            v-model="deactivationPayload.deactivation_type"
            :label="Showing.DEACTIVATION_TYPE_OTHER"
          >
            Not Rented
          </ElRadio>
        </div>
        <div class="row justify-content-center mt-4 transition">
          <SdFloatLabel v-if="deactivationPayload.deactivation_type === Showing.DEACTIVATION_TYPE_RENTED_WITH_SHOWDIGS">
            <ElAutocomplete
              v-model="selectedLead"
              :fetch-suggestions="autocompleteLeads"
              placeholder="Prospect Name"
              :disabled="!!prospect"
              value-key="name"
              @select="selectLead"
            >
              <template slot-scope="{ item }">
                <div class="font-17 line-height-1 pt-2 pb-2">
                  {{ item.name }}
                  <span
                    v-if="item.tour_at"
                    class="d-block line-height-1 mt-1 text-gray-dark font-13"
                  >
                    {{ item.tour_at }}
                  </span>
                </div>
              </template>
            </ElAutocomplete>
          </SdFloatLabel>
          <SdFloatLabel v-if="deactivationPayload.deactivation_type === Showing.DEACTIVATION_TYPE_OTHER">
            <ElInput
              v-model="deactivationPayload.deactivation_comment"
              class="deactivation-comment"
              :placeholder="inputPlaceholderText"
            />
          </SdFloatLabel>
        </div>
      </div>
    </div>
    <UnitPauseStopImpact
      v-if="visible"
      class="mt-4"
      pause-or-stop="stop"
      :with-impact-section="shouldShowImpactSection"
      :showing-profile-id="unit.last_showing_profile.id"
      @impact-fetched="impactFetched"
    />
    <div slot="footer">
      <el-button
        size="medium"
        @click="$emit('close')"
      >
        Cancel
      </el-button>
      <el-button
        type="primary"
        :disabled="isDisabledStopButton"
        size="medium"
        :loading="uiFlags.isStopping"
        @click="stop"
      >
        {{ unit.last_showing_profile.is_open_house ? "Stop open house" : "Stop showing" }}
      </el-button>
    </div>
  </ElDialog>
</template>

<script>
import {
 computed, onMounted, reactive, ref,
} from '@vue/composition-api';

import { showErrorNotify } from '@/utils/NotifyUtil';
import Showing from '@/constants/Showing';

import UnitPauseStopImpact from './UnitPauseStopImpact';

const defaultDeactivationPayload = {
  deactivation_type: Showing.DEACTIVATION_TYPE_RENTED_WITH_SHOWDIGS,
  deactivation_comment: undefined,
  lead_id: undefined,
};
export default {
  name: 'UnitStopDialog',
  components: {
    UnitPauseStopImpact,
  },
  props: {
    unit: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    prospect: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props, context) {
    const unit = props.unit;
    const dispatch = context.root.$store.dispatch;
    const leads = ref([]);
    const selectedLead = ref(props.prospect ? props.prospect.full_name : '');
    const uiFlags = reactive({
      isStopping: false,
      isFetchingImpact: true,
      hasNonCancellableTours: false,
    });
    const deactivationPayload = reactive({ ...defaultDeactivationPayload });
    if (props.prospect) {
      deactivationPayload.lead_id = props.prospect.id;
    }

    const inputPlaceholderText = computed(computeInputPlaceholderText);
    const isDisabledStopButton = computed(computeIsDisabledStopButton);

    const isShowingProfileNotPublishListing = props.unit?.active_showing_profile?.type !== Showing.TYPE_PUBLISH_LISTING;
    const shouldShowImpactSection = ref(isShowingProfileNotPublishListing);
    onMounted(handleMount);

    return {
      deactivationPayload,
      stop,
      autocompleteLeads,
      selectLead,
      selectedLead,
      Showing,
      uiFlags,
      isDisabledStopButton,
      inputPlaceholderText,
      impactFetched,
      shouldShowImpactSection,
    };

    async function stop() {
      uiFlags.isStopping = true;
      const isWithShowdigs = deactivationPayload.deactivation_type === Showing.DEACTIVATION_TYPE_RENTED_WITH_SHOWDIGS;
      if (isWithShowdigs) {
        deactivationPayload.deactivation_comment = undefined;
        if (!deactivationPayload.lead_id) {
          deactivationPayload.deactivation_type = Showing.DEACTIVATION_TYPE_RENTED_WITH_SHOWDIGS_UNKNOWN_LEAD;
        }
      } else {
        deactivationPayload.lead_id = undefined;
      }
      try {
        await dispatch('ShowingProfile/deactivate', { deactivationPayload, showingProfileId: unit.last_showing_profile.id });
        context.emit('close', 'success', isWithShowdigs);
      } catch (e) {
        showErrorNotify(context, e);
      }
      uiFlags.isStopping = false;
    }

    async function handleMount() {
      if (unit.last_showing_profile.id && !leads.value.length) {
        const leadsRsponse = await dispatch('ShowingProfile/leads', unit.last_showing_profile.id);
        leads.value = leadsRsponse.map((item) => {
          const timezone = unit.property.timezone;
          return {
            id: item.lead.id,
            name: `${item.lead.full_name}`,
            tour_at: `Toured at ${context.root.$momentUtil(item.tour_at, timezone).toDisplayWithDayFormat()}`,
          };
        });
        leads.value.push({ id: undefined, name: 'Other' });
      }
    }

    async function autocompleteLeads(query, cb) {
      if (!query) {
        cb(leads.value);
      }
      const filteredLeads = leads.value.filter((lead) => lead.name.toLowerCase().includes(query.toLowerCase()));
      cb(filteredLeads);
    }

    function selectLead(lead) {
      deactivationPayload.lead_id = lead.id;
    }

    function computeInputPlaceholderText() {
      if (unit.last_showing_profile.is_open_house) {
        return 'Why do you want to stop showing the Open house on this unit?';
      }
      return 'Why do you want to stop showing this unit?';
    }

    function computeIsDisabledStopButton() {
      if (
        uiFlags.hasNonCancellableTours
        || uiFlags.isStopping
        || uiFlags.isFetchingImpact
        || !deactivationPayload.deactivation_type
      ) {
        return true;
      }
      if (deactivationPayload.deactivation_type && deactivationPayload.deactivation_type === Showing.DEACTIVATION_TYPE_RENTED_WITH_SHOWDIGS) {
        return selectedLead.value === '';
      }
      return false;
    }

    function impactFetched(data) {
      uiFlags.isFetchingImpact = false;
      uiFlags.hasNonCancellableTours = data.tours.hasNoneCancellable;
    }
  },
};
</script>

<style lang="scss" scoped>
.el-select,
.el-input,
.el-autocomplete {
  width: 320px;
}
.lead-name,
.lead-tour-at {
  line-height: 1rem;
}
</style>
<style lang="scss">
#stop-showing-dialog {
  .el-dialog {
    max-width: 545px;
  }
  .deactivation-comment {
    width: 370px;
  }
}
</style>
